import React, { useState } from "react"
import { ReactOnMessageIcon } from "components/Common/Icons/Chat/Conversation/ConversationIcons"
import axios from "axios"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { useSelector } from "react-redux"

import configs from "config"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
const { Url, apiVersion } = configs.client

function ReactOnMessage({ message }) {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const dispatch = useDispatch()

  const [reactPopup, setReactPopup] = useState(false)
  const toggleReactPopup = () => setReactPopup(!reactPopup)
  const reacts = ["👍", "😂", "😡", "🥲", "😍", "😁"]

  const onEmojiClick = emoji => {
    axios
      .patch(
        `${Url}/${apiVersion}/messages/${message._id}/reaction`,
        {
          emoji: `${emoji}`,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Dropdown isOpen={reactPopup} toggle={toggleReactPopup} direction="up">
      <DropdownToggle
        caret
        className="react-on-mesage-toggler"
        style={{ padding: "0" }}
      >
        <ReactOnMessageIcon />
      </DropdownToggle>
      {reactPopup && (
        <DropdownMenu className="d-flex gap-2 react-on-message">
          {reacts.map(emoji => (
            <DropdownItem
              className="react-emoji"
              key={emoji}
              onClick={() => onEmojiClick(emoji)}
            >
              {emoji}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  )
}

export default ReactOnMessage
