import React, { useEffect, useState } from "react"
import TeamsInfo from "./TeamsComponents/TeamsInfo"
import TeamsTable from "./TeamsComponents/TeamsTable"
import AddNewTeam from "./TeamsComponents/AddNewTeam"
import EditTeam from "./TeamsComponents/EditTeam"
import DeleteTeam from "./TeamsComponents/DeleteTeam"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"
import ViewTeam from "./TeamsComponents/ViewTeam"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import UserTeamProfile from "pages/UserProfile/UserTeamProfile"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

export default function Teams() {
  const [isloading, setIsLoading] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)
  const [teams, setTeams] = useState([])
  const [addNewTeam, setAddNewTeam] = useState(false)
  const dispatch = useDispatch()

  const toggleAdd = () => {
    setAddNewTeam(!addNewTeam)
  }
  const [modalDeleteTeam, setModalDeleteTeam] = useState(false)
  const [deleteTeam, setDeleteTeam] = useState(null)
  const toggleDelete = team => {
    setDeleteTeam(team)
    setModalDeleteTeam(!modalDeleteTeam)
  }

  const [modalEditTeam, setModalEditTeam] = useState(false)
  const [editTeam, setEditTeam] = useState(null)
  const toggleEdit = team => {
    setEditTeam(team)
    setModalEditTeam(!modalEditTeam)
  }

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))
  const [searchName, setSearchName] = useState("")

  const fetchTeamsData = () => {
    let filters = []
    if (searchName) {
      filters.push(`name=${searchName}`)
    }
    const filtersQueryString = filters.length > 0 ? `?${filters.join("&")}` : ""

    setIsLoading(true)

    axios
      .get(`${Url}/${apiVersion}/teams${filtersQueryString}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const Allteams = res.data.data
        setTeams(Allteams)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)

        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    fetchTeamsData()
  }, [searchName])

  const [viewTeamComponents, setViewTeamComponents] = useState(false)
  const [selectedTeamComponents, setSelectedTeamComponents] = useState(false)
  const toggleViewTeamComponents = team => {
    setViewTeamComponents(team)
    setSelectedTeamComponents(team)
    setEditTeam(team)
  }
  const toggleCloseView = () => {
    setViewTeamComponents(false)
  }

  const handleEditButtonClick = () => {
    setEditTeam(selectedTeamComponents)
    setModalEditTeam(true)
    setViewTeamComponents(false)
  }
  const handleview = () => {
    setViewTeamComponents(selectedTeamComponents)
    // setSelectedTeamComponents()
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  const [viewSelectedTeam, setViewSelectedTeam] = useState([])
  return (
    <>
      {viewTeamComponents ? (
        <div className=" user-setting-page ">
          {/* <button onClick={handleEditButtonClick}>edit</button> */}
          <ViewTeam
            handleEditButtonClick={handleEditButtonClick}
            toggleEdit={toggleEdit}
            toggleCloseView={toggleCloseView}
            team={selectedTeamComponents}
            setIsLoading={setIsLoading}
            isloading={isloading}
            viewSelectedTeam={viewSelectedTeam}
            setViewSelectedTeam={setViewSelectedTeam}
            setShowEditToast={setShowEditToast}
            fetchTeamsData={fetchTeamsData}
            setViewTeamComponents={setViewTeamComponents}
            toggleViewTeamComponents={toggleViewTeamComponents}
          />
        </div>
      ) : (
        <div className=" user-setting-page ">
          {/* add new team */}
          <div className="d-flex justify-content-between align-items-center users-setting">
            <p>
              Setting <span className="users-setting-span">> Teams</span>
            </p>
            <button onClick={toggleAdd}> + Add New Team</button>
          </div>

          {/* success message */}
          <div
            className="position-relative"
            aria-live="polite"
            aria-atomic="true"
          >
            <div className="success-message">
              <Toast isOpen={showSuccessToast} fade={true}>
                <ToastBody className="success-message-bg">
                  <h5 className="m-0 mb-2">Success</h5>
                  <p className="m-0">Team Added Successfully</p>
                </ToastBody>
              </Toast>
            </div>
          </div>

          {/* Edit Message */}
          <div
            className="position-relative"
            aria-live="polite"
            aria-atomic="true"
          >
            <div className="success-message">
              <Toast isOpen={showEditToast} fade={true}>
                <ToastBody className="edit-message-bg">
                  <h5 className="m-0 mb-2">Edit</h5>
                  <p className="m-0">User edited Successfully</p>
                </ToastBody>
              </Toast>
            </div>
          </div>

          {/* Delete message */}
          <div
            className="position-relative"
            aria-live="polite"
            aria-atomic="true"
          >
            <div className="success-message">
              <Toast isOpen={showDeleteToast} fade={true}>
                <ToastBody className="delete-message-bg">
                  <h5 className="m-0 mb-2">Delete</h5>
                  <p className="m-0">Team Deleted Successfully</p>
                </ToastBody>
              </Toast>
            </div>
          </div>

          <TeamsInfo
            setIsLoading={setIsLoading}
            isloading={isloading}
            teams={teams}
            team={selectedTeamComponents}
            handleview={handleview}
            toggleViewTeamComponents={toggleViewTeamComponents}
          />

          <TeamsTable
            teams={teams.teams}
            setTeams={setTeams}
            isloading={isloading}
            setIsLoading={setIsLoading}
            toggleDelete={toggleDelete}
            toggleEdit={toggleEdit}
            toggleViewTeamComponents={toggleViewTeamComponents}
            setSearchName={setSearchName}
          />

          <AddNewTeam
            handleEditButtonClick={handleEditButtonClick}
            isOpen={addNewTeam}
            toggle={toggleAdd}
            setShowSuccessToast={setShowSuccessToast}
            teams={teams}
            setTeams={setTeams}
            fetchTeamsData={fetchTeamsData}
            setViewTeamComponents={setViewTeamComponents}
            toggleViewTeamComponents={toggleViewTeamComponents}
            toggleEdit={toggleEdit}
            viewSelectedTeam={viewSelectedTeam}
          />
          <EditTeam
            toggle={toggleEdit}
            isOpen={modalEditTeam}
            team={editTeam}
            setShowEditToast={setShowEditToast}
            fetchTeamsData={fetchTeamsData}
            setViewTeamComponents={setViewTeamComponents}
            toggleViewTeamComponents={toggleViewTeamComponents}
            viewSelectedTeam={viewSelectedTeam}
            setViewSelectedTeam={setViewSelectedTeam}
          />

          <DeleteTeam
            team={deleteTeam}
            isOpen={modalDeleteTeam}
            toggle={toggleDelete}
            setTeams={setTeams}
            setShowDeleteToast={setShowDeleteToast}
            fetchTeamsData={fetchTeamsData}
          />
        </div>
      )}
    </>
  )
}
