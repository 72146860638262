import React from "react"

function SingleNoteToolsIcon() {
  return (
    <svg
      width="3"
      height="19"
      viewBox="0 0 3 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="1.43911"
        cy="4.60941"
        r="1.43906"
        transform="rotate(90 1.43911 4.60941)"
        fill="#8D9EB5"
      />
      <circle
        cx="1.43911"
        cy="9.64612"
        r="1.43906"
        transform="rotate(90 1.43911 9.64612)"
        fill="#8D9EB5"
      />
      <circle
        cx="1.43911"
        cy="14.6828"
        r="1.43906"
        transform="rotate(90 1.43911 14.6828)"
        fill="#8D9EB5"
      />
    </svg>
  )
}

export default SingleNoteToolsIcon
