import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListTemplateMessageContent({ chat }) {
  return (
    <>
      <CorrectMessageMark
        fromWho={chat.lastMessage.status}
        sentMessage={chat.lastMessage.sent}
        deliveredMessage={chat.lastMessage.delivered}
        seenMessage={chat.lastMessage.seen}
      />
      {`${chat.lastMessage.template.name
        .replace(/_/g, " ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })} template`}
    </>
  )
}

export default ChatListTemplateMessageContent
