import React from "react"

export default function LogoutPopupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
    >
      <path
        d="M53.4375 59.0625V66.0938C53.4375 67.9586 52.6967 69.747 51.3781 71.0656C50.0595 72.3842 48.2711 73.125 46.4063 73.125H18.2813C16.4164 73.125 14.628 72.3842 13.3094 71.0656C11.9908 69.747 11.25 67.9586 11.25 66.0938V23.9063C11.25 22.0414 11.9908 20.253 13.3094 18.9344C14.628 17.6158 16.4164 16.875 18.2813 16.875H45C48.883 16.875 53.4375 20.0232 53.4375 23.9063V30.9375M64.6875 59.0625L78.75 45L64.6875 30.9375M30.9375 45H75.9375"
        stroke="#6B7889"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
