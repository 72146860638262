import React from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"

export default function Finish({
  step,
  setStep,
  setData,
  setSingleTemplateData,
}) {
  const navigate = useNavigate()

  return (
    <>
      <div className="row justify-content-center finish-page-conatiner finished-animation m-0 d-flex justify-content-center align-items-center">
        <div>
          <div className="text-center d-flex flex-column justify-content-center align-items-center ">
            <div
              style={{
                width: "130px",
                height: "130px",
              }}
              className="step-complete mb-3"
            >
              <i className="fas fa-check complete text-white   fa-10x"></i>
            </div>
            <div>
              <h5
                style={{
                  color: "#2aaa4a",
                  fontSize: "32px",
                  fontWeight: "semibold",
                }}
              >
                Finished
              </h5>
              <p
                style={{
                  color: "#2aaa4a",
                  fontSize: "16px",
                }}
                className="text-muted"
              >
                Your broadcast has been sent successfully
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="d-flex" style={{ gap: "16px 8px" }}>
              <Button
                className="btn-finish-actiontypography btn-finish-action btn-finish-new-broadcast"
                style={{ width: "160px" }}
                onClick={() => {
                  if (step == 3) {
                    setData({
                      type: "",
                      templateName: "",
                      clients: [{}],
                    })

                    setSingleTemplateData(null)
                    setStep(0)
                  } else {
                    navigate("/broadcast")
                  }
                }}
              >
                New broadcast
              </Button>
              <Button
                className="btn-finish-actiontypography btn-finish-action btn-finish-new-broadcast"
                style={{ width: "160px" }}
                onClick={() => {
                  navigate("/broadcast-reports")
                }}
              >
                Reports
              </Button>
            </div>

            <Button
              className="btn-finish-actiontypography text-center btn-finish-action btn-finish-new-broadcast mt-2"
              onClick={() => navigate("/")}
            >
              Go back to home
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
