import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListDocumentMessageContent({
  messageContent,
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div title={messageContent}>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <svg
        viewBox="0 0 13 20"
        height="20"
        width="13"
        preserveAspectRatio="xMidYMid meet"
        version="1.1"
        x="0px"
        y="0px"
        enableBackground="new 0 0 13 20"
      >
        <path
          fill="currentColor"
          d="M10.2,3H2.5C1.7,3,1,3.7,1,4.5v10.1C1,15.3,1.7,16,2.5,16h7.7c0.8,0,1.5-0.7,1.5-1.5v-10 C11.6,3.7,11,3,10.2,3z M7.6,12.7H3.5v-1.3h4.1V12.7z M9.3,10H3.5V8.7h5.8V10z M9.3,7.3H3.5V6h5.8V7.3z"
        ></path>
      </svg>{" "}
      <span>{messageContent}</span>
    </div>
  )
}

export default ChatListDocumentMessageContent
