import React from "react"

function SendButtonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
    >
      <g clipPath="url(#clip0_0_196)">
        <path
          d="M32.8229 14.9141C32.632 14.6194 32.3635 14.3831 32.047 14.231C31.7305 14.079 31.3783 14.0172 31.0289 14.0523L10.8345 16.2396C10.4613 16.2743 10.1067 16.4189 9.81555 16.6551C9.52441 16.8912 9.30978 17.2083 9.19877 17.5664C9.08776 17.9244 9.08535 18.3074 9.19184 18.6668C9.29833 19.0262 9.50895 19.346 9.79709 19.5858L16.7699 25.1452L18.927 33.8225C19.0155 34.163 19.197 34.4722 19.4511 34.7155C19.7053 34.9588 20.0221 35.1266 20.3662 35.2001L20.4949 35.2281C20.869 35.281 21.2504 35.2202 21.5895 35.0536C21.9286 34.8869 22.2097 34.6221 22.3963 34.2936L32.8492 16.9011C33.0335 16.6014 33.1288 16.2556 33.1242 15.9038C33.1195 15.552 33.015 15.2088 32.8229 14.9141ZM11.2464 18.1891L28.8734 16.2794L17.7648 23.4206L11.2464 18.1891ZM20.8984 33.1862L18.8325 25.0814L29.9411 17.9402L20.8984 33.1862Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_196">
          <rect
            width="31.6098"
            height="31.6098"
            fill="white"
            transform="translate(0.963562 17.5567) rotate(-32.735)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SendButtonIcon
