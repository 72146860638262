import StepperComponent from "components/Broadcast/StepperComponent"
import React from "react"
import Finish from "../UploadeFile/Finish"

function FinishComponent({ step, setStep, setData, setSingleTemplateData }) {
  return (
    <div className="steps-page-layout my-auto">
      <div className="stepper-wrapper">
        <StepperComponent step={step} />
      </div>

      <Finish
        step={step}
        setStep={setStep}
        setData={setData}
        setSingleTemplateData={setSingleTemplateData}
      />

      {/* <div className="row justify-content-center">
        <div>
          <div className="text-center">
            <div className="mb-4">
              <i className="mdi mdi-check-circle-outline mdi-300px text-success display-4" />
            </div>
            <div>
              <h5>Finished</h5>
              <p className="text-muted">
                Your broadcast has been sent successfully
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default FinishComponent
