import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const usePreviousRoute = () => {
  const location = useLocation()
  const [previousRoute, setPreviousRoute] = useState("")

  useEffect(() => {
    // console.log("pathname is", location.pathname)
    // Update the previous route when the location changes
    return () => setPreviousRoute(location.pathname)
  }, [location])

  return previousRoute
}

export default usePreviousRoute
