import React, { useState } from "react"

import ChooseWayComponent from "./EnterData/ChooseWayComponent"
import ChooseATemplateComponent from "./EnterData/ChooseATemplateComponent"
import EnterDataTemplatePreviewComponent from "./EnterData/EnterDataTemplatePreviewComponent"
import FinishComponent from "./EnterData/FinishComponent"

import "./Broadcast.css"
import "./UploadeFile/fileSteps.css"

function Broadcast() {
  const [step, setStep] = useState(0)
  const [data, setData] = useState({
    type: "",
    templateName: "",
    clients: [{}],
  })
  const [singleTemplateData, setSingleTemplateData] = useState(null)

  return (
    <div
      className="h-100 d-flex justify-content-center"
      style={{ width: "90%" }}
    >
      {step === 0 && (
        <ChooseWayComponent setStep={setStep} data={data} setData={setData} />
      )}

      {step === 1 && data.type === "manual" && (
        <ChooseATemplateComponent
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          singleTemplateData={singleTemplateData}
          setSingleTemplateData={setSingleTemplateData}
        />
      )}

      {step === 2 && data.type === "manual" && (
        <EnterDataTemplatePreviewComponent
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          singleTemplateData={singleTemplateData}
          setSingleTemplateData={setSingleTemplateData}
        />
      )}

      {step === 3 && data.type === "manual" && (
        <FinishComponent
          step={step}
          setStep={setStep}
          setData={setData}
          setSingleTemplateData={setSingleTemplateData}
        />
      )}
    </div>
  )
}

export default Broadcast
