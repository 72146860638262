import React, { useState } from "react"

import FieldTypes from "./FieldTypes"
import DropdownField from "./FieldTypes/DropdownField"

function AddTicketField() {
  const [step, setStep] = useState("fieldTypes")
  const [fieldTypeData, setFieldTypeData] = useState({})

  return (
    <>
      {step === "fieldTypes" && (
        <FieldTypes
          setStep={setStep}
          fieldTypeData={fieldTypeData}
          setFieldTypeData={setFieldTypeData}
        />
      )}

      {step !== "fieldTypes" && (
        <DropdownField
          step={step}
          fieldTypeData={fieldTypeData}
          setStep={setStep}
        />
      )}
    </>
  )
}

export default AddTicketField
