import React from "react"

function SecondaryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M9.47047 0.0588379C4.5342 0.0588379 0.529297 4.06374 0.529297 9.00001C0.529297 13.9363 4.5342 17.9412 9.47047 17.9412C14.4067 17.9412 18.4116 13.9363 18.4116 9.00001C18.4116 4.06374 14.4067 0.0588379 9.47047 0.0588379Z"
        fill="#636678"
      />
      <path
        d="M9.4707 3.03931V9.74519H13.9413"
        stroke="#F5F6F7"
        strokeWidth="1.02273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SecondaryIcon
