import React from "react"

export default function ViewConversations({ conv }) {
  if (!conv || (!conv?.bodyOn && !conv?.bodyOff)) {
    <div className="Conversations-details m-2 mb-3 ">
      return <p>No data found</p>
    </div>
  }
  return (
    <>
      <div className="Conversations-details m-2 mb-3 ">
        <div className="ans-details  ">
          <h5 className="m-0  fw-bold text-capitalize">
            Inside business hour
          </h5>
          <p className="m-0">{conv?.bodyOn}</p>
        </div>
      </div>
      <div className="Conversations-details m-2 mb-3 ">
        <div className="ans-details  ">
          <h5 className="m-0 fw-bold text-capitalize">
            outside business hour
          </h5>
          <p className="m-0">{conv?.bodyOff}</p>
        </div>
      </div>
    </>
  )
}
