import React from "react"

function ChatToolsIcon() {
  return (
    <svg
      width="4"
      height="26"
      viewBox="0 0 4 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2"
        cy="2.5"
        r="2"
        transform="rotate(90 2 2.5)"
        fill="#8D9EB5"
      />
      <circle
        cx="2"
        cy="9.5"
        r="2"
        transform="rotate(90 2 9.5)"
        fill="#8D9EB5"
      />
      <circle
        cx="2"
        cy="16.5"
        r="2"
        transform="rotate(90 2 16.5)"
        fill="#8D9EB5"
      />
      <circle
        cx="2"
        cy="23.5"
        r="2"
        transform="rotate(90 2 23.5)"
        fill="#8D9EB5"
      />
    </svg>
  )
}

export default ChatToolsIcon
