import React, { useState } from "react"
import {
  BubbleTipIcon,
  ReplyMessageIcon,
  ReplyTeamMemberMessageIcon,
  TeamMessageBubbleTipIcon,
} from "components/Common/Icons/Chat/Conversation/ConversationIcons"

import DefaultPhoto from "../../../assets/images/default.jpg"
import {
  convertData,
  filterMessageType,
  formatTimestamp,
} from "helpers/helper_functions"

import ReactOnMessage from "./ReactOnMessage"
import ReplyMessageComponent from "./SingleMessageTypes/ReplyMessageComponent"
import CorrectIconMark from "./CorrectIconMark"

function SingleMessage({
  message,
  nextMessage,
  clientNumber,
  setSelectedReplyMessage,
}) {
  const setMessageTime = message => {
    let messageTime

    if (message.status !== "pending" && message.status !== "failed") {
      messageTime = message.received ? message.received : message.sent
      return messageTime
    } else if (message.status === "failed") {
      messageTime = message.updatedAt
      return messageTime
    }

    return null
  }

  const checkReactions = () => {
    if (message.clientReaction && message.userReaction) {
      if (message.clientReaction.emoji == message.userReaction.emoji)
        return `${message.clientReaction.emoji}2`
      return `${message.clientReaction.emoji}${message.userReaction.emoji}`
    }
    if (message.clientReaction) return message.clientReaction.emoji
    if (message.userReaction) return message.userReaction.emoji

    return ""
  }

  const handleReplyMessage = message => {
    if (!message.reply) return null

    return <ReplyMessageComponent message={message} />
  }

  return (
    <>
      {message.actionType ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-inline-flex flex-column justify-content-center align-self-center message message-action">
            <p className="mb-0 align-self-start message-action-body">
              {(() => {
                switch (message.actionType) {
                  case "takeOwnership":
                    return `${message.takeOwnership.to?.firstName} ${
                      message.takeOwnership.to?.lastName
                    } took the chat's ownership from ${
                      message.takeOwnership.from?.firstName
                    } ${message.takeOwnership.from?.lastName}  ${convertData(
                      message.createdAt
                    )}`

                  case "archive":
                    return message.archive === "bot" ||
                      message.archive === "auto"
                      ? `The bot archived the chat ${convertData(
                          message.createdAt
                        )}`
                      : `${message.user?.firstName} ${
                          message.user?.lastName
                        } archived the chat ${convertData(message.createdAt)}`

                  case "start":
                    return `${message.start.firstName} ${
                      message.start.lastName
                    } started the chat  ${convertData(message.createdAt)}`

                  case "transfer":
                    switch (message.transfer.type) {
                      case "bot":
                        return `The bot transferred the chat to ${
                          message.transfer.to?.firstName
                        } ${message.transfer.to?.lastName}  ${convertData(
                          message.createdAt
                        )}`

                      case "user":
                        return message.user._id === message.transfer.from?._id
                          ? `${message.transfer.from?.firstName} ${message.transfer.from?.lastName} transferred the chat to ${message.transfer.to?.firstName} ${message.transfer.to?.lastName}`
                          : `${message.user.firstName} ${
                              message.user.lastName
                            } transferred the chat from ${
                              message.transfer.from?.firstName
                            } ${message.transfer.from?.lastName} to ${
                              message.transfer.to?.firstName
                            } ${message.transfer.to?.lastName}  ${convertData(
                              message.createdAt
                            )}`

                      case "team":
                        return message.user._id === message.transfer.from?._id
                          ? `${message.transfer.from?.firstName} ${message.transfer.from?.lastName} (${message.transfer.fromTeam?.name}) transferred the chat to ${message.transfer.to?.firstName} ${message.transfer.to?.lastName} (${message.transfer.toTeam?.name})`
                          : `${message.user.firstName} ${
                              message.user.lastName
                            } transferred the chat from ${
                              message.transfer.from?.firstName
                            } ${message.transfer.from?.lastName} (${
                              message.transfer.fromTeam?.name
                            }) to ${message.transfer.to?.firstName} ${
                              message.transfer.to?.lastName
                            } (${message.transfer.toTeam?.name})  ${convertData(
                              message.createdAt
                            )}`

                      default:
                        return `actions *${message.transfer.type}*`
                    }

                  case "botReceive":
                    return `The bot has received the chat ${convertData(
                      message.createdAt
                    )}`

                  default:
                    return `other action type has been done *${message.actionType}*`
                }
              })()}
            </p>
          </div>
        </div>
      ) : message?.from === clientNumber ? (
        <div className="d-flex align-items-center gap-2 client-message-container message-actions-container">
          <div className="d-inline-flex flex-column align-self-start message position-relative">
            {handleReplyMessage(message)}

            <p className="mb-0 align-self-start message-client-body">
              {filterMessageType(message)}
            </p>
            <span className="align-self-end message-client-time">
              {formatTimestamp(setMessageTime(message))}
            </span>

            {message.clientReaction || message.userReaction ? (
              <span className="reacts-on-client-message">
                {checkReactions()}
              </span>
            ) : null}

            {message.from !== nextMessage.from && <BubbleTipIcon />}
          </div>

          <div className="d-flex align-items-center gap-1 message-actions">
            <span onClick={() => setSelectedReplyMessage(message)}>
              <ReplyMessageIcon />
            </span>

            <ReactOnMessage message={message} />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row align-self-end team-message-container message-actions-container">
          <div className="d-flex align-items-center gap-1 message-actions">
            <ReactOnMessage message={message} />

            <span onClick={() => setSelectedReplyMessage(message)}>
              <ReplyTeamMemberMessageIcon />
            </span>
          </div>

          <div className="d-inline-flex flex-column gap-1 align-self-end message team-message">
            <div className="d-flex align-items-center gap-2 align-self-end">
              {message.user?.firstName.toLowerCase().includes("system") ||
              message.user?.firstName.toLowerCase().includes("bot") ||
              message.user?.lastName.toLowerCase().includes("system") ||
              message.user?.lastName.toLowerCase().includes("bot") ? (
                ""
              ) : (
                <span className="team-member-role">
                  {message.session?.team?.name
                    ? message.session?.team?.name
                    : ""}
                </span>
              )}
              <span className="team-member-name">{`${
                message.user?.firstName.toLowerCase().includes("system") ||
                message.user?.firstName.toLowerCase().includes("bot") ||
                message.user?.lastName.toLowerCase().includes("system") ||
                message.user?.lastName.toLowerCase().includes("bot")
                  ? "Bot ~"
                  : `${message.user?.firstName} ${message.user?.lastName}`
              }`}</span>
            </div>
            <div className="d-flex flex-column w-100">
              {handleReplyMessage(message)}

              <p className="mb-0 align-self-start message-member-body">
                {filterMessageType(message)}
              </p>
              <span className="align-self-end message-member-time">
                {formatTimestamp(setMessageTime(message))}{" "}
                {message.status === "failed" ? (
                  <i className="mdi mdi-clock-time-nine text-danger align-middle"></i>
                ) : (
                  <CorrectIconMark
                    fromWho={message.status}
                    sentMessage={message.sent}
                    deliveredMessage={message.delivered}
                    seenMessage={message.seen}
                  />
                )}
              </span>

              {message.clientReaction || message.userReaction ? (
                <span className="reacts-on-team-member-message">
                  {checkReactions()}
                </span>
              ) : null}

              {message.from !== nextMessage.from && (
                <TeamMessageBubbleTipIcon />
              )}
            </div>
          </div>

          <img
            className={
              message.from !== nextMessage.from ? "user-img" : "hide-img"
            }
            src={DefaultPhoto}
          />
        </div>
      )}
    </>
  )
}

export default SingleMessage
