import React, { useState } from "react"
import { Input } from "reactstrap"

import Addicon from "components/Common/Icons/HomeIcons/Addicon"
import CreateNewChat from "../CreateNewChat"

function SearchAddContainer({ setSearchInChatsValue }) {
  const [createChatModal, setCreateChatModal] = useState(false)
  const toggleCreateChatModal = () => setCreateChatModal(!createChatModal)

  return (
    <div className="d-flex align-items-center search-add-container">
      <Input
        type="search"
        placeholder="Search"
        onChange={e => setSearchInChatsValue(e.target.value)}
      />

      <span
        className="d-flex justify-content-center align-items-center"
        onClick={toggleCreateChatModal}
      >
        <Addicon />
      </span>

      <CreateNewChat
        createChatModal={createChatModal}
        toggleCreateChatModal={toggleCreateChatModal}
      />
    </div>
  )
}

export default SearchAddContainer
