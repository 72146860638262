import React, { useEffect } from "react"
import { FormGroup, Input, Label } from "reactstrap"

function AddTicketClientInfo({
  handleInputChange,
  clientName,
  clientEmail,
  clientPhone,
  errors,
}) {
  useEffect(() => {}, [clientName])

  return (
    <div className="p-2 rounded-2 border shadow-sm mt-4">
      <h5 className="">Client Details</h5>

      <FormGroup>
        <Label for="clientName">
          Name <span className="text-danger">*</span>
        </Label>
        <Input
          type="text"
          id="clientName"
          placeholder="Enter client name"
          defaultValue={clientName ? clientName : ""}
          onChange={handleInputChange}
        />
        {errors.clientName && (
          <span className="text-danger">{errors.clientName}</span>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="clientPhone">
          Phone Number <span className="text-danger">*</span>
        </Label>
        <Input
          type="text"
          id="clientPhone"
          defaultValue={clientPhone ? `966${clientPhone}` : ""}
          placeholder="Enter phone number"
          onChange={handleInputChange}
        />
        {errors.clientPhone && (
          <span className="text-danger">{errors.clientPhone}</span>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="clientEmail">
          Email <span className="text-danger">*</span>
        </Label>
        <Input
          type="email"
          id="clientEmail"
          defaultValue={clientEmail ? clientEmail : ""}
          placeholder="Enter client email"
          onChange={handleInputChange}
        />
        {errors.clientEmail && (
          <span className="text-danger">{errors.clientEmail}</span>
        )}
      </FormGroup>
    </div>
  )
}

export default AddTicketClientInfo
