import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

function ResetChangeNameModal({
  resetContactNameModal,
  toggleResetContactNameModal,
  changeContactNameMethod,
}) {
  return (
    <Modal
      isOpen={resetContactNameModal}
      toggle={toggleResetContactNameModal}
      centered
    >
      <ModalHeader toggle={toggleResetContactNameModal}>
        Reset Contact Name
      </ModalHeader>
      <ModalBody>
        Do you want to reset the contact name to its default value?
      </ModalBody>
      <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
        <Button
          color="secondary"
          onClick={() => changeContactNameMethod("reset")}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Reset
        </Button>{" "}
        <Button
          color="secondary"
          outline
          onClick={toggleResetContactNameModal}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ResetChangeNameModal
