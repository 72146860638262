import React from "react"

function InboxIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#240;&#159;&#166;&#134; icon &#34;message&#34;">
        <g id="Group">
          <path
            id="Vector"
            d="M22 7.28143C22 3.09381 19.9672 1 15.9017 1H7.77064C3.70511 1 1.67234 3.09381 1.67234 7.28143V12.516C1.67234 16.7036 3.70511 18.7974 7.77064 18.7974H8.27883C8.59391 18.7974 8.89882 18.9544 9.09194 19.2162L10.6165 21.31C11.2873 22.2312 12.385 22.2312 13.0558 21.31L14.5804 19.2162C14.743 18.9858 15.1191 18.7974 15.3935 18.7974H15.9017C19.9672 18.7974 22 17.7505 22 12.516V11.4691"
            stroke="#6B7889"
            strokeWidth="1.36722"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="Group_2">
            <path
              id="Vector_2"
              d="M7.77393 10.4224H7.76479"
              stroke="#6B7889"
              strokeWidth="2.05082"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M11.8418 10.4224H11.8326"
              stroke="#6B7889"
              strokeWidth="2.05082"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_4"
              d="M15.9072 10.4224H15.898"
              stroke="#6B7889"
              strokeWidth="2.05082"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default InboxIcon
