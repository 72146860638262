import LogoutPopupIcon from "components/Common/Icons/SidebarIcons/LogoutPopupIcon"
import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

export default function LogoutPop({ isOpen, toggle, handleLogout }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="text-center logout-popup "
    >
      <ModalHeader toggle={toggle} className="border-0"></ModalHeader>
      <ModalBody>
        <div>
          <LogoutPopupIcon />
          <h4 className="m-0 mt-3 mb-4">Are you sure you want to log out?</h4>
        </div>

        <div className=" d-flex gap-2 justify-content-center action-logout">
          <Button
            className="col-3 rounded-3 m-0 p-0 p-1"
            onClick={() => {
              handleLogout()
              toggle()
            }}
          >
            Log out
          </Button>

          <Button
            className="col-3 bg-transparent cancel-logout rounded-3 m-0 p-1 "
            onClick={toggle}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
