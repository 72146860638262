import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import PositiveIcon from "components/Common/Icons/TicketDashboard/PositiveIcon"
import NegativeIcon from "components/Common/Icons/TicketDashboard/NegativeIcon"
import NeutralIcon from "../../../components/Common/Icons/TicketDashboard/NeutralIcon"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import moment from 'moment'

export default function CustomerSatisfaction({ startDate,
    endDate, }) {
    const [isloading, setIsLoading] = useState(false)
    const [ticketRating, setTicketRating] = useState([])

    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))
    const dispatch = useDispatch()

    const fetchAllTicketRating = async () => {
        try {
            setIsLoading(true)
            const params = {
                startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
            };
            axios
                .get(`${Url}/${apiVersion}/ticket-dashboard/rating`, {
                    headers: { Authorization: `Bearer ${access}` },
                    params: params,
                })
                .then(response => {
                    const AllTicketRating = response.data.data
                    console.log("All Ticket Rating", response.data.data)
                    setTicketRating(AllTicketRating)
                    setIsLoading(false)
                })
                .catch(err => {
                    if (err.response?.status == 401 || err.response?.status == 0) {
                        dispatch(unAuthUser())
                        // UnAuthorized()
                    } else {
                        console.log(err)
                    }
                })
        } catch (error) {
            console.error("Unexpected error:", error)
        }
    }
    useEffect(() => {
        fetchAllTicketRating()
    }, [startDate, endDate]);

    const negativePercentage = (
        (Math.round(ticketRating.Negative) / ticketRating.responsesReceived) *
        100
    ).toFixed(2)
    const NeutralPercentage = (
        (Math.round(ticketRating.Neutral) / ticketRating.responsesReceived) *
        100
    ).toFixed(2)
    const posttivePercentage = (
        (Math.round(ticketRating.Positive) / ticketRating.responsesReceived) *
        100
    ).toFixed(2)

    if (!authorized) {
        return <UnAuthorizedPopup />
    }

    return (
        <>
            {isloading ? (
                <div className="loading d-flex justify-content-center align-items-center mb-2 p-3">
                    <div className="spinner-border d-flex justify-content-center align-items-center"></div>
                </div>
            ) : ticketRating ? (
                <Container className="d-flex flex-column gap-3 justify-content-between p-2 ">
                    <Row className="mb-2 py-3 heading-border">
                        <Col className="d-flex flex-column justify-content-center ">
                            <p className="ticket-overview-item-heading  mb-2">
                                Responses Recevied
                            </p>
                            <span className="customer-satisfaction-details">{ticketRating.responsesReceived} Responses</span>
                        </Col>

                        <Col className="d-flex flex-column flex-grow-0  justify-content-center align-items-start">
                            <Row className="mb-3">
                                <Col className="d-flex gap-3">
                                    <div>
                                        <div className="">
                                            <PositiveIcon />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="m-0 ticket-overview-item-heading ">Positive</p>
                                        <span className="customer-satisfaction-details">{posttivePercentage > 0 ? posttivePercentage : 0}%</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="satisfaction-details-bar">
                                    <div
                                        style={{ width: `${posttivePercentage}%` }}
                                        className="progress-bar progress-bar-positive"
                                    ></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mb-2 d-flex  ">
                        <Col className="d-flex flex-column  justify-content-center align-items-start">
                            <Row className="mb-3">
                                <Col className="d-flex gap-3">
                                    <div>
                                        <div className="">
                                            <NegativeIcon />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="m-0 ticket-overview-item-heading ">Negative</p>
                                        <span className="customer-satisfaction-details">{negativePercentage > 0 ? negativePercentage : 0}%</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="satisfaction-details-bar">
                                    <div
                                        style={{ width: `${negativePercentage}%` }}
                                        className="progress-bar progress-bar-negative"
                                    ></div>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="d-flex flex-column  flex-grow-0 align-items-start">
                            <Row className="mb-3">
                                <Col className="d-flex gap-3">
                                    <div>
                                        <div className="">
                                            <NeutralIcon />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="m-0 ticket-overview-item-heading ">Neutral</p>
                                        <span className="customer-satisfaction-details">{NeutralPercentage > 0 ? NeutralPercentage : 0}%</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="satisfaction-details-bar">
                                    <div style={{ width: `${NeutralPercentage}%` }} className="progress-bar progress-bar-neutral"></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <p className="d-flex justify-content-center align-items-center mb-2 fw-bold p-3 fs-5 ">No data available </p>
            )}
        </>
    )
}
