import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { updateSelectedTeams } from "store/actions"

import configs from "../../../config"
const { Url, apiVersion } = configs.client

function AdminTeamsDropdown({ teams, selectedTeams, setSelectedTeams }) {
  const { userData, access } = useSelector(state => ({
    userData: state.Login.userData,
    access: state.Login.userAccess,
  }))

  const dispatch = useDispatch()

  const [drp_secondary_sm, setDrp_secondary_sm] = useState(false)

  const handleTeamChange = team => {
    setSelectedTeams(prevTeams =>
      prevTeams.includes(team)
        ? prevTeams.filter(item => item !== team)
        : [...prevTeams, team]
    )
  }

  const handleSelectAllTeams = () => {
    setSelectedTeams(teams.map(team => team._id))
  }

  const handleDeselectAllTeams = () => {
    setSelectedTeams([])
  }

  const handleUserTeams = () => {
    if (userData.role !== "admin") {
      setSelectedTeams([userData.team])
    }
  }

  useEffect(() => {
    if (selectedTeams.length === 0 && teams.length > 0) {
      handleSelectAllTeams()
    }

    // dispatch(updateSelectedTeams(selectedTeams))
  }, [selectedTeams, teams])

  const handleToggle = () => setDrp_secondary_sm(!drp_secondary_sm)

  const isAllTeamsSelected = selectedTeams.length === teams.length

  return (
    <Dropdown
      isOpen={drp_secondary_sm}
      toggle={handleToggle}
      className="dropdown-select-teams"
    >
      <DropdownToggle
        caret
        color="secondary"
        className="btn btn-secondary btn-sm font-size-14"
      >
        Select Teams &nbsp; <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            isAllTeamsSelected
              ? handleDeselectAllTeams()
              : handleSelectAllTeams()
          }}
        >
          <Label check>
            <Input
              type="checkbox"
              id="all-teams"
              checked={isAllTeamsSelected}
              readOnly
            />
            {" All Teams"}
          </Label>
        </DropdownItem>

        <DropdownItem divider />
        {teams.map(team => (
          <DropdownItem
            key={team._id}
            onClick={() => handleTeamChange(team._id)}
          >
            <Label check>
              <Input
                type="checkbox"
                id={`checkbox-${team._id}`}
                checked={selectedTeams.includes(team._id)}
                readOnly
              />
              {` ${team.name}`}
            </Label>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default AdminTeamsDropdown
