import React from "react"

function TooLateIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.88 0C3.08167 0 0 3.08167 0 6.88C0 10.6783 3.08167 13.76 6.88 13.76C10.6783 13.76 13.76 10.6783 13.76 6.88C13.76 3.08167 10.6783 0 6.88 0Z"
          fill="#DF4128"
        />
        <path
          d="M6.87988 2.29321V7.45321H10.3199"
          stroke="#F5F6F7"
          strokeWidth="0.781818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default TooLateIcon
