import React from "react"

function DangerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M9.52907 0.0588379C4.59279 0.0588379 0.587891 4.06374 0.587891 9.00001C0.587891 13.9363 4.59279 17.9412 9.52907 17.9412C14.4653 17.9412 18.4702 13.9363 18.4702 9.00001C18.4702 4.06374 14.4653 0.0588379 9.52907 0.0588379Z"
        fill="#F3A82D"
      />
      <path
        d="M9.5293 3.03931V9.74519H13.9999"
        stroke="#F5F6F7"
        strokeWidth="1.02273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DangerIcon
