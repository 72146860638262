import React, { useEffect, useState } from "react"
import axios from "axios"
import SingleNote from "./SingleNote"

import configs from "../../../config"
const { Url, apiVersion } = configs.client

import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import CustomSpinner from "components/Common/CustomSpinner"
import CreateNewNote from "./CreateNewNote"

import NoNotesFound from "./../../../components/Common/Icons/Chat/Notes/no-notes-found.png"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

function AllNotes() {
  let { access, userData, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    userData: state.Login.userData,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [notes, setNotes] = useState([])
  const [noNotesFound, setNoNotesFound] = useState(false)
  const [createNewNoteModal, setCreateNoteModal] = useState(false)
  const toggleCreateNewNoteModal = () => setCreateNoteModal(!createNewNoteModal)

  const fetchNotesData = () => {
    setLoading(true)
    setNoNotesFound(false)

    axios
      .get(`${Url}/${apiVersion}/chats/${params.id}/notes`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setNotes(res.data.data.notes.reverse())
        res.data.data.notes.length === 0 && setNoNotesFound(true)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setNoNotesFound(true)

        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    fetchNotesData()
  }, [params.id])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="d-flex flex-column flex-grow-1 pt-1 tab-content-notes">
      {loading ? (
        <div className="w-100 h-100 d-flex align-items-center">
          <CustomSpinner />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center notes-filters-container">
            <div className="d-flex notes-filters">
              {/* <div className="active-filter">All</div>
              <div>Recently</div> */}
            </div>

            <div
              className="d-flex justify-content-between align-items-center btn btn-secondary py-1 px-2"
              onClick={() => toggleCreateNewNoteModal()}
            >
              <i className="bx bx-plus"></i>
              <span>Add Note</span>

              <CreateNewNote
                access={access}
                params={params}
                fetchNotesData={fetchNotesData}
                createNewNoteModal={createNewNoteModal}
                toggleCreateNewNoteModal={toggleCreateNewNoteModal}
              />
            </div>
          </div>

          <div className="d-flex flex-column notes-container">
            {noNotesFound ? (
              <div className="align-self-center d-flex flex-column justify-content-center mw-50 my-auto">
                <img
                  className="mx-auto"
                  style={{ height: "10.8125rem" }}
                  src={NoNotesFound}
                />
                <h6 className="mx-auto font-size-20">No Notes Yet!</h6>
                <span className="mx-auto font-size-16 text-muted">
                  Start typing your notes by the button add new note
                </span>
              </div>
            ) : (
              notes.map(note => <SingleNote key={note._id} note={note} />)
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default AllNotes
