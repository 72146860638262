import React from "react"
import { Container, Row } from "reactstrap"

export default function TemplateFooter({ selectedTemplateData }) {
  return (
    <Container>
      {selectedTemplateData.components.map((component, idx) => {
        if (component.type == "FOOTER")
          return (
            <Row key={component.text}>
              <p
                style={{
                  marginTop:
                    selectedTemplateData?.language == "ar" ? "" : "10px",
                }}
                className="footer-temp"
              >
                {component.text}
              </p>
            </Row>
          )
      })}
    </Container>
  )
}
