import React, { useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap"
import { Col, Container, Row } from "reactstrap"

export default function ChoseColumnNumber({
  selectNumber,
  setSelectedNumber,
  columns,
  selectedOptions,
  selectedColumnHeader,
  selectFileColumn,
  columnNumberRequired,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

  const handleSelectedNumbersColumns = option => {
    setSelectedNumber(option)
    toggleDropdown() // Close dropdown after selecting an item
  }

  return (
    <Container
      style={{
        backgroundColor: "#f5f5f9",
        boxShadow: "0px 0px 4px 0px #00000000",
      }}
      className={`d-flex justify-content-center flex-column p-2 rounded-3 column-number ${
        columnNumberRequired && !selectNumber ? "error-column-number" : ""
      }`}
    >
      {/* Input client number */}
      <Row md={12} className="d-flex justify-content-start align-items-start">
        <p className="m-0 mb-2 text-left">Choose the customer numbers column</p>
      </Row>

      <Container className="input-group gap-2 input-group-sm my-1 d-flex justify-content-between mt-1 mb-1">
        <Col
          style={{
            color: "#2a3042",
            borderRadius: "5px",
            fontWeight: "bold",
          }}
          className="bg-white d-flex justify-content-center align-items-center col-2"
          id="inputGroup-sizing-sm"
        >
          Number
        </Col>

        <Col>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="w-100"
          >
            <DropdownToggle
              className="w-100 dropdown-toggle-temp-preview-var border rounded-3"
              style={{
                background: "white",
                margin: "0",
                direction: "ltr",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                }}
                className="d-flex justify-content-between align-items-center p-1"
              >
                <p
                  style={{
                    color: selectNumber ? "black" : "#ACB4BF",
                  }}
                  className="mb-0"
                >
                  {selectNumber || "Choose Number column"}
                </p>
                <i
                  style={{
                    color: "#ACB4BF",
                  }}
                  className="fas fa-chevron-down"
                ></i>
              </div>
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {columns.map(column => (
                <DropdownItem
                  onClick={() => handleSelectedNumbersColumns(column)}
                  className="DropdownItem"
                  key={column}
                  disabled={
                    selectedOptions.includes(column) ||
                    selectedColumnHeader.includes(column) ||
                    (selectFileColumn && selectFileColumn.includes(column))
                  }
                >
                  {column}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Container>
    </Container>
  )
}
