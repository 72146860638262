import React from "react"

function KeyboardIcon() {
  return (
    <svg
      className="svg"
      viewBox="0 0 229 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="86.5" y="144.5" width="89" height="10" fill="#F5F5F9" />
      <rect x="88.5" y="75" width="16" height="10" fill="#F5F5F9" />
      <rect x="18.5" y="109" width="16" height="10" fill="#F5F5F9" />
      <path
        d="M42.5 179.5H217.5C223.023 179.5 227.5 175.023 227.5 169.5V67.5C227.5 61.9772 223.023 57.5 217.5 57.5H12C6.47716 57.5 2 61.9772 2 67.5V172C2 176.142 5.35786 179.5 9.5 179.5V179.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="18"
        y="75"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="53"
        y="75"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="88"
        y="75"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="123"
        y="75"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="158"
        y="75"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="193"
        y="75"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="193"
        y="110"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="193"
        y="145"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="158"
        y="110"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="123"
        y="110"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="88"
        y="110"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="53"
        y="110"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="53"
        y="145"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="18"
        y="110"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="18"
        y="145"
        width="17"
        height="17"
        rx="3.5"
        stroke="#2A3042"
        strokeWidth="3"
      />
      <rect
        x="88"
        y="145"
        width="87"
        height="17"
        rx="3.5"
        stroke="#2AAA4A"
        strokeWidth="3"
      />
      <path
        d="M115.5 57.5V41.5C115.5 35.9772 119.977 31.5 125.5 31.5H211.5C217.023 31.5 221.5 27.0228 221.5 21.5V0"
        stroke="#2A3042"
        strokeWidth="3"
      />
    </svg>
  )
}

export default KeyboardIcon
