import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListInteractiveMessageContent({
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <span className="text-primary">Bot message</span>
    </div>
  )
}

export default ChatListInteractiveMessageContent
