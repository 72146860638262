import React from 'react'

function UploadPCIcon() {
  return (
    <svg
      className="svg"
      viewBox="0 0 289 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="63.6501"
        y="65.2717"
        width="161.573"
        height="119.14"
        rx="16.3205"
        fill="#F5F5F9"
      />
      <path d="M0 222.765H288.058" stroke="black" strokeWidth="3" />
      <path
        d="M48.9616 190.94V61.1916C48.9616 54.8821 54.0765 49.7672 60.386 49.7672H79.9706"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M241.544 199.1V61.1916C241.544 54.8821 236.429 49.7672 230.12 49.7672H210.535"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M179.5 38.4999C196.3 45.6999 189.833 59.4999 184.5 65.4999H173V69.9999H116C98.8 73.5999 90.1666 61.8332 88 55.4999C85.6 35.0999 100.333 30.6666 108 30.9999C106 18.5999 112.5 10.1666 116 7.49988C136 -6.90012 149.333 6.49988 153.5 14.9999L149.5 17.9999L148 23.9999C152.048 23.6304 154.27 23.5121 156 27.4999C170 21.4999 177.5 32.3332 179.5 38.4999Z"
        fill="#F5F5F9"
      />
      <path
        d="M147.512 25.284L147.475 25.3253L147.084 25.2087C146.096 24.914 145.532 23.8746 145.827 22.8851L147.512 25.284ZM147.512 25.284C148.36 25.3336 149.158 24.7957 149.409 23.9497C151.334 17.4807 157.403 12.959 164.161 12.959C165.193 12.959 166.03 12.1223 166.03 11.0905C166.03 10.0587 165.193 9.22196 164.161 9.22196C155.472 9.22196 148.172 15.0072 145.827 22.8847L147.512 25.284Z"
        fill="#2A3042"
        stroke="#F9FFF9"
        strokeWidth="1.27159"
      />
      <path
        d="M142.797 88.4904C142.797 89.6168 143.71 90.5302 144.836 90.5302C145.962 90.5302 146.876 89.618 146.876 88.4904V51.987C146.876 50.8607 145.963 49.9473 144.836 49.9473C143.71 49.9473 142.797 50.8607 142.797 51.987V88.4904Z"
        fill="#2AAA4A"
        stroke="#2AAA4A"
        strokeWidth="1.27159"
      />
      <path
        d="M144.836 54.8755L135.047 64.6644C134.251 65.462 132.958 65.4614 132.162 64.6648C131.365 63.868 131.365 62.5769 132.162 61.78L143.393 50.5488C144.189 49.7512 145.481 49.7518 146.278 50.5484L157.51 61.78C158.306 62.5767 158.306 63.8673 157.51 64.6642C157.111 65.0643 156.587 65.2622 156.067 65.2622L144.836 54.8755ZM144.836 54.8755L154.625 64.6648C155.023 65.0626 155.546 65.2622 156.067 65.2622L144.836 54.8755Z"
        fill="#2AAA4A"
        stroke="#2AAA4A"
        strokeWidth="1.27159"
      />
      <path
        d="M181.419 71.4942H174.023C173.342 71.4942 172.79 70.9422 172.79 70.2615C172.79 69.5809 173.342 69.0288 174.023 69.0288H181.419C191.614 69.0288 199.91 60.7334 199.91 50.538C199.91 40.3426 191.614 32.0473 181.419 32.0473H181.241C180.884 32.0473 180.544 31.8923 180.309 31.622C180.075 31.3517 179.97 30.9933 180.021 30.6393C180.131 29.8715 180.186 29.1002 180.186 28.3491C180.186 19.5132 172.997 12.3237 164.161 12.3237C160.723 12.3237 157.445 13.3979 154.68 15.431C154.073 15.8775 153.21 15.6793 152.858 15.011C145.028 0.100481 124.576 -1.90185 113.973 11.069C109.506 16.5334 107.751 23.6418 109.157 30.5698C109.312 31.335 108.727 32.0482 107.949 32.0482H107.455C97.2598 32.0482 88.9644 40.3436 88.9644 50.539C88.9644 60.7343 97.2598 69.0297 107.455 69.0297H114.851C115.532 69.0297 116.084 69.5818 116.084 70.2624C116.084 70.9431 115.532 71.4952 114.851 71.4952H107.455C95.9001 71.4952 86.4988 62.0939 86.4988 50.5389C86.4988 39.308 95.3796 30.1118 106.487 29.6047C105.444 22.417 107.439 15.1668 112.064 9.50876C123.416 -4.37953 145.172 -2.82284 154.369 12.6636C157.303 10.8242 160.66 9.85927 164.16 9.85927C174.866 9.85927 183.349 18.9709 182.606 29.6162C193.612 30.2334 202.375 39.381 202.375 50.538C202.375 62.0939 192.974 71.4942 181.419 71.4942Z"
        fill="#2A3042"
      />
      <rect
        x="76.7065"
        y="117.497"
        width="128.932"
        height="17.9526"
        rx="8.16027"
        fill="white"
      />
      <rect
        x="76.7065"
        y="117.497"
        width="60.386"
        height="17.9526"
        rx="8.16027"
        fill="#2A3042"
      />
      <path
        d="M79.9706 148.507H199.927"
        stroke="#2A3042"
        strokeWidth="3.26411"
        strokeLinecap="round"
      />
      <path
        d="M82.4187 166.459H158.309"
        stroke="#2AAA4A"
        strokeWidth="3.26411"
        strokeLinecap="round"
      />
      <path
        d="M253.784 213.789H32.6411C37.2108 220.317 44.8815 222.493 48.1456 222.765H230.12C244.482 224.071 251.88 217.325 253.784 213.789Z"
        fill="#E2E5EC"
      />
      <path
        d="M102.003 199.916H27.7449V199.916C27.7449 212.535 37.9746 222.765 50.5937 222.765H236.648C249.267 222.765 259.497 212.535 259.497 199.916V199.916H119.14"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  )
}

export default UploadPCIcon