import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListLocationMessageContent({
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <i className="dripicons-location"></i> <span>Location</span>
    </div>
  )
}

export default ChatListLocationMessageContent
