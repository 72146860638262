import React from "react"

function ChooseSingleWayOptionComponent({
  handleChoose,
  icon,
  label,
  isSelected,
}) {
  return (
    <button
      onClick={handleChoose}
      className={`d-flex flex-column justify-content-center align-items-center w-100 mb-3 rounded-3 template-sending-option btn-sending-option`}
    >
      <div className="mb-2">{icon}</div>

      <p>{label}</p>

      <div
        className={`single-option-style ${
          isSelected ? "selected-single-option-style" : ""
        }`}
      >
        <i className="fas fa-check"></i>
      </div>
    </button>
  )
}

export default ChooseSingleWayOptionComponent
