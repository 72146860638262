import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListVideoMessageContent({
  messageContent,
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div title={messageContent}>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <svg
        viewBox="0 0 16 20"
        height="20"
        width="16"
        preserveAspectRatio="xMidYMid meet"
        version="1.1"
        x="0px"
        y="0px"
        enableBackground="new 0 0 16 20"
      >
        <path
          fill="currentColor"
          d="M15.243,5.868l-3.48,3.091v-2.27c0-0.657-0.532-1.189-1.189-1.189H1.945 c-0.657,0-1.189,0.532-1.189,1.189v7.138c0,0.657,0.532,1.189,1.189,1.189h8.629c0.657,0,1.189-0.532,1.189-1.189v-2.299l3.48,3.09 V5.868z"
        ></path>
      </svg>{" "}
      <span>
        {messageContent
          ? messageContent.length > 50
            ? messageContent.substring(0, 50) + "..."
            : messageContent
          : "Video"}
      </span>
    </div>
  )
}

export default ChatListVideoMessageContent
