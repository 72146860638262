import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import AwayIcon from "components/Common/Icons/SidebarIcons/AwayIcon"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddCategories from "./AddCategories"
import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import RestoreIcone from "components/Common/Icons/SettingIcons/RestoreIcone"


export default function CategoriesTable({
  setIsLoading,
  isloading,
  CategoriesList,
  setCategoriesList,
  handleEditCategory,
  toggleEdit,
  toggleActive,
}) {
  return (
    <div className="table-container table-container-categories m-0 p-0">
      {isloading ? (
        <div className="  loading">
          <div className="spinner-border "></div>
        </div>
      ) : (
        <table>
          <thead className="text-center">
            <tr>
              {/* <th>Id </th> */}
              <th> Name</th>
              <th>Description </th>
              <th> status </th>
              <th> time </th>
              {/* <th>Action </th> */}
            </tr>
          </thead>
          {CategoriesList && CategoriesList.length > 0 ? (
            <tbody>
              {CategoriesList.map(Categorie => (
                <tr key={Categorie._id} className="tr" onClick={() => toggleEdit(Categorie)}>
                  {/* <td className="text-center"> {Categorie._id} </td> */}

                  <td className="text-center">{Categorie.name}</td>
                  {Categorie?.description ? (
                    <td className="text-center ellipsis-cell">{Categorie.description}</td>
                  ) : (
                    <td className="text-center">___</td>
                  )}
                  <td className="text-center d-flex gap-2 align-items-center justify-content-center">
                    <button
                      disabled
                      className={`border-0 d-flex gap-2 align-items-center justify-content-center  user-status ${Categorie.status === "active" ? "online-status" : ""
                        } ${Categorie.status === "inactive" ? "away-status" : ""
                        }`}
                    >
                      {Categorie.status === "active" && <OnlineIcon />}
                      {Categorie.status === "inactive" && <AwayIcon />}
                      {Categorie.status}
                    </button>
                  </td>

                  <td className="text-center">
                    {/* {moment(Categorie.createdAt).format("YYYY-MM-DD HH:mm:ss")} */}
                    {moment(Categorie.createdAt).format("YYYY-MM-DD , h:mm A")}
                  </td>



                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="6" className="text-center p-2">
                  No Categories found
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </div>
  )
}
