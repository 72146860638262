import React, { useEffect, useState } from "react"
import ServiceHoursTable from "./ServiceHoursComponents/ServiceHoursTable"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"
import AddServiceHours from "./ServiceHoursComponents/AddServiceHours"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import DeleteServiceHours from "./ServiceHoursComponents/DeleteServiceHours"
import ViewServiceHours from "./ServiceHoursComponents/ViewServiceHours"
import EditServiceSHours from "./ServiceHoursComponents/EditServiceSHours"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"

export default function ServiceHours() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false)
  const [serviceHours, setServiceHours] = useState([])
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)

  const fetchservicesData = () => {
    setIsLoading(true)
    axios
      .get(`${Url}/${apiVersion}/services`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const Allservices = res.data.data
        setServiceHours(Allservices)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)

        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const [addNewServiceHours, setAddNewServiceHours] = useState(false)
  const toggleAdd = () => {
    setAddNewServiceHours(!addNewServiceHours)
  }

  const [modalDeleteServices, setModalDeleteServices] = useState(false)
  const [deleteServices, setDeleteServices] = useState(null)
  const toggleDelete = Services => {
    setDeleteServices(Services)
    setModalDeleteServices(!modalDeleteServices)
  }
  const [modalViewServices, setModalViewServices] = useState(false)
  const [ViewServices, setViewServices] = useState(null)
  const toggleView = Services => {
    setViewServices(Services)
    setModalViewServices(!modalViewServices)
  }

  const [showEditToast, setShowEditToast] = useState(false)
  const [editServices, setEditServices] = useState(null)
  const [modalAddNewServices, setModalAddNewServices] = useState(false)
  const toggleEdit = Services => {
    setEditServices(Services)
    setModalAddNewServices(!modalAddNewServices)
  }

  useEffect(() => {
    fetchservicesData()
  }, [])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <div>
      {/* add new Service Hours */}
      <div className=" user-setting-page ">
        <div className="d-flex justify-content-between align-items-center users-setting">
          <p>
            Setting <span className="users-setting-span">> Service Hours</span>
          </p>
          <button onClick={toggleAdd}> + Add ServiceHours</button>
        </div>

        {/* success message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showSuccessToast} fade={true}>
              <ToastBody className="success-message-bg">
                <h5 className="m-0 mb-2">Success</h5>
                <p className="m-0">Service Hours Added Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Delete message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showDeleteToast} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Delete</h5>
                <p className="m-0">Services Deleted Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Edit Message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showEditToast} fade={true}>
              <ToastBody className="edit-message-bg">
                <h5 className="m-0 mb-2">Edit</h5>
                <p className="m-0">Services edited Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        <ServiceHoursTable
          isloading={isloading}
          serviceHours={serviceHours.services}
          toggleDelete={toggleDelete}
          toggleView={toggleView}
          toggleEdit={toggleEdit}
        />

        <AddServiceHours
          toggle={toggleAdd}
          setShowSuccessToast={setShowSuccessToast}
          isOpen={addNewServiceHours}
          fetchservicesData={fetchservicesData}
        />

        <DeleteServiceHours
          isOpen={modalDeleteServices}
          toggle={toggleDelete}
          services={deleteServices}
          setShowDeleteToast={setShowDeleteToast}
          fetchservicesData={fetchservicesData}
        />

        <ViewServiceHours
          isOpen={modalViewServices}
          toggle={toggleView}
          // services={ViewServices}
        />

        <EditServiceSHours
          toggle={toggleEdit}
          isOpen={modalAddNewServices}
          services={editServices}
          setShowEditToast={setShowEditToast}
          fetchservicesData={fetchservicesData}
        />
      </div>
    </div>
  )
}
