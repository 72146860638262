import React, { useEffect, useState } from "react"

export default function ViewTeamconversation({ viewSelectedTeam }) {
  // const { team } = props;
  return (
    <div className="item mb-3">
      <div className="team-conversation">
        <h5>Team Conversation</h5>
      </div>
      <div className="team-conversation-data ">
        <p className="m-0 mb-1">
          Name: <span>{viewSelectedTeam.conversation?.name}</span>
        </p>
        <p>
          Description: <span>{viewSelectedTeam.conversation?.description}</span>
        </p>
        <div className="team-conversation-message">
          <div className="team-conversation d-flex justify-content-around  ">
            <h5 className="m-0 text-capitalize">Inside business hour</h5>
            <h5 className="m-0 text-capitalize">outside business hour </h5>
          </div>

          <div className=" d-flex justify-content-around align-items-center  ">
            <p className="m-0 w-50">{viewSelectedTeam.conversation?.bodyOn}</p>
            <p className="m-0 w-50">
              {viewSelectedTeam.conversation?.bodyOff}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
