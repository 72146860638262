import React from "react"
import TableAddIcon from "components/Common/Icons/SettingIcons/TableAddIcon"
import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
export default function PrivateAnswersTable({
  isloading,
  PrivateAnswersData,
  toggleEdit,
  toggleDelete,
}) {
  return (
    <div className="table-container-services suggested-answer-table">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 table-header ">
        <h5 className="m-0">Name</h5>
        <h5 className="m-0">Action</h5>
      </div>
      {isloading ? (
        <div className="d-flex justify-content-center align-items-center p-5">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <div>
          {PrivateAnswersData?.answers?.length > 0 ? (
            PrivateAnswersData?.answers?.map((answer, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center  m-0 p-0 table-data-private-ans mt-3 "
              >
                <div className=" w-75">
                  <h5 className="m-0 fw-bold text-capitalize mb-2">
                    {answer?.name}
                  </h5>
                  <p className="m-0 p-0 ">{answer?.body}</p>
                </div>
                <div>
                  <button
                    onClick={e => {
                      e.stopPropagation()
                      toggleEdit(answer)
                    }}
                    // onClick={() => toggleEdit(answer)}
                    title="edit"
                    className="border-0 bg-transparent"
                  >
                    <TableEditIcon />
                  </button>
                  <button
                    onClick={e => {
                      e.stopPropagation()
                      toggleDelete(answer)
                    }}
                    // onClick={() => toggleDelete(answer)}
                    className="border-0 bg-transparent"
                    title="delete"
                  >
                    <TableDeleteIcon />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="d-flex justify-content-center align-items-center m-0 p-1 table-data-private-ans  fs-5">
              No Answer found
            </p>
          )}
        </div>
      )}
    </div>
  )
}
