import React from "react"
import InboxIcon from "components/Common/Icons/HomeIcons/InboxIcon"
import { useSelector } from "react-redux"

function InboxHeader() {
  const { chatsType } = useSelector(state => ({
    chatsType: state.Login.chatsType,
  }))

  const headerText = () => {
    if (chatsType === "userChats") return "Inbox"
    if (chatsType === "teamChats") return "Team Inbox"
    if (chatsType === "archivedChats") return "Archived Inbox"
    if (chatsType === "teamUserChats") return "Team Member Inbox"
  }

  return (
    <div className="inbox-header d-flex justify-content-between">
      <h4 className="mb-0">{headerText()}</h4>

      {chatsType === "userChats" && <InboxIcon />}
    </div>
  )
}

export default InboxHeader
