import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

function ArchiveChat({
  conversationActions,
  confirmArchiveChatModal,
  toggleconfirmArchiveChatModal,
}) {
  return (
    <Modal
      isOpen={confirmArchiveChatModal}
      toggle={toggleconfirmArchiveChatModal}
      centered
    >
      <ModalHeader toggle={toggleconfirmArchiveChatModal}>
        Archive Chat
      </ModalHeader>
      <ModalBody>Verify the archiving of this chat.</ModalBody>
      <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
        <Button
          color="secondary"
          onClick={() => {
            conversationActions("archive")
            toggleconfirmArchiveChatModal()
          }}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Archive
        </Button>{" "}
        <Button
          color="secondary"
          outline
          onClick={toggleconfirmArchiveChatModal}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ArchiveChat
