import React, { useState } from "react"
import Conversation from "./Conversation/Conversation"
import AllNotes from "./Notes/AllNotes"
import ProjectDetails from "./ProjectInfo/ProjectDetails"
import ChatHistory from "./History/ChatHistory"

function Chat() {
  const [activeTab, setActiveTab] = useState(1)
  const [sessionIndicator, setSessionIndicator] = useState(false)

  return (
    <div className="d-flex justify-content-between chat-page">
      <Conversation setSessionIndicator={setSessionIndicator} />

      <div className="d-flex flex-column notes-box">
        <div className="d-flex justify-content-between align-items-center notes-box-tabs">
          <div
            className={`single-tab ${activeTab === 1 && "active-single-tab"}`}
            onClick={() => setActiveTab(1)}
          >
            Notes
          </div>
          <div
            className={`single-tab ${activeTab === 2 && "active-single-tab"}`}
            onClick={() => setActiveTab(2)}
          >
            Project Info
          </div>
          <div
            className={`single-tab ${activeTab === 3 && "active-single-tab"}`}
            onClick={() => setActiveTab(3)}
          >
            History
          </div>
        </div>

        {activeTab === 1 && <AllNotes />}

        {activeTab === 2 && (
          <div className="d-flex flex-column flex-grow-1 pt-1 tab-content-notes project-info">
            <ProjectDetails sessionIndicator={sessionIndicator} />
          </div>
        )}

        {activeTab === 3 && <ChatHistory />}
      </div>
    </div>
  )
}

export default Chat
