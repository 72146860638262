import React from "react"
import axios from "axios"

import DangerIcon from "components/Common/Icons/HeaderIcons/DangerIcon"
import { formatTimestamp, getMessageContent } from "helpers/helper_functions"
import TooLateIcon from "components/Common/Icons/HeaderIcons/TooLateIcon"
import OnTimeIcon from "components/Common/Icons/HeaderIcons/OnTimeIcon"
import { useDispatch } from "react-redux"
import { addNewOpenTab } from "store/actions"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import DefaultPhoto from "../../../assets/images/default.jpg"

import configs from "config"
const { Url, apiVersion } = configs.client

function ChatItem({ chat }) {
  const { access, chatsType } = useSelector(state => ({
    access: state.Login.userAccess,
    chatsType: state.Login.chatsType,
  }))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChatWithNotifications = () => {
    chat.notification &&
      axios
        .patch(
          `${Url}/${apiVersion}/chats/${chat.client}`,
          {
            type: "notification",
            notification: false,
          },
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        )
        .then(response => {})
        .catch(err => {
          console.log(err)
        })
  }

  return (
    <div
      onClick={() => {
        dispatch(addNewOpenTab(chat))
        navigate(`/chat/${chat.client}`)
        chat.notification && handleChatWithNotifications()
      }}
      className={`d-flex flex-row justify-content-between chat ${
        chatsType !== "archivedChats"
          ? chat.notification && "chat-with-new-message"
          : ""
      }`}
    >
      <div className="user-details d-flex align-items-center">
        <div className="user-img">
          <img
            src={DefaultPhoto}
            alt={chat.contactName?.name ? chat.contactName?.name : chat.client}
          />
        </div>

        <div className="user-message align-self-center">
          <h5>
            {chat.contactName?.name
              ? chat.contactName?.name.length > 30
                ? chat.contactName?.name.substring(0, 30) + "..."
                : chat.contactName?.name
              : chat.client}
          </h5>
          <p className="mb-0">{getMessageContent(chat)}</p>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-end flex-column chat-time">
        <span>
          {chat.lastMessage?.received &&
            formatTimestamp(chat.lastMessage?.received)}
          {!chat.lastMessage?.received &&
            chat.lastMessage?.sent &&
            !chat.lastMessage?.delivered &&
            !chat.lastMessage?.seen &&
            formatTimestamp(chat.lastMessage?.sent)}
          {!chat.lastMessage?.received &&
            chat.lastMessage?.sent &&
            chat.lastMessage?.delivered &&
            !chat.lastMessage?.seen &&
            formatTimestamp(chat.lastMessage?.delivered)}
          {!chat.lastMessage?.received &&
            chat.lastMessage?.sent &&
            chat.lastMessage?.delivered &&
            chat.lastMessage?.seen &&
            formatTimestamp(chat.lastMessage?.seen)}
        </span>

        <div className="d-flex justify-content-between align-items-center">
          {chat.lastSession?.status === "onTime" && <OnTimeIcon />}
          {chat.lastSession?.status === "danger" && <DangerIcon />}
          {chat.lastSession?.status === "tooLate" && <TooLateIcon />}
        </div>
      </div>
    </div>
  )
}

export default ChatItem
