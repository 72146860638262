import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import configs from "config"
import { useParams } from "react-router-dom"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import SubmitButtonComponent from "./SubmitButtonComponent"
const { Url, apiVersion } = configs.client

const FormDetails = ({
  fetchTicketData,
  form,
  questions,
  setDoingEditsLoading,
  statuses,
  ticketData,
  teams,
}) => {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const params = useParams()
  const dispatch = useDispatch()

  const [formQuestionsEditModal, setFormQuestionsEditModal] = useState(false)
  const [editedQuestions, setEditedQuestions] = useState(
    questions?.map(question => ({
      fieldId: question.field?._id,
      answer: question.answer.join(", "),
    }))
  )

  const toggleFormQuestionsEditModal = () =>
    setFormQuestionsEditModal(!formQuestionsEditModal)

  const handleInputChange = (index, value) => {
    const updatedQuestions = [...editedQuestions]
    updatedQuestions[index].answer = typeof value == "boolean" ? !value : value
    setEditedQuestions(updatedQuestions)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Highlight focused option, otherwise white
      color: state.isSelected ? "#333" : "#000", // Change text color if selected
    }),
    control: provided => ({
      ...provided,
      borderColor: "#ddd", // Custom border color for the select input
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff", // Make sure the dropdown menu is white
    }),
  }

  const hasEmptyRequiredFields = editedQuestions.some((question, index) => {
    const field = questions[index].field
    return (
      field?.required && (!question.answer || question.answer.trim() === "")
    )
  })

  const handleSaveChanges = statusId => {
    // Perform validation to check for required fields

    // if (hasEmptyRequiredFields) {
    //   alert("Please fill out all required fields.")
    //   return
    // }

    // If all required fields are filled, proceed with saving the changes
    let requestBody = {
      questions: editedQuestions.map(q => ({
        field: q.fieldId,
        answer:
          typeof q.answer === "boolean"
            ? [`${q.answer}`]
            : q.answer.split(", ").map(answer => answer.trim()),
      })),
    }

    if (statusId) {
      requestBody.status = statusId
    }

    setDoingEditsLoading(true)
    toggleFormQuestionsEditModal()

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/form`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        console.log("Form updated successfully", res.data)
        setDoingEditsLoading(false)
        fetchTicketData()
      })
      .catch(err => {
        setDoingEditsLoading(false)

        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  function handleSubmit() {
    if (!hasEmptyRequiredFields) {
      handleSaveChanges()
    }
  }

  function isUserSupervisor(userData, teamsData, supervisorTeamId) {
    const superVisorTeam = teams.find(team => team._id === supervisorTeamId)

    if (superVisorTeam && superVisorTeam.supervisor._id === userData._id) {
      return true
    } else {
      return false
    }
  }

  const renderField = (field, index, editable = false) => {
    const isEmptyRequiredField =
      field?.required &&
      (!editedQuestions[index].answer ||
        editedQuestions[index].answer.trim() === "")

    return (
      <FormGroup key={field?._id}>
        <Label for={field?._id}>
          {field?.name}{" "}
          {field?.required && <span className="text-danger">*</span>}
        </Label>
        {(() => {
          switch (field?.type.value) {
            case "dropdown":
              return (
                <Select
                  id={field?._id}
                  value={
                    editedQuestions[index].answer
                      ? {
                          value: editedQuestions[index].answer,
                          label: editedQuestions[index].answer,
                        }
                      : null
                  }
                  onChange={selectedOption =>
                    handleInputChange(
                      index,
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  options={field?.values.map(value => ({
                    value,
                    label: value,
                  }))}
                  isDisabled={!editable}
                  isClearable
                  placeholder={`Select ${field?.name}`}
                  className="basic-select"
                  classNamePrefix="select"
                  styles={customStyles}
                />
              )
            case "text":
              return (
                <Input
                  type="text"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            case "number":
              return (
                <Input
                  type="text"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  pattern="[0-9]*"
                  onInput={e => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "")
                  }}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            case "text-area":
              return (
                <Input
                  type="textarea"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            case "checkbox":
              return (
                <FormGroup check inline className="mb-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      id={field?._id}
                      checked={editedQuestions[index].answer}
                      onClick={e => handleInputChange(index, e.target.checked)}
                      disabled={!editable}
                    />{" "}
                    {field?.name}{" "}
                    {field?.required && <span className="text-danger">*</span>}
                  </Label>
                </FormGroup>
              )
            case "multi-select":
              return (
                <Select
                  isMulti
                  name={field?._id}
                  options={field?.values.map(value => ({
                    value,
                    label: value,
                  }))}
                  value={editedQuestions[index].answer
                    .split(", ")
                    .map(value => ({
                      value,
                      label: value,
                    }))} // Use the current state for the value
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedOptions => {
                    const values = selectedOptions.map(option => option.value)
                    handleInputChange(index, values.join(", "))
                  }}
                  isDisabled={!editable}
                />
              )
            case "decimal":
              return (
                <Input
                  type="text"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  onChange={e => handleInputChange(index, e.target.value)}
                  onInput={e => {
                    const value = e.target.value
                    e.target.value = value.match(/^\d*\.?\d{0,4}$/)
                      ? value
                      : value.slice(0, -1)
                  }}
                  readOnly={!editable}
                />
              )
            case "date":
              return (
                <Input
                  type="date"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            default:
              return null
          }
        })()}

        {isEmptyRequiredField && (
          <div className="text-danger mt-1">Required.</div>
        )}
      </FormGroup>
    )
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Card className="shadow-none rounded-0">
      <CardHeader className="d-flex justify-content-between">
        <h4 className="mb-0">Form</h4>

        {userData?.role === "admin" ||
        userData?._id === ticketData?.assignee?._id ||
        userData?._id === ticketData?.creator?._id ||
        isUserSupervisor(userData, teams, ticketData?.team?._id) ? (
          <i
            className="bx bx-edit-alt bx-sm"
            style={{ cursor: "pointer" }}
            onClick={toggleFormQuestionsEditModal}
          ></i>
        ) : (
          <div></div>
        )}
      </CardHeader>
      <CardBody
        style={{
          height: "calc(75vh - 6px)",
          maxHeight: "calc(75vh - 6px)",
          overflow: "auto",
        }}
      >
        {questions?.map((question, index) =>
          renderField(question.field, index, false)
        )}
      </CardBody>

      <Modal
        isOpen={formQuestionsEditModal}
        toggle={toggleFormQuestionsEditModal}
        centered
      >
        <ModalHeader toggle={toggleFormQuestionsEditModal}>
          Edit Form Questions
        </ModalHeader>
        <ModalBody>
          {questions?.map((question, index) =>
            renderField(question.field, index, true)
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            outline
            onClick={toggleFormQuestionsEditModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <SubmitButtonComponent
            statuses={statuses}
            handleSubmit={handleSubmit}
            handleSubmitStatus={handleSaveChanges}
          />
        </ModalFooter>
      </Modal>
    </Card>
  )
}

export default FormDetails
