import React, { useState, useEffect, useRef } from "react"
import Tab from "./Tab"
import socket from "socket"

import ArchiveIcon from "components/Common/Icons/HeaderIcons/ArchiveIcon"
import NewTabIcon from "components/Common/Icons/HeaderIcons/NewTabIcon"

import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import CreateNewChat from "pages/Home/CreateNewChat"
import { deleteClosedTab, updateOpenTabs } from "store/actions"
import { useDispatch } from "react-redux"
import usePreviousRoute from "components/CustomHooks/PreviousRoute"

function TabsConstainer() {
  const { openChatsTabs } = useSelector(state => ({
    openChatsTabs: state.Login.openChatsTabs,
  }))

  const tabsContainerRef = useRef(null)
  const dispatch = useDispatch()
  const params = useParams()
  const previousRoute = usePreviousRoute()

  const navigate = useNavigate()
  const [navigateTarget, setNavigateTarget] = useState(null)
  const [curIndex, setCurIndex] = useState(null)
  const [createChatModal, setCreateChatModal] = useState(false)
  const toggleCreateChatModal = () => setCreateChatModal(!createChatModal)

  const tabs = openChatsTabs.map(chat => chat.client)

  useEffect(() => {
    localStorage.setItem("openChatsTabs", JSON.stringify(openChatsTabs))
  }, [openChatsTabs])

  useEffect(() => {
    const tabsContainer = tabsContainerRef.current

    const handleWheel = event => {
      event.preventDefault()
      tabsContainer.scrollBy({
        left: event.deltaY,
        behavior: "smooth",
      })
    }

    tabsContainer.addEventListener("wheel", handleWheel)

    return () => {
      tabsContainer.removeEventListener("wheel", handleWheel)
    }
  }, [])

  const previousTabsRef = useRef([])

  useEffect(() => {
    if (socket) {
      const handleUpdating = () => {
        socket.emit("client_to_server", { tabs })
      }

      const handleServerToClient = response => {
        if (response.tabs) {
          const updatedTabs = response.tabs.map(tab => tab.chat)

          // Update state and local storage with the new tabs
          dispatch(updateOpenTabs(updatedTabs))
          localStorage.setItem("openChatsTabs", JSON.stringify(updatedTabs))

          // Compare the current status with the previous status
          updatedTabs.forEach((chat, index) => {
            const previousChat = Array.isArray(previousTabsRef.current)
              ? previousTabsRef.current.filter(c => c.client === chat.client)[0]
              : undefined

            const tabToDelete =
              previousRoute.split("/")[previousRoute.split("/").length - 1]

            if (
              previousChat &&
              previousChat.status === "open" &&
              chat.status === "archived" &&
              previousRoute.includes(chat.client)
            ) {
              dispatch(deleteClosedTab(tabToDelete))
            }
          })

          previousTabsRef.current = updatedTabs
        }
      }

      socket.on("updating", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      return () => {
        socket.off("updating", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [
    tabs,
    openChatsTabs.length,
    previousRoute,
    previousTabsRef.current,
    socket,
  ])

  return (
    <div className="tabs" ref={tabsContainerRef}>
      {openChatsTabs.map((tab, idx) => (
        <Tab
          key={tab._id}
          name={tab.contactName?.name ? tab.contactName?.name : tab.client}
          id={tab._id}
          number={tab.client}
          index={idx}
          tabLength={openChatsTabs.length}
          openChatsTabs={openChatsTabs}
          navigateTarget={navigateTarget}
          setNavigateTarget={setNavigateTarget}
          setCurIndex={setCurIndex}
          tab={tab}
        >
          {tab.lastSession?.status === "onTime" ? (
            <i
              className="bx bxs-time"
              style={{ color: "#2AAA4A", fontSize: "16px" }}
            ></i>
          ) : tab.status === "archived" ? (
            <ArchiveIcon />
          ) : tab.lastSession?.status === "danger" ? (
            <i
              className="bx bxs-time"
              style={{ color: "#F3A82D", fontSize: "16px" }}
            ></i>
          ) : tab.lastSession?.status === "tooLate" ? (
            <i
              className="bx bxs-time"
              style={{ color: "#DF4128", fontSize: "16px" }}
            ></i>
          ) : tab.lastSession?.status === "open" ? (
            <i
              className="bx bxs-time"
              style={{ color: "#3879f0", fontSize: "16px" }}
            ></i>
          ) : (
            <i
              className="bx bxs-time"
              style={{ color: "transparent", fontSize: "16px" }}
            ></i>
          )}
        </Tab>
      ))}

      {openChatsTabs.length > 0 && (
        <div className="add-new-chat" onClick={toggleCreateChatModal}>
          <NewTabIcon />
        </div>
      )}

      <CreateNewChat
        createChatModal={createChatModal}
        toggleCreateChatModal={toggleCreateChatModal}
      />
    </div>
  )
}

export default TabsConstainer
