import React from "react"
import { Row } from "reactstrap"

export default function TemplateHeader({
  selectedTemplateData,
  firstValues,
  columns,
  selectedColumnHeader,
}) {
  const firstValue = selectedColumnHeader
    ? firstValues[columns.indexOf(selectedColumnHeader)]
    : ""
  const getReplacedText = (text, value) => {
    const parts = text.split("1")
    return parts.flatMap((part, index) =>
      index < parts.length - 1
        ? [
            part,
            <span className="highlight" key={index}>
              {value}
            </span>,
          ]
        : [part]
    )
  }
  return (
    <Row>
      {selectedTemplateData.components.map((component, idx) => {
        if (component.type === "HEADER") {
          return (
            <div className=" pb-0 " key={component.text}>
              <h5>
                <span
                  style={{
                    fontSize: "22px",
                    fontFamily:
                      selectedTemplateData?.language == "ar"
                        ? "Droid Arabic Kufi"
                        : "Inter",
                  }}
                  className="m-0 fw-bold text-dark fs-2 "
                >
                  {!selectedColumnHeader && component.text}
                  {selectedColumnHeader &&
                    getReplacedText(component.text, firstValue)}
                </span>
              </h5>
            </div>
          )
        } else {
          ;("")
        }
      })}
    </Row>
  )
}
