import React from "react"

export default function PerviousAction({ setSelectedOptions }) {
  return (
    <button
      className="pervious-button d-flex justify-content-center align-items-center position-relative"
      style={{ right: "auto" }}
    >
      Previous
    </button>
  )
}
