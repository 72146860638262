import React from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"

function LocationSingleMessage(props) {
  return (
    <div
      id="gmaps-markers"
      className="gmaps"
      style={{ position: "relative", width: "350px" }}
    >
      <Map
        google={props.google}
        style={{ width: "100%", height: "100%" }}
        zoom={14}
      >
        <Marker
          title={props.message.location.latitude.name}
          name={props.message.location.latitude.name}
          position={{
            lat: props.message.location.latitude,
            lng: props.message.location.longitude,
          }}
        />
        <Marker name={props.message.location.latitude.name} />
        <InfoWindow>
          <div>
            <h1>{props.message.location.latitude.name}</h1>
          </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCidslXcpxuYg-BJ0JY4ixqM1DVnV6bxE8",
  LoadingContainer: () => <div>Loading...</div>,
  v: "3",
})(LocationSingleMessage)
