import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

function ErrorModel({ setErrorHandler, errorHandler, ...props }) {
  return (
    <Modal
      {...props}
      show={errorHandler}
      onHide={() => setErrorHandler(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div>
            <div className="text-center">
              <div className="mb-4">
                <i className="mdi mdi-close-network-outline mdi-300px text-danger display-2" />
              </div>
              <div>
                <h5 className="text-danger">Error</h5>
                <p className="text-muted">
                  Something wrong happened. Please try again!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setErrorHandler(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ErrorModel
