import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img1.png"

const Pages404 = () => {
  //meta title
  document.title = "ChatPlus | 404 Error Page"

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="text-center mb-4">
                <div className="mt-1 text-center">
                  <Link
                    className="btn btn-secondary waves-effect waves-light"
                    to="/"
                  >
                    Back to Home Page
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pages404
