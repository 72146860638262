import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setTeamMemberUserId } from "store/actions"
import TeamMemberStatusController from "./TeamMemberStatusController"

function TeamMemberRow({ user, selectedTeamMember, ticketFeature }) {
  const dispatch = useDispatch()
  const [userStatus, setUserStatus] = useState(user.status)

  const handleTeamMemberState = userStatus => {
    if (userStatus === "Away") return "#42526D"
    if (userStatus === "Service hours") return "#F3A82D"
    if (userStatus === "Online") return "#17E34A"
    if (userStatus === "Offline") return "#DF4128"
  }

  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center table-body ${
          selectedTeamMember === user._id && "active-table-body"
        }`}
        onClick={() => {
          dispatch(setTeamMemberUserId(user._id))
        }}
      >
        <TeamMemberStatusController
          userId={user._id}
          setUserStatus={setUserStatus}
        />

        <div className="d-flex align-items-center ms-1">
          <span className="me-2 team-member-img">
            <img
              src="https://placehold.co/19x19"
              alt={`${user.firstName} ${user.lastName}`}
            />

            <span
              className="icon"
              style={{
                backgroundColor: handleTeamMemberState(userStatus),
              }}
            ></span>
          </span>
          <h6 className="mb-0 d-inline-block">{`${user.firstName} ${user.lastName}`}</h6>
        </div>

        <span className="numbers">
          {ticketFeature ? user.tickets.all : user.sessions.all}
        </span>
        <span className="numbers">
          {ticketFeature ? user.tickets.new : user.sessions.onTime}
        </span>
        <span className="numbers">
          {ticketFeature ? user.tickets.pending : user.sessions.danger}
        </span>
        <span className="numbers">
          {ticketFeature ? user.tickets.solved : user.sessions.tooLate}
        </span>
        <span className="numbers">
          {ticketFeature ? user.tickets.open : user.sessions.open}
        </span>
      </div>
    </>
  )
}

export default TeamMemberRow
