import TableAddIcon from "components/Common/Icons/SettingIcons/TableAddIcon"
import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import React, { useState } from "react"
import ViewAnswers from "./ViewAnswers"
export default function SuggestedAnswersTabl({
  suggestedAnswers,
  isloading,
  toggleView,
  toggleDelete,
  toggleEdit,
  toggleAddView,
  allAnswers,
  toggleDeleteView,
  toggleEditView,
}) {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const toggleAccordion = index => {
    if (expandedIndex === index) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(index)
    }
  }

  return (
    <div className="table-container-services suggested-answer-table">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 table-header ">
        <h5 className="m-0">Name</h5>
        <h5 className="m-0">Action</h5>
      </div>
      {isloading ? (
        <div className="   d-flex justify-content-center align-center p-5">
          <div className="spinner-border "></div>
        </div>
      ) : (
        <div>
          {suggestedAnswers.length > 0 ? (
            suggestedAnswers.map((answer, index) => (
              <>
                <div
                  onClick={() => toggleAccordion(index)}
                  key={answer}
                  className="d-flex justify-content-between align-items-center  m-0 p-0 table-data mt-3 "
                >
                  <h4 className="m-0">{answer.name}</h4>
                  <div>
                    <button
                      onClick={e => {
                        e.stopPropagation()
                        toggleAddView(answer)
                      }}
                      // onClick={() => toggleView(answer)}
                      title="add"
                      className="border-0 bg-transparent"
                    >
                      <TableAddIcon />
                    </button>
                    <button
                      onClick={e => {
                        e.stopPropagation()
                        toggleEdit(answer)
                      }}
                      // onClick={() => toggleEdit(answer)}
                      title="edit"
                      className="border-0 bg-transparent"
                    >
                      <TableEditIcon />
                    </button>
                    <button
                      onClick={e => {
                        e.stopPropagation()
                        toggleDelete(answer)
                      }}
                      // onClick={() => toggleDelete(answer)}
                      className="border-0 bg-transparent"
                      title="delete"
                    >
                      <TableDeleteIcon />
                    </button>
                  </div>
                </div>
                <div>
                  {expandedIndex === index && (
                    <div className="accordion-content m-0 ">
                      {answer.answers?.length > 0 ? (
                        <ViewAnswers
                          toggleDeleteView={toggleDeleteView}
                          toggleEditView={toggleEditView}
                          answer={answer}
                          allAnswers={allAnswers}
                        />
                      ) : (
                        <p className="text-center m-0 p-3 ">
                          No answers available
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </>
            ))
          ) : (
            <div className="d-flex justify-content-between align-items-center  m-0 p-0 table-data ">
              No Answer found
            </div>
          )}
        </div>
      )}
    </div>
  )
}
