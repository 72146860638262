module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:
      "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  client: {
    Url: "https://wp.designal.cc",
    businessNumber: "966920034242",
    apiVersion: "api/v1",
  },
  templateEstimatedCost: [
    {
      Market: "Egypt",
      Currency: "$US",
      Marketing: 0.1073,
      Utility: 0.0052,
    },
    {
      Market: "Saudi Arabia",
      Currency: "$US",
      Marketing: 0.0455,
      Utility: 0.0115,
    },
    {
      Market: "United Arab Emirates",
      Currency: "$US",
      Marketing: 0.0384,
      Utility: 0.0157,
    },
  ],
}
