import SolvedTicket from 'components/Common/Icons/TicketDashboard/SolvedTicket'
import TotalTicket from 'components/Common/Icons/TicketDashboard/TotalTicket'
import React, { useEffect, useState } from 'react'
import { Col, Row } from "reactstrap"
import UnsolvedTicket from '../../../components/Common/Icons/TicketDashboard/UnsolvedTicket'
import AverageTimeReply from '../../../components/Common/Icons/TicketDashboard/AverageTimeReply'
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import moment from 'moment'

export default function OverViewTicket({
    startDate,
    endDate,

}) {

    const [isloading, setIsLoading] = useState(false)
    const [ticketDashboardNumbers, setTicketDashboardNumbers] = useState([])

    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))

    const dispatch = useDispatch()

    const fetchAllTicketNumbers = async () => {
        try {
            setIsLoading(true)
            const params = {
                startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
            };
            axios
                .get(`${Url}/${apiVersion}/ticket-dashboard/numbers`, {

                    headers: { Authorization: `Bearer ${access}` },
                    params: params,
                })
                .then(response => {
                    const AllTickerNumbers = response.data.data
                    console.log("All Ticket Numbers", response.data.data)
                    setTicketDashboardNumbers(AllTickerNumbers)
                    setIsLoading(false)
                })
                .catch(err => {
                    if (err.response?.status == 401 || err.response?.status == 0) {
                        dispatch(unAuthUser())
                        // UnAuthorized()
                    } else {
                        console.log(err)
                    }
                })
        } catch (error) {
            console.error("Unexpected error:", error)
        }
    }
    useEffect(() => {
        fetchAllTicketNumbers();
    }, [startDate, endDate]);

    if (!authorized) {
        return <UnAuthorizedPopup />
    }

    return (
        <Row className=' ticket-overview gap-4 all-ticket'>
            <Col className='ticket-overview-item all '>
                <Row >
                    <Col className='d-flex gap-3' >
                        <div>
                            <div className='ticket-overview-icons bg-total '>
                                <TotalTicket />
                            </div>
                        </div>
                        <div>
                            <p className='m-0 ticket-overview-item-heading '>
                                Total Tickets
                            </p>
                            <span className='ticket-overview-item-details'>
                                {isloading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <span>{ticketDashboardNumbers.totalTickets}</span>
                                )}
                            </span>

                        </div>
                    </Col>
                </Row>

            </Col>

            <Col className='ticket-overview-item all '>
                <Row >
                    <Col className='d-flex gap-3' >
                        <div>
                            <div className='ticket-overview-icons bg-solved'>
                                <SolvedTicket />
                            </div>
                        </div>
                        <div>
                            <p className='m-0 ticket-overview-item-heading '>
                                Solved Tickets
                            </p>
                            <span className='ticket-overview-item-details'>
                                {isloading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <span>{ticketDashboardNumbers.solvedTickets}</span>
                                )}
                            </span>
                        </div>
                    </Col>
                </Row>

            </Col>

            <Col className='ticket-overview-item all '>
                <Row >
                    <Col className='d-flex gap-3' >
                        <div>
                            <div className='ticket-overview-icons bg-unsolved '>
                                <UnsolvedTicket />
                            </div>
                        </div>
                        <div>
                            <p className='m-0 ticket-overview-item-heading '>
                                Unsolved Tickets
                            </p>
                            <span className='ticket-overview-item-details'>
                                {isloading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <span>{ticketDashboardNumbers.unsolvedTickets
                                    }</span>
                                )}
                            </span>
                        </div>
                    </Col>
                </Row>

            </Col>

            <Col className='ticket-overview-item all '>
                <Row >
                    <Col className='d-flex gap-3' >
                        <div>
                            <div className='ticket-overview-icons bg-average'>
                                <AverageTimeReply />
                            </div>
                        </div>
                        <div>
                            <p className='m-0 ticket-overview-item-heading '>
                                Average Time Reply
                            </p>
                            <div className='ticket-overview-item-details'>
                                {isloading ? (
                                    <p>Loading...</p>
                                ) : (
                                    // <div className='d-flex align-items-center justify-content-between gap-4 '>
                                    //     {ticketDashboardNumbers.solvedTimeAverage?.days > 0 && (
                                    //         <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                                    //             <span>{ticketDashboardNumbers.solvedTimeAverage.days}</span>
                                    //             <p className='m-0 p-0 ticket-overview-number '>Days </p>
                                    //         </div>
                                    //     )}
                                    //     {ticketDashboardNumbers.solvedTimeAverage?.days > 0 && (
                                    //             <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                                    //             <span>{ticketDashboardNumbers.solvedTimeAverage.hours}</span>
                                    //             <p className='m-0 p-0 ticket-overview-number'>hours </p>
                                    //         </div>
                                    //     )}
                                    //     {ticketDashboardNumbers.solvedTimeAverage?.days > 0 && (
                                    //             <div className='d-flex flex-column align-items-center justify-content-center text-center'>
                                    //             <span>{ticketDashboardNumbers.solvedTimeAverage.minutes}</span>
                                    //             <p className='m-0 p-0 ticket-overview-number'>Min</p>
                                    //         </div>
                                    //     )}


                                    // </div>

                                        <div className='d-flex align-items-center justify-content-between gap-3 '>
                                            {ticketDashboardNumbers.solvedTimeAverage?.days > 0 && (
                                                <>
                                                <div className='d-flex gap-1 align-items-end justify-content-center text-center'>
                                                    <span>{ticketDashboardNumbers.solvedTimeAverage.days}</span>
                                                    <p className='m-0 p-0 ticket-overview-number '>Days </p>
                                                </div>
                                                    <span className=' m-0 fw-bold'>:</span>
                                                </>
                                            )}
                                            {ticketDashboardNumbers.solvedTimeAverage?.days > 0 && (
                                                <>
                                                <div className='d-flex gap-1 align-items-end justify-content-center text-center'>
                                                    <span>{ticketDashboardNumbers.solvedTimeAverage.hours}</span>
                                                    <p className='m-0 p-0 ticket-overview-number'>hours </p>
                                                </div>
                                                      <span className='separator'>:</span>
                                                </>
                                            )}
                                            {ticketDashboardNumbers.solvedTimeAverage?.days > 0 && (
                                                <div className='d-flex gap-1 align-items-end justify-content-center text-center'>
                                                    <span>{ticketDashboardNumbers.solvedTimeAverage.minutes}</span>
                                                    <p className='m-0 p-0 ticket-overview-number'>Min</p>
                                                </div>
                                            )}

                                        </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}
