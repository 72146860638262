import React from "react"

export default function RestorePopUp() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 734 734"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.728 200.333H200.333C218.743 200.333 233.666 215.257 233.666 233.667C233.666 252.076 218.742 267 200.333 267H66.9997C30.1807 267 0.333008 237.152 0.333008 200.333V67.0001C0.333008 48.5908 15.257 33.6668 33.6663 33.6668C52.0757 33.6668 66.9997 48.5908 66.9997 67.0001V156.18C109.317 95.9601 169.02 49.5588 238.617 23.5438C322.443 -7.79017 414.833 -7.3859 498.383 24.6805C581.933 56.7465 650.87 118.261 692.206 197.632C733.543 277.004 744.426 368.753 722.803 455.593C701.18 542.433 648.546 618.367 574.816 669.087C501.086 719.807 411.353 741.813 322.523 730.96C233.694 720.103 151.9 677.137 92.5543 610.153C40.6057 551.52 8.99734 478.03 1.87467 400.593C0.188241 382.263 15.405 367.293 33.8147 367.28C52.224 367.263 67.1167 382.22 69.175 400.517C76.0457 461.58 101.57 519.353 142.675 565.75C191.183 620.5 258.039 655.62 330.646 664.49C403.253 673.363 476.6 655.377 536.863 613.917C597.13 572.46 640.15 510.393 657.823 439.413C675.496 368.433 666.603 293.44 632.816 228.563C599.026 163.687 542.68 113.407 474.39 87.1968C406.1 60.9868 330.58 60.6561 262.063 86.2678C204.002 107.97 154.366 147.031 119.664 197.743C119.052 198.638 118.405 199.502 117.728 200.333Z"
        fill="#6B7889"
      />
    </svg>
  )
}
