import React from "react"
import InboxIcon from "components/Common/Icons/TeamMembersIcons/InboxIcon"
import OnTimeIcon from "components/Common/Icons/TeamMembersIcons/OnTimeIcon"
import DangerIcon from "components/Common/Icons/TeamMembersIcons/DangerIcon"
import TooLateIcon from "components/Common/Icons/TeamMembersIcons/TooLateIcon"
import OpenIcon from "components/Common/Icons/TeamMembersIcons/OpenIcon"
import TeamMemberRow from "./TeamMemberRow"
import SecondaryIcon from "components/Common/Icons/TeamMembersIcons/SecondaryIcon"

function TeamMemberTable({ team, selectedTeamMember, ticketFeature }) {
  return (
    <>
      <h5 className="my-2">{team.teamName}</h5>

      <div className="d-flex justify-content-between table-header">
        <i className="mdi mdi-dots-vertical opacity-0"></i>
        <div>Name</div>

        {!ticketFeature ? (
          <>
            <span className="message-state-icon">
              <InboxIcon />
            </span>
            <span className="message-state-icon">
              <OnTimeIcon />
            </span>
            <span className="message-state-icon">
              <DangerIcon />
            </span>
            <span className="message-state-icon">
              <TooLateIcon />
            </span>
            <span className="message-state-icon">
              <OpenIcon />
            </span>
          </>
        ) : (
          <>
            <span className="message-state-icon">
              <InboxIcon />
            </span>
            <span className="message-state-icon">
              <TooLateIcon />
            </span>
            <span className="message-state-icon">
              <DangerIcon />
            </span>
            <span className="message-state-icon">
              <SecondaryIcon />
            </span>
            <span className="message-state-icon">
              <OnTimeIcon />
            </span>
          </>
        )}
      </div>

      {team.users.map(user => (
        <TeamMemberRow
          key={user._id}
          user={user}
          selectedTeamMember={selectedTeamMember}
          ticketFeature={ticketFeature}
        />
      ))}
    </>
  )
}

export default TeamMemberTable
