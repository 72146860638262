import React, { useState, useEffect } from "react"
import axios from "axios"
import { useSelector } from "react-redux"

import configs from "../../../src/config"
const { Url, apiVersion } = configs.client

import "./ShowTemplatesComponent.css"
import { Col, Input, Row, Spinner } from "reactstrap"

function ShowTemplatesComponent({
  setStep,
  data,
  setData,
  singleTemplateData,
  setSingleTemplateData,
}) {
  const { access } = useSelector(state => ({
    access: state.Login?.userAccess,
  }))

  const [templatesData, setTemplatesData] = useState(null)
  const [templatesNames, setTemplatesNames] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [searchedData, setSearchedData] = useState("")

  useEffect(() => {}, [singleTemplateData])

  const handleSelectTemplate = template => {
    setSelectedTemplate(template.id)
    setData({ ...data, templateName: template.name })

    setSingleTemplateData(
      templatesData.filter(item => item.id === template.id)[0]
    )
  }

  useEffect(() => {
    const getTemplates = () => {
      setIsLoading(true)

      axios
        .get(`${Url}/${apiVersion}/whatsapp-templates?status=approved `, {
          headers: { Authorization: `Bearer ${access}` },
        })
        .then(res => {
          setTemplatesData(res.data.data.templates)

          const templateFromApi = res.data.data.templates.map(template => ({
            id: template.id,
            name: template.name,
          }))

          setTemplatesNames(templateFromApi)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error fetching templates:", error)
        })
    }

    getTemplates()
  }, [])

  const filteredTemplates = templatesNames.filter(template =>
    template.name.toLowerCase().includes(searchedData.toLowerCase())
  )

  return (
    <div className="templates-list-layout">
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <Col md={8}>
          <h3 className="mb-0">Choose a Template</h3>
        </Col>

        <Col md={4}>
          <Input
            type="search"
            className="form-control "
            placeholder="Search"
            onChange={e => {
              setSearchedData(e.target.value)
            }}
          />
        </Col>
      </Row>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center templates-list templates-list-loading-container">
          <div>
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column templates-list template-preview-wrapper-scrollbar">
          {filteredTemplates.length > 0 ? (
            filteredTemplates.map(template => (
              <button
                onClick={() => handleSelectTemplate(template)}
                key={template.id}
                className="d-flex justify-content-between align-items-center single-template-list-item"
              >
                <div className="single-template-name">
                  {template.name
                    .replace(/_/g, " ")
                    .replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                      )
                    })}
                </div>

                {template.id === selectedTemplate ? (
                  <div className="template-check-icon">
                    <div className="template-icon"></div>
                  </div>
                ) : (
                  <div className="selected-template"></div>
                )}
              </button>
            ))
          ) : (
            <div className="text-center">No templates found</div>
          )}
        </div>
      )}

      <div className="d-flex justify-content-end mt-5">
        <button
          onClick={() => {
            setData({ ...data, type: "" })
            setStep(0)
          }}
          className="pervious-enter-data d-inline-block"
        >
          Previous
        </button>

        <button
          onClick={() => setStep(2)}
          title={
            !singleTemplateData
              ? "You need to choose an option first"
              : "let's go!"
          }
          className={`next-enter-data ${
            !singleTemplateData && "btn-disabled"
          } d-inline-block ms-3`}
          disabled={!singleTemplateData}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default ShowTemplatesComponent
