import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import ActionOpation from "../ActionOpation"
import PerviousAction from "../PerviousAction"
import { Link } from "react-router-dom"
import * as XLSX from "xlsx"

import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone"
import "react-international-phone/style.css"
import "./fileSteps.css"
import ChoseColumnNumber from "./ChoseColumnNumber"
import { Row, Col, Container, Button } from "reactstrap"

export default function DragDropFile({
  nextStep,
  columns,
  setColumns,
  files,
  setFiles,
  countryCode,
  setCountryCode,
  selectNumber,
  setSelectedNumber,
  selectedOptions,
  setSelectedOptions,
  selectedColumnHeader,
  selectFileColumn,
  firstValues,
  setFirstValues,
  prevStep,
  setNumRows,
}) {
  const uploadFileIcon = (
    <svg
      style={{
        width: "50%",
        height: "50%",
        margin: "15px 0",
      }}
      className="upload-file-input"
      //   height=" 10vh"
      //   width=" 60vh"
      viewBox="0 0 289 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="63.6501"
        y="65.2717"
        width="161.573"
        height="119.14"
        rx="16.3205"
        fill="#F5F5F9"
      />
      <path d="M0 222.765H288.058" stroke="black" strokeWidth="3" />
      <path
        d="M48.9616 190.94V61.1916C48.9616 54.8821 54.0765 49.7672 60.386 49.7672H79.9706"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M241.544 199.1V61.1916C241.544 54.8821 236.429 49.7672 230.12 49.7672H210.535"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M179.5 38.4999C196.3 45.6999 189.833 59.4999 184.5 65.4999H173V69.9999H116C98.8 73.5999 90.1666 61.8332 88 55.4999C85.6 35.0999 100.333 30.6666 108 30.9999C106 18.5999 112.5 10.1666 116 7.49988C136 -6.90012 149.333 6.49988 153.5 14.9999L149.5 17.9999L148 23.9999C152.048 23.6304 154.27 23.5121 156 27.4999C170 21.4999 177.5 32.3332 179.5 38.4999Z"
        fill="#F5F5F9"
      />
      <path
        d="M147.512 25.284L147.475 25.3253L147.084 25.2087C146.096 24.914 145.532 23.8746 145.827 22.8851L147.512 25.284ZM147.512 25.284C148.36 25.3336 149.158 24.7957 149.409 23.9497C151.334 17.4807 157.403 12.959 164.161 12.959C165.193 12.959 166.03 12.1223 166.03 11.0905C166.03 10.0587 165.193 9.22196 164.161 9.22196C155.472 9.22196 148.172 15.0072 145.827 22.8847L147.512 25.284Z"
        fill="#2A3042"
        stroke="#F9FFF9"
        strokeWidth="1.27159"
      />
      <path
        d="M142.797 88.4904C142.797 89.6168 143.71 90.5302 144.836 90.5302C145.962 90.5302 146.876 89.618 146.876 88.4904V51.987C146.876 50.8607 145.963 49.9473 144.836 49.9473C143.71 49.9473 142.797 50.8607 142.797 51.987V88.4904Z"
        fill="#2AAA4A"
        stroke="#2AAA4A"
        strokeWidth="1.27159"
      />
      <path
        d="M144.836 54.8755L135.047 64.6644C134.251 65.462 132.958 65.4614 132.162 64.6648C131.365 63.868 131.365 62.5769 132.162 61.78L143.393 50.5488C144.189 49.7512 145.481 49.7518 146.278 50.5484L157.51 61.78C158.306 62.5767 158.306 63.8673 157.51 64.6642C157.111 65.0643 156.587 65.2622 156.067 65.2622L144.836 54.8755ZM144.836 54.8755L154.625 64.6648C155.023 65.0626 155.546 65.2622 156.067 65.2622L144.836 54.8755Z"
        fill="#2AAA4A"
        stroke="#2AAA4A"
        strokeWidth="1.27159"
      />
      <path
        d="M181.419 71.4942H174.023C173.342 71.4942 172.79 70.9422 172.79 70.2615C172.79 69.5809 173.342 69.0288 174.023 69.0288H181.419C191.614 69.0288 199.91 60.7334 199.91 50.538C199.91 40.3426 191.614 32.0473 181.419 32.0473H181.241C180.884 32.0473 180.544 31.8923 180.309 31.622C180.075 31.3517 179.97 30.9933 180.021 30.6393C180.131 29.8715 180.186 29.1002 180.186 28.3491C180.186 19.5132 172.997 12.3237 164.161 12.3237C160.723 12.3237 157.445 13.3979 154.68 15.431C154.073 15.8775 153.21 15.6793 152.858 15.011C145.028 0.100481 124.576 -1.90185 113.973 11.069C109.506 16.5334 107.751 23.6418 109.157 30.5698C109.312 31.335 108.727 32.0482 107.949 32.0482H107.455C97.2598 32.0482 88.9644 40.3436 88.9644 50.539C88.9644 60.7343 97.2598 69.0297 107.455 69.0297H114.851C115.532 69.0297 116.084 69.5818 116.084 70.2624C116.084 70.9431 115.532 71.4952 114.851 71.4952H107.455C95.9001 71.4952 86.4988 62.0939 86.4988 50.5389C86.4988 39.308 95.3796 30.1118 106.487 29.6047C105.444 22.417 107.439 15.1668 112.064 9.50876C123.416 -4.37953 145.172 -2.82284 154.369 12.6636C157.303 10.8242 160.66 9.85927 164.16 9.85927C174.866 9.85927 183.349 18.9709 182.606 29.6162C193.612 30.2334 202.375 39.381 202.375 50.538C202.375 62.0939 192.974 71.4942 181.419 71.4942Z"
        fill="#2A3042"
      />
      <rect
        x="76.7065"
        y="117.497"
        width="128.932"
        height="17.9526"
        rx="8.16027"
        fill="white"
      />
      <rect
        x="76.7065"
        y="117.497"
        width="60.386"
        height="17.9526"
        rx="8.16027"
        fill="#2A3042"
      />
      <path
        d="M79.9706 148.507H199.927"
        stroke="#2A3042"
        strokeWidth="3.26411"
        strokeLinecap="round"
      />
      <path
        d="M82.4187 166.459H158.309"
        stroke="#2AAA4A"
        strokeWidth="3.26411"
        strokeLinecap="round"
      />
      <path
        d="M253.784 213.789H32.6411C37.2108 220.317 44.8815 222.493 48.1456 222.765H230.12C244.482 224.071 251.88 217.325 253.784 213.789Z"
        fill="#E2E5EC"
      />
      <path
        d="M102.003 199.916H27.7449V199.916C27.7449 212.535 37.9746 222.765 50.5937 222.765H236.648C249.267 222.765 259.497 212.535 259.497 199.916V199.916H119.14"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  )
  const Excel = (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1477 18.0656L10.236 15.2625V35.9754C10.2362 36.2005 10.2807 36.4232 10.367 36.6311C10.4533 36.8389 10.5796 37.0277 10.7389 37.1866C10.8981 37.3456 11.0871 37.4717 11.2951 37.5576C11.5031 37.6436 11.7259 37.6877 11.9509 37.6875H39.4072C39.6324 37.6881 39.8556 37.6442 40.0639 37.5584C40.2722 37.4727 40.4615 37.3467 40.6211 37.1877C40.7806 37.0286 40.9073 36.8397 40.9937 36.6317C41.0802 36.4237 41.1248 36.2007 41.125 35.9754V28.3437L26.1477 18.0656Z"
        fill="#185C37"
      />
      <path
        d="M26.1477 0.312501H11.9509C11.7259 0.312312 11.5031 0.356446 11.2951 0.442383C11.0871 0.52832 10.8981 0.654377 10.7389 0.813357C10.5796 0.972336 10.4533 1.16113 10.367 1.36894C10.2807 1.57676 10.2362 1.79954 10.236 2.02456V9.65625L26.1477 19L34.5729 21.8031L41.125 19V9.65625L26.1477 0.312501Z"
        fill="#21A366"
      />
      <path d="M10.236 9.65625H26.1477V19H10.236V9.65625Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M21.6239 7.78751H10.236V31.1469H21.6239C22.0776 31.1446 22.5122 30.9637 22.8335 30.6432C23.1548 30.3227 23.3368 29.8886 23.3402 29.4348V9.49957C23.3368 9.04581 23.1548 8.61166 22.8335 8.29119C22.5122 7.97073 22.0776 7.78976 21.6239 7.78751Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M20.6881 8.72188H10.236V32.0813H20.6881C21.1418 32.079 21.5764 31.898 21.8977 31.5776C22.219 31.2571 22.401 30.823 22.4044 30.3692V10.4339C22.401 9.98018 22.219 9.54603 21.8977 9.22556C21.5764 8.9051 21.1418 8.72413 20.6881 8.72188Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M20.6881 8.72188H10.236V30.2125H20.6881C21.1418 30.2102 21.5764 30.0293 21.8977 29.7088C22.219 29.3884 22.401 28.9542 22.4044 28.5004V10.4339C22.401 9.98018 22.219 9.54603 21.8977 9.22556C21.5764 8.9051 21.1418 8.72413 20.6881 8.72188Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M19.7522 8.72188H10.236V30.2125H19.7522C20.206 30.2102 20.6406 30.0293 20.9619 29.7088C21.2832 29.3884 21.4652 28.9542 21.4686 28.5004V10.4339C21.4652 9.98018 21.2832 9.54603 20.9619 9.22556C20.6406 8.9051 20.206 8.72413 19.7522 8.72188Z"
        fill="black"
      />
      <path
        d="M2.59137 8.72188H19.7523C20.2068 8.7215 20.643 8.90162 20.9648 9.22265C21.2866 9.54368 21.4679 9.97936 21.4686 10.4339V27.5661C21.4679 28.0206 21.2866 28.4563 20.9648 28.7774C20.643 29.0984 20.2068 29.2785 19.7523 29.2781H2.59137C2.36623 29.2785 2.14323 29.2345 1.9351 29.1487C1.72697 29.0628 1.5378 28.9368 1.3784 28.7778C1.219 28.6188 1.0925 28.4299 1.00612 28.222C0.919744 28.0141 0.875189 27.7912 0.875 27.5661V10.4339C0.875189 10.2088 0.919744 9.9859 1.00612 9.77799C1.0925 9.57008 1.219 9.38123 1.3784 9.22223C1.5378 9.06323 1.72697 8.9372 1.9351 8.85135C2.14323 8.7655 2.36623 8.7215 2.59137 8.72188Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        d="M6.19376 24.5674L9.80332 18.9842L6.49707 13.4326H9.15213L10.9562 16.9875C11.1229 17.3239 11.2437 17.574 11.2983 17.7407H11.3228C11.4406 17.4705 11.5657 17.2103 11.6965 16.9559L13.6256 13.4383H16.0694L12.6783 18.9583L16.1556 24.5717H13.5552L11.4708 20.6747C11.374 20.5071 11.2918 20.3316 11.225 20.15H11.1905C11.1297 20.3269 11.0487 20.4962 10.949 20.6546L8.80282 24.5674H6.19376Z"
        fill="white"
      />
      <path
        d="M39.4086 0.312502H26.1477V9.65625H41.125V2.02456C41.1248 1.79942 41.0803 1.57653 40.9939 1.36861C40.9075 1.1607 40.781 0.971848 40.6216 0.812849C40.4622 0.653851 40.273 0.527824 40.0649 0.441972C39.8568 0.356119 39.6338 0.312125 39.4086 0.312502Z"
        fill="#33C481"
      />
      <path d="M26.1477 19H41.125V28.3438H26.1477V19Z" fill="#107C41" />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="4.46013"
          y1="7.37638"
          x2="17.8835"
          y2="30.6236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F" />
          <stop offset="0.5" stopColor="#117E43" />
          <stop offset="1" stopColor="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  )

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles?.length) {
      const file = acceptedFiles[0]
      const updatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })

      setFiles([updatedFile])
      setFileUploadRequired(false)
      const reader = new FileReader()
      reader.onload = event => {
        const binaryStr = event.target.result
        const workbook = XLSX.read(binaryStr, { type: "binary" })
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 })

        if (jsonData.length > 0) {
          const columns = jsonData[0]
          setColumns(columns)

          const firstValues = columns.map((column, index) => {
            return jsonData.length > 1 ? jsonData[1][index] : null
          })

          setFirstValues(firstValues)
        }

        if (jsonData.length > 0) {
          const numRows = jsonData.length - 1 // Number of rows excluding the header
          setNumRows(numRows)
        }
      }
      reader.readAsBinaryString(file)
    }
  }, [])

  const [columnNumberRequired, setColumnNumberRequired] = useState(false)
  const handleColumnRequired = () => {
    if (!selectNumber && showChoseColumn) {
      setColumnNumberRequired(true)
    }
  }
  //react-dropzone: drag&drop
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      maxSize: 25 * 1024 * 1024,
      accept: {
        "file/xls": [".xls", ".xlsx", ".csv"],
      },
      multiple: false,
      autoFocus: true,
      onDrop,
    })
  //remove file:
  const removeFile = name => {
    setFiles(files => files.filter(file => file.name !== name))
  }

  //file loder:
  const [isUploaderOpen, setIsUploaderOpen] = useState(true)
  //error file upload message:
  const [fileUploadRequired, setFileUploadRequired] = useState(false)
  const handleErrorUploadFile = () => {
    if (files.length === 0) {
      setFileUploadRequired(true)
    }
  }
  //file size
  const maxSize =
    acceptedFiles.length > 0
      ? (
          acceptedFiles.reduce((acc, file) => acc + file.size, 0) /
          (1024 * 1024)
        ).toFixed(2)
      : ""

  const countries = defaultCountries.filter(country => {
    const { iso2 } = parseCountry(country)
    return ["eg", "sa", "ae"].includes(iso2)
  })

  const [showChoseColumn, setShowChoseColumn] = useState(false)
  useEffect(() => {
    if (files.length > 0) {
      const timer = setTimeout(() => {
        setShowChoseColumn(true)
      }, 1000)

      return () => clearTimeout(timer)
    } else {
      setShowChoseColumn(false)
    }
  }, [files])

  const clearNumbersColumns = () => {
    prevStep()
    setSelectedNumber("")
    setFiles("")
  }
  return (
    <Container>
      {/* select country */}
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <Col>
          <h3>Upload Excel File</h3>
        </Col>
        <Col className=" d-flex justify-content-end">
          <PhoneInput
            className="country-drop-down"
            defaultCountry="sa"
            value={countryCode}
            onChange={setCountryCode}
            countries={countries}
          />
        </Col>
      </Row>

      {/* drag & drop input */}
      <Row
        style={{
          margin: "8px 0",
          cursor: "pointer",
          backgroundColor: "#f5f5f9",
        }}
        md={3}
        {...getRootProps()}
        className={`rounded-3 input-style  d-flex flex-column justify-content-around align-items-center text-center cursor-pointer ${
          fileUploadRequired ? "error-input-style" : ""
        }`}
      >
        <input {...getInputProps()} />

        <div>{uploadFileIcon}</div>
        <p className="m-0 mb-3">
          Drag & drop files or{" "}
          <span
            style={{
              color: "#2aaa4a",
              textDecoration: "underline",
            }}
            className="text-center"
          >
            Browse
          </span>
        </p>
      </Row>

      {/* file info */}
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          <p>Supported format : XLS, XLSX, CSV</p>
        </Col>
        <Col className=" d-flex justify-content-end">
          <p>
            Maximum size : <span> 25MB</span>
          </p>
        </Col>
      </Row>

      {/* file upload  */}
      {files.length > 0 && isUploaderOpen && (
        <>
          {files.map(file => (
            <Row
              style={{
                backgroundColor: "#f5f5f9",
                border: "1px solid #e7e7e8",
              }}
              key={file.name}
              className=" p-2 position-relative mb-3 pb-0 border rounded-3  d-flex flex-column justify-content-around "
            >
              <Row>
                <Col className="d-flex gap-2">
                  {Excel}
                  <div>
                    <p className="m-0">{file.name}</p>
                    <span
                      style={{
                        color: "#2aaa4a",
                      }}
                    >
                      {maxSize} Mb
                    </span>
                  </div>
                </Col>
                <Col>
                  <button
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "18px",
                    }}
                    onClick={() => removeFile(file.name)}
                    className="position-absolute top-0 end-0 border-0 bg-transparent text-dark close-loader"
                  >
                    &times;
                  </button>
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: "0px",
                }}
                className="d-flex align-items-center justify-content-between   "
              >
                <Col
                  md={10}
                  className="file-load bg-white rounded-5 position-relative "
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="file-load-present position-absolute top-0 start-0 bg-dark rounded-5 p-1"
                  ></div>
                </Col>
                <Col className=" d-flex justify-content-end">
                  <p>100% </p>
                </Col>
              </Row>
            </Row>
          ))}
        </>
      )}

      {showChoseColumn && (
        <ChoseColumnNumber
          selectNumber={selectNumber}
          setSelectedNumber={setSelectedNumber}
          columns={columns}
          setColumns={setColumns}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          selectedColumnHeader={selectedColumnHeader}
          selectFileColumn={selectFileColumn}
          columnNumberRequired={columnNumberRequired}
        />
      )}

      {/* button action*/}
      <></>
      <Container
        style={{
          bottom: "-20px",
        }}
        className="d-flex button-action justify-content-end mt-3 gap-2"
      >
        {files.length > 0 && isUploaderOpen && selectNumber ? (
          <>
            <span onClick={clearNumbersColumns}>
              <PerviousAction />
            </span>
            <span onClick={nextStep}>
              <ActionOpation />
            </span>
          </>
        ) : (
          <>
            <span onClick={clearNumbersColumns}>
              <PerviousAction />
            </span>

            <Button
              onClick={() => {
                handleErrorUploadFile()
                handleColumnRequired()
              }}
              className="next-page-option-button-disabled position-relative"
            >
              Next
            </Button>
          </>
        )}
      </Container>
    </Container>
  )
}
