import React from 'react'

export default function TotalTicket() {
    return (
        <svg
            width="22"
            height="17"
            viewBox="0 0 22 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6411 0.991434V3.62715M14.6411 7.18215V9.81786M14.6411 13.3857V16.0214M1.45608 14.4143C1.45608 14.8405 1.6254 15.2493 1.9268 15.5507C2.2282 15.8521 2.63698 16.0214 3.06322 16.0214H19.4368C19.863 16.0214 20.2718 15.8521 20.5732 15.5507C20.8746 15.2493 21.0439 14.8405 21.0439 14.4143V11.4571C20.395 11.2804 19.8223 10.8952 19.4139 10.3608C19.0056 9.82641 18.7844 9.17255 18.7844 8.50001C18.7844 7.82747 19.0056 7.1736 19.4139 6.6392C19.8223 6.10481 20.395 5.71957 21.0439 5.54286V2.58572C21.0439 2.15948 20.8746 1.7507 20.5732 1.4493C20.2718 1.1479 19.863 0.978577 19.4368 0.978577H3.06322C2.63698 0.978577 2.2282 1.1479 1.9268 1.4493C1.6254 1.7507 1.45608 2.15948 1.45608 2.58572V5.53643C2.11051 5.70896 2.68944 6.09325 3.10252 6.62935C3.51561 7.16545 3.73963 7.82321 3.73963 8.50001C3.73963 9.1768 3.51561 9.83456 3.10252 10.3707C2.68944 10.9068 2.11051 11.2911 1.45608 11.4636V14.4143Z"
                stroke="white"
                strokeWidth="1.64286"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
