import React from "react"

export default function TableAddIcon() {
  return (
    // <svg
    //   width="20"
    //   height="20"
    //   viewBox="0 0 512 512"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clipPath="url(#clip0_267_592)">
    //     <path
    //       d="M256 512C114.625 512 0 397.391 0 256C0 114.609 114.625 0 256 0C397.391 0 512 114.609 512 256C512 397.391 397.391 512 256 512ZM256 64C149.969 64 64 149.969 64 256C64 362.031 149.969 448 256 448C362.047 448 448 362.031 448 256C448 149.969 362.047 64 256 64ZM288 384H224V288H128V224H224V128H288V224H384V288H288V384Z"
    //       fill="#8D9EB5"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_267_592">
    //       <rect width="512" height="512" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>

    <svg
      width="20"
      height="20"
      viewBox="0 0 718 718"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M384 259C384 245.193 372.807 234 359 234C345.193 234 334 245.193 334 259V334H259C245.193 334 234 345.193 234 359C234 372.807 245.193 384 259 384H334V459C334 472.807 345.193 484 359 484C372.807 484 384 472.807 384 459V384H459C472.807 384 484 372.807 484 359C484 345.193 472.807 334 459 334H384V259Z"
        fill="#8D9EB5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M359 0.666504C161.098 0.666504 0.666992 161.098 0.666992 359C0.666992 556.903 161.098 717.333 359 717.333C556.904 717.333 717.334 556.903 717.334 359C717.334 161.098 556.904 0.666504 359 0.666504ZM50.667 359C50.667 188.712 188.713 50.6665 359 50.6665C529.287 50.6665 667.334 188.712 667.334 359C667.334 529.286 529.287 667.333 359 667.333C188.713 667.333 50.667 529.286 50.667 359Z"
        fill="#8D9EB5"
      />
    </svg>

    // <svg
    //   width="20"
    //   height="20"
    //   viewBox="0 0 450 450"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M25 225H425"
    //     stroke="#8D9EB5"
    //     strokeWidth="50"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M225 425V25"
    //     stroke="#8D9EB5"
    //     strokeWidth="50"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  )
}
