import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
const { Url, apiVersion } = configs.client
import configs from "../../config"
import AwayIcon from "components/Common/Icons/SidebarIcons/AwayIcon"
import ExpandArrowDownIcon from "components/Common/Icons/SidebarIcons/ExpandArrowDownIcon"
import ExpandArrowUpIcon from "components/Common/Icons/SidebarIcons/ExpandArrowUpIcon"
import LogoutIcon from "components/Common/Icons/SidebarIcons/LogoutIcon"
import OfflineIcon from "components/Common/Icons/SidebarIcons/OfflineIcon"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import ProfileDropdownIcon from "components/Common/Icons/SidebarIcons/ProfileDropdownIcon"
import RightArrowIcon from "components/Common/Icons/SidebarIcons/RightArrowIcon"
import ServiceHoursIcon from "components/Common/Icons/SidebarIcons/ServiceHoursIcon"
import { logoutUser, updateUserData } from "store/actions"

import LogoutPop from "../Common/LogoutPop"
import { useSelector, useDispatch } from "react-redux"
import { changeSelectedOverview, updateChatsType } from "store/actions"

import LOGO from "../../assets/images/logo.jpg"

function SidebarCustom() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userData, chatsType, access } = useSelector(state => ({
    userData: state.Login.userData,
    chatsType: state.Login.chatsType,
    access: state.Login.userAccess,
  }))

  const userRole = useSelector(state => state.Login.userData?.role)

  const location = useLocation()
  const path = location.pathname
  const [userprofileStatus, setUserProfileStatus] = useState(userData?.status)
  const [activeSubMenu, setActiveSubMenu] = useState("")
  const [expandedMenu, setExpandedMenu] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [subDropdownOpen, setSubDropdownOpen] = useState(false)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const [currentActiveNav, setCurrentActiveNav] = useState("")

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const toggleSubDropdown = () => {
    setSubDropdownOpen(!subDropdownOpen)
  }

  const handleSubMenuClick = subMenu => {
    setActiveSubMenu(subMenu)
  }

  const toggleMenu = menu => {
    setExpandedMenu(prevMenu => {
      if (prevMenu === menu) {
        return ""
      } else {
        return menu
      }
    })
  }

  //logout popup
  const [modalLogoutOpen, setModalLogoutOpen] = useState(false)
  const toggleModalLogout = () => {
    setModalLogoutOpen(!modalLogoutOpen)
  }

  const handleLogout = () => {
    dispatch(logoutUser())
    navigate("/login")
  }

  useEffect(() => {
    if (!userData) {
      handleLogout()
    }
  }, [userData])

  useEffect(() => {
    if (chatsType === "teamUserChats") {
      setExpandedMenu("")
      handleSubMenuClick("")
    }

    if (chatsType === "userChats") {
      setExpandedMenu("inbox")
      handleSubMenuClick("user-inbox")
    }

    if (chatsType === "teamChats") {
      setExpandedMenu("inbox")
      handleSubMenuClick("team-inbox")
    }
  }, [chatsType])

  useEffect(() => {
    if (
      path !== "/" ||
      path !== "/broadcast" ||
      path !== "/broadcast-reports" ||
      path !== "/performance-reports" ||
      path !== "/users" ||
      path !== "/teams" ||
      path !== "/conversation" ||
      path !== "/service-hours" ||
      path !== "/suggested-answers" ||
      path !== "/broadcast/upload-file" ||
      path !== "/ticket/list" ||
      path !== "/ticket/fields" ||
      path !== "/ticket/forms" ||
      path !== "/ticket/home"
    ) {
      handleSubMenuClick("")
    }
  }, [path])

  //update User status
  function handleUpdateUserStatus(statusUser) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.patch(
          `${Url}/${apiVersion}/users/updateMe`,
          { status: statusUser },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              Authorization: `Bearer ${access}`,
            },
          }
        )

        if (response.status >= 200 && response.status < 300) {
          resolve(response.data)
          localStorage.setItem("authUser", JSON.stringify(response.data.data))
          dispatch(updateUserData(response.data.data.user))
        } else {
          reject(response.statusText)
        }
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 0) {
          console.log(error)
        } else {
          console.log("ERROR STATUS", error)
        }

        reject(error)
      }
    })
  }

  //update user status after successful
  const handleStatusChange = async status => {
    setIsUpdatingStatus(true)

    try {
      await handleUpdateUserStatus(status)
      setUserProfileStatus(status)
      setIsUpdatingStatus(false)
    } catch (error) {
      console.error("Failed to update user status.")
    }
  }

  // Unauthorized route for users
  useEffect(() => {
    if (userRole !== "admin") {
      const restrictedPaths = [
        "/users",
        "/teams",
        "/conversation",
        "/suggested-answers",
        "/service-hours",
        "/ticket/forms",
        "/ticket/fields",
        "/ticket/categories",
        "/ticket/status",
      ]
      if (restrictedPaths.includes(window.location.pathname)) {
        navigate("/unauthorized")
      }
    }
  }, [userRole, navigate])

  return (
    <aside className="sidebar">
      <div
        className="logo"
        onClick={() => {
          navigate("/temp")
          setTimeout(() => {
            navigate("/")
          }, 0)
        }}
        style={{ marginBottom: "0.9375rem" }}
      >
        <Link
          to="/"
          onClick={() => {
            dispatch(changeSelectedOverview("My Overview", "all", "userChats"))
          }}
        >
          <img width="60%" src={LOGO} />
        </Link>
      </div>

      <div className="d-flex flex-column justify-content-between align-items-between sidebar-content">
        <div className="nav-items">
          <div
            className={`menu-item ${
              path === "/" && !activeSubMenu ? "active-menu-item" : ""
            }`}
            onClick={() => {
              dispatch(
                changeSelectedOverview("My Overview", "all", "userChats")
              )
              setActiveSubMenu("")
              toggleMenu("home")
            }}
          >
            <Link to="/">
              <h6 className="d-flex align-items-center py-3 px-4 pe-3">
                <i className="bx bx-home bx-sm pe-2"></i>
                <span>Home</span>
              </h6>
            </Link>
          </div>

          <div
            className={`menu-item ${
              path === "/" && activeSubMenu ? "active-menu-item" : ""
            }`}
          >
            <h6
              className="d-flex align-items-center py-3 px-4 pe-3"
              onClick={() => toggleMenu("inbox")}
            >
              <i className="bx bx-message-alt-dots bx-sm pe-2"></i>

              <span
                className="d-flex justify-content-between align-items-center"
                style={{ width: "82%" }}
              >
                <span className="ms-0">Inbox</span>

                {expandedMenu === "inbox" ? (
                  <ExpandArrowUpIcon />
                ) : (
                  <ExpandArrowDownIcon />
                )}
              </span>
            </h6>

            <div
              className="sub-menu d-flex flex-column ps-4"
              style={{ height: expandedMenu === "inbox" ? "auto" : "0" }}
            >
              <Link
                to="/"
                className="text-decoration-none text-reset ps-3"
                onClick={() => {
                  dispatch(
                    changeSelectedOverview("My Overview", "all", "userChats")
                  )
                  handleSubMenuClick("user-inbox")
                }}
              >
                <span
                  className={`py-1 ${
                    activeSubMenu === "user-inbox" ? "active-sub-menu" : ""
                  } ps-3`}
                >
                  User Inbox
                </span>
              </Link>
              <Link
                to="/"
                className="text-decoration-none text-reset ps-3"
                onClick={() => {
                  dispatch(
                    changeSelectedOverview("Team Overview", "all", "teamChats")
                  )
                  handleSubMenuClick("team-inbox")
                }}
              >
                <span
                  className={`py-1 ${
                    activeSubMenu === "team-inbox" ? "active-sub-menu" : ""
                  } ps-3`}
                >
                  Team Inbox
                </span>
              </Link>
              <Link
                to="/"
                className="text-decoration-none text-reset ps-3"
                onClick={() => {
                  dispatch(updateChatsType("archivedChats"))
                  handleSubMenuClick("archived-inbox")
                }}
              >
                <span
                  className={`py-1 ${
                    activeSubMenu === "archived-inbox" ? "active-sub-menu" : ""
                  } ps-3`}
                >
                  Archived Inbox
                </span>
              </Link>
            </div>
          </div>

          <div
            className={`menu-item ${
              path === "/broadcast" || path === "/broadcast/upload-file"
                ? "active-menu-item"
                : ""
            }`}
            onClick={() => toggleMenu("broadcast")}
          >
            <Link to="/broadcast">
              <h6 className="d-flex align-items-center py-3 px-4 pe-3">
                <i className="bx bx-station bx-sm pe-2"></i>
                <span>Broadcast</span>
              </h6>
            </Link>
          </div>

          {/* ********* tickets ********** */}
          <div
            className={`menu-item ${
              path === "/ticket/list" ||
              path === "/ticket/fields" ||
              path === "/ticket/forms" ||
              path === "/ticket/home" ||
              path === "/ticket/categories" ||
              path === "/ticket/status"
                ? "active-menu-item"
                : ""
            }`}
          >
            <h6
              className="d-flex align-items-center py-3 px-4 pe-3"
              onClick={() => toggleMenu("ticket")}
            >
              <i className="mdi mdi-ticket-confirmation-outline mdi-24px pe-2"></i>

              <span
                className="d-flex justify-content-between align-items-center"
                style={{ width: "82%" }}
              >
                <span className="ms-0">Tickets</span>

                {expandedMenu === "ticket" ? (
                  <ExpandArrowUpIcon />
                ) : (
                  <ExpandArrowDownIcon />
                )}
              </span>
            </h6>

            <div
              className="sub-menu d-flex flex-column ps-4"
              style={{ height: expandedMenu === "ticket" ? "auto" : "0" }}
            >
              {/* <Link
                to="/ticket/home"
                className="text-decoration-none text-reset ps-3"
              >
                <span
                  className={`py-1 ${
                    path === "/ticket/home" ? "active-sub-menu" : ""
                  } ps-3`}
                  onClick={() => setCurrentActiveNav("ticket-home")}
                >
                  Tickets Home
                </span>
              </Link> */}

              <Link
                to="/ticket/list"
                className="text-decoration-none text-reset ps-3"
              >
                <span
                  className={`py-1 ${
                    path === "/ticket/list" ? "active-sub-menu" : ""
                  } ps-3`}
                  onClick={() =>
                    setCurrentActiveNav(prevNav => {
                      setExpandedMenu("ticket")
                      return "ticket-list"
                    })
                  }
                >
                  Tickets List
                </span>
              </Link>

              {userRole === "admin" && (
                <>
                  <Link
                    to="/ticket/fields"
                    className="text-decoration-none text-reset ps-3"
                  >
                    <span
                      className={`py-1 ${
                        path === "/ticket/fields" ? "active-sub-menu" : ""
                      } ps-3`}
                      onClick={() => setCurrentActiveNav("ticket-fields")}
                    >
                      Fields
                    </span>
                  </Link>

                  <Link
                    to="/ticket/forms"
                    className="text-decoration-none text-reset ps-3"
                  >
                    <span
                      className={`py-1 ${
                        path === "/ticket/forms" ? "active-sub-menu" : ""
                      } ps-3`}
                      onClick={() => setCurrentActiveNav("ticket-forms")}
                    >
                      Forms
                    </span>
                  </Link>

                  <Link
                    to="/ticket/categories"
                    className="text-decoration-none text-reset ps-3"
                  >
                    <span
                      className={`py-1 ${
                        path === "/ticket/categories" ? "active-sub-menu" : ""
                      } ps-3`}
                      onClick={() => setCurrentActiveNav("ticket-categories")}
                    >
                      Categories
                    </span>
                  </Link>

                  <Link
                    to="/ticket/status"
                    className="text-decoration-none text-reset ps-3"
                  >
                    <span
                      className={`py-1 ${
                        path === "/ticket/status" ? "active-sub-menu" : ""
                      } ps-3`}
                      onClick={() => setCurrentActiveNav("ticket-status")}
                    >
                      Statuses
                    </span>
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* ********* reports ********** */}
          <div
            className={`menu-item ${
              path === "/broadcast-reports" || path === "/performance-reports"
                ? "active-menu-item"
                : ""
            }`}
          >
            <h6
              className="d-flex align-items-center py-3 px-4 pe-3"
              onClick={() => toggleMenu("report")}
            >
              <i className="bx bx-bar-chart-alt-2 bx-sm pe-2"></i>

              <span
                className="d-flex justify-content-between align-items-center"
                style={{ width: "82%" }}
              >
                <span className="ms-0">Reports</span>

                {expandedMenu === "report" ? (
                  <ExpandArrowUpIcon />
                ) : (
                  <ExpandArrowDownIcon />
                )}
              </span>
            </h6>

            <div
              className="sub-menu d-flex flex-column ps-4"
              style={{ height: expandedMenu === "report" ? "auto" : "0" }}
            >
              <Link
                to="/broadcast-reports"
                className="text-decoration-none text-reset ps-3"
              >
                <span
                  className={`py-1 ${
                    path === "/broadcast-reports" ? "active-sub-menu" : ""
                  } ps-3`}
                  onClick={() =>
                    setCurrentActiveNav(prevNav => {
                      setExpandedMenu("report")
                      return "broadcast-reports"
                    })
                  }
                >
                  Braodcast Reports
                </span>
              </Link>

              <Link
                to="/performance-reports"
                className="text-decoration-none text-reset ps-3"
              >
                <span
                  className={`py-1 ${
                    path === "/performance-reports" ? "active-sub-menu" : ""
                  } ps-3`}
                  onClick={() => setCurrentActiveNav("preformance-reports")}
                >
                  Performance Reports
                </span>
              </Link>
            </div>
          </div>

          {/* ********* settings ********** */}
          {userRole === "admin" && (
            <div
              className={`menu-item ${
                path === "/users" ||
                path === "/teams" ||
                path === "/conversation" ||
                path === "/suggested-answers" ||
                path === "/service-hours"
                  ? "active-menu-item"
                  : ""
              }`}
            >
              <h6
                className="d-flex align-items-center py-3 px-4 pe-3"
                onClick={() => toggleMenu("settings")}
              >
                <i className="bx bx-cog bx-sm pe-2"></i>

                <span
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: "82%" }}
                >
                  <span className="ms-0">Settings</span>

                  {expandedMenu === "settings" ? (
                    <ExpandArrowUpIcon />
                  ) : (
                    <ExpandArrowDownIcon />
                  )}
                </span>
              </h6>

              <div
                className="sub-menu d-flex flex-column ps-4"
                style={{ height: expandedMenu === "settings" ? "auto" : "0" }}
              >
                <Link
                  to="/users"
                  className="text-decoration-none text-reset ps-3"
                >
                  <span
                    className={`py-1 ${
                      path === "/users" ? "active-sub-menu" : ""
                    } ps-3`}
                    onClick={() => setCurrentActiveNav("users")}
                  >
                    Users
                  </span>
                </Link>

                <Link
                  to="/teams"
                  className="text-decoration-none text-reset ps-3"
                >
                  <span
                    className={`py-1 ${
                      path === "/teams" ? "active-sub-menu" : ""
                    } ps-3`}
                    onClick={() => setCurrentActiveNav("teams")}
                  >
                    Teams
                  </span>
                </Link>

                <Link
                  to="/conversation"
                  className="text-decoration-none text-reset ps-3"
                >
                  <span
                    className={`py-1 ${
                      path === "/conversation" ? "active-sub-menu" : ""
                    } ps-3`}
                    onClick={() => setCurrentActiveNav("conversation")}
                  >
                    Conversation
                  </span>
                </Link>

                <Link
                  to="/service-hours"
                  className="text-decoration-none text-reset ps-3"
                >
                  <span
                    className={`py-1 ${
                      path === "/service-hours" ? "active-sub-menu" : ""
                    } ps-3`}
                    onClick={() => setCurrentActiveNav("service-hours")}
                  >
                    Service Hours
                  </span>
                </Link>

                <Link
                  to="/suggested-answers"
                  className="text-decoration-none text-reset ps-3"
                >
                  <span
                    className={`py-1 ${
                      path === "/suggested-answers" ? "active-sub-menu" : ""
                    } ps-3`}
                    onClick={() => setCurrentActiveNav("suggested-answers")}
                  >
                    Suggested Answers
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-start profile-card-container ps-4">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            direction="down"
          >
            <DropdownToggle
              tag="div"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
              className="d-flex align-items-center"
            >
              <div className="me-2 user-icon position-relative">
                <span
                  className="user-status"
                  style={{
                    backgroundColor:
                      userData?.status === "Online"
                        ? "#17E34A"
                        : userData.status === "Offline"
                        ? "#DF4128"
                        : userData.status === "Service hours"
                        ? "#F3A82D"
                        : "#42526D",
                  }}
                ></span>
                <i className="bx bx-user-circle bx-md"></i>
              </div>
              <div className="user-details">
                <h6 className="mb-0">{`${userData?.firstName} ${userData?.lastName}`}</h6>
                <span>{userData.role === "admin" ? "Admin" : "User"}</span>
              </div>
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem
                id="profile"
                className="d-flex align-items-center name"
              >
                <div className="me-2">
                  <ProfileDropdownIcon />
                </div>

                <div className="">
                  <h6 className="mb-0">{`${userData?.firstName} ${userData?.lastName}`}</h6>

                  <span>{userData?.email}</span>
                </div>
              </DropdownItem>

              <Link to="/profile">
                <DropdownItem id="profile">Profile</DropdownItem>
              </Link>
              <Link to="/private-answers">
                <DropdownItem id="settings1">Private Answers</DropdownItem>
              </Link>
              <Link to="/team-info">
                <DropdownItem id="settings1">Team Info</DropdownItem>
              </Link>

              <Dropdown
                isOpen={subDropdownOpen}
                toggle={toggleSubDropdown}
                direction="right"
                className="dropstart"
              >
                <DropdownToggle
                  tag="button"
                  tabindex="0"
                  role="menuitem"
                  data-toggle="dropdown"
                  aria-expanded={subDropdownOpen}
                  className="dropdown-item d-flex align-items-center status"
                >
                  <div className="status-icon">
                    {!isUpdatingStatus &&
                      userprofileStatus === "Service hours" && (
                        <ServiceHoursIcon />
                      )}
                    {!isUpdatingStatus && userprofileStatus === "Online" && (
                      <OnlineIcon />
                    )}
                    {!isUpdatingStatus && userprofileStatus === "Away" && (
                      <AwayIcon />
                    )}
                    {!isUpdatingStatus && userprofileStatus === "Offline" && (
                      <OfflineIcon />
                    )}
                    {isUpdatingStatus && (
                      <div className="m-0 opacity-0">
                        <OfflineIcon />
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-between align-items-center status-header">
                    <h6 className="mb-0">
                      {isUpdatingStatus ? "Updating..." : userprofileStatus}
                    </h6>

                    <RightArrowIcon />
                  </div>
                </DropdownToggle>

                <DropdownMenu
                  data-popper-placement="left-end"
                  className="sub-dropdown-menu"
                  right
                >
                  <DropdownItem
                    onClick={() => {
                      handleStatusChange("Service hours")
                    }}
                    disabled={userprofileStatus === "Service hours"}
                  >
                    <ServiceHoursIcon />
                    <h6>Service hours</h6>
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      handleStatusChange("Online")
                    }}
                    disabled={userprofileStatus === "Online"}
                  >
                    <OnlineIcon />
                    <h6>Online</h6>
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      handleStatusChange("Offline")
                    }}
                    disabled={userprofileStatus === "Offline"}
                  >
                    <OfflineIcon />
                    <h6>Offline</h6>
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      handleStatusChange("Away")
                    }}
                    disabled={userprofileStatus === "Away"}
                  >
                    <AwayIcon />
                    <h6>Away</h6>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <DropdownItem
                id="logout"
                className="d-flex align-items-center logout"
                onClick={toggleModalLogout}
              >
                <div className="logout-icon">
                  <LogoutIcon />
                </div>

                <div>
                  <h6 className="mb-0">Log out</h6>
                </div>
              </DropdownItem>
              <LogoutPop
                handleLogout={handleLogout}
                isOpen={modalLogoutOpen}
                toggle={toggleModalLogout}
              />
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </aside>
  )
}

export default SidebarCustom
