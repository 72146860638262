import React, { useRef, useState, useEffect } from "react"
import "./AudioSingleMessage.css"

import configs from "config"
const { Url } = configs.client

const AudioSingleMessage = ({ audioSource, dataSource }) => {
  const audioRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)

  const togglePlayPause = () => {
    const audio = audioRef.current
    if (!audio) return // Ensure audio element exists

    if (audio.paused) {
      audio.play()
      setIsPlaying(true)
    } else {
      audio.pause()
      setIsPlaying(false)
    }
  }

  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`
  }

  useEffect(() => {
    const audio = audioRef.current

    const updateTime = () => {
      setCurrentTime(audio.currentTime)
    }

    const onEnded = () => {
      // Handle the "ended" event by setting isPlaying to false
      setIsPlaying(false)
    }

    audio.addEventListener("timeupdate", updateTime)
    audio.addEventListener("ended", onEnded)

    return () => {
      audio.removeEventListener("timeupdate", updateTime)
      audio.removeEventListener("ended", onEnded)
    }
  }, [])

  return (
    <div className="audio-player">
      {dataSource === "url" ? (
        <audio ref={audioRef} src={`${Url}/${audioSource}`}></audio>
      ) : (
        <audio ref={audioRef} src={audioSource}></audio>
      )}
      <div className="controls">
        <button
          onClick={togglePlayPause}
          className={`play-pause ${isPlaying ? "pause" : "play"}`}
        >
          {/*{isPlaying ? "\u23F8" : "\u23F5"}*/}
          {isPlaying ? "◼︎" : "►"}
        </button>
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{
            width: `${(currentTime / audioRef.current?.duration) * 100}%`,
          }}
        ></div>
      </div>
      <div className="time">
        <span className="current-time">{formatTime(currentTime)}</span>
        <span className="total-time">
          {formatTime(audioRef.current?.duration || 0)}
        </span>
      </div>
    </div>
  )
}

export default AudioSingleMessage
