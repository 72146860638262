import React from "react"

function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M1.48438 0.969727L6.67225 6.15761L1.48437 11.3455"
        stroke="#6B7889"
        strokeWidth="1.72929"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RightArrowIcon
