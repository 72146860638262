import React from "react"

export default function ViewTeamServiceHours({ viewSelectedTeam }) {
  function formatTime12Hour(hours, minutes) {
    const WorksHoures = hours >= 12 ? "PM" : "AM"
    const formattedHours = hours % 12 || 12
    return `${formattedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${WorksHoures}`
  }
  function calculateHours(duration) {
    const fromHours = duration.from.hours
    const fromMinutes = duration.from.minutes
    const toHours = duration.to.hours
    const toMinutes = duration.to.minutes

    let diffHours = toHours - fromHours
    let diffMinutes = toMinutes - fromMinutes

    if (diffMinutes < 0) {
      diffHours--
      diffMinutes += 60
    }

    const formattedHours = diffHours.toString().padStart(2, "0")
    const formattedMinutes = diffMinutes.toString().padStart(2, "0")
    return `${formattedHours}:${formattedMinutes}`
  }

  return (
    <div className="item mb-3">
      <div className="team-conversation">
        <h5>Service Hours </h5>
      </div>
      <div className="team-conversation-data ">
        <p className="m-0 mb-1">
          Name: <span>{viewSelectedTeam.serviceHours?.name}</span>
        </p>
        <p>
          Response Time:{" "}
          <span>
            {viewSelectedTeam.serviceHours?.responseTime.hours}h:
            {viewSelectedTeam.serviceHours?.responseTime.minutes}m
          </span>
        </p>
        {/* <div className="team-conversation-message">
          <div className="team-conversation d-flex justify-content-around  ">
            <h5 className="m-0 text-capitalize">Service Day</h5>
            <h5 className="m-0 text-capitalize">Work Day From </h5>
            <h5 className="m-0 text-capitalize">Work Day To </h5>
            <h5 className="m-0 text-capitalize">total </h5>
          </div>

          <div className=" d-flex justify-content-around  ">
            <p className="m-0">
              {viewSelectedTeam.serviceHours.durations[0].day}
            </p>
            <p className="m-0">
              {viewSelectedTeam.serviceHours?.durations[0].from?.hours}h:
              {viewSelectedTeam.serviceHours?.durations[0].from?.minutes}m
            </p>
            <p className="m-0">
              {viewSelectedTeam.serviceHours?.durations[0].to?.hours}h:
              {viewSelectedTeam.serviceHours?.durations[0].to?.minutes}m
            </p>
            <p className="m-0">
              {viewSelectedTeam.serviceHours?.durations[0].from?.hours}
            </p>
          </div>
        </div> */}
        <div className="team-conversation-message tt">
          <table className="table ">
            <thead className="text-center">
              <tr className="table-body">
                <th scope="col">Service Day</th>
                <th scope="col">Work Day From</th>
                <th scope="col">Work Day To</th>
                <th scope="col">Total Hours</th>
              </tr>
            </thead>
            <tbody>
              {viewSelectedTeam?.serviceHours?.durations?.length > 0 ? (
                viewSelectedTeam.serviceHours?.durations.map(
                  (duration, index) => (
                    <tr key={index}>
                      <td className="text-center">{duration.day}</td>
                      <td className="text-center">
                        {formatTime12Hour(
                          duration?.from?.hours,
                          duration?.from?.minutes
                        )}
                      </td>
                      <td className="text-center">
                        {formatTime12Hour(
                          duration?.to?.hours,
                          duration?.to?.minutes
                        )}
                      </td>
                      <td className="text-center">
                        {" "}
                        {calculateHours(duration)}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
