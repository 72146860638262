import React, { useEffect, useState } from "react"
import { Formik, useFormik, validateYupSchema } from "formik"
import { FormControl } from "react-bootstrap"
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Toast,
    ToastBody,
    ModalFooter,
} from "reactstrap"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useDispatch } from "react-redux"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import Select from "react-select"
export default function StatusCategory({
    isOpen,
    toggle,
    category,
    fetchAllCategories,
}) {

    console.log("categoryyyy", category)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))
    //select
    const colorStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "gray" : "white",
            backgroundColor: state.isFocused ? "#EBEDF0" : "white",
            color: state.isSelected ? "gray" : "black",
        }),
        menu: provided => ({
            ...provided,
            zIndex: 10000,
            height: "1rem",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: "10rem",
            overflowY: "auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // color: "white",
            color: state.data.color,
            backgroundColor: state.data.backgroundColor,
            width: "max-content",
            padding: "0.2rem 0.8rem",
            borderRadius: "0.6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",

        }),
    }

    const options = [
        { label: "active", value: "Option 1", color: "#409261", backgroundColor: "#e9ffef" },
        { label: "inactive", value: "Option 2", color: "#6b7889", backgroundColor: "#e4e4e4" },
    ];

    const [selectedCategorey, setSelectedCategorey] = useState(null);

    useEffect(() => {
        const selectedOption = options.find(option => option.label === values.status);
        setSelectedCategorey(selectedOption);
    }, []);

    // formik
    const {
        handleChange,
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        handleBlur,
        touched,
    } = useFormik({
        initialValues: {
            status: category?.status || ""

        },
        // validationSchema: AddCategoriesSchema,
        onSubmit: values => {
            setIsLoading(true);
            axios
                .patch(`${Url}/${apiVersion}/ticketCategories/${category?._id}/status`, values, {
                    headers: {
                        "Content-Type": "application/json; charset=utf8",
                        Authorization: `Bearer ${access}`,
                    },
                })
                .then(res => {
                    // Assume status code 200 is the success status code
                    if (res.status === 200 || res.status === 204) {

                        fetchAllCategories()
                        toggle()
                        // toggle();
                        setIsLoading(false)
                        setShowEditToast(true)
                        setTimeout(() => setShowEditToast(false), 2500)
                    } else {
                        console.error("Unexpected status code:", res.status, res.data)
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // toggle()
                    console.error("Error:", error);
                    if (error?.response?.status === 401 || error?.response?.status === 0) {
                        UnAuthorized();
                    } else if (error?.response?.status === 403) {
                        setErrorMessage(error?.response?.data.message);
                        setTimeout(() => setErrorMessage(null), 3500);
                    } else {
                        setErrorMessage(error?.response?.data.message);
                        setTimeout(() => setErrorMessage(null), 3500);
                    }
                });
        },
    });



    useEffect(() => {
        if (category?.status) {
            const selectedOption = options.find(option => option.label === category.status);
            setSelectedCategorey(selectedOption);
        }
    }, [category]);
    const handleStatusChange = selectedOption => {
        setSelectedCategorey(selectedOption);
        setFieldValue("status", selectedOption.label);
    };

    if (!authorized) {
        return <UnAuthorizedPopup />
    }
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered >
            <ModalHeader toggle={toggle}> User Status</ModalHeader>
            <ModalBody
                // className="border-0 d-flex flex-column align-items-center justify-content-center"
                toggle={toggle}
            >
                {errorMessage && (
                    <div className="" aria-live="polite" aria-atomic="true">
                        <div className="">
                            <Toast isOpen={errorMessage} fade={true}>
                                <ToastBody
                                    className="delete-message-bg">
                                    <h5 className="m-0 mb-2">Error</h5>
                                    <p className="m-0">{errorMessage}</p>
                                </ToastBody>
                            </Toast>
                        </div>
                    </div>
                )}

                <form
                    onSubmit={handleSubmit}
                >


                    <Row>
                        <Col md={12}>
                            <FormGroup className="m-0 m-mb-5">
                                <Label className="mb-1">Status</Label>
                                <Select
                                    className="form-selects flex-grow-1"
                                    id="inputGroupSelect01"
                                    name="status"
                                    options={options}
                                    styles={colorStyles}
                                    value={selectedCategorey}
                                    onChange={handleStatusChange}
                                />
                                {errors.status && touched.status && (
                                    <div className="error mb-0">{errors.status}</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    {isLoading && (
                        <div className="loading-overlay">
                            <Spinner />
                        </div>
                    )}
                    <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
                        <Button type="submit" className="edit-button">
                            Save
                        </Button>{" "}
                        <Button onClick={toggle} className="edit-button cancel-delete ">
                            Cancel
                        </Button>
                    </FormGroup>

                </form>
            </ModalBody>
        </Modal>
    )
}
