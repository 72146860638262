import React from "react"

import configs from "config"
const { Url } = configs.client

function StickerSingleMessage({ message }) {
  return (
    <>
      <img
        src={`${Url}/${message.sticker.file}`}
        style={{ maxHeight: "10rem" }}
        alt="Message sticker"
      />
    </>
  )
}

export default StickerSingleMessage
