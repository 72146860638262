import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  CardHeader,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import configs from "config"
const { Url, apiVersion } = configs.client
import { useSelector } from "react-redux"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import Select from "react-select"
import CustomSpinner from "components/Common/CustomSpinner"
import AddTicketClientInfo from "./AddTicketClientInfo"

function AddTicket() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [forms, setForms] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)
  const [errors, setErrors] = useState({})
  const [natureOfRequest, setNatureOfRequest] = useState("")
  const [complaintReport, setComplaintReport] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [categories, setCategories] = useState([])
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([])
  const [selectedStatusId, setSelectedStatusId] = useState("")
  const [selectedCategoryId, setSelectedCategoryId] = useState("")
  const [selectedTeamId, setSelectedTeamId] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("")
  const [loadingData, setLoadingData] = useState(true)
  const [creatingTicket, setCreatingTicket] = useState(false)
  const [gettingFormData, setGettingFormData] = useState(false)
  const [refNo, setRefNo] = useState("")

  const [noData, setNoData] = useState(false)

  const [clientName, setClientName] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientPhone, setClientPhone] = useState("")

  const fetchFormsData = () => {
    axios
      .get(`${Url}/${apiVersion}/forms`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const activeForms = res.data.data.forms.filter(
          form => form.status === "active"
        )
        setForms(activeForms)

        setLoadingData(false)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }

        setLoadingData(false)
      })
  }

  const fetchFormDetails = formId => {
    setGettingFormData(true)

    axios
      .get(`${Url}/${apiVersion}/forms/${formId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setSelectedForm(res.data.data.form)
        setGettingFormData(false)
      })
      .catch(err => {
        setGettingFormData(false)

        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchTicketStatuses = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketStatuses?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setStatuses(res.data.data.statuses)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchTicketCategories = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketCategories?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setCategories(res.data.data.categories)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTeams(res.data.data.teams)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchUsers = () => {
    axios
      .get(
        `${Url}/${apiVersion}/users?type=ticketCreate&teamID=${selectedTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        const users = res.data.data.users
        setUsers(users)

        // Set the default assignee: prioritize the supervisor if available, otherwise the first user
        if (users.length > 0) {
          const defaultUser = users.find(user => user.supervisor) || users[0]
          setSelectedUserId(defaultUser._id)
        }
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const createTicketRequest = () => {
    setCreatingTicket(true)

    const body = {
      priority: document.getElementById("prioritySelect").value,
      status: selectedStatusId,
      category: selectedCategoryId,
      refNo: document.getElementById("refNo").value,
      requestNature: natureOfRequest,
      complaintReport: complaintReport,
      requestType: document.getElementById("typeOfRequest").value,
      team: selectedTeamId,
      assignee: selectedUserId || null,
      client: {
        name: document.getElementById("clientName").value,
        email: document.getElementById("clientEmail").value,
        phone: document.getElementById("clientPhone").value,
      },
      form: selectedForm._id,
      questions: selectedForm.fields.map(({ field }) => {
        let answer = null

        if (field.type.value === "multi-select") {
          answer = Array.from(
            document.querySelectorAll(`[name="${field._id}"]`)
          )
            .map(option => option.value)
            .filter(Boolean)
        } else if (field.type.value === "checkbox") {
          answer = [document.getElementById(field._id).checked]
        } else {
          answer = [document.getElementById(field._id).value]
        }

        return {
          field: field._id,
          answer,
        }
      }),
    }

    axios
      .post(`${Url}/${apiVersion}/tickets`, body, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        console.log("Ticket created successfully", res)
        setCreatingTicket(false)
        navigate("/ticket/list")
      })
      .catch(err => {
        setCreatingTicket(false)

        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log("Error creating ticket", err)
        }
      })
  }

  useEffect(() => {
    fetchFormsData()
    fetchTicketStatuses()
    fetchTicketCategories()
    fetchTeams()
  }, [])

  useEffect(() => {
    selectedTeamId && fetchUsers()
  }, [selectedTeamId])

  const handleFormChange = e => {
    const selectedFormId = e.target.value
    setErrors(prevErrors => ({ ...prevErrors, form: null }))
    fetchFormDetails(selectedFormId)
  }

  const handleStatusChange = e => {
    const selectedStatusId = e.target.value
    setSelectedStatusId(selectedStatusId)
    setErrors(prevErrors => ({ ...prevErrors, status: null }))
  }

  const handleCategoryChange = e => {
    const selectedCategoryId = e.target.value
    setSelectedCategoryId(selectedCategoryId)
    setErrors(prevErrors => ({ ...prevErrors, category: null }))
  }

  const handleTeamChange = e => {
    const selectedTeamId = e.target.value
    setSelectedTeamId(selectedTeamId)
    setErrors(prevErrors => ({ ...prevErrors, team: null }))
  }

  const handleUserChange = e => {
    const selectedUserId = e.target.value
    setSelectedUserId(selectedUserId)
  }

  const handleInputChange = e => {
    const { id, value } = e.target

    if (id === "refNo") {
      setRefNo(value)
    }

    if (id === "typeOfRequest") {
      // Find the first team that has the selected type of request in its ticketRequests array
      const matchingTeam = teams.find(team =>
        team.ticketRequests.includes(value)
      )

      if (matchingTeam) {
        setSelectedTeamId(matchingTeam._id) // Automatically select the matching team
        fetchUsers(matchingTeam._id) // Fetch users for the selected team
      }
    }

    // If the priority is selected, remove the error for priority
    if (id === "prioritySelect" && value) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors }
        delete newErrors.priority
        return newErrors
      })
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [id]: value ? null : errors[id],
    }))
  }

  const handleMultiSelectChange = (field, selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []

    setErrors(prevErrors => {
      const newErrors = { ...prevErrors }

      if (selectedValues.length > 0) {
        delete newErrors[field._id]
      } else {
        if (field.required) {
          newErrors[field._id] = `Required`
        }
      }

      return newErrors
    })

    setSelectedForm(prevForm => {
      if (!prevForm) return prevForm

      return {
        ...prevForm,
        fields: prevForm.fields.map(f => {
          if (f.field._id === field._id) {
            return { ...f, selectedValues }
          }
          return f
        }),
      }
    })
  }

  const validateField = (field, value) => {
    let isValid = true

    if (field.type.value === "multi-select") {
      if (field.required && (!value || value.length === 0)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field._id]: `Required`,
        }))
        isValid = false
      } else {
        setErrors(prevErrors => {
          const newErrors = { ...prevErrors }
          delete newErrors[field._id]
          return newErrors
        })
        isValid = true
      }
    } else if (field.type.value === "checkbox") {
      if (field.required && value === null) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field._id]: `${field.name} must be checked or unchecked`,
        }))
        isValid = false
      } else {
        setErrors(prevErrors => {
          const newErrors = { ...prevErrors }
          delete newErrors[field._id]
          return newErrors
        })
        isValid = true
      }
    } else {
      if (field.required && (!value || value.length === 0)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field._id]: `Required`,
        }))
        isValid = false
      } else {
        setErrors(prevErrors => {
          const newErrors = { ...prevErrors }
          delete newErrors[field._id]
          return newErrors
        })
        isValid = true
      }
    }

    return isValid
  }

  const renderField = field => {
    const error = errors[field._id]

    switch (field.type.value) {
      case "dropdown":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="select"
              id={field._id}
              defaultValue={field.defaultValue}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            >
              <option value="">Select {field.name}</option>
              {field.values.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </Input>
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "text":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              id={field._id}
              placeholder={field.name}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "number":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              id={field._id}
              placeholder={field.name}
              pattern="[0-9]*"
              onBlur={e => validateField(field, e.target.value)}
              onInput={e => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "")
              }}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "text-area":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="textarea"
              id={field._id}
              placeholder={field.name}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "checkbox":
        return (
          <FormGroup key={field._id} check inline className="mb-2">
            <Label check>
              <Input
                type="checkbox"
                id={field._id}
                onChange={e => validateField(field, e.target.checked)}
                onBlur={e => validateField(field, e.target.checked)}
              />
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "multi-select":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Select
              isMulti
              name={field._id}
              options={field.values.map(value => ({ value, label: value }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={selectedOptions => {
                handleMultiSelectChange(field, selectedOptions)
              }}
              onBlur={() => {
                const fieldValue = document.getElementsByName(field._id)[0]
                  .value
                validateField(field, fieldValue)
              }}
              // defaultValue={field.values
              //   .filter(value => value === field.defaultValue)
              //   .map(value => ({ value, label: value }))}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "decimal":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              id={field._id}
              placeholder={field.name}
              onBlur={e => validateField(field, e.target.value)}
              onInput={e => {
                const value = e.target.value
                e.target.value = value.match(/^\d*\.?\d{0,4}$/)
                  ? value
                  : value.slice(0, -1)
              }}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "date":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="date"
              id={field._id}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      default:
        return null
    }
  }

  const validateForm = () => {
    let isValid = true
    const newErrors = {}

    if (!selectedForm) {
      newErrors.form = "Required"
      isValid = false
    } else if (selectedForm.fields) {
      selectedForm.fields.forEach(({ field, selectedValues }) => {
        const fieldValue = document.getElementById(field._id)?.value
        const fieldType = field.type.value

        if (fieldType === "multi-select") {
          // console.log("selected values are", selectedValues)
          if (
            field.required &&
            (!selectedValues || selectedValues.length === 0)
          ) {
            newErrors[field._id] = `Required`
            isValid = false
          }
        } else if (field.required && !fieldValue) {
          newErrors[field._id] = `Required`
          isValid = false
        }
      })
    }

    if (!document.getElementById("prioritySelect").value) {
      newErrors.priority = "Required"
      isValid = false
    }

    if (!selectedStatusId) {
      newErrors.status = "Required"
      isValid = false
    }

    if (!selectedCategoryId) {
      newErrors.category = "Required"
      isValid = false
    }

    if (!document.getElementById("refNo").value) {
      newErrors.refNo = "Required"
      isValid = false
    }

    if (!selectedTeamId) {
      newErrors.team = "Required"
      isValid = false
    }

    if (!document.getElementById("clientName").value) {
      newErrors.clientName = "Required"
      isValid = false
    }

    if (!document.getElementById("clientPhone").value) {
      newErrors.clientPhone = "Required"
      isValid = false
    }

    if (!document.getElementById("clientEmail").value) {
      newErrors.clientEmail = "Required"
      isValid = false
    }

    if (!document.getElementById("typeOfRequest").value) {
      newErrors.typeOfRequest = "Required"
      isValid = false
    }

    if (!document.getElementById("natureOfRequest").value) {
      newErrors.natureOfRequest = "Required"
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleNatureOfRequestChange = e => {
    const value = e.target.value
    setNatureOfRequest(value)

    if (value) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors }
        delete newErrors.natureOfRequest
        return newErrors
      })
    }
  }

  const handleSave = () => {
    if (validateForm()) {
      createTicketRequest()
    } else {
      console.log("Form is invalid. Please fill out the required fields.")
      console.log("Errors:", errors)
    }
  }

  useEffect(() => {
    if (refNo) {
      axios
        .get(
          `https://rd0.cpvarabia.com/api/Care/Project_Users.php?Token=OKRJ_R85rkn9nrgg&ReferenceNo=${refNo}`
        )
        .then(res => {
          if (res.data.error) {
            setNoData(true)
            // Clear the client info fields if there's an error
            setClientName("")
            setClientEmail("")
            setClientPhone("")
          } else {
            setNoData(false)
            console.log("data", res.data.Name)
            // Set the client info fields with the response data
            setClientName(res.data.Name)
            setClientEmail(res.data.Email)
            setClientPhone(res.data.Mobile)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [refNo])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="py-3 px-2">
      <Container fluid className="py-4 px-5 pt-2 position-relative">
        <div className="d-flex align-items-center mb-1">
          <i
            onClick={() => navigate("/ticket/list")}
            className="mdi mdi-arrow-left text-secondary py-0 px-3"
            style={{ cursor: "pointer" }}
          ></i>

          <div className="flex-grow-1">
            <Breadcrumbs title="Tickets" breadcrumbItem="New Ticket" />
          </div>
        </div>

        {creatingTicket && (
          <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
            <div>
              <CustomSpinner />
            </div>
          </div>
        )}

        {loadingData ? (
          <Row>
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          </Row>
        ) : (
          <Row className="gx-4">
            <Col md={8} style={{ height: "78vh" }}>
              <Card style={{ height: "100%", overflow: "auto" }}>
                <CardHeader>
                  <h5 className="mb-0">Form</h5>
                  {selectedForm && (
                    <span>
                      Fields marked with <span className="text-danger">*</span>{" "}
                      are required
                    </span>
                  )}
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="formSelect">Form Name</Label>
                    <Input
                      type="select"
                      id="formSelect"
                      onChange={handleFormChange}
                    >
                      <option value="">Choose a form</option>
                      {forms.map(form => (
                        <option key={form._id} value={form._id}>
                          {form.name}
                        </option>
                      ))}
                    </Input>

                    {errors.form && (
                      <div className="text-danger mb-3">{errors.form}</div>
                    )}
                  </FormGroup>

                  {!selectedForm ? (
                    <div className="text-center mt-5">
                      <i
                        className="mdi mdi-file-document-outline"
                        style={{ fontSize: "50px", color: "#ccc" }}
                      ></i>
                      <p className="mt-3" style={{ color: "#ccc" }}>
                        No Form Chosen Yet!
                      </p>
                    </div>
                  ) : gettingFormData ? (
                    <CustomSpinner />
                  ) : (
                    <div className="mt-3">
                      {selectedForm.fields.map(field =>
                        renderField(field.field)
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={4} style={{ height: "73vh" }}>
              <Card style={{ height: "100%", overflow: "auto" }}>
                <CardBody>
                  <div className="p-2 rounded-2 border shadow-sm">
                    <h5>Ticket Info</h5>

                    <FormGroup>
                      <Label for="prioritySelect">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="prioritySelect"
                        onChange={handleInputChange}
                      >
                        <option value="">Choose priority</option>
                        <option value="Low">Low</option>
                        <option value="Normal">Normal</option>
                        <option value="High">High</option>
                        <option value="Urgent">Urgent</option>
                      </Input>
                      {errors.priority && (
                        <span className="text-danger">{errors.priority}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="category">
                        Category <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="category"
                        onChange={handleCategoryChange}
                      >
                        <option value="">Choose category</option>
                        {categories.map(category => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </Input>
                      {errors.category && (
                        <span className="text-danger">{errors.category}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="statusSelect">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="statusSelect"
                        onChange={handleStatusChange}
                      >
                        <option value="">Choose status</option>
                        {statuses.map(status => (
                          <option key={status._id} value={status._id}>
                            {status.name}
                          </option>
                        ))}
                      </Input>
                      {errors.status && (
                        <span className="text-danger">{errors.status}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="refNo">
                        Reference number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="refNo"
                        placeholder="Enter ref Number"
                        onChange={handleInputChange}
                      />
                      {errors.refNo && (
                        <span className="text-danger">{errors.refNo}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="typeOfRequest">
                        Type of Request <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="typeOfRequest"
                        onChange={handleInputChange}
                      >
                        <option value="">Choose type</option>
                        <option value="RD0">RD0</option>
                        <option value="Edit RD0">Edit RD0</option>
                        <option value="Missing Data">Missing Data</option>
                        <option value="Design Review">Design Review</option>
                        <option value="RD6">RD6</option>
                        <option value="RD7">RD7</option>
                        <option value="Finance">Finance</option>
                        <option value="Inspection">Inspection</option>
                        <option value="MALATH Issue">MALATH Issue</option>
                        <option value="MALATH Complaint">
                          MALATH Complaint
                        </option>
                        <option value="Other">Other</option>
                      </Input>

                      {errors.typeOfRequest && (
                        <span className="text-danger">
                          {errors.typeOfRequest}
                        </span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="natureOfRequest">
                        Nature of Request <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="natureOfRequest"
                        value={natureOfRequest}
                        onChange={handleNatureOfRequestChange}
                      >
                        <option value="">Choose nature</option>
                        <option value="Request">Request</option>
                        <option value="Complaint">Complaint</option>
                        <option value="Inquiry">Inquiry</option>
                      </Input>

                      {errors.natureOfRequest && (
                        <span className="text-danger">
                          {errors.natureOfRequest}
                        </span>
                      )}
                    </FormGroup>

                    {natureOfRequest === "Complaint" && (
                      <FormGroup check inline className="mb-2">
                        <Label check>
                          <Input
                            type="checkbox"
                            id="complaintReport"
                            checked={complaintReport}
                            onClick={() => setComplaintReport(!complaintReport)}
                          />{" "}
                          Complaint Report{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        {errors.complaintReport && (
                          <span className="text-danger">
                            {errors.complaintReport}
                          </span>
                        )}
                      </FormGroup>
                    )}

                    <FormGroup>
                      <Label for="teamSelect">
                        Choose a team <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="teamSelect"
                        value={selectedTeamId}
                        onChange={handleTeamChange}
                      >
                        <option value="">Select team</option>
                        {teams.map(team => (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        ))}
                      </Input>
                      {errors.team && (
                        <span className="text-danger">{errors.team}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="assigneeSelect">Assignee</Label>
                      <Input
                        type="select"
                        id="assigneeSelect"
                        disabled={!selectedTeamId}
                        value={selectedUserId}
                        onChange={handleUserChange}
                      >
                        <option value="">Choose assignee</option>
                        {users.map(user => (
                          <option key={user._id} value={user._id}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>

                  <AddTicketClientInfo
                    handleInputChange={handleInputChange}
                    clientName={clientName}
                    clientPhone={clientPhone}
                    clientEmail={clientEmail}
                    errors={errors}
                  />

                  {/* <div className="p-2 rounded-2 border shadow-sm mt-4">
                    <h5 className="">Client Details</h5>

                    <FormGroup>
                      <Label for="clientName">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="clientName"
                        placeholder="Enter client name"
                        defaultValue={clientName ? clientName : ""}
                        onChange={handleInputChange}
                      />
                      {errors.clientName && (
                        <span className="text-danger">{errors.clientName}</span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="clientPhone">
                        Phone Number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="clientPhone"
                        placeholder="Enter phone number"
                        onChange={handleInputChange}
                      />
                      {errors.clientPhone && (
                        <span className="text-danger">
                          {errors.clientPhone}
                        </span>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for="clientEmail">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        id="clientEmail"
                        placeholder="Enter client email"
                        onChange={handleInputChange}
                      />
                      {errors.clientEmail && (
                        <span className="text-danger">
                          {errors.clientEmail}
                        </span>
                      )}
                    </FormGroup>
                  </div> */}
                </CardBody>
              </Card>

              <div className="d-flex justify-content-end">
                <Button
                  onClick={e => {
                    e.preventDefault()
                    navigate("/ticket/list")
                  }}
                  color="secondary"
                  outline
                  className="py-1 px-2"
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={handleSave}
                  className="py-1 px-2 ms-2"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default AddTicket
