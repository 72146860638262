import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import configs from "config"
import { useParams } from "react-router-dom"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import SubmitButtonComponent from "./SubmitButtonComponent"
const { Url, apiVersion } = configs.client
import Select from "react-select"

function EditTicketInfo({
  ticketData,
  fetchTicketData,
  doingEditsLoading,
  setDoingEditsLoading,
}) {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const params = useParams()
  const dispatch = useDispatch()

  const [ticketInfoModal, setTicketInfoModal] = useState(false)
  const toggleTicketInfoModal = () => setTicketInfoModal(!ticketInfoModal)

  const [userInfoModal, setUserInfoModal] = useState(false)
  const toggleUserInfoModal = () => setUserInfoModal(!userInfoModal)

  const [clientInfoModal, setClientInfoModal] = useState(false)
  const toggleClientInfoModal = () => setClientInfoModal(!clientInfoModal)

  const [statuses, setStatuses] = useState([])
  const [statusesData, setStatusesData] = useState([])
  const [categories, setCategories] = useState([])
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([])
  const [selectedTeamId, setSelectedTeamId] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("")

  const [showTeamError, setShowTeamError] = useState(false)
  const [clientInfoErrors, setClientInfoErrors] = useState({
    name: "",
    number: "",
    email: "",
  })

  const [formEditTicketInfo, setFormEditTicketInfo] = useState({
    priority: ticketData?.priority,
    statusSelect: ticketData?.status?._id,
    category: ticketData?.category?._id,
  })

  const [formEditClientInfo, setFormEditClientInfo] = useState({
    name: ticketData?.client?.name || "",
    number: ticketData?.client?.number || "",
    email: ticketData?.client?.email || "",
  })

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Highlight focused option, otherwise white
      color: state.isSelected ? "#333" : "#000", // Change text color if selected
    }),
    control: provided => ({
      ...provided,
      borderColor: "#ddd", // Custom border color for the select input
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff", // Make sure the dropdown menu is white
    }),
  }

  function isUserSupervisor(userData, teamsData, supervisorTeamId) {
    const superVisorTeam = teams.find(team => team._id === supervisorTeamId)

    if (superVisorTeam && superVisorTeam.supervisor._id === userData._id) {
      return true
    } else {
      return false
    }
  }

  const fetchTicketStatuses = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketStatuses?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const statusOptions = res.data.data.statuses.map(status => ({
          value: status._id,
          label: status.name,
        }))
        setStatuses(statusOptions)
        setStatusesData(res.data.data.statuses)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchTicketCategories = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketCategories?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const categoryOptions = res.data.data.categories.map(category => ({
          value: category._id,
          label: category.name,
        }))
        setCategories(categoryOptions)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const teamOptions = res.data.data.teams.map(team => ({
          value: team._id,
          label: team.name,
        }))
        setTeams(teamOptions)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const fetchUsers = () => {
    axios
      .get(
        `${Url}/${apiVersion}/users?type=ticketCreate&teamID=${selectedTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        const userOptions = res.data.data.users.map(user => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
        }))
        setUsers(userOptions)
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleEditTicketInfoSubmit = () => {
    const requestBody = {}

    if (formEditTicketInfo.priority !== ",,,") {
      requestBody.priority = formEditTicketInfo.priority
    }
    if (formEditTicketInfo.statusSelect !== ",,,") {
      requestBody.status = formEditTicketInfo.statusSelect
    }
    if (formEditTicketInfo.category !== ",,,") {
      requestBody.category = formEditTicketInfo.category
    }

    toggleTicketInfoModal()
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/info`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        console.log("Ticket updated successfully", res.data)
        fetchTicketData()
        setDoingEditsLoading(false)
      })
      .catch(err => {
        setDoingEditsLoading(false)
        if (err.response?.status === 401 || err.response?.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleEditUserInfoSubmit = statusId => {
    if (!selectedTeamId) {
      setShowTeamError(true)
      return
    }

    const requestBody = {}

    if (selectedTeamId) {
      requestBody.team = selectedTeamId
    }
    if (selectedUserId) {
      requestBody.assignee = selectedUserId
    }
    if (statusId) {
      requestBody.status = statusId
    }

    if (!Object.keys(requestBody).length) {
      setShowTeamError(true)
      return
    }

    toggleUserInfoModal()
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/transfer`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        console.log("Ticket updated successfully", res.data)
        setDoingEditsLoading(false)
        fetchTicketData()
      })
      .catch(err => {
        setDoingEditsLoading(false)

        if (err.response?.status === 401 || err.response?.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleEditClientInfoSubmit = () => {
    const errors = {}

    if (!formEditClientInfo.name) {
      errors.name = "Required"
    }

    if (!formEditClientInfo.number) {
      errors.number = "Required"
    } else if (formEditClientInfo.number.length < 11) {
      errors.number = "Phone number must be at least 11 digits"
    }

    if (!formEditClientInfo.email) {
      errors.email = "Required"
    }

    setClientInfoErrors(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    const requestBody = {
      client: formEditClientInfo,
    }

    toggleClientInfoModal()
    setDoingEditsLoading(true)

    axios
      .patch(
        `${Url}/${apiVersion}/tickets/${params.ticketId}/client`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        console.log("Client details updated successfully", res.data)
        setDoingEditsLoading(false)
        fetchTicketData()
      })
      .catch(err => {
        setDoingEditsLoading(false)

        if (err.response?.status === 401 || err.response?.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleInputChange = selectedOption => {
    const { name, value } = selectedOption
    setFormEditTicketInfo({
      ...formEditTicketInfo,
      [name]: value,
    })
  }

  const handleClientInputChange = selectedOption => {
    const { name, value } = selectedOption
    setFormEditClientInfo({
      ...formEditClientInfo,
      [name]: value,
    })
  }

  const handleTeamChange = e => {
    const selectedTeamId = e.target.value
    setSelectedTeamId(selectedTeamId)
    setShowTeamError(false)
  }

  const handleUserChange = e => {
    const selectedUserId = e.target.value
    setSelectedUserId(selectedUserId)
  }

  useEffect(() => {
    fetchTicketStatuses()
    fetchTicketCategories()
    fetchTeams()
  }, [])

  useEffect(() => {
    selectedTeamId && fetchUsers()
  }, [selectedTeamId])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Card
      style={{
        height: "calc(74vh - 6px)",
        maxHeight: "calc(74vh - 6px)",
        overflow: "auto",
      }}
      className="p-3"
    >
      <CardBody className="shadow-sm rounded-3 mb-2">
        <div className="mb-3 d-flex justify-content-between">
          <h5 className="mb-0">Ticket Info</h5>

          {userData?.role === "admin" ||
          userData?._id === ticketData?.assignee?._id ||
          userData?._id === ticketData?.creator?._id ||
          isUserSupervisor(userData, teams, ticketData?.team?._id) ? (
            <i
              className="bx bx-edit-alt bx-sm"
              style={{ cursor: "pointer" }}
              onClick={() => toggleTicketInfoModal()}
            ></i>
          ) : (
            <div></div>
          )}
        </div>

        <div className="">
          <h6 className="text-muted">
            Priority <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.priority == "Low" && (
              <Badge pill className="badge-soft-primary ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Low
              </Badge>
            )}

            {ticketData?.priority == "Normal" && (
              <Badge pill className="badge-soft-success ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Normal
              </Badge>
            )}

            {ticketData?.priority == "High" && (
              <Badge pill className="badge-soft-warning ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> High
              </Badge>
            )}

            {ticketData?.priority == "Urgent" && (
              <Badge pill className="badge-soft-danger ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Urgent
              </Badge>
            )}

            {(!ticketData?.priority ||
              (ticketData?.priority !== "Low" &&
                ticketData?.priority !== "Normal" &&
                ticketData?.priority !== "High" &&
                ticketData?.priority !== "Urgent")) && (
              <Badge pill className="badge-soft-dark ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span>{" "}
                {ticketData?.priority || "Unknown"}
              </Badge>
            )}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Status <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.status?.category?.toLowerCase() == "pending" && (
              <Badge pill className="badge-soft-warning ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Pending
              </Badge>
            )}

            {ticketData?.status?.category?.toLowerCase() == "solved" && (
              <Badge pill className="badge-soft-secondary ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Solved
              </Badge>
            )}

            {ticketData?.status?.category?.toLowerCase() == "new" && (
              <Badge pill className="badge-soft-danger ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> New
              </Badge>
            )}

            {ticketData?.status?.category?.toLowerCase() == "open" && (
              <Badge pill className="badge-soft-success ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span> Open
              </Badge>
            )}

            {(!ticketData?.status?.category ||
              (ticketData?.status?.category?.toLowerCase() !== "new" &&
                ticketData?.status?.category?.toLowerCase() !== "open" &&
                ticketData?.status?.category?.toLowerCase() !== "solved" &&
                ticketData?.status?.category?.toLowerCase() !== "pending")) && (
              <Badge pill className="badge-soft-dark ms-1 font-size-12">
                <span className="font-size-16 me-1">&#9679;</span>{" "}
                {ticketData?.status?.category || "Unknown"}
              </Badge>
            )}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Reference No <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.refNo ? ticketData?.refNo : "Enter a ref number"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Category <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.category?.name
              ? ticketData?.category?.name
              : "Choose a category name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Type of request <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.requestType
              ? ticketData?.requestType
              : "Choose request type"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Nature of request <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1 mb-0">
            {ticketData?.requestNature
              ? ticketData?.requestNature
              : "Choose the request type"}
          </p>
        </div>
      </CardBody>

      <CardBody className="shadow-sm rounded-3 mb-2">
        <div className="mb-3 d-flex justify-content-between">
          <h5 className="mb-0">User Info</h5>

          {userData?.role === "admin" ||
          userData?._id === ticketData?.assignee?._id ||
          userData?._id === ticketData?.creator?._id ||
          isUserSupervisor(userData, teams, ticketData?.team?._id) ? (
            <i
              className="bx bx-edit-alt bx-sm"
              style={{ cursor: "pointer" }}
              onClick={() => toggleUserInfoModal()}
            ></i>
          ) : (
            <div></div>
          )}
        </div>

        <div className="">
          <h6 className="text-muted">
            Requested by <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.creator?.firstName || ticketData?.creator?.lastName
              ? `${ticketData?.creator?.firstName} ${ticketData?.creator?.lastName}`
              : "Choose an creator name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Assignee team <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.team?.name
              ? ticketData?.team?.name
              : "Choose a team name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Assignee user <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1 mb-0">
            {ticketData?.assignee?.firstName || ticketData?.assignee?.lastName
              ? `${ticketData?.assignee?.firstName} ${ticketData?.assignee?.lastName}`
              : "Choose an assignee name"}
          </p>
        </div>
      </CardBody>

      {/* test */}
      <CardBody className="shadow-sm rounded-3">
        <div className="mb-3 d-flex justify-content-between">
          <h5 className="mb-0">Client Details</h5>
          {userData?.role === "admin" ||
          userData?._id === ticketData?.assignee?._id ||
          userData?._id === ticketData?.creator?._id ||
          isUserSupervisor(userData, teams, ticketData?.team?._id) ? (
            <i
              className="bx bx-edit-alt bx-sm"
              style={{ cursor: "pointer" }}
              onClick={() => toggleClientInfoModal()}
            ></i>
          ) : (
            <div></div>
          )}
        </div>

        <div className="">
          <h6 className="text-muted">
            Name <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.client?.name
              ? ticketData?.client?.name
              : "Enter client name"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Phone Number <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1">
            {ticketData?.client?.number
              ? ticketData?.client?.number
              : "Enter phone number"}
          </p>
        </div>

        <div className="">
          <h6 className="text-muted">
            Email <span className="text-danger">*</span>
          </h6>

          <p className="border border-secondary border-opacity-25 rounded-1 p-1 mb-0">
            {ticketData?.client?.email
              ? ticketData?.client?.email
              : "Enter email address"}
          </p>
        </div>
      </CardBody>

      <Modal isOpen={ticketInfoModal} toggle={toggleTicketInfoModal} centered>
        <ModalHeader toggle={toggleTicketInfoModal}>
          Edit Ticket info
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="priority">
              Priority <span className="text-danger">*</span>
            </Label>

            <Select
              id="priority"
              name="priority"
              options={[
                { value: "Low", label: "Low" },
                { value: "Normal", label: "Normal" },
                { value: "High", label: "High" },
                { value: "Urgent", label: "Urgent" },
              ]}
              // Pass the entire object for `value`, matching the selected option
              value={
                formEditTicketInfo.priority
                  ? {
                      value: formEditTicketInfo.priority,
                      label: formEditTicketInfo.priority,
                    }
                  : null
              }
              onChange={selectedOption =>
                setFormEditTicketInfo({
                  ...formEditTicketInfo,
                  priority: selectedOption.value, // Update the state with selected value
                })
              }
              styles={customStyles}
            />
          </FormGroup>

          <FormGroup>
            <Label for="statusSelect">
              Status <span className="text-danger">*</span>
            </Label>

            <Select
              id="statusSelect"
              name="statusSelect"
              options={statuses}
              value={statuses.find(
                option => option.value === formEditTicketInfo.statusSelect
              )}
              onChange={selectedOption =>
                setFormEditTicketInfo({
                  ...formEditTicketInfo,
                  statusSelect: selectedOption.value,
                })
              }
              styles={customStyles}
            />
          </FormGroup>

          <FormGroup>
            <Label for="category">
              Category <span className="text-danger">*</span>
            </Label>

            <Select
              id="category"
              name="category"
              options={categories}
              value={categories.find(
                option => option.value === formEditTicketInfo.category
              )}
              onChange={selectedOption =>
                setFormEditTicketInfo({
                  ...formEditTicketInfo,
                  category: selectedOption.value,
                })
              }
              styles={customStyles}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleTicketInfoModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            onClick={handleEditTicketInfoSubmit}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={userInfoModal} toggle={toggleUserInfoModal} centered>
        <ModalHeader toggle={toggleUserInfoModal}>Edit User info</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="teamSelect">
              Choose a team <span className="text-danger">*</span>
            </Label>

            <Select
              id="teamSelect"
              options={teams}
              value={teams.find(option => option.value === selectedTeamId)}
              onChange={selectedOption =>
                setSelectedTeamId(selectedOption.value)
              }
              styles={customStyles}
            />

            {showTeamError && <div className="text-danger mt-2">Required</div>}
          </FormGroup>

          <FormGroup>
            <Label for="assigneeSelect">Assignee</Label>

            <Select
              id="assigneeSelect"
              options={users}
              value={users.find(option => option.value === selectedUserId)}
              onChange={selectedOption =>
                setSelectedUserId(selectedOption.value)
              }
              isDisabled={!selectedTeamId}
              styles={customStyles}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleUserInfoModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <SubmitButtonComponent
            disabledStatus={!selectedTeamId}
            statuses={statusesData}
            handleSubmit={handleEditUserInfoSubmit}
            handleSubmitStatus={handleEditUserInfoSubmit}
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={clientInfoModal} toggle={toggleClientInfoModal} centered>
        <ModalHeader toggle={toggleClientInfoModal}>
          Edit Client Details
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="clientName">
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              id="clientName"
              name="name"
              type="text"
              defaultValue={formEditClientInfo.name}
              onChange={e =>
                setFormEditClientInfo({
                  ...formEditClientInfo,
                  name: e.target.value,
                })
              }
            />

            {clientInfoErrors.name && (
              <div className="text-danger">{clientInfoErrors.name}</div>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="clientPhone">
              Phone Number <span className="text-danger">*</span>
            </Label>
            <Input
              id="clientPhone"
              name="number"
              type="text"
              defaultValue={formEditClientInfo.number}
              onChange={e =>
                setFormEditClientInfo({
                  ...formEditClientInfo,
                  number: e.target.value,
                })
              }
            />

            {clientInfoErrors.number && (
              <div className="text-danger">{clientInfoErrors.number}</div>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="clientEmail">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              id="clientEmail"
              name="email"
              type="email"
              defaultValue={formEditClientInfo.email}
              onChange={e =>
                setFormEditClientInfo({
                  ...formEditClientInfo,
                  email: e.target.value,
                })
              }
            />

            {clientInfoErrors.email && (
              <div className="text-danger">{clientInfoErrors.email}</div>
            )}
          </FormGroup>
        </ModalBody>

        <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
          <Button
            color="secondary"
            outline
            onClick={toggleClientInfoModal}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            onClick={handleEditClientInfoSubmit}
            style={{ padding: "0.1875rem 0.875rem" }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  )
}

export default EditTicketInfo
