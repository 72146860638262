import React, { useEffect, useState } from "react"
import { Formik, useFormik, validateYupSchema } from "formik"
import { FormControl } from "react-bootstrap"
import Switch from 'react-switch';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Toast,
    ToastBody,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { Checkbox } from "primereact/checkbox"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import AwayIcon from "components/Common/Icons/SidebarIcons/AwayIcon"

export default function EditStatus({
    toggle,
    isOpen,
    setShowEditToast,
    fetchAllStatuses,
    status,
}) {
    console.log("selected-status", status)


    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const AddStatusesSchema = Yup.object().shape({
        name: Yup.string()
            .required("required")
            .matches(/^(?!\s+$).*/, "invalid"),
        endUserDisplayName: Yup.string()
            .required("required")
            .matches(/^(?!\s+$).*/, "invalid"),
        // assigneeDisplayName: Yup.string()
        //     .required("required")
        //     .matches(/^(?!\s+$).*/, "invalid"),
        description: Yup.string(),
        category: Yup.string().required("required"),
        status: Yup.string(),
        // default: Yup.boolean()
        //     .test('check-default-status', 'Default can only be true if status is active!', function (value) {
        //         const { status } = this.parent;
        //         if (status !== 'active' && value === true) {
        //             return false;
        //         }
        //         return true;
        //     })
        // status: Yup.boolean()
        //     .test('status-check', 'Cannot be active if default is checked', function (value) {
        //         const { default: isDefault } = this.parent;
        //         return !(isDefault && value);
        //     }),
        // default: Yup.boolean()


        // default: Yup.boolean().required("required"),

    })

    // formik
    const {
        handleChange,
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        handleBlur,
        touched,
    } = useFormik({
        initialValues: {
            name: status?.name || "",
            endUserDisplayName: status?.endUserDisplayName || "",
            // assigneeDisplayName: status?.assigneeDisplayName || "",
            description: status?.description || "",
            category: status?.category || "",
            status: status?.status || "",
            // default: status?.default || "",

        },

        validationSchema: AddStatusesSchema,
        onSubmit: values => {
            const requestData = {
                ...values,
                ...(values.default === true ? { default: values.default } : {}),
            };

            if (selectedCategory?.value === 'solved' && values.default) {
                setErrorMessage("Couldn't make a default status with category solved ");
                setTimeout(() => setErrorMessage(false), 3500)
                return;
            }
            setIsLoading(true)
            axios
                .patch(`${Url}/${apiVersion}/ticketStatuses/${status?._id}`, requestData, {
                    headers: {
                        "Content-Type": "application/json; charset=utf8",
                        Authorization: `Bearer ${access}`,
                    },
                })
                .then(res => {
                    if (res.status === 200) {
                        fetchAllStatuses()
                        toggle()
                        setIsLoading(false)
                        setShowEditToast(true)
                        setTimeout(() => setShowEditToast(false), 2500)
                    } else {
                        console.error("Unexpected status code:", res.status, res.data)
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error("Error:", error)
                    if (error.response.status === 401 || error.response.status === 0) {
                        UnAuthorized()
                        // dispatch(authorized())
                    } else if (error.response?.status === 403) {
                        setErrorMessage(error.response.data.message)
                        setTimeout(() => setErrorMessage(false), 3500)
                    } else {
                        setErrorMessage(error.response?.data.message)
                        setTimeout(() => setErrorMessage(false), 3500)
                        console.log(error)
                    }
                })
        },
    })

    //select-category
    const colorStylesCategory = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "gray" : "white",
            backgroundColor: state.isFocused ? "#EBEDF0" : "white",
            color: state.isSelected ? "gray" : "black",
        }),
        menu: provided => ({
            ...provided,
            zIndex: 10000,
            height: "1rem",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: "10rem",
            overflowY: "auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // color: "white",
            color: state.data.color,
            backgroundColor: state.data.backgroundColor,
            width: "max-content",
            padding: "0.2rem 0.8rem",
            borderRadius: "0.6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
        }),
    }
    const optionsCategory = [
        {
            label: "new",
            value: "new",
            color: "#00BFAE",
            backgroundColor: "#E0F7FA",
        },
        {
            label: "open",
            value: "open",
            color: "#0288D1",
            backgroundColor: "#E3F2FD",
        },
        {
            label: "pending",
            value: "pending",
            color: "#C62828",
            backgroundColor: "#FFEBEE",
        },
        {
            label: "solved",
            value: "solved",
            color: "#388E3C",
            backgroundColor: "#e9ffef",
        },
    ]
    const [selectedCategory, setSelectedCategory] = useState(null)
    const handleChangeCategory = selectedOption => {
        setSelectedCategory(selectedOption)
        setFieldValue("category", selectedOption.label)
    }

    useEffect(() => {

        if (status?.category) {
            const selectedOption = optionsCategory.find(
                option => option.value === status.category
            )
            setSelectedCategory(selectedOption)
            setFieldValue("category", selectedOption.value)
        }

        if (status) {
            setFieldValue("status", status.status || "")
            setFieldValue("name", status?.name)
            setFieldValue("endUserDisplayName", status?.endUserDisplayName)
            // setFieldValue("assigneeDisplayName", status?.assigneeDisplayName)
            setFieldValue("description", status?.description)
        }

    }, [status])

    // switch active -- inactive:
    const [isChecked, setIsChecked] = useState(status?.status === 'active');
    useEffect(() => {
        if (status?.status) {
            setIsChecked(status.status === 'active');
        }
    }, [status]);
    useEffect(() => {
        setFieldValue("status", isChecked ? 'active' : 'inactive');
    }, [isChecked, setFieldValue]);

    // default status:
    const [isDefaultChecked, setIsDefaultChecked] = useState(status?.default === true);
    useEffect(() => {
        setIsDefaultChecked(status?.default === true);
    }, [status]);

    const toggleDefaultSwitch = () => {
        const newDefaultChecked = !isDefaultChecked;
        setIsDefaultChecked(newDefaultChecked);
        setFieldValue("status", 'active')
        setFieldValue("default", newDefaultChecked);

        if (newDefaultChecked) {
            setIsChecked(true);
        }
    };
    const toggleSwitch = () => {
        if (!isDefaultChecked) {
            setIsChecked(!isChecked);
        }
    };

    if (!authorized) {
        return <UnAuthorizedPopup />
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            onClosed={() => resetForm()}
            size="lg"
            centered
        >
            <ModalHeader className="mb-0" toggle={toggle}>Edit Status</ModalHeader>
            <ModalBody>
                {errorMessage && (
                    <div className="" aria-live="polite" aria-atomic="true">
                        <div className="">
                            <Toast isOpen={errorMessage} fade={true}>
                                <ToastBody className="delete-message-bg">
                                    <h5 className="m-0 mb-2">Error</h5>
                                    <p className="m-0">{errorMessage}</p>
                                </ToastBody>
                            </Toast>
                        </div>
                    </div>
                )}

                <Row className="justify-content-center ">
                    <form onSubmit={handleSubmit}>

                        <Row>

                            <Row>
                                <Col>
                                    <FormGroup className="m-0 m-mb-3">
                                        <Label className="mb-1">name</Label>
                                        <Input
                                            className=" p-5px m-0 "
                                            id="namw"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                        />
                                        {errors.name && touched.name && (
                                            <div className="error mb-0">
                                                {errors.name}
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup className="m-0 m-mb-3">
                                        <Label className="mb-1">End User View</Label>
                                        <Input
                                            className=" p-5px m-0 "
                                            id="endUserDisplayName"
                                            name="endUserDisplayName"
                                            value={values.endUserDisplayName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                        />
                                        {errors.endUserDisplayName && touched.endUserDisplayName && (
                                            <div className="error mb-0">
                                                {errors.endUserDisplayName}
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col>
                                    <FormGroup className="m-0 m-mb-3">
                                        <Label className="mb-1">Assignee View</Label>
                                        <Input
                                            className=" p-5px m-0 "
                                            id="assigneeDisplayName"
                                            name="assigneeDisplayName"
                                            value={values.assigneeDisplayName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                        />
                                        {errors.assigneeDisplayName &&
                                            touched.assigneeDisplayName && (
                                                <div className="error mb-0">
                                                    {errors.assigneeDisplayName}
                                                </div>
                                            )}
                                    </FormGroup>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col>
                                    <FormGroup className="m-0 m-mb-5">
                                        <Label className="mb-1">Description</Label>
                                        <FormControl
                                            as="textarea"
                                            rows={6}
                                            id="description"
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="p-2"
                                        />
                                        {errors.description && touched.description && (
                                            <div className="error mb-0">{errors.description}</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormGroup className="m-0 m-mb-5">
                                        <Label className="mb-1">Category</Label>
                                        <Select
                                            // isDisabled
                                            className="form-selects flex-grow-1"
                                            id="category"
                                            name="category"
                                            options={optionsCategory}
                                            styles={colorStylesCategory}
                                            value={selectedCategory}
                                            onChange={handleChangeCategory}
                                        />
                                        {errors.category && touched.category && (
                                            <div className="error mb-0">{errors.category}</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>


                            <Row className=" d-flex align-items-center justify-content-between gap-3 mt-3 ">

                                <FormGroup className="m-0 m-mb-5">

                                    <div className="custom-switch d-flex align-items-center  ">
                                        <Label className="custom-checkbox-text w-sm fs-5 ">
                                            Default
                                        </Label>
                                        <Switch
                                            name="default"
                                            onChange={toggleDefaultSwitch}
                                            checked={isDefaultChecked}
                                            onColor="#86d3ff"
                                            offColor="#ccc"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={20}
                                            width={48}
                                            disabled={status?.default === true}
                                        />
                                        {errors.default && touched.default && (
                                            <div className="error mb-0">
                                                {errors.default}
                                            </div>
                                        )}

                                    </div>
                                </FormGroup>
                            </Row>

                            <Row className="d-flex ">
                                <FormGroup className="m-0 m-mb-5 d-flex  align-items-center justify-content-between">
                                    {/* <Label className="mb-3">Status</Label> */}
                                    <div className="custom-switch d-flex  align-items-center justify-content-center  ">
                                        <Label className="custom-checkbox-text w-sm   fs-5">

                                            {isChecked ? 'Active' : 'Inactive'}
                                        </Label>
                                        <Switch
                                            name="status"
                                            onChange={toggleSwitch}
                                            checked={isChecked}
                                            onColor="#86d3ff"
                                            offColor="#ccc"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={20}
                                            width={48}
                                        // disabled={isDefaultChecked}
                                        />
                                        {errors.status && touched.status && (
                                            <div className="error mb-0">
                                                {errors.status}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Row>

                        </Row>


                        {isLoading && (
                            <div className="loading-overlay">
                                <Spinner />
                            </div>
                        )}

                        <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
                            <Button type="submit" className="edit-button">
                                Edit
                            </Button>{" "}
                            <Button onClick={toggle} className="edit-button cancel-delete ">
                                Cancel
                            </Button>
                        </FormGroup>
                    </form>
                </Row>
            </ModalBody >
        </Modal >
    )
}
