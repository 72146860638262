import React, { useEffect, useState } from "react"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"

import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import PrivateAnswersTable from "./PrivateAnswers/PrivateAnswersTable"
import AddPrivateAnswers from "./PrivateAnswers/AddPrivateAnswers"
import EditPrivateAnswers from "./PrivateAnswers/EditPrivateAnswers"
import DeletePrivateAnswers from "./PrivateAnswers/DeletePrivateAnswers"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
export default function PrivateAnswers() {
  const [isloading, setIsLoading] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)

  const [PrivateAnswersData, setPrivateAnswersData] = useState([])

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const PrivateAnswers = useSelector(state => state.Login?.userData?.answersSet)

  const fetchAnswersData = () => {
    setIsLoading(true)
    axios
      .get(`${Url}/${apiVersion}/answers-sets/${PrivateAnswers}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const AllAnswersSets = res.data.data.answersSet
        setPrivateAnswersData(AllAnswersSets)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        if (error?.response?.status === 401 || error?.response?.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.error("Error fetching teams:", error)
        }
      })
  }
  useEffect(() => {
    fetchAnswersData()
  }, [])

  const [addNewPrivateAnswer, setAddNewPrivateAnswer] = useState(false)
  const toggleAdd = () => {
    setAddNewPrivateAnswer(!addNewPrivateAnswer)
  }

  const [modalDeletePrivateAnswer, setModalDeletePrivateAnswer] =
    useState(false)
  const [deletePrivateAnswer, setDeletePrivateAnswer] = useState(null)
  const toggleDelete = ans => {
    setDeletePrivateAnswer(ans)
    setModalDeletePrivateAnswer(!modalDeletePrivateAnswer)
  }

  const [modalEditPrivateAnswer, setModalEditePrivateAnswer] = useState(false)
  const [editPrivateAnswer, setEditPrivateAnswer] = useState(null)
  const toggleEdit = ans => {
    setEditPrivateAnswer(ans)
    setModalEditePrivateAnswer(!modalEditPrivateAnswer)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className=" user-setting-page  ">
      <div className="performance-pag">
        <div className="d-flex justify-content-between align-items-center users-setting">
          <p>
            <span className="users-setting-span"> Private Answers</span>
          </p>
          <div className="d-flex justify-content-center gap-2 align-items-center">
            <button onClick={toggleAdd}>+ Add Answer</button>
          </div>
        </div>
        {/* success message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showSuccessToast} fade={true}>
              <ToastBody className="success-message-bg">
                <h5 className="m-0 mb-2">Success</h5>
                <p className="m-0">Ansewr Added Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Edit Message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showEditToast} fade={true}>
              <ToastBody className="edit-message-bg">
                <h5 className="m-0 mb-2">Edit</h5>
                <p className="m-0">Answer edited Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Delete message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showDeleteToast} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Delete</h5>
                <p className="m-0">Answer Deleted Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        <PrivateAnswersTable
          isloading={isloading}
          PrivateAnswersData={PrivateAnswersData}
          setShowSuccessToast={setShowSuccessToast}
          fetchAnswersData={fetchAnswersData}
          toggleDelete={toggleDelete}
          toggleEdit={toggleEdit}
        />
      </div>
      <AddPrivateAnswers
        toggle={toggleAdd}
        isOpen={addNewPrivateAnswer}
        setShowSuccessToast={setShowSuccessToast}
        fetchAnswersData={fetchAnswersData}
        PrivateAnswers={PrivateAnswers}
      />
      <DeletePrivateAnswers
        isOpen={modalDeletePrivateAnswer}
        ans={deletePrivateAnswer}
        toggle={toggleDelete}
        fetchAnswersData={fetchAnswersData}
        setShowDeleteToast={setShowDeleteToast}
      />

      <EditPrivateAnswers
        fetchAnswersData={fetchAnswersData}
        setShowEditToast={setShowEditToast}
        isOpen={modalEditPrivateAnswer}
        ans={editPrivateAnswer}
        toggle={toggleEdit}
      />
    </div>
  )
}
