import store from "store"
import io from "socket.io-client"

import configs from "config"
const { Url, apiVersion } = configs.client

const access = store.getState().Login.userAccess

let socket = null

if (access) {
  socket = io.connect(`${Url}`, {
    query: {
      token: `Bearer ${access}`,
    },
  })
}

export default socket
