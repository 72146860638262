import ArrowBackIcon from "components/Common/Icons/SettingIcons/ArrowBackIcon"
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import ViewTeamconversation from "./ViewTeamconversation"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import ViewTeamServiceHours from "../UsersComponents/ViewTeamServiceHours"
import ViewTeamAnswer from "./ViewTeamAnswer"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import EditTeam from "./EditTeam"
import ViewTeamTicketRequest from "./ViewTeamTicketRequest"
export default function ViewTeam({
  toggleCloseView,
  team,
  isloading,
  setIsLoading,
  handleEditButtonClick,
  viewSelectedTeam,
  setViewSelectedTeam,
  setShowEditToast,
  fetchTeamsData,
  setViewTeamComponents,
  toggleViewTeamComponents,
}) {
  // const [isLoading, setIsLoading] = useState(false)
  // const [viewSelectedTeam, setViewSelectedTeam] = useState([])
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  useEffect(() => {
    if (team?._id) {
      setIsLoading(true)

      axios
        .get(`${Url}/${apiVersion}/teams/${team._id}`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setIsLoading(false)
          setViewSelectedTeam(res.data.data.team)
          toggle()
        })
        .catch(error => {
          setIsLoading(false)
          if (error.response?.status === 401 || error.response?.status === 0) {
            UnAuthorized()
          } else if (error.response?.status === 403) {
            console.log("error response", error)
          } else {
            console.log(error)
          }
        })
    }
  }, [team])

  const [modalEditTeam, setModalEditTeam] = useState(false)
  const [editTeam, setEditTeam] = useState(null)
  const toggleEdit = team => {
    setEditTeam(team)
    setModalEditTeam(!modalEditTeam)
  }
  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2 align-items-center mb-3">
          <button
            className="bg-transparent border-0 "
            onClick={toggleCloseView}
          >
            <ArrowBackIcon />
          </button>
          <h3 className="m-0 text-capitalize h3">
            {viewSelectedTeam.name} Team
          </h3>
        </div>

        <button
          className="edit-view"
          onClick={() => toggleEdit(viewSelectedTeam)}
        // onClick={()=>handleEditButtonClick(viewSelectedTeam)}
        // onClick={handleEditButtonClick}
        >
          Edit Team
        </button>
      </div>

      {isloading ? (
        <div className="right-side d-flex justify-content-center align-items-center">
          <div className="spinner-border d-flex justify-content-center align-items-center"></div>
        </div>
      ) : (
        <Row className="view-team-height  ">
          <>
            <div className=" left-side ">
              <Row className="row-md-4 Supervisor-team">
                <div className=" d-flex justify-content-center mb-2">
                  {viewSelectedTeam?.supervisor?.photo ? (
                    <img
                      className="Supervisor-photo d-flex justify-content-center align-items-center"
                      src={`${Url}/${viewSelectedTeam?.supervisor?.photo}`}
                      // src={viewSelectedTeam.supervisor.photo}
                      alt={`${viewSelectedTeam?.supervisor?.firstName} ${viewSelectedTeam?.supervisor?.lastName}`}
                    />
                  ) : (
                    <div className="Supervisor-photo d-flex justify-content-center align-items-center"></div>
                  )}
                </div>
                <h5 className="m-0">
                  {viewSelectedTeam?.supervisor?.firstName +
                    " " +
                    viewSelectedTeam?.supervisor?.lastName}
                </h5>
                <p className="m-0">Supervisor</p>
              </Row>

              <div className="row-md-8 p-3 overflow ">
                <h5 className="mb-3">Team Members</h5>

                {viewSelectedTeam?.users?.map(user => (
                  <div key={user.id} className="mb-4">
                    <div className="d-flex align-items-center gap-3 ">
                      <div>
                        {user.photo ? (
                          <img
                            className="empty-avatar"
                            src={`${Url}/${user?.photo}`}
                          // src={user.photo}
                          // alt={`${user.firstName} ${user.lastName}`}
                          />
                        ) : (
                          <div className="empty-avatar d-flex justify-content-center align-items-center"></div>
                        )}
                      </div>
                      <div>
                        <p className="m-0">
                          {user.firstName + " " + user.lastName}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" right-side">
              <ViewTeamconversation viewSelectedTeam={viewSelectedTeam} />
              <ViewTeamServiceHours viewSelectedTeam={viewSelectedTeam} />
              <ViewTeamAnswer viewSelectedTeam={viewSelectedTeam} />
              <ViewTeamTicketRequest viewSelectedTeam={viewSelectedTeam} />
            </div>
          </>
        </Row>
      )}

      <EditTeam
        toggle={toggleEdit}
        isOpen={modalEditTeam}
        team={editTeam}
        setShowEditToast={setShowEditToast}
        fetchTeamsData={fetchTeamsData}
        setViewTeamComponents={setViewTeamComponents}
        toggleViewTeamComponents={toggleViewTeamComponents}
      />
    </>
  )
}
