import React, { useRef, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import TimePicker from "react-time-picker"

import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
export default function AddServiceHours({
  isOpen,
  toggle,
  setShowSuccessToast,
  fetchservicesData,
}) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const weekDaysArray = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ]
  // validateYupSchema
  const ServiceSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name")
      .matches(/^(?!\s+$).*/, "invalid"),
    responseTime: Yup.object().shape({
      hours: Yup.number().required("required"),
      minutes: Yup.number().required("required"),
    }),
    durations: Yup.array()
      .min(1, "At least one day must be selected")
      .test(
        "at-least-one-day-selected",
        "At least one day must be selected",
        function (value) {
          return value.some(day => day.checked)
        }
      ),
  })
  // Formik
  const {
    handleChange,
    values,
    values: { Signature },
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      responseTime: {
        hours: "",
        minutes: "",
      },
      durations: weekDaysArray.map(day => ({
        day,
        from: {
          hours: "09",
          minutes: "00",
        },
        to: {
          hours: "17",
          minutes: "00",
        },
        checked: false,
      })),
    },
    validationSchema: ServiceSchema,
    onSubmit: values => {
      const selectedDays = values.durations.filter(day => day.checked)
      const durations = selectedDays.map(day => ({
        day: day.day,
        from: {
          hours: parseInt(day.from.hours),
          minutes: parseInt(day.from.minutes),
        },
        to: {
          hours: parseInt(day.to.hours),
          minutes: parseInt(day.to.minutes),
        },
      }))
      const requestData = {
        name: values.name,
        responseTime: values.responseTime,
        durations: durations,
      }
      setIsLoading(true)
      axios
        .post(`${Url}/${apiVersion}/services`, requestData, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 201) {
            const newTeam = res.data.data.team

            toggle()
            setIsLoading(false)
            resetForm()
            setShowSuccessToast(true)
            setTimeout(() => setShowSuccessToast(false), 2500)
            fetchservicesData()
          } else {
            console.error("Unexpected status code:", res.status, res.data)
            setIsLoading(false)
          }
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error:", error)
          if (error.response.status === 401 || error.response.status === 0) {
            UnAuthorized()
          } else if (error.response?.status === 403) {
            setErrorMessage(error.response.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
          } else {
            setErrorMessage(error.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            console.log(error)
          }
        })
    },
  })

  // handle check box
  const handleCheckboxChange = (day, checked, index) => {
    const updatedDuration = [...values.durations]
    updatedDuration[index].checked = checked

    if (checked) {
      updatedDuration[index] = {
        ...updatedDuration[index],
        from: {
          hours: "",
          minutes: "",
        },
        to: {
          hours: "",
          minutes: "",
          // hours: 17,
          // minutes: 0,
        },
      }
    } else {
      updatedDuration[index] = {
        ...updatedDuration[index],
        from: {
          hours: "",
          minutes: "",
        },
        to: {
          hours: "",
          minutes: "",
        },
      }
    }
    setFieldValue(`durations`, updatedDuration)
  }

  // handle time
  const handleTimeChange = (day, field, value, index) => {
    const updatedDuration = [...values.durations]
    const dayIndex = updatedDuration.findIndex(
      durations => durations.day === day
    )
    if (dayIndex !== -1) {
      updatedDuration[dayIndex][field] = value
      setFieldValue(`durations[${dayIndex}]`, updatedDuration[dayIndex])
    }
  }
  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  const handleModalClosed = () => {
    resetForm()
  }

  const handleClick = event => {
    event.target.focus()
    event.target.click()
  }
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={handleModalClosed}
        centered
        size="lg"
      >
        <ModalHeader toggle={toggle}>Add New Service Hours</ModalHeader>
        <ModalBody>
          {errorMessage && (
            <div className="" aria-live="polite" aria-atomic="true">
              <div className="">
                <Toast isOpen={errorMessage} fade={true}>
                  <ToastBody className="delete-message-bg">
                    <h5 className="m-0 mb-2">Error</h5>
                    <p className="m-0">{errorMessage}</p>
                  </ToastBody>
                </Toast>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Service Hours Name</Label>
              <Input
                className=" p-5px m-0"
                id="team-name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                // placeholder="Email"
                type="text"
              />
              {errors.name && touched.name && (
                <div className="error mb-0">{errors.name}</div>
              )}
            </FormGroup>

            <h5 className="m-0 mb-2 mt-2">Response Time:</h5>
            <Row className=" d-flex  justify-content-between">
              <Col md={6}>
                <FormGroup className="m-0 m-mb-2  align-items-center">
                  <Label className="mb-1 ">Hours:</Label>
                  <div className="m-0  d-flex  gap-2 align-items-center">
                    {" "}
                    <Input
                      placeholder="Hours"
                      className=" p-5px m-0"
                      min="0"
                      max="24"
                      type="number"
                      id="responseTime.hours"
                      name="responseTime.hours"
                      value={values.responseTime.hours}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.responseTime?.hours &&
                    touched.responseTime?.hours && (
                      <div className="error mb-0">
                        {errors.responseTime?.hours}
                      </div>
                    )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="m-0 m-mb-2  align-items-center">
                  <Label className="mb-1 ">Minutes:</Label>
                  <div className="m-0  d-flex  gap-2 align-items-center">
                    {" "}
                    <Input
                      placeholder="Minutes"
                      className=" p-5px m-0"
                      min="0"
                      max="59"
                      type="number"
                      id="responseTime.minutes"
                      name="responseTime.minutes"
                      // value={values.responseTime.minutes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.responseTime?.minutes &&
                    touched.responseTime?.minutes && (
                      <div className="error mb-0">
                        {errors.responseTime?.minutes}
                      </div>
                    )}
                </FormGroup>
              </Col>
            </Row>

            <h5 className=" m-0 mb-3 mt-2"> Template Work Day: </h5>

            {weekDaysArray.map((day, key) => (
              <div className="row mb-3 weekdays" key={key}>
                <div className="col-12 d-flex justify-content-between align-items-center border p-2 rounded row-day-border">
                  <div className="col-sm-3">
                    <div className="form-check d-flex gap-2 align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={day}
                        name={day}
                        onChange={e => {
                          handleCheckboxChange(day, e.target.checked, key)
                        }}
                      />
                      <label className="form-check-label" htmlFor={day}>
                        {day}
                      </label>
                    </div>
                  </div>

                  {/* From time column */}
                  <div className="col-sm-4">
                    <div className="d-flex align-items-center">
                      <label className="mb-0 me-3">From:</label>
                      {/* <div className="col-sm-9">
                        <TimePicker
                          onClick={handleClick}
                          required={values.durations[key].checked}
                          type="time"
                          className="form-control"
                          onChange={e => {
                            const [hours, minutes] = e.target.value.split(":")
                            handleTimeChange(
                              day,
                              "from",
                              { hours, minutes },
                              key
                            )
                          }}
                          disabled={!values.durations[key].checked}
                        />
                        {values?.durations[key].checked &&
                          !values?.durations[key]?.from?.hours &&
                          !values?.durations[key]?.from?.minutes && (
                            <p className="error mb-0  d-inline-block">
                              require
                            </p>
                          )}
                      </div> */}
                      <div className="col-sm-9">
                        <input
                          onClick={handleClick}
                          required={values.durations[key].checked}
                          type="time"
                          className="form-control"
                          onChange={e => {
                            const [hours, minutes] = e.target.value.split(":")
                            handleTimeChange(
                              day,
                              "from",
                              { hours, minutes },
                              key
                            )
                          }}
                          disabled={!values.durations[key].checked}
                        />
                        {values?.durations[key].checked &&
                          !values?.durations[key]?.from?.hours &&
                          !values?.durations[key]?.from?.minutes && (
                            <p className="error mb-0  d-inline-block">
                              require
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* To time column */}
                  <div className="col-sm-4  form-time-data">
                    <div className="d-flex align-items-center ">
                      <label className="mb-0 me-3">To:</label>
                      <div className="col-sm-9">
                        <input
                          required={values.durations[key].checked}
                          type="time"
                          className="form-control"
                          onChange={e => {
                            const [hours, minutes] = e.target.value.split(":")
                            handleTimeChange(day, "to", { hours, minutes }, key)
                          }}
                          disabled={!values.durations[key].checked}
                        />
                        {values?.durations[key].checked &&
                          !values?.durations[key]?.to?.hours &&
                          !values?.durations[key]?.to?.minutes && (
                            <p className="error mb-0  d-inline-block">
                              required
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {errors.durations && (
              <div className="error mb-0">{errors.durations}</div>
            )}
            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}

            <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
              <Button type="submit" className="edit-button">
                Add
              </Button>{" "}
              <Button onClick={toggle} className="edit-button cancel-delete ">
                Cancel
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}
