import React from "react"

export default function NegativeIcon() {
  return (
    <svg
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3836 1.02428C29.69 1.32571 31.0121 2.17999 31.0121 3.78714C31.0121 4.43999 30.8279 4.89142 30.6107 5.27714C30.4764 5.49428 30.4936 5.66214 30.6943 5.74571C31.7486 6.21428 32.6193 7.15142 32.6193 8.50785C32.6193 9.26142 32.4186 9.94785 32.0336 10.4328C31.8493 10.6843 31.8829 10.885 32.1836 11.0686C32.9707 11.5043 33.4736 12.4757 33.4736 13.5807C33.4736 14.3507 33.2221 15.2043 32.7529 15.6229C32.5029 15.8571 32.5529 16.0243 32.8371 16.2586C33.3893 16.6771 33.6907 17.4807 33.6907 18.435C33.6907 20.0757 32.4186 21.3986 30.7443 21.3986H24.7679C23.2607 21.3986 22.2393 22.185 22.2393 23.4243C22.2393 25.7343 25.1029 29.8864 25.1029 32.8829C25.1029 34.44 24.0814 35.3771 22.7586 35.3771C21.5536 35.3771 20.9343 34.54 20.2814 33.2679C17.7864 28.3464 14.405 24.3786 11.8436 20.98C9.66714 18.0671 8.59572 15.6229 8.54572 11.605C8.46214 5.42714 13.4671 0.723564 21.3357 0.656422L23.6629 0.639278C25.8557 0.622849 27.4629 0.789993 28.3843 1.02499M0.309288 11.4879C0.309288 6.46571 3.42357 2.26357 7.625 2.26357H10.6214C7.59143 4.47285 6.20215 7.82142 6.26857 11.655C6.31857 15.9071 7.97643 18.9378 9.45 20.7793H6.98857C3.22215 20.7793 0.309288 16.6936 0.309288 11.4879Z"
        fill="#DF4128"
      />
    </svg>
  )
}
