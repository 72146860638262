import React from "react"
import CorrectMessageMark from "components/Common/CorrectMessageMark"

function ChatListContactMessageContent({
  chat,
  fromWho,
  sentMessage,
  deliveredMessage,
  seenMessage,
}) {
  return (
    <div>
      <CorrectMessageMark
        fromWho={fromWho}
        sentMessage={sentMessage}
        deliveredMessage={deliveredMessage}
        seenMessage={seenMessage}
      />
      <i className="bx bxs-user"></i>{" "}
      <span>
        {chat.lastMessage.contacts.length === 1
          ? chat.lastMessage.contacts[0].name
          : `${chat.lastMessage.contacts[0].name} and ${
              chat.lastMessage.contacts.length - 1
            } other contacts`}
      </span>
    </div>
  )
}

export default ChatListContactMessageContent
