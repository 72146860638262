import React from "react"

export default function NeutralIcon() {
  return (
    <svg
      width="24"
      height="36"
      viewBox="0 0 24 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.476246 21.75C0.476246 16.525 0.419996 11.075 0.419996 5.85001C0.419996 4.27501 1.695 3.00001 3.27 3.00001C5.4375 3.00001 6.365 4.80626 6.2575 7.22875L7.5375 2.65876C7.85 1.59376 9.18749 0.285005 11.0337 0.705005C12.5462 1.11751 13.4737 2.67626 13.0737 4.20126L12.1275 8.00001L15.6712 2.30626C16.4587 0.943755 18.4112 0.490005 19.9487 1.37876C21.3112 2.16626 21.7862 4.25001 20.965 5.62751L16.3062 13.2538C19.8312 14.4263 22.4387 16.9063 23.4562 19.7963C24.1087 21.6563 22.5137 23.8813 20.5762 23.8813C18.1587 23.8813 17.7375 21.5813 16.2762 20.3238C15.4137 19.5738 14.2137 19.1338 12.9762 19.1713C10.5512 19.2588 8.45999 21.2 8.34749 23.6375C8.22249 26.3375 10.4112 28.7625 13.0737 28.7625C14.9487 28.7625 17.1112 27.3913 17.7712 25.6463C18.1937 24.53 19.5162 23.8788 20.7112 23.8788C22.6237 23.8788 24.0512 25.8513 23.4837 27.8038C21.9587 32.3663 17.5962 35.3163 12.5087 35.3038C6.27125 35.2663 0.476246 29.6988 0.476246 21.75Z"
        fill="#FFC061"
      />
      <path
        d="M11.4925 0.872498C12.7275 1.4475 13.4312 2.8375 13.0737 4.20125L12.195 7.72625L10.125 11.125L11.805 3.7225C11.9825 3.005 12.1187 1.58375 11.4925 0.872498ZM20.4587 1.77125C21.4175 2.725 21.6725 4.43875 20.965 5.6275L16.3125 13.2437C15.14 12.9025 13.8712 12.9025 12.45 12.9025C13.7575 12.6087 15.2412 12.2262 16.12 11.125C16.9987 10.0237 20.0275 4.7775 20.0275 4.7775C20.48 4.095 21.06 2.71375 20.4587 1.77125ZM6.265 6.995C6.29625 5.14375 5.69749 3.6875 4.37124 3.1875C4.87624 3.8075 5.01624 4.85875 5.00874 5.5L5.24249 11.125L6.265 6.995Z"
        fill="#F8B649"
      />
    </svg>
  )
}
