import React from "react"
import { Table } from "reactstrap"

const TicketsTable = ({ tickets }) => {
  return (
    <div style={{ maxHeight: "24.375rem", overflowY: "scroll" }}>
      {tickets && tickets.length > 0 && (
        <div className="table-responsive">
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>Category</th>
                <th>Degree</th>
                <th>RD5 Status</th>
                <th>Creator</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((item, key) => (
                <tr key={key}>
                  <td>{item.TicketID}</td>
                  <td>{item.Code}</td>
                  <td>{item.DegreeCategory}</td>
                  <td>{item.DegreeName}</td>
                  <td>{item.RD5Status || "-"}</td>
                  <td>{item.CreatorName}</td>
                  <td>{item.CreatedAt?.split(" ")[0]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default TicketsTable
