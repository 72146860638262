import React, { useState, useEffect } from "react"

const TimerCountDown = ({ restart, setRestart }) => {
  // Set initial time to 2 minutes (120 seconds)
  const [time, setTime] = useState(120)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (restart) {
      setTime(120)
      setIsActive(true)
    }
  }, [restart])

  useEffect(() => {
    if (!isActive) return

    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timer)
          return 0
        }

        setRestart(false)
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [isActive])

  // Format the time as MM:SS
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`
  }

  return <p className="mb-2">{formatTime(time)}</p>
}

export default TimerCountDown
