import React from "react"

export default function ResetFiltersIcon() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 700 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="reset-filters-icon"
      >
        <path
          d="M512.5 125C479.438 124.962 446.962 133.722 418.405 150.382C389.848 167.042 366.238 191.001 350 219.8V100H300V300H500V250H390.417C401.55 228.279 418.27 209.917 438.856 196.803C459.441 183.689 483.15 176.298 507.541 175.39C531.932 174.482 556.124 180.09 577.628 191.637C599.132 203.183 617.171 220.252 629.888 241.085C642.605 261.918 649.54 285.764 649.981 310.168C650.422 334.571 644.351 358.652 632.395 379.931C620.439 401.21 603.028 418.918 581.955 431.234C560.882 443.549 536.908 450.027 512.5 450H500V500H512.5C562.228 500 609.919 480.246 645.083 445.083C680.246 409.919 700 362.228 700 312.5C700 262.772 680.246 215.081 645.083 179.917C609.919 144.754 562.228 125 512.5 125Z"
          fill="#fff"
        />
        <path
          d="M600 50H50V129.275L235.355 314.633L250 329.275V550H350V500H400V550C400 563.261 394.732 575.979 385.355 585.355C375.979 594.732 363.261 600 350 600H250C236.739 600 224.021 594.732 214.645 585.355C205.268 575.979 200 563.261 200 550V350L14.645 164.633C10.0018 159.989 6.31857 154.477 3.80577 148.41C1.29297 142.344 -0.00023923 135.841 3.31944e-08 129.275V50C3.31944e-08 36.7392 5.26784 24.0215 14.6447 14.6447C24.0215 5.26784 36.7392 0 50 0H600V50Z"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
