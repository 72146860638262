import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    Label,
    Legend,
    Tooltip,
} from "recharts"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import moment from 'moment'

export default function TypeRequest({ startDate,
    endDate, }) {

    const dispatch = useDispatch()
    const [isloading, setIsLoading] = useState(false)
    const [ticketRequestType, setTicketRequestType] = useState([])

    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))

    const fetchAllRequestType = async () => {
        try {
            setIsLoading(true)
            const params = {
                startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
            };
            axios
                .get(`${Url}/${apiVersion}/ticket-dashboard/request-type`, {
                    headers: { Authorization: `Bearer ${access}` },
                    params: params,
                })
                .then(response => {
                    const AllTickerRequestType = response.data.data
                    console.log("All Ticket Request Nature", response.data.data)
                    const dataArray = Object.keys(AllTickerRequestType).filter(key => key !== "total").map(key => ({
                        name: key,
                        value: AllTickerRequestType[key],
                    }));
                    setTicketRequestType(dataArray)
                    setIsLoading(false)
                })
                .catch(err => {
                    if (err.response?.status == 401 || err.response?.status == 0) {
                        dispatch(unAuthUser())
                        // UnAuthorized()
                    } else {
                        console.log(err)
                    }
                })
        } catch (error) {
            console.error("Unexpected error:", error)
        }
    }

    useEffect(() => {
        fetchAllRequestType()
    }, [startDate, endDate]);

    const COLORS = ["#5A6778", "#6B7889", "#7D8699", "#959DAC", "#AEB4C1", "#C6CBD4", "#E0E3E7"];
    const totalValue = ticketRequestType.reduce((sum, entry) => sum + entry.value, 0)
    // const percentage = Math.round((entry.payload.value / totalValue) * 100)
    // const CustomLegendFormatter = (value, entry) => {
    //     const nameColor = "#9291A5"
    //     const valueColor = "#6B7889"

    //     return (
    //         < >
    //             <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: '2px' }}>
    //                 <span style={{ color: nameColor, width: "80px", marginRight: "10px", fontSize: "8px" }}>{value}</span>
    //                 <span style={{ color: valueColor, fontWeight: 'bold', width: "30px" }}>
    //                     {entry.payload.value}
    //                 </span>
    //                 <span style={{ color: valueColor, fontWeight: 'bold' }}>
    //                     {percentage}%
    //                 </span>
    //             </span>
    //         </>
    //     )
    // }

    const CustomTooltip = ({ payload, label }) => {
        if (!payload || !payload.length) return null
        return (
            <div className='custom-tooltip' style={{ backgroundColor: "#5A6778", color: '#FFFFFF', padding: '7px', borderRadius: '4px' }}>
                <p className='value m-0'>{`${payload[0].value}`}</p>
            </div>
        );
    };

    const CustomLabel = ({ viewBox }) => {
        const { cx, cy } = viewBox;
        return (
            <g>

                <text
                    x={cx}
                    y={cy - 10}
                    textAnchor="middle"
                    fill="#9291A5"
                    fontSize="10"

                >
                    Total
                </text>

                <text
                    x={cx}
                    y={cy + 10}
                    textAnchor="middle"
                    fontSize="16"
                    fontWeight="bold"
                    fill="#6B7889"
                >
                    {totalValue}

                </text>
            </g>
        );
    };

    if (!authorized) {
        return <UnAuthorizedPopup />
    }

    return (
        <>
            {isloading ? (
                <div className="loading d-flex justify-content-center align-items-center mb-2 p-3">
                    <div className="spinner-border d-flex justify-content-center align-items-center"></div>
                </div>
            ) : (ticketRequestType && ticketRequestType.length > 0 && ticketRequestType.some(item => item.value > 0)) ? (
                <div className="d-flex justify-content-around align-items-center">
                    <ResponsiveContainer width="50%" height={170}>
                        <PieChart>
                            <Pie data={ticketRequestType} cx={70} cy="50%" dataKey="value" stroke="none" outerRadius="93%">
                                {ticketRequestType.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            {/* <Legend
                                verticalAlign="middle"
                                align="right"
                                // width="50%"
                                layout="vertcal"

                                // wrapperStyle={{
                                //     // width: "180px",
                                //     height: "150px",
                                //     overflowY: "auto",
                                //     marginTop: "5px",

                                // }}
                                iconType="circle"
                                formatter={CustomLegendFormatter}
                                iconSize={6}
                            /> */}
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </ResponsiveContainer>
                    <div >
                        {ticketRequestType.map((ticket, idx) => (
                            <div key={idx} className="d-flex gap-2 align-items-center justify-content-between mt-1 ">
                                <div style={{
                                    backgroundColor: COLORS[idx % COLORS.length],
                                    width: "6px",
                                    height: "6px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                }}></div>
                                <p className="m-0 flex-grow-1 chart-zero-values-p">{ticket.name}</p>
                                <p style={{
                                    width: "25px",

                                }} className="m-0 chart-zero-values-span   ">{ticket.value}</p>
                                <span className="m-0 chart-zero-values-span " >
                                    {Math.round((ticket.value / totalValue) * 100)} %
                                </span>
                            </div>
                        ))}

                    </div>
                </div>

            ) : (ticketRequestType && ticketRequestType.length > 0) ? (
                <div className="d-flex justify-content-around align-items-center">
                    {console.log("object", ticketRequestType)}
                    <ResponsiveContainer width="50%" height={170}>
                        <PieChart>
                            <Pie
                                data={[{ value: 1 }]}
                                cx={65}
                                cy="50%"
                                dataKey="value"
                                stroke="none"
                                innerRadius={50}
                                outerRadius={70}
                            >
                                <Cell fill="#e0e0e0" />
                                <Label content={<CustomLabel />} />
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div >
                        {ticketRequestType.map((ticket, idx) => (
                            <div key={idx} className="d-flex gap-3 align-items-center justify-content-between ">
                                <div style={{
                                    backgroundColor: "#e0e0e0",
                                    width: "6px",
                                    height: "6px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginLeft: "10px",
                                }}></div>
                                <p className="m-0 flex-grow-1 chart-zero-values-p">{ticket.name}</p>
                                <span className="m-0 chart-zero-values-span ">{ticket.value}</span>
                            </div>
                        ))}

                    </div>
                </div>

            ) : (
                <p className="d-flex justify-content-center align-items-center mb-2 fw-bold p-3 fs-5">No data available</p>
            )}
        </>
    )
}
