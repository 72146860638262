import React from "react"

function InboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.94118 0.0588379C4.0049 0.0588379 0 4.06374 0 9.00001C0 13.9363 4.0049 17.9412 8.94118 17.9412C13.8775 17.9412 17.8824 13.9363 17.8824 9.00001C17.8824 4.06374 13.8775 0.0588379 8.94118 0.0588379Z"
        fill="#42526D"
      />
      <path
        d="M8.94141 3.03931V9.74519H13.412"
        stroke="#F5F6F7"
        strokeWidth="1.02273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default InboxIcon
