import React, { useCallback, useEffect, useRef, useState } from "react"
import PerviousAction from "../PerviousAction"
import { useDropzone } from "react-dropzone"
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap"

import "./fileSteps.css"

import configs from "../../../config"
const { Url, apiVersion } = configs.client
const templateEstimatedCost = configs.templateEstimatedCost

import { Formik } from "formik"
import { Form } from "reactstrap"
import axios from "axios"
import { useSelector } from "react-redux"

import TemplateHeader from "./TemplateHeader"
import TemplateHeaderVar from "./TemplateHeaderVar"
import TemplateFooter from "./TemplateFooter"
import TemplateButtons from "./TemplateButtons"
import TemplateBodyVars from "./TemplateBodyVars"
import TemplateFormatFiles from "./TemplateFormatFiles"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import CustomSpinner from "components/Common/CustomSpinner"

// expimport { size } from 'lodash';
export default function PreviewTemplate({
  nextStep,
  prevStep,
  selectedTemplate,
  templates,
  columns,
  files,
  setFiles,
  countryCode,
  selectNumber,
  setSelectedNumber,
  selectedOptions,
  setSelectedOptions,
  headerVar,
  bodyVars,
  selectedColumnHeader,
  setSelectedColumnHeader,
  selectFileColumn,
  setSelectFileColumn,
  isSendData,
  setIsSendData,
  showAuthPopUpt,
  setShowAuthPopUpt,
  firstValues,
  numRows,
  chosenTemplateData,
}) {
  // excel file

  let { access } = useSelector(state => ({
    access: state.Login?.userAccess,
  }))

  const submitRef = useRef(null)

  const [excelRatesDate, setExcelRatesData] = useState(templateEstimatedCost)

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [uploadProgress, setUploadProgress] = useState(0)
  const [loadedSize, setLoadedSize] = useState(0)
  const [filesTemplate, setFilesTemplate] = useState([])
  const [isUploaderOpen, setIsUploaderOpen] = useState(true)
  const [showPopUpConfirm, setShowPopUpConfirm] = useState(false)

  // selected option from right dropdown
  const [selectFileOpation, setSelectFileOpation] = useState(null)
  const [estimatedBroadcastCost, setEstimatedBroadcastCost] = useState(0)

  const handleSelectFileOption = (Option, indx) => {
    setSelectFileOpation(Option)
  }

  const hideselectedFilesColumn = () => {
    setSelectFileColumn(null)
  }

  const handleHidePopUpConfirm = () => {
    setShowPopUpConfirm(false)
  }

  const handleEstimatedCost = (pinCode, rows) => {
    let selectedCountryRate
    let targetCountry

    if (pinCode === "+966") {
      targetCountry = excelRatesDate.filter(
        country => country.Market === "Saudi Arabia"
      )

      if (chosenTemplateData.category === "MARKETING") {
        selectedCountryRate = targetCountry[0].Marketing
      } else {
        selectedCountryRate = targetCountry[0].Utility
      }
    }

    if (pinCode === "+20") {
      targetCountry = excelRatesDate.filter(
        country => country.Market === "Egypt"
      )

      if (chosenTemplateData.category === "MARKETING") {
        selectedCountryRate = targetCountry[0].Marketing
      } else {
        selectedCountryRate = targetCountry[0].Utility
      }
    }

    if (pinCode === "+971") {
      targetCountry = excelRatesDate.filter(
        country => country.Market === "United Arab Emirates"
      )

      if (chosenTemplateData.category === "MARKETING") {
        selectedCountryRate = targetCountry[0].Marketing
      } else {
        selectedCountryRate = targetCountry[0].Utility
      }
    }

    // console.log("selectedCountryRate", selectedCountryRate)
    // console.log("rows", rows)

    setEstimatedBroadcastCost(rows * selectedCountryRate)
  }

  // frist values "columns" of : body var
  function replaceNumbersWithValues(
    str,
    selectedOptions,
    firstValues,
    columns
  ) {
    if (!selectedOptions) return str
    let result = []
    let regex = new RegExp(`\\b([1-9][0-9]{0,2}|1000)\\b`, "g")
    let lastIndex = 0

    str.replace(regex, (match, number, index) => {
      const option = selectedOptions[number - 1]
      if (!option) return match
      const columnIndex = columns.indexOf(option)
      if (columnIndex !== -1) {
        const value = firstValues[columnIndex]
        // Push preceding text
        result.push(str.slice(lastIndex, index))
        // Push highlighted value
        result.push(
          <span key={index} className="highlight">
            {value}
          </span>
        )
        lastIndex = index + match.length
      }
      return match
    })

    // Push remaining text
    result.push(str.slice(lastIndex))

    return result
  }

  // select item "excel column" from dropdown
  const handleSelect = (column, index) => {
    const newSelectedOptions = [...selectedOptions]
    newSelectedOptions[index] = column
    setSelectedOptions(newSelectedOptions)
  }

  const clearSelectedOptions = () => {
    prevStep()
    setSelectedOptions([])
    setSelectedColumnHeader("")
    setSelectedNumber("")
    setFiles("")
    setSelectFileOpation("")
    setSelectFileColumn("")
  }
  // visibility chose file
  const chooseFile = useRef()

  const visableChoeFile = () => {
    setSelectFileColumn(null)
    if (chooseFile.current) {
      chooseFile.current.className = chooseFile.current.className.replace(
        "d-none",
        "d-flex"
      )
    }
  }

  const hideChooseFile = () => {
    if (chooseFile.current) {
      chooseFile.current.className = chooseFile.current.className.replace(
        "d-flex",
        "d-none"
      )
      setSelectFileOpation(null)
    }
  }

  // upload drop dile
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles?.length) {
      const file = acceptedFiles[0]
      const updatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setFilesTemplate([updatedFile])
      setFileUploadRequired(false)
    }
  }, [])

  // template
  const selectedTemplateData = templates.find(
    template => template.id === selectedTemplate
  )

  let exampleBodyText = []
  try {
    if (selectedTemplateData) {
      let tampateBody = selectedTemplateData.components.filter(
        type => type.type == "BODY"
      )
      if (tampateBody[0].example) {
        exampleBodyText = tampateBody[0].example.body_text[0]
      }
    }
  } catch (e) {
    console.log(e)
  }
  // accpet upload file " img || doc"
  const accept = {}
  if (
    selectedTemplateData?.components.some(
      component => component.format === "IMAGE"
    )
  ) {
    accept["image/jpeg"] = [".jpeg", ".jpg", ".png"]
  } else if (
    selectedTemplateData?.components.some(
      component => component.format === "DOCUMENT"
    )
  ) {
    accept["file/pdf"] = [
      ".pdf",
      ".doc",
      ".docx",
      ".xls",
      ".xlsx",
      ".ppt",
      ".pptx",
      ".txt",
      ".csv",
      ".zip",
      ".rar",
      ".tar",
      ".gz",
      ".7z",
    ]
  }
  // react dropzone
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      maxSize: 25 * 1024 * 1024,
      onDrop,
      accept: accept,
    })

  //error file upload message:
  const [fileUploadRequired, setFileUploadRequired] = useState(false)
  const handleErrorUploadFile = () => {
    if (
      chooseFile.current &&
      chooseFile.current.classList.contains("d-flex") &&
      !chooseFile.current.classList.contains("d-none")
    ) {
      if (filesTemplate.length === 0) {
        setFileUploadRequired(true)
      }
    }
    if (
      !selectNumber ||
      (headerVar &&
        (!selectedColumnHeader || selectedColumnHeader.length === 0)) ||
      (FormatVars && !docAndImageVar) ||
      (bodyVars && bodyVarValid)
    ) {
      setIsSubmitted(true)
    }
  }

  const handleShowPopUpConfirm = () => {
    if (
      !selectNumber ||
      (headerVar &&
        (!selectedColumnHeader || selectedColumnHeader.length === 0)) ||
      (FormatVars && !docAndImageVar) ||
      (bodyVars && bodyVarValid)
    ) {
      setShowPopUpConfirm(false)
    } else {
      setShowPopUpConfirm(true)
    }
  }
  const handleSelectedFileColumn = option => {
    setSelectFileColumn(option)
  }
  //remove file:
  const removeFile = name => {
    setFilesTemplate(filesTemplate =>
      filesTemplate.filter(file => file.name !== name)
    )
  }
  //file size to MEGABYTES:
  const FileSizeMB = filesTemplate
    ? (
        filesTemplate.reduce((acc, file) => acc + file.size, 0) /
        (1024 * 1024)
      ).toFixed(2)
    : ""
  //================================================================
  // variables condition validation
  const bodyVarValid =
    exampleBodyText.length !==
    selectedOptions.filter(item => item !== undefined && item !== "").length
  const docAndImageVar = selectFileColumn || filesTemplate.length > 0
  const FormatVars =
    selectedTemplateData?.components[0].format === "DOCUMENT" ||
    selectedTemplateData?.components[0].format === "IMAGE"
  //================================================================

  useEffect(() => {
    handleEstimatedCost(countryCode, numRows)
  }, [])

  return (
    <div className="position-relative">
      {isSendData && (
        <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
          <CustomSpinner />
        </div>
      )}

      <div className="d-flex justify-content-between">
        <h3 className="mb-4 fw-bold">Preview Template</h3>
        <span className="fs-5">
          Estimated cost:{" "}
          <span className="text-danger fw-bolder">
            {estimatedBroadcastCost === 0 ? (
              <Spinner size="sm" animation="grow" />
            ) : (
              `${estimatedBroadcastCost} $US`
            )}
          </span>
        </span>
      </div>

      <Container
        className="d-flex flex-column justify-content-center boxShadow-preview-template chose-template"
        style={{
          textAlign: selectedTemplateData?.language == "ar" ? "right" : "left",
          background: "#f5f5f9",
          padding: "10px 20px  0",
          borderRadius: "10px",
          boxShadow: " box-shadow: 0px 0px 4px 0px #0000001b",
          height:
            selectedTemplateData?.language == "ar"
              ? "400px"
              : "auto" && selectedTemplateData?.name === "seasonal_promotion"
              ? "410px"
              : "auto",
          overflowY:
            selectedTemplateData?.language === "ar" ||
            selectedTemplateData?.name === "seasonal_promotion"
              ? "auto"
              : "none",
        }}
      >
        {/* return templateName */}
        <Row>
          <h3
            className="mb-3"
            style={{
              direction: "ltr",
              textTransform: "capitalize",
              marginTop:
                selectedTemplateData?.language == "ar"
                  ? "100px"
                  : "" || selectedTemplateData?.name === "seasonal_promotion"
                  ? "100px"
                  : "",
            }}
          >
            {selectedTemplateData.name.replace(/_/g, " ")} Template
          </h3>
        </Row>

        <TemplateHeader
          selectedTemplateData={selectedTemplateData}
          firstValues={firstValues}
          columns={columns}
          selectedColumnHeader={selectedColumnHeader}
        />

        <Formik
          initialValues={{
            headerVarsValue: selectedColumnHeader,
            bodyVarsValues: [],
            file: "",
          }}
          onSubmit={async (values, actions) => {
            setIsSubmitted(true)
            setShowPopUpConfirm(false)

            if (
              (headerVar &&
                (!selectedColumnHeader || selectedColumnHeader.length === 0)) ||
              FormatVars & !docAndImageVar ||
              (bodyVars && bodyVarValid)
            ) {
              actions.setSubmitting(false)
              return
            }

            const formData = new FormData()
            // Append the form values to the FormData object

            if (values.headerVarsValue) {
              formData.append(headerVar, values.headerVarsValue)
            }
            if (values.bodyVarsValues) {
              values.bodyVarsValues.forEach((value, index) => {
                formData.append(bodyVars[index], value)
              })
            }

            // chick format = image
            {
              selectedTemplateData?.components.map((component, index) => {
                if (component.format === "IMAGE") {
                  actions.setValues({
                    ...values,
                    file: filesTemplate,
                  })
                  formData.append("file", filesTemplate)
                }
              })
            }

            // chick format = document
            {
              selectedTemplateData?.components.map((component, index) => {
                if (component.format === "DOCUMENT") {
                  actions.setValues({
                    ...values,
                    file: filesTemplate,
                  })

                  formData.append("file", filesTemplate)
                  actions.setSubmitting(true)
                }
              })
            }

            //convert FormData to object
            function formDataToObject(formData) {
              const object = {}
              formData.forEach((value, key) => {
                if (object.hasOwnProperty(key)) {
                  if (!Array.isArray(object[key])) {
                    object[key] = [object[key]]
                  }

                  object[key].push(value)
                } else {
                  object[key] = value
                }
              })
              return object
            }

            const formDataObject = formDataToObject(formData)

            const uploadFile = async () => {
              // if (filesTemplate != []) {
              if (filesTemplate && filesTemplate.length > 0 && access) {
                formData.append("file", filesTemplate[0])
                setIsSendData(true)

                try {
                  const response = await axios.post(
                    `${Url}/${apiVersion}/upload/single`,
                    formData,
                    {
                      headers: {
                        Authorization: `Bearer ${access}`,
                        "Content-Type": "multipart/form-data",
                      },
                      onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                        setUploadProgress(percentCompleted)
                        setLoadedSize(progressEvent.loaded)
                      },
                    }
                  )
                  if (response.status === 201) {
                    // console.log("File already uploaded")
                    const fileName = response.data.file
                    await sendAllData(fileName)
                  }
                } catch (error) {
                  console.log("File send error", error)
                  if (
                    error.response.status == 401 ||
                    error.response.status == 0
                  ) {
                    UnAuthorized()
                    setShowAuthPopUpt(true)
                  } else {
                    console.log(error)
                  }
                } finally {
                  setIsSendData(false)
                }
              }
            }
            uploadFile()

            const sendAllData = async fileName => {
              if (!fileName) {
                fileName = await uploadFile()
              }
              setIsSendData(true)

              const existingFormData = new FormData()
              existingFormData.append("type", "sheet")
              existingFormData.append("file", files[0])
              existingFormData.append("templateName", selectedTemplateData.name)
              existingFormData.append("countryCode", countryCode)
              existingFormData.append("number", selectNumber)
              if (selectedColumnHeader != "") {
                existingFormData.append("Winter Sale", selectedColumnHeader)
              }
              exampleBodyText.map((bodyText, index) => {
                existingFormData.append(bodyText, selectedOptions[index])
              })
              // // Send Data in FormatVars templates
              if (FormatVars) {
                if (fileName) {
                  existingFormData.append("attachment", fileName)
                  existingFormData.append("attachmentType", "file")
                }
                if (selectFileColumn) {
                  existingFormData.append("attachment", selectFileColumn)
                  existingFormData.append("attachmentType", "link")
                }
              }

              try {
                const response = await axios.post(
                  `${Url}/${apiVersion}/broadcast`,
                  existingFormData,
                  {
                    headers: {
                      "content-type": "multipart/form-data",
                      Authorization: `Bearer ${access}`,
                    },
                  }
                )

                if (response.status === 201) {
                  nextStep()
                  // console.log("Data sent successfully")
                } else {
                  // console.log("Failed to send data", response.status)
                }
              } catch (error) {
                console.log("Error sending data", error)
                if (
                  error.response.status == 401 ||
                  error.response.status == 0
                ) {
                  UnAuthorized()
                  setShowAuthPopUpt(true)
                } else {
                  console.log(error)
                }
              } finally {
                setIsSendData(false)
              }
            }
            const fileName = filesTemplate.length ? filesTemplate[0].name : null
            if (!fileName) {
              sendAllData()
            }
          }}
        >
          {/* {formik => ( */}
          {({
            handleSubmit,
            // formik,
          }) => (
            <Form
              key="formikSingleTemplatePreviewForm"
              dir={selectedTemplateData.language == "ar" ? "rtl" : "ltr"}
              onSubmit={handleSubmit}
            >
              <div>
                {/* view header component */}
                {showAuthPopUpt && <UnAuthorizedPopup />}

                {selectedTemplateData.components.map((component, idx) => {
                  if (component.example && component.example.header_text) {
                    headerVar = component.example.header_text[0]
                  }
                })}

                {/* return header var */}
                <TemplateHeaderVar
                  firstValues={firstValues}
                  isSubmitted={isSubmitted}
                  selectedColumnHeader={selectedColumnHeader}
                  headerVar={headerVar}
                  selectFileColumn={selectFileColumn}
                  selectNumber={selectNumber}
                  selectedOptions={selectedOptions}
                  columns={columns}
                  setSelectedColumnHeader={setSelectedColumnHeader}
                />

                <p>
                  {/* return body */}
                  {selectedTemplateData.components.map(component => {
                    if (component.example && component.example.body_text) {
                      bodyVars = component.example.body_text[0]
                    }

                    if (component.type == "BODY")
                      return component.text.split("\n").map((str, idx) => (
                        <Container
                          key={idx}
                          className="body-temp text-dark  mb-0 bg-white  "
                          style={{
                            fontFamily:
                              selectedTemplateData?.language == "ar"
                                ? "Droid Arabic Kufi"
                                : "Inter",
                            fontSize:
                              selectedTemplateData?.language == "ar"
                                ? "13px"
                                : "15px",
                            borderRadius:
                              selectedTemplateData?.language == "ar"
                                ? "1px"
                                : "8px",
                            padding:
                              selectedTemplateData?.language == "ar"
                                ? "2px 20px"
                                : "10px 20px",
                          }}
                        >
                          {replaceNumbersWithValues(
                            str,
                            selectedOptions,
                            firstValues,
                            columns
                          )}
                        </Container>
                      ))
                  })}

                  {/* body var */}
                  <TemplateBodyVars
                    firstValues={firstValues}
                    columns={columns}
                    bodyVars={bodyVars}
                    selectedOptions={selectedOptions}
                    isSubmitted={isSubmitted}
                    selectFileColumn={selectFileColumn}
                    selectNumber={selectNumber}
                    selectedColumnHeader={selectedColumnHeader}
                    handleSelect={handleSelect}
                  />

                  {/* input chose file Or image */}
                  <TemplateFormatFiles
                    handleSelectedFileColumn={handleSelectedFileColumn}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    columns={columns}
                    chooseFile={chooseFile}
                    selectedOptions={selectedOptions}
                    selectNumber={selectNumber}
                    selectedTemplateData={selectedTemplateData}
                    isSubmitted={isSubmitted}
                    selectFileOpation={selectFileOpation}
                    selectFileColumn={selectFileColumn}
                    uploadProgress={uploadProgress}
                    FileSizeMB={FileSizeMB}
                    removeFile={removeFile}
                    isUploaderOpen={isUploaderOpen}
                    filesTemplate={filesTemplate}
                    fileUploadRequired={fileUploadRequired}
                    handleSelectFileOption={handleSelectFileOption}
                    visableChoeFile={visableChoeFile}
                    selectedColumnHeader={selectedColumnHeader}
                    hideChooseFile={hideChooseFile}
                    hideselectedFilesColumn={hideselectedFilesColumn}
                  />

                  {/* Return footer */}
                  <TemplateFooter selectedTemplateData={selectedTemplateData} />

                  {/* return buttons */}
                  <TemplateButtons
                    selectedTemplateData={selectedTemplateData}
                  />
                </p>

                <Modal
                  isOpen={showPopUpConfirm}
                  toggle={() => setShowPopUpConfirm(!showPopUpConfirm)}
                  className="text-center"
                  centered
                >
                  <ModalBody>
                    <h3 className="my-4">Are you sure you want to proceed?</h3>
                  </ModalBody>

                  <ModalFooter className="justify-content-center py-1 mb-3 border-top-0">
                    <button
                      className="preivous-btn-enter-data"
                      onClick={handleHidePopUpConfirm}
                    >
                      Cancel
                    </button>

                    <button
                      className="next-btn-enter-data"
                      style={{ padding: 0 }}
                      onClick={() => submitRef.current.click()}
                    >
                      Confirm
                    </button>
                  </ModalFooter>
                </Modal>

                <button
                  className="btn btn-success d-none"
                  type="submit"
                  ref={submitRef}
                >
                  sumbit
                </button>

                {/* action buttons */}
                <Container
                  style={{
                    direction: "ltr",
                    bottom: "-3.125rem",
                  }}
                  className="d-flex button-action justify-content-end my-3 gap-2"
                >
                  <span onClick={clearSelectedOptions}>
                    <PerviousAction />
                  </span>

                  <span
                    onClick={() => {
                      handleErrorUploadFile()
                      handleShowPopUpConfirm()
                    }}
                    type="button"
                    className={`d-flex justify-content-center align-items-center ${
                      isSendData ||
                      !selectNumber ||
                      (headerVar &&
                        (!selectedColumnHeader ||
                          selectedColumnHeader.length === 0)) ||
                      (FormatVars && !docAndImageVar) ||
                      (bodyVars && bodyVarValid)
                        ? "next-page-option-button-disabled position-relative"
                        : "next-page-option-button position-relative"
                    }`}
                    disabled={isSendData || !selectNumber}
                  >
                    <div
                      style={{
                        color: "white",
                      }}
                      className="next-page-option-button d-flex justify-content-center align-items-center position-relative"
                    >
                      Send
                    </div>
                  </span>
                </Container>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}
