import React from "react"

function OnlineIcon() {
  return (
    <svg
    className="svg-user-table"

      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <circle
        cx="6.0237"
        cy="6.0237"
        r="6.0237"
        transform="matrix(1 0 0 -1 0.720703 12.6421)"
        fill="#17E34A"
      />
    </svg>
  )
}

export default OnlineIcon
