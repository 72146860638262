import React, { useEffect, useState } from "react"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import AwayIcon from "components/Common/Icons/SidebarIcons/AwayIcon"

export default function StatusesTable({
    setIsLoading,
    isloading,
    statusesList,
    toggleEdit,
}) {

    console.log("kkkkkkkkk", statusesList)
    return (
        <div className="table-container table-container-categories m-0 p-0">
            {isloading ? (
                <div className="  loading">
                    <div className="spinner-border "></div>
                </div>
            ) : (
                <table>
                    <thead className="text-center">
                        <tr>
                            <th>name</th>
                            <th>End User View</th>

                            <th>Description</th>
                            <th>Category</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    {statusesList && statusesList.length > 0 ? (
                        <tbody>
                            {statusesList.map(status => (
                                <tr
                                    key={status.id}
                                    className={`tr ${status?.default === true ? "default-status" : ""}`}
                                    onClick={() => toggleEdit(status)}
                                >
                                    <td className="text-center">{status.name ? status.name : "___"}</td>
                                    <td className="text-center">{status.endUserDisplayName ? status.endUserDisplayName : "___"}</td>


                                    {status?.description ? (
                                        <td className="text-center ellipsis-cell">
                                            {status.description}
                                        </td>
                                    ) : (
                                        <td className="text-center">___</td>
                                    )}

                                    <td className="text-center d-flex gap-2 align-items-center justify-content-center ">
                                        <button

                                            disabled
                                            className={`border-0 d-flex gap-2 align-items-center justify-content-center user-status ${status.category === "pending" ? "pending-category" :
                                                status.category === "solved" ? "solved-category" :
                                                    status.category === "open" ? "open-category" :
                                                        status.category === "new" ? "new-category" : ""
                                                }`}
                                        >
                                            {status.category}
                                        </button>
                                    </td>





                                    <td className="text-center ">
                                        <button
                                            disabled
                                            className={`border-0 user-status gap-2 ${status.status === "active" ? "online-status" :
                                                status.status === "inactive" ? "away-status" : ""
                                                }`}
                                        >
                                            {status.status === "active" && <OnlineIcon />}
                                            {status.status === "inactive" && <AwayIcon />}  &nbsp;
                                            {status.status}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="6" className="text-center p-2">
                                    No Statuses found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            )}
        </div>
    )
}

