import React from "react"
import { DropdownButton } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import { Container, Row, Col } from "reactstrap"
export default function TemplateBodyVars({
  columns,
  bodyVars,
  selectedOptions,
  isSubmitted,
  selectFileColumn,
  selectNumber,
  selectedColumnHeader,
  handleSelect,
}) {
  return (
    <Row>
      {bodyVars && (
        <>
          <Container
            style={{
              direction: "ltr",
            }}
          >
            {bodyVars.map((bodyVarInput, idx) => (
              <>
                <Container
                  key={idx}
                  className="input-group input-group-sm my-1 d-flex justify-content-around mt-2 gap-1"
                >
                  {/* input var number */}{" "}
                  <Col
                    style={{
                      color: "#2a3042",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                    className=" bg-white d-flex justify-content-center align-items-center col-2 "
                    id="inputGroup-sizing-sm"
                  >
                    {`input: {{${idx + 1}}}`}
                  </Col>
                  <Col>
                    <DropdownButton
                      className="dropdown-toggle-temp-preview-var  border rounded-3"
                      style={{
                        background: "white",
                        margin: "0",
                        direction: "ltr",
                      }}
                      // title="▼"
                      title={
                        <>
                          <div
                            style={{
                              backgroundColor: "white",
                              width: "40vw",
                            }}
                            className=" d-flex justify-content-between align-items-center p-1"
                          >
                            {selectedOptions[idx] ? (
                              <p
                                style={{
                                  color: selectedOptions ? "black" : "#ACB4BF",
                                }}
                                className="mb-0"
                              >
                                {selectedOptions[idx]}
                              </p>
                            ) : (
                              <p
                                style={{
                                  color: "#ACB4BF",
                                }}
                                className="mb-0"
                              >
                                Choose the respective column
                              </p>
                            )}
                            <i
                              style={{
                                color: "#ACB4BF",
                              }}
                              className="fas fa-chevron-down"
                            ></i>
                          </div>
                        </>
                      }
                    >
                      {columns.map((column, index) => (
                        <Dropdown.Item
                          disabled={
                            (selectFileColumn &&
                              selectFileColumn.includes(column)) ||
                            selectNumber.includes(column) ||
                            selectedColumnHeader.includes(column) ||
                            (selectedOptions.includes(column) &&
                              selectedOptions[idx] !== column)
                          }
                          onClick={() => handleSelect(column, idx)}
                          className="DropdownItem  "
                          key={column}
                        >
                          {column}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Col>
                </Container>
                {bodyVars && !selectedOptions[idx] && isSubmitted && (
                  <>
                    <Row>
                      <p
                        style={{
                          direction: "rtl",
                          textAlign: "right",
                        }}
                        className="text-danger mb-0"
                      >
                        required
                      </p>
                    </Row>
                  </>
                )}
              </>
            ))}
          </Container>
        </>
      )}
    </Row>
  )
}
