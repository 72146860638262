import React from "react"

function OfflineIcon() {
  return (
    <svg
    className="svg-user-table"

      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <circle
        cx="6.0237"
        cy="6.0237"
        r="6.0237"
        transform="matrix(1 0 0 -1 0.470703 12.6729)"
        fill="#DF4128"
      />
    </svg>
  )
}

export default OfflineIcon
