import React from "react"
import { convertData } from "helpers/helper_functions"
import DefaultPhoto from "./../../../assets/images/default.jpg"

function SingleHistory({ item }) {
  return (
    <li className="list-group-item border-0">
      <div className="d-flex">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-xs">
            <img
              src={DefaultPhoto}
              alt=""
              className="avatar-title rounded-circle bg-light"
              height="18"
            />
          </div>
        </div>
        <div className="flex-grow-1">
          <h5 className="font-size-14 mb-0">{`${item.user?.firstName} ${item.user?.lastName}`}</h5>
          <span className="font-size-12 text-muted">
            {`Done on ${convertData(item.createdAt)}`}
          </span>
          <p className="text-muted mb-1">
            {(() => {
              switch (item.actionType) {
                case "takeOwnership":
                  return `${item.takeOwnership.to?.firstName} ${item.takeOwnership.to?.lastName} took the chat's ownership from ${item.takeOwnership.from?.firstName} ${item.takeOwnership.from?.lastName}`

                case "archive":
                  return item.archive === "bot" || item.archive === "auto"
                    ? "The bot archived the chat"
                    : `${item.user?.firstName} ${item.user?.lastName} archived the chat`

                case "start":
                  return `${item.start.firstName} ${item.start.lastName} started the chat`

                case "transfer":
                  switch (item.transfer.type) {
                    case "bot":
                      return `The bot transferred the chat to ${item.transfer.to?.firstName} ${item.transfer.to?.lastName}`

                    case "user":
                      return item.user._id === item.transfer.from?._id
                        ? `${item.transfer.from?.firstName} ${item.transfer.from?.lastName} transferred the chat to ${item.transfer.to?.firstName} ${item.transfer.to?.lastName}`
                        : `${item.user.firstName} ${item.user.lastName} transferred the chat from ${item.transfer.from?.firstName} ${item.transfer.from?.lastName} to ${item.transfer.to?.firstName} ${item.transfer.to?.lastName}`

                    case "team":
                      return item.user._id === item.transfer.from?._id
                        ? `${item.transfer.from?.firstName} ${item.transfer.from?.lastName} (${item.transfer.fromTeam?.name}) transferred the chat to ${item.transfer.to?.firstName} ${item.transfer.to?.lastName} (${item.transfer.toTeam?.name})`
                        : `${item.user.firstName} ${item.user.lastName} transferred the chat from ${item.transfer.from?.firstName} ${item.transfer.from?.lastName} (${item.transfer.fromTeam?.name}) to ${item.transfer.to?.firstName} ${item.transfer.to?.lastName} (${item.transfer.toTeam?.name})`

                    default:
                      return `actions *${item.transfer.type}*`
                  }

                case "botReceive":
                  return "The bot has received the chat"

                default:
                  return `other action type has been done *${message.actionType}*`
              }
            })()}
          </p>
        </div>
      </div>
    </li>
  )
}

export default SingleHistory
