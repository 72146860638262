import React, { useState } from "react"

import configs from "config"
const { Url } = configs.client

function VideoSingleMessage({ message }) {
  const [isPlaying, setIsPlaying] = useState(false)

  const toggleVideo = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <>
      <video
        src={`${Url}/${message.video.file}`}
        controls
        onClick={toggleVideo}
        className="video-player"
        style={{ width: "20rem" }}
      >
        Your browser does not support the video.
      </video>

      {message.video.caption && (
        <p
          className="mb-1"
          style={{ maxWidth: "20rem", wordWrap: "break-word" }}
        >
          {message.video.caption}
        </p>
      )}
    </>
  )
}

export default VideoSingleMessage
