import React, { useState } from "react"
import { Button, Table } from "reactstrap"
import VisitReportModal from "./VisitReportModal"
import { useParams } from "../../../../node_modules/react-router-dom/dist/index"

const InspectionVisitsTable = ({ sessionIndicator, visits }) => {
  const [report, setReport] = useState()

  const params = useParams()

  const [sendReportModal, setSendReportModal] = useState(false)
  const sendReportToggle = () => {
    setSendReportModal(!sendReportModal)
  }

  return (
    <div style={{ maxHeight: "24.375rem", overflowY: "scroll" }}>
      {visits && visits.length > 0 && (
        <div className="table-responsive">
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th>Stage</th>
                <th>Inspector</th>
                <th>Date</th>
                <th>Status</th>
                {!params.ticketId && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {visits.map((item, key) => (
                <tr key={key}>
                  <td>{item.StageName}</td>
                  <td>{item.InspectorName}</td>
                  <td>{item.StageDate?.split(" ")[0]}</td>
                  <td>{item.Status}</td>
                  {!params.ticketId && (
                    <td>
                      <Button
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                          setReport(item)
                          sendReportToggle()
                        }}
                        disabled={!sessionIndicator}
                        title={
                          sessionIndicator ? "Send report" : "Open a session"
                        }
                      >
                        Send Visit Report
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {sendReportModal && (
        <VisitReportModal report={report} sendReportToggle={sendReportToggle} />
      )}
    </div>
  )
}

export default InspectionVisitsTable
