import React, { useState } from "react"
import "./ChooseWayComponent.css"

import UploadPCIcon from "components/Broadcast/Icons/UploadPCIcon"
import KeyboardIcon from "components/Broadcast/Icons/KeyboardIcon"
import ChooseSingleWayOptionComponent from "components/Broadcast/ChooseSingleWayOptionComponent"
import { useNavigate } from "react-router-dom"

function ChooseWayComponent({ setStep, data, setData }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null)

  const navigate = useNavigate()

  const handleChoose = index => {
    setSelectedOptionIndex(index)

    setData({ ...data, type: index === 0 ? "sheet" : "manual" })
  }

  return (
    <div className="options-layout">
      <h3 className="mb-4 text-capitalize choose-way-heading">
        template Sending options
      </h3>

      <div className="row justify-content-center">
        <div className="col-md-6">
          <ChooseSingleWayOptionComponent
            key={0}
            index={0}
            isSelected={selectedOptionIndex === 0}
            handleChoose={() => handleChoose(0)}
            icon={<UploadPCIcon />}
            label="Upload Excel File"
          />
        </div>

        <div className="col-md-6">
          <ChooseSingleWayOptionComponent
            key={1}
            index={1}
            isSelected={selectedOptionIndex === 1}
            handleChoose={() => handleChoose(1)}
            icon={<KeyboardIcon />}
            label="Enter Data Manually"
          />
        </div>
      </div>

      <div className="d-flex align-items-center gap-2">
        <i className="bx bxs-error-alt bx-sm bx-flashing"></i>
        <span className="font-size-16">
          Don’t worry. You can change these settings by going back
        </span>
      </div>

      <div className="text-end">
        <button
          onClick={() => {
            selectedOptionIndex === 1 && setStep(1)

            if (selectedOptionIndex === 0) navigate("/broadcast/upload-file")
          }}
          title={
            !data.type ? "You need to choose an option first" : "let's go!"
          }
          className={`next-enter-data ${!data.type && "btn-disabled"}`}
          disabled={!data.type}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default ChooseWayComponent
