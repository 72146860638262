import React, { useState, useEffect } from "react"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"

import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
export default function ViewAnswers({
  answer,
  allAnswers,
  toggleDeleteView,
  toggleEditView,
}) {
  const { access } = useSelector(state => ({
    access: state.Login.userAccess,
  }))

  return (
    <div>
      {answer.answers.map((answer, answerIndex) => (
        <div key={answer?._id} className="answer-details m-2 mb-3 ">
          <div className="accordion-content-data m-0">
            {allAnswers?.map(ans => {
              if (ans?._id === answer?._id) {
                return (
                  <div
                    key={ans?._id}
                    className="ans-details d-flex justify-content-between align-items-center"
                  >
                    <div className=" w-75">
                      <h4 className="m-0 mb-1 fw-bold text-capitalize">
                        {ans.name}
                      </h4>
                      <p className="m-0">{ans.body}</p>
                    </div>

                    <div>
                      <button
                        onClick={() => toggleEditView(ans)}
                        className="border-0 bg-transparent"
                      >
                        <TableEditIcon />
                      </button>
                      <button
                        onClick={() => toggleDeleteView(ans)}
                        className="border-0 bg-transparent"
                      >
                        <TableDeleteIcon />
                      </button>
                    </div>
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      ))}
    </div>
  )
}
