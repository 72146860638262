import React from "react"

export default function PositiveIcon() {
  return (
    <svg
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3836 34.9757C29.69 34.6743 31.0121 33.82 31.0121 32.2129C31.0121 31.56 30.8279 31.1086 30.6107 30.7229C30.4764 30.5057 30.4936 30.3379 30.6943 30.2543C31.7486 29.7857 32.6193 28.8486 32.6193 27.4922C32.6193 26.7386 32.4186 26.0522 32.0336 25.5672C31.8493 25.3157 31.8829 25.115 32.1836 24.9314C32.9707 24.4957 33.4736 23.5243 33.4736 22.4193C33.4736 21.6493 33.2221 20.7957 32.7528 20.3771C32.5028 20.1429 32.5529 19.9757 32.8371 19.7414C33.3893 19.3229 33.6907 18.5193 33.6907 17.565C33.6907 15.9243 32.4186 14.6014 30.7443 14.6014H24.7679C23.2607 14.6014 22.2393 13.815 22.2393 12.5757C22.2393 10.2657 25.1029 6.11358 25.1029 3.11715C25.1029 1.56001 24.0814 0.622864 22.7586 0.622864C21.5536 0.622864 20.9343 1.46001 20.2814 2.73215C17.7864 7.65358 14.405 11.6214 11.8436 15.02C9.66714 17.9329 8.59571 20.3771 8.54571 24.395C8.46214 30.5729 13.4671 35.2764 21.3357 35.3436L23.6628 35.3607C25.8557 35.3772 27.4629 35.21 28.3843 34.975M0.30928 24.5121C0.30928 29.5343 3.42357 33.7364 7.62499 33.7364H10.6214C7.59142 31.5271 6.20214 28.1786 6.26857 24.345C6.31857 20.0929 7.97642 17.0622 9.45 15.2207H6.98857C3.22214 15.2207 0.30928 19.3064 0.30928 24.5121Z"
        fill="#2AAA4A"
      />
    </svg>
  )
}
