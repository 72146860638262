import React, { useState } from 'react';
import { Col, Row } from "reactstrap"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import OverViewTicket from './TicketDahboard/OverViewTicket';
import TicketCharts from './TicketDahboard/TicketCharts';
import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"



export default function TicketDahboard() {

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  //setDate
  const handleDateChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }


  return (
    <div className=" user-setting-page ticket-dashboard  d-flex flex-column gap-3 ">
      <Row className='d-flex justify-content-between align-items-center '>
        <Col className="d-flex align-items-center users-setting">
          <p>
      Ticket ><span className="users-setting-span"> Dashboard</span>
          </p>
        </Col>

        <Col className="d-flex justify-content-end flex-grow-0">
          {/* ******* dropdown filter ******** */}
          <DatePicker
            className="broadcast-filter-date-picker text-capital"
            selectsRange={true}
            dateFormat="yyyy/MM/dd"
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            maxDate={new Date()}
            placeholderText="select Date"
            isClearable
            minDate={null}
          />

        </Col>
      </Row>


      {/*  ******* ticket Overview ******** */}
    
        <OverViewTicket startDate={startDate} endDate={endDate} />
     
      {/*  ******* ticket Charts ******** */}
     
        <TicketCharts startDate={startDate} endDate={endDate} />
      
    </div>
  )
}
