import React, { useState } from "react"
import BroadcastReportsTable from "./BroadcastReportsComponents/BroadcastReportsTable"
import BroadcastreportsFilter from "./BroadcastReportsComponents/BroadcastreportsFilter"
import PaginationComponent from "./BroadcastReportsComponents/PaginationComponent"
import { Col, Row } from "reactstrap"
import BroadcastReportChart from "./BroadcastReportsComponents/BroadcastReportChart"

function BroadcastReports() {
  const [isLoading, setIsLoading] = useState(true)
  const [messageInformation, setMessageInformation] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedTemplate, setSelectedTemplate] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedBroadcast, setSelectedBroadcast] = useState(null)
  const [showPopUpChart, setShowPopUpChart] = useState(false)
  const [selectedBroadcastData, setSelectedBroadcastData] = useState(null)

  const ViewBroadcastData = messageInformation?.find(broadcast => {
    return messageInformation
  })
  const totalPages = ViewBroadcastData?.totalPages
  const totalResults = ViewBroadcastData?.totalResults

  const showPopUp = broadcast => {
    setSelectedBroadcast(broadcast)
    setShowPopUpChart(true)
    setSelectedBroadcastData(broadcast)
  }
  const ClosePopUp = () => {
    setShowPopUpChart(false)
  }

  return (
    <div className="   user-setting-page broadcast-report">
      <div className="broadcast-report-header ">
        <div>
          <h4 className="mb-0 mb-3">Broadcast Reports</h4>
        </div>
        {/* result & filter components */}
        <Row className=" d-flex justify-content-between aligm-items-center mb-4">
          <Col md={3}>
            {" "}
            <div>
              <Col className="d-flex m-0 mt-1">
                <p className="m-0 me-1 text-header"> Total Results:</p>
                {isLoading ? (
                  <div className="d-flex">
                    <span className="m-0 font-weight-bold fw-bold ">
                      loading....
                    </span>
                  </div>
                ) : (
                  <p className="d-flex m-0 text-header">
                    {totalResults > 0 ? (
                      <span className="fw-bold me-1 ml-1 text-body">
                        {totalResults}
                      </span>
                    ) : (
                      <span className="me-1 ml-1 text-body">0</span>
                    )}
                    Broadcast
                  </p>
                )}
              </Col>
            </div>
          </Col>
          <Col>
            {" "}
            <BroadcastreportsFilter
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
              // showAuthPopUp={showAuthPopUp}
              // setShowAuthPopUp={setShowAuthPopUp}
            />
          </Col>
        </Row>
        {/* table components */}
        <div
          className={` ${
            isLoading
              ? "broadcast-table-height-loading"
              : "broadcast-table-height"
          }`}
        >
          <BroadcastReportsTable
            showPopUp={showPopUp}
            selectedTemplate={selectedTemplate}
            selectedUser={selectedUser}
            startDate={startDate}
            endDate={endDate}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            messageInformation={messageInformation}
            setMessageInformation={setMessageInformation}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        {/* Pagination Component */}
        {!isLoading && messageInformation?.length >= 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>

      {/* popup data */}
      {showPopUpChart && (
        <div id="popup">
          <BroadcastReportChart
            ClosePopUp={ClosePopUp}
            messageInformation={messageInformation}
            selectedBroadcastData={selectedBroadcastData}
          />
        </div>
      )}
    </div>
  )
}

export default BroadcastReports
