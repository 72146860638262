import React from "react"
import ResetFiltersIcon from "components/Common/Icons/SettingIcons/ResetFiltersIcon"
import DownloadSheetIcon from "components/Common/Icons/BroadcastReport/DownloadSheetIcon"
import * as XLSX from "xlsx"

export default function PerformanceReportsActions({
  handleResetFilters,
  isFiltered,
  PerformancesData,
}) {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(PerformancesData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Performances")
    XLSX.writeFile(wb, "PerformanceReports.xlsx")
    handleResetFilters()
  }

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <button
          onClick={exportToExcel}
          className="border-0 bg-transparent gap-2 d-flex justify-content-around align-items-center download-filters-report"
        >
          <DownloadSheetIcon /> Download Excel Sheet
        </button>
        {isFiltered && (
          <button
            onClick={handleResetFilters}
            className="border-0 bg-transparent  d-flex justify-content-around align-items-center reset-filters-report"
          >
            <ResetFiltersIcon />
          </button>
        )}
      </div>
    </div>
  )
}
