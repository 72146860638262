import React, { useState } from "react"

import configs from "config"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
const { Url } = configs.client

import "./ImageSingleMessage.css"

function ImageSingleMessage({ message }) {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div
        className="single-message-image-preview"
        onClick={() => setModalShow(true)}
      >
        <img
          className="position-relative"
          src={`${Url}/${message.image.file}`}
          style={{
            maxWidth: "100%",
            maxHeight: "13.4375rem",
            marginBottom: "0.3125rem",
          }}
          alt="Message Image"
        />

        <div className="single-image-message-overlay">
          <i className="mdi mdi-fullscreen"></i>
        </div>
      </div>

      {message.image.caption && (
        <p
          className="mb-1"
          style={{ maxWidth: "20rem", wordWrap: "break-word" }}
        >
          {message.image.caption}
        </p>
      )}

      <Modal
        isOpen={modalShow}
        toggle={() => setModalShow(!modalShow)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={() => setModalShow(false)}>
          Image preview
        </ModalHeader>
        <ModalBody>
          <img
            src={`${Url}/${message.image.file}`}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="Message Image"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ImageSingleMessage
