import React, { useEffect, useState } from "react"
import SuggestedAnswersTabl from "./SuggestedAnswersComponents/SuggestedAnswersTabl"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

import AddsuggestedAnswer from "./SuggestedAnswersComponents/AddsuggestedAnswer"
import DeleteSuggestedAnswer from "./SuggestedAnswersComponents/DeleteSuggestedAnswer"
import EditSuggestedAnswer from "./SuggestedAnswersComponents/EditSuggestedAnswer"
import AddViewAnswers from "./SuggestedAnswersComponents/AddViewAnswers"
import DeleteViewAnswers from "./SuggestedAnswersComponents/DeleteViewAnswers"
import EditViewAnswers from "./SuggestedAnswersComponents/EditViewAnswers"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

function SuggestedAnswers() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)

  const [suggestedAnswers, setSuggestedAnswers] = useState([])

  const fetchSuggestedAnswersData = () => {
    setIsLoading(true)
    axios
      .get(`${Url}/${apiVersion}/answers-sets`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const AllSuggestedAnswers = res.data.data.answersSets
        setSuggestedAnswers(AllSuggestedAnswers)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)

        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const [addNewAnswer, setAddNewAnswer] = useState(false)
  const toggleAdd = () => {
    setAddNewAnswer(!addNewAnswer)
  }

  const [ViewAnswers, setViewAnswers] = useState(null)
  const toggleView = Answer => {
    setViewAnswers(Answer)
    // setModalViewServices(!modalViewServices)
  }

  const [modalDeleteAnswer, setModalDeleteAnswer] = useState(false)
  const [deleteAnswer, setDeleteAnswer] = useState(null)
  const toggleDelete = Services => {
    setDeleteAnswer(Services)
    setModalDeleteAnswer(!modalDeleteAnswer)
  }

  const [editAnswer, setEditAnswer] = useState(null)
  const [modalEditAnswer, setModalEditAnswer] = useState(false)
  const toggleEdit = Answer => {
    setEditAnswer(Answer)
    setModalEditAnswer(!modalEditAnswer)
  }

  const [addViewAnswer, setAddViewAnswer] = useState(false)
  const [modalAddViewAnswer, setModalAddViewAnswer] = useState(false)
  const toggleAddView = Answer => {
    setAddViewAnswer(Answer)
    setModalAddViewAnswer(!modalAddViewAnswer)
  }

  const [modalDeleteViewAnswer, setModalDeleteViewAnswer] = useState(false)
  const [deleteViewAnswer, setDeleteViewAnswer] = useState(null)
  const toggleDeleteView = ans => {
    setDeleteViewAnswer(ans)
    setModalDeleteViewAnswer(!modalDeleteViewAnswer)
  }

  const [modalEditViewAnswer, setModalEditViewAnswer] = useState(false)
  const [editViewAnswer, setEditViewAnswer] = useState(null)
  const toggleEditView = ans => {
    setEditViewAnswer(ans)
    setModalEditViewAnswer(!modalEditViewAnswer)
  }

  const [allAnswers, setAllAnswers] = useState()

  const fetchAllAnswersData = () => {
    setIsLoading(true)
    axios
      .get(`${Url}/${apiVersion}/answers/`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const AnswersData = res.data.data.answers
        setAllAnswers(AnswersData)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)

        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }
  useEffect(() => {
    fetchAllAnswersData()
  }, [])

  useEffect(() => {
    fetchSuggestedAnswersData()
  }, [])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <div>
      <div className=" user-setting-page ">
        {/* add new answer */}
        <div className="d-flex justify-content-between align-items-center users-setting">
          <p>
            Setting{" "}
            <span className="users-setting-span">> Suggested Answers</span>
          </p>
          <button onClick={toggleAdd}>+ Add Suggested Answers</button>
        </div>

        {/* success message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showSuccessToast} fade={true}>
              <ToastBody className="success-message-bg">
                <h5 className="m-0 mb-2">Success</h5>
                <p className="m-0">Ansewr Added Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Edit Message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showEditToast} fade={true}>
              <ToastBody className="edit-message-bg">
                <h5 className="m-0 mb-2">Edit</h5>
                <p className="m-0">Answer edited Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        {/* Delete message */}
        <div
          className="position-relative"
          aria-live="polite"
          aria-atomic="true"
        >
          <div className="success-message">
            <Toast isOpen={showDeleteToast} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Delete</h5>
                <p className="m-0">Answer Deleted Successfully</p>
              </ToastBody>
            </Toast>
          </div>
        </div>

        <SuggestedAnswersTabl
          isloading={isloading}
          suggestedAnswers={suggestedAnswers}
          toggleView={toggleView}
          toggle={toggleAdd}
          toggleDelete={toggleDelete}
          toggleEdit={toggleEdit}
          toggleAddView={toggleAddView}
          allAnswers={allAnswers}
          toggleDeleteView={toggleDeleteView}
          toggleEditView={toggleEditView}
        />
        <AddsuggestedAnswer
          toggle={toggleAdd}
          isOpen={addNewAnswer}
          fetchSuggestedAnswersData={fetchSuggestedAnswersData}
          setShowSuccessToast={setShowSuccessToast}
        />
        <DeleteSuggestedAnswer
          isOpen={modalDeleteAnswer}
          Answer={deleteAnswer}
          toggle={toggleDelete}
          fetchSuggestedAnswersData={fetchSuggestedAnswersData}
          setShowDeleteToast={setShowDeleteToast}
        />
        <EditSuggestedAnswer
          fetchSuggestedAnswersData={fetchSuggestedAnswersData}
          setShowEditToast={setShowEditToast}
          isOpen={modalEditAnswer}
          toggle={toggleEdit}
          Answer={editAnswer}
        />

        <AddViewAnswers
          Answer={addViewAnswer}
          toggle={toggleAddView}
          isOpen={modalAddViewAnswer}
          fetchAllAnswersData={fetchAllAnswersData}
          setShowSuccessToast={setShowSuccessToast}
          fetchSuggestedAnswersData={fetchSuggestedAnswersData}
        />

        <DeleteViewAnswers
          isOpen={modalDeleteViewAnswer}
          ans={deleteViewAnswer}
          toggle={toggleDeleteView}
          fetchSuggestedAnswersData={fetchSuggestedAnswersData}
          setShowDeleteToast={setShowDeleteToast}
        />

        <EditViewAnswers
          isOpen={modalEditViewAnswer}
          ans={editViewAnswer}
          toggle={toggleEditView}
          fetchAllAnswersData={fetchAllAnswersData}
          fetchSuggestedAnswersData={fetchSuggestedAnswersData}
          setShowEditToast={setShowEditToast}
        />
      </div>
    </div>
  )
}

export default SuggestedAnswers
