import { ErrorMessage, Field, FieldArray } from "formik"
import React from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

function DropdownFields({ values }) {
  return (
    <>
      <h4 className="mb-0 bg-light p-1 px-2">Field Values</h4>
      <div className="inner-repeater mb-4">
        <Label className="mb-1 mt-2 font-size-16">value</Label>
        <table style={{ width: "100%" }}>
          <tbody>
            <FieldArray
              name="dropdownValues"
              render={arrayHelpers => (
                <>
                  {values?.map((value, index) => (
                    <>
                      <tr key={index}>
                        <td>
                          <Row className="mb-2">
                            <Col md="11" xs="10">
                              <Field
                                type="text"
                                name={`dropdownValues.${index}`}
                                className="inner form-control"
                                placeholder="Enter a dropdown value"
                                as={Input}
                              />
                            </Col>

                            <Col
                              md="1"
                              xs="2"
                              className="d-flex align-items-center"
                            >
                              <div className="d-grid">
                                {values.length > 1 && (
                                  <Button
                                    color="danger"
                                    className="btn-block inner py-1"
                                    id="unknown-btn"
                                    style={{ width: "100%" }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <i className="bx bxs-trash"></i>
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </>
                  ))}

                  <ErrorMessage
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "0.875em",
                    }}
                    name="dropdownValues"
                  />

                  <Button
                    onClick={() => arrayHelpers.push("")}
                    color="primary"
                    className="mt-1 py-1"
                  >
                    Add Value
                  </Button>
                </>
              )}
            />
          </tbody>
        </table>
      </div>

      <FormGroup>
        <div className="d-flex flex-column gap-1 mb-2">
          <Label for="defaultValue" className="mb-0">
            Default values
          </Label>
          <span className="text-muted">
            Specifies what selected value the field has by default
          </span>
        </div>

        <Field id="defaultValue" name="defaultValue" type="select" as={Input}>
          <option value="" disabled selected>
            Select a value
          </option>
          {values.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </Field>
        <ErrorMessage
          component="div"
          style={{ color: "red", fontSize: "0.875em" }}
          name="defaultValue"
        />
      </FormGroup>
    </>
  )
}

export default DropdownFields
