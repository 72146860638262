import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import configs from "../../../config"
import DownloadSheetIcon from "components/Common/Icons/BroadcastReport/DownloadSheetIcon"
const { Url, apiVersion } = configs.client
import { useSelector } from "react-redux"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
// import UnAuthorized from "store/auth/Logout-Condition/UnAuthorized"
// import AuthPopUp from "components/Common/PopUp/AuthPopUp/AuthPopUp"

export default function DownloadExcelSheet({ BroadcastID, BroadcastName }) {
  const dispatch = useDispatch()

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  //downloadExcelSheet
  const DownloadFileSheet = () => {
    axios
      .get(`${Url}/${apiVersion}/broadcast/${BroadcastID}?type=sheet`, {
        headers: { Authorization: `Bearer ${access}` },
        responseType: "blob",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `Template: ${BroadcastName} , broadcast_${BroadcastID}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      {/* {showAuthPopUp && <AuthPopUp />} */}
      <div className="mt-3 mb-2">
        <button
          onClick={DownloadFileSheet}
          className="d-flex gap-2 justify-content-center align-items-center download-sheet"
        >
          <DownloadSheetIcon />
          Download excel sheet
        </button>
      </div>
    </>
  )
}
