import PropTypes from "prop-types"
import React, { useState } from "react"
import axios from "axios"
import OtpInput from "react-otp-input"

import { Alert, Form, Input, FormFeedback, Label } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate, Navigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import {
  loginUser,
  loginSuccess,
  socialLogin,
  apiError,
  sendOTPRequest,
} from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

import configs from "../../config"
import LoginForm from "./LoginForm"
import TimerCountDown from "components/Common/TimerCountDown"
const { Url, apiVersion } = configs.client

const Login = props => {
  // meta title
  document.title = "ChatPlus | Login"

  const { loading } = useSelector(state => ({ loading: state.Login.loading }))
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(1)
  const [otp, setOtp] = useState("")
  const [emailCode, setEmailCode] = useState("")
  const [passowrdCode, setPassowrdCode] = useState("")
  const [restart, setRestart] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser())

      setEmailCode(values.email)
      setPassowrdCode(values.password)

      axios
        .post(`${Url}/${apiVersion}/users/login`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
          },
        })
        .then(response => {
          if (response.data.status == "success") {
            dispatch(sendOTPRequest())
            setCurrentStep(2)
            dispatch(apiError(""))
          }
        })
        .catch(err => {
          console.log(err)
          dispatch(sendOTPRequest())
          dispatch(apiError(err.response.data?.message))
        })
    },
  })

  const handleResendOTP = () => {
    setRestart(true)

    dispatch(loginUser())

    axios
      .post(
        `${Url}/${apiVersion}/users/login`,
        {
          email: emailCode,
          password: passowrdCode,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(response => {
        if (response.data.status == "success") {
          dispatch(sendOTPRequest())
          dispatch(apiError(""))
        }
      })
      .catch(err => {
        console.log(err)
        dispatch(sendOTPRequest())
        dispatch(apiError(err.response.data?.message))
      })
  }

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      error: login.error,
    })
  )

  const { error } = useSelector(LoginProperties)

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  if (localStorage.getItem("token")) {
    return <Navigate to="/" />
  }

  const sendingOTP = () => {
    dispatch(loginUser())

    axios
      .post(
        `${Url}/${apiVersion}/users/otp`,
        {
          email: emailCode,
          password: passowrdCode,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
          },
        }
      )
      .then(res => {
        if (res.data.status == "success") {
          localStorage.setItem("token", JSON.stringify(res.data?.token))

          localStorage.setItem("authUser", JSON.stringify(res.data?.data))

          dispatch(loginSuccess(res.data))
          // dispatch(latest_status(res.data.data.user.status))

          navigate("/")
          // window.location.href = "/"
        }
        // console.log("OTP RESPONSE", res)
      })
      .catch(err => {
        console.log(err)
        dispatch(apiError(err.response?.data?.message))
      })
  }

  return (
    <React.Fragment>
      <div className="login-page">
        <div className="left-side"></div>

        <div className="right-side d-flex align-items-center">
          <div className="form-outer-container">
            <h1 className={currentStep === 2 ? "text-center" : ""}>
              {currentStep == 1 ? "Welcome back" : "OTP"}
            </h1>

            {currentStep === 2 && (
              <p className={currentStep === 2 ? "text-center mb-5" : ""}>
                <span>Kindly enter the code sent via WhatsApp</span>
              </p>
            )}

            {currentStep === 1 && (
              <span>Kindly enter your contact details to connect</span>
            )}

            {currentStep === 1 && (
              <div className="form-container">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  {error ? (
                    <Alert color="danger" className="text-center">
                      {error}
                    </Alert>
                  ) : null}

                  <div className="mb-4">
                    <Label className="form-label">Email address</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-4">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type="password"
                      placeholder="Enter your password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="my-4 d-grid">
                    <button
                      className="btn btn-secondary btn-lg btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Logging in..." : "Log In"}
                    </button>
                  </div>
                </Form>
              </div>
            )}

            {error && currentStep === 2 ? (
              <Alert color="danger" className="text-center">
                {error}
              </Alert>
            ) : null}

            {currentStep === 2 && (
              <div className="text-center otp-form-container">
                <div className="mb-4">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    containerStyle="justify-content-center mb-3"
                    renderSeparator={<span> </span>}
                    renderInput={props => (
                      <input
                        {...props}
                        style={{ width: "40px" }}
                        className="otp-input"
                      />
                    )}
                  />

                  <TimerCountDown restart={restart} setRestart={setRestart} />

                  <span
                    className="resend-otp"
                    onClick={() => {
                      handleResendOTP()
                    }}
                  >
                    <u>Resend OTP code</u>
                  </span>
                </div>

                <div className="d-flex justify-content-center gap-2 mb-4">
                  <button
                    className="btn btn-outline-secondary btn-sm py-2 px-5 btn-block rounded-3"
                    onClick={() => setCurrentStep(1)}
                    disabled={loading}
                  >
                    Go back
                  </button>
                  <button
                    className="btn btn-secondary btn-sm py-2 px-5 btn-block rounded-3"
                    onClick={() => {
                      sendingOTP()
                    }}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Continue"}
                  </button>
                </div>
              </div>
            )}

            <div className="login-footer">
              &copy; {new Date().getFullYear()} DomApp.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
