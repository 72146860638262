import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  API_ERROR,
  RESET_DATA,
  UPDATE_CHATS_TYPE,
  UPDATE_CHATS_STATUS,
  CHANGE_SELECTED_OVERVIEW,
  UPDATE_SELECTED_TEAMS,
  TEAM_SESSION_NUMBERS,
  USER_SESSION_NUMBERS,
  CHANGE_CHAT_FILTER,
  UPDATE_ARCHIVED_USER_ID,
  UPDATE_ARCHIVED_START_DATE,
  UPDATE_ARCHIVED_END_DATE,
  SET_SELECTED_TEAM_MEMBER,
  ADD_OPEN_TABS,
  DELETE_CLOSED_TABS,
  SEND_OTP_REQUEST,
  RESET_ARCHIVED_USER_ID,
  RESET_ARCHIVED_DATE,
  UPDATE_USER_DATA,
  UPDATE_ACCESS_TOKEN,
  UN_AUTH_USER,
  UPDATE_OPEN_TABS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  userData: localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser")).user
    : null,
  userAccess: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
  authorized: true,
  currentOverView: "My Overview",
  chatsType: "userChats",
  chatStatus: "all",
  selectedTeams: "",
  userSessions: "",
  teamSessions: "",
  chatsFilter: "all",
  archivedUserId: "",
  archivedStartDate: "",
  archivedEndDate: "",
  selectedTeamMember: "",
  openChatsTabs: JSON.parse(localStorage.getItem("openChatsTabs")) || [],
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        userData: action.payload,
        authorized: true,
        loading: true,
      }
      break

    case UPDATE_USER_DATA:
      state = {
        ...state,
        userData: action.payload,
      }
      break

    case SEND_OTP_REQUEST:
      state = {
        ...state,
        loading: false,
      }
      break

    case RESET_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        userData: "",
        userAccess: null,
        authorized: false,
        isUserLogout: true,
      }

    case LOGIN_SUCCESS:
      state = {
        ...state,
        authorized: true,
        loading: false,
        userAccess: action.payload.token,
        userData: action.payload.data.user,
      }
      break

    case LOGOUT_USER:
      state = {
        error: "",
        loading: false,
        userData: "",
        userAccess: "",
        authorized: false,
        currentOverView: "My Overview",
        chatsType: "userChats",
        chatStatus: "all",
        selectedTeams: "",
        userSessions: "",
        teamSessions: "",
        chatsFilter: "all",
        archivedUserId: "",
        archivedStartDate: "",
        archivedEndDate: "",
        selectedTeamMember: "",
        openChatsTabs: [],
      }
      break

    case UPDATE_ACCESS_TOKEN:
      state = { ...state, userAccess: action.payload }
      break

    case UN_AUTH_USER:
      state = { ...initialState, authorized: false }
      break

    // case LOGOUT_USER_SUCCESS:
    //   state = { ...state, isUserLogout: true }
    //   break

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break

    case UPDATE_CHATS_TYPE:
      state = {
        ...state,
        chatsType: action.payload,
        chatStatus: action.payload === "archivedChats" ? "" : state.chatStatus,
      }
      break

    case UPDATE_CHATS_STATUS:
      state = {
        ...state,
        chatsFilter: "all",
        selectedTeamMember: "",
        chatStatus: action.payload,
      }
      break

    case CHANGE_SELECTED_OVERVIEW:
      state = {
        ...state,
        selectedTeamMember: "",
        currentOverView: action.payload.overview,
        chatStatus: action.payload.newChatStatus,
        chatsType: action.payload.newChatsType,
      }
      break

    case UPDATE_SELECTED_TEAMS:
      state = { ...state, selectedTeams: action.payload }
      break

    case USER_SESSION_NUMBERS:
      state = { ...state, userSessions: action.payload }
      break

    case TEAM_SESSION_NUMBERS:
      state = { ...state, teamSessions: action.payload }
      break

    case CHANGE_CHAT_FILTER:
      state = { ...state, chatsFilter: action.payload }
      break

    case UPDATE_ARCHIVED_USER_ID:
      state = { ...state, archivedUserId: action.payload }
      break

    case UPDATE_ARCHIVED_START_DATE:
      state = { ...state, archivedStartDate: action.payload }
      break

    case UPDATE_ARCHIVED_END_DATE:
      state = { ...state, archivedEndDate: action.payload }
      break

    case SET_SELECTED_TEAM_MEMBER:
      state = {
        ...state,
        chatsType: "teamUserChats",
        chatsFilter: "all",
        chatStatus: "",
        archivedUserId: "",
        archivedStartDate: "",
        archivedEndDate: "",
        selectedTeamMember: action.payload,
      }
      break

    case ADD_OPEN_TABS:
      const chatExists = state.openChatsTabs.some(
        chat => chat.client === action.payload.client
      )

      if (chatExists) {
        return state
      }
      return {
        ...state,
        openChatsTabs: [...state.openChatsTabs, action.payload],
      }

    case DELETE_CLOSED_TABS:
      state = {
        ...state,
        openChatsTabs: state.openChatsTabs.filter(
          chat => chat.client != action.payload
        ),
      }
      break

    case UPDATE_OPEN_TABS:
      state = {
        ...state,
        openChatsTabs: action.payload,
      }
      break

    case RESET_ARCHIVED_USER_ID:
      state = {
        ...state,
        archivedUserId: "",
      }
      break

    case RESET_ARCHIVED_DATE:
      state = {
        ...state,
        archivedStartDate: "",
        archivedEndDate: "",
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default login
