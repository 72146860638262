// Import necessary libraries
import React from "react"
import { Doughnut } from "react-chartjs-2"
import "chart.js/auto"
import { Col } from "reactstrap"

const DoughnutChart = ({ selectedBroadcastData }) => {
  const data = {
    labels: [
      "Read Messages",
      "Delivered Messages",
      "Sent Messages",
      "Failed Messages",
      "Pending Messages",
    ],
    datasets: [
      {
        data: [
          selectedBroadcastData?.broadcastData?.read,
          selectedBroadcastData?.broadcastData?.delivered,
          selectedBroadcastData?.broadcastData?.sent,
          selectedBroadcastData?.broadcastData?.failed,
          selectedBroadcastData?.broadcastData?.pending,
        ],
        backgroundColor: [
          "#546272",
          "#77879B",
          "#A0B2CA",
          "#CCDEF6",
          "#E0E6E9",
        ],
        hoverBackgroundColor: [
          "#546272",
          "#77879B",
          "#A0B2CA",
          "#CCDEF6",
          "#E0E6E9",
        ],
        borderColor: ["#5E6A79", "#77879B", "#A0B2CA", "#CCDEF6", "#E0E6E9"],
        borderWidth: 1,
        hoverRadius: 10,

        // hoverOffset: 20,
      },
    ],
  }

  const options = {
    cutout: "60%",
    plugins: {
      legend: {
        display: false,
      },
      font: {
        size: 22,
      },
      tooltip: {
        displayColors: false,
        // backgroundColor: "lightgray",
        // bodyFontColor: "red",

        callbacks: {
          label: function (context) {
            const value = context.raw
            const percentage = (
              (value / selectedBroadcastData?.broadcastData?.totalMessages) *
              100
            ).toFixed(0)

            return ` ${value} (${percentage}%) `
          },
        },
      },
    },
  }

  return (
    <div>
      <Col lg={8} xs={8} md={8}></Col>
      <Doughnut className="doughnut-chart " data={data} options={options} />
      <Col />
    </div>
  )
}

export default DoughnutChart
