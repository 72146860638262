import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row } from "reactstrap"
import axios from "axios"
import { useSelector } from "react-redux"
const { Url, apiVersion } = configs.client
import configs from "../../../config"
import moment from "moment"
import accessToken from "helpers/jwt-token-access/accessToken"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import TableView from "components/Common/Icons/SettingIcons/TableView"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
export default function BroadcastReportsTable({
  setMessageInformation,
  messageInformation,
  setIsLoading,
  isLoading,
  currentPage,
  setCurrentPage,
  selectedTemplate,
  selectedUser,
  startDate,
  endDate,
  showPopUp,
}) {
  const [showAuthPopUp, setShowAuthPopUp] = useState(false)
  const dispatch = useDispatch()

  const ViewBroadcastData = messageInformation?.find(broadcast => {
    return messageInformation
  })
  const totalPages = ViewBroadcastData?.totalPages

  // const token = accessToken
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const cancelTokenSource = useRef(null)

  const getInfoMessage = () => {
    // cancel request if send more requests at the same time:
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to a new request."
      )
    }
    cancelTokenSource.current = axios.CancelToken.source()
    if (!access) return
    setIsLoading(true)
    const userQueryParam =
      selectedUser?.length > 0
        ? `&users=${selectedUser.map(user => user.value).join(",")}`
        : ""
    const templatesQueryParam =
      selectedTemplate?.length > 0
        ? `&templates=${selectedTemplate
            .map(template => template.value)
            .join(",")}`
        : ""

    const startDateQueryParam = startDate
      ? `&startDate=${moment(startDate).format("MM-DD-Y")}`
      : ""
    const endDateQueryParam = endDate
      ? `&endDate=${moment(endDate).format("MM-DD-Y")}`
      : ""
    axios
      .get(
        `${Url}/${apiVersion}/broadcast?page=${currentPage}${userQueryParam}${templatesQueryParam}${startDateQueryParam}${endDateQueryParam}`,
        {
          headers: { Authorization: `Bearer ${access}` },
          cancelToken: cancelTokenSource.current.token,
        }
      )
      .then(response => {
        const data = response.data.data
        const results = response.data.results

        const AllMessageInfo = response.data.data.broadcasts?.map(
          broadcasts => ({
            id: broadcasts._id,
            template: broadcasts.template,
            time: broadcasts.time,
            results: broadcasts.results,
            firstName: broadcasts.user.firstName,
            lastName: broadcasts.user.lastName,
            broadcastData: broadcasts.broadcastData,
            results: results,
            totalResults: data.totalResults,
            totalPages: data.totalPages,
            page: data.page,
          })
        )
        setMessageInformation(AllMessageInfo)
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const isFirstPageLoadRef = useRef(true)
  useEffect(() => {
    if (isFirstPageLoadRef.current) {
      if (currentPage !== 1) {
        getInfoMessage()
      }
      isFirstPageLoadRef.current = false
    } else if (currentPage === 1) {
      getInfoMessage()
    }
  }, [selectedUser, selectedTemplate, startDate, endDate])

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedUser, selectedTemplate, startDate, endDate])

  useEffect(() => {
    getInfoMessage()
  }, [currentPage])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="table-container table-broadcast  m-0 p-0">
      {isLoading ? (
        <div className=" d-flex justify-content-center align-items-center loading">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <table className="">
          <thead className="text-center">
            <tr>
              <th className="text-header">Template</th>
              <th className="text-header">User</th>
              <th className="text-header">Total Messages</th>
              <th className="text-header">Time</th>
              {/* <th className="text-header">Action</th> */}
            </tr>
          </thead>

          {messageInformation?.length > 0 ? (
            <tbody>
              {messageInformation.map(broadcast => (
                <React.Fragment key={broadcast.id}>
                  <tr
                    className="tr"
                    onClick={() => {
                      showPopUp(broadcast)
                    }}
                  >
                    <td className="text-center text-capitalize">
                      {broadcast.template.replace(/_/g, " ")}
                    </td>
                    <td className="text-center text-capitalize">
                      {broadcast.firstName + " " + broadcast.lastName}
                    </td>
                    <td className="text-center text-capitalize">
                      {broadcast.broadcastData?.totalMessages}
                    </td>
                    <td className="text-center text-capitalize">
                      {broadcast.time}
                    </td>
                    {/* <td className="text-center  ">
                      <button
                        title="view"
                        onClick={() => {
                          showPopUp(broadcast)
                        }}
                        className=" border-0 bg-transparent text-center  "
                      >
                        <TableView />
                      </button>
                    </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          ) : (
            <tr className="">
              <td className="text-center" colSpan={4}>
                {" "}
                No broadcasts available
              </td>
            </tr>
          )}
        </table>
      )}
    </div>
  )
}
