import React from "react"
import { Container, Row } from "reactstrap"

export default function TemplateButtons({ selectedTemplateData }) {
  return (
    <Container>
      {selectedTemplateData.components.map((component, idx) => {
        if (component.type == "BUTTONS")
          return component.buttons.map((button, idx) => {
            return (
              <>
                <Row md={4}>
                  <button
                    style={{
                      color: "#2a3042",
                      border: "1px solid #2a3042",
                      transition: "all 0.3s ease-in-out",
                    }}
                    className=" d-block mb-2 rounded-2 buttons-preview-template p-2 fs-6 bg-transparent b"
                    key={idx}
                  >
                    {button.text}
                  </button>
                </Row>
              </>
            )
          })
      })}
    </Container>
  )
}
