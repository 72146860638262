import React, { useState } from "react"
import FileStepper from "./FileStepper"
import "./fileSteps.css"

import DragDropFile from "./DragDropFile"
import ChoseFileTemp from "./ChoseFileTemp"
import PreviewTemplate from "./PreviewTemplate"
import Finish from "./Finish"

export default function UploadFileSteps() {
  const [step, setStep] = useState(1)
  const [columns, setColumns] = useState([])
  const [files, setFiles] = useState([])
  const [countryCode, setCountryCode] = useState()

  const [numRows, setNumRows] = useState(0)

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  let headerVar
  let bodyVars

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templates, setTemplates] = useState([])
  const [selectNumber, setSelectedNumber] = useState("")
  const [selectedOptions, setSelectedOptions] = useState(Array(bodyVars))
  const [selectedColumnHeader, setSelectedColumnHeader] = useState("")
  const [selectFileColumn, setSelectFileColumn] = useState(null)
  const [isSendData, setIsSendData] = useState(false)
  const [showAuthPopUpt, setShowAuthPopUpt] = useState(false)
  const [firstValues, setFirstValues] = useState([])
  const [selectedTemplateData, setSelectedTemplateData] = useState(null)

  return (
    <>
      <div className="h-100 d-flex justify-content-center">
        <div className="Upload-File-Steps px-3 my-auto w-100 align-items-center position-relative">
          <div className="file-stepper-bg">
            <FileStepper step={step} />
          </div>

          <div style={{ width: "90%" }}>
            {step === 1 && (
              <ChoseFileTemp
                prevStep={prevStep}
                step={step}
                nextStep={nextStep}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                templates={templates}
                setTemplates={setTemplates}
                showAuthPopUpt={showAuthPopUpt}
                setShowAuthPopUpt={setShowAuthPopUpt}
                setSelectedTemplateData={setSelectedTemplateData}
              />
            )}

            {step === 2 && (
              <DragDropFile
                columns={columns}
                setColumns={setColumns}
                step={step}
                nextStep={nextStep}
                files={files}
                setFiles={setFiles}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                selectNumber={selectNumber}
                setSelectedNumber={setSelectedNumber}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                selectedColumnHeader={selectedColumnHeader}
                setSelectedColumnHeader={setSelectedColumnHeader}
                selectFileColumn={selectFileColumn}
                prevStep={prevStep}
                firstValues={firstValues}
                setFirstValues={setFirstValues}
                setNumRows={setNumRows}
              />
            )}

            {step === 3 && (
              <PreviewTemplate
                prevStep={prevStep}
                step={step}
                nextStep={nextStep}
                selectedTemplate={selectedTemplate}
                templates={templates}
                setTemplates={setTemplates}
                columns={columns}
                files={files}
                setFiles={setFiles}
                countryCode={countryCode}
                selectNumber={selectNumber}
                setSelectedNumber={setSelectedNumber}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                headerVar={headerVar}
                bodyVars={bodyVars}
                selectedColumnHeader={selectedColumnHeader}
                setSelectedColumnHeader={setSelectedColumnHeader}
                setSelectFileColumn={setSelectFileColumn}
                selectFileColumn={selectFileColumn}
                isSendData={isSendData}
                setIsSendData={setIsSendData}
                showAuthPopUpt={showAuthPopUpt}
                setShowAuthPopUpt={setShowAuthPopUpt}
                firstValues={firstValues}
                setFirstValues={setFirstValues}
                numRows={numRows}
                chosenTemplateData={selectedTemplateData}
              />
            )}

            {step === 4 && <Finish nextStep={nextStep} />}
          </div>
        </div>
      </div>
    </>
  )
}
