import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import Knob from "./Knob"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/actions"
import { useNavigate } from "react-router-dom"

const UnAuthorizedPopup = () => {
  const [timer, setTimer] = useState(3)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 1) {
          clearInterval(interval)
          dispatch(logoutUser())
          UnAuthorized()
          navigate("/login")
        }
        return prevTimer - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [dispatch, navigate])

  return (
    <Modal size="sm" isOpen={true} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <div className="avatar-sm mb-4 mx-auto">
            <Knob
              value={timer}
              fgColor="#f46a6a"
              step={1}
              height={80}
              width={60}
              max={10}
            />
          </div>
          <p className="text-muted font-size-16 mb-4">
            Your session has been ended
          </p>
        </ModalBody>
      </div>
    </Modal>
  )
}

UnAuthorizedPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default UnAuthorizedPopup
