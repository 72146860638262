import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
export default function ViewServiceHours({
  services,
  toggle,
  isOpen,
  service,
}) {
  function formatTime12Hour(hours, minutes) {
    const WorksHoures = hours >= 12 ? "PM" : "AM"
    const formattedHours = hours % 12 || 12
    return `${formattedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${WorksHoures}`
  }
  function calculateHours(duration) {
    const fromHours = duration.from.hours
    const fromMinutes = duration.from.minutes
    const toHours = duration.to.hours
    const toMinutes = duration.to.minutes

    let diffHours = toHours - fromHours
    let diffMinutes = toMinutes - fromMinutes

    if (diffMinutes < 0) {
      diffHours--
      diffMinutes += 60
    }

    const formattedHours = diffHours.toString().padStart(2, "0")
    const formattedMinutes = diffMinutes.toString().padStart(2, "0")
    return `${formattedHours}:${formattedMinutes}`
  }
  return (
    // <Modal isOpen={isOpen} toggle={toggle} centered size="lg" className="">
    //   <ModalHeader className="border-0 mb-0 pb-0 " toggle={toggle}>
    //     View Service Hours
    //   </ModalHeader>
    //   <ModalBody
    //     className="border-0  d-flex flex-column align-items-center justify-content-center"
    //     toggle={toggle}
    //   >
    //     <div className=" mb-3 view-selected-services-hours">
    //       <div className="">
    //         <div className="d-flex justify-content-between">
    //           <p className="m-0 mb-1">
    //             Name: <span>{services?.name}</span>
    //           </p>
    //           <p>
    //             Response Time:{" "}
    //             <span>
    //               {services?.responseTime?.hours}h:
    //               {services?.responseTime?.minutes}m
    //             </span>
    //           </p>
    //         </div>

    //         <div className="table-border">
    //           <table className="table ">
    //             <thead className="text-center">
    //               <tr className="table-body tr">
    //                 <th scope="col">Service Day</th>
    //                 <th scope="col">Work Day From</th>
    //                 <th scope="col">Work Day To</th>
    //                 <th scope="col">Total Hours</th>
    //               </tr>
    //             </thead>
    //             <tbody>
    //               {services?.durations?.length > 0 ? (
    //                 services?.durations.map((duration, index) => (
    //                   <tr key={index}>
    //                     <td className="text-center">{duration.day}</td>
    //                     <td className="text-center">
    //                       {formatTime12Hour(
    //                         duration?.from?.hours,
    //                         duration?.from?.minutes
    //                       )}
    //                     </td>
    //                     <td className="text-center">
    //                       {formatTime12Hour(
    //                         duration?.to?.hours,
    //                         duration?.to?.minutes
    //                       )}
    //                     </td>
    //                     <td className="text-center">
    //                       {" "}
    //                       {calculateHours(duration)}
    //                     </td>
    //                   </tr>
    //                 ))
    //               ) : (
    //                 <tr>
    //                   <td colSpan="4" className="text-center">
    //                     No data available
    //                   </td>
    //                 </tr>
    //               )}
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   </ModalBody>
    // </Modal>

    // <div  className="m-0 mb-2  view-selected-services-hours ">
    //   <div className="">
    //     <div className="d-flex justify-content-between">
    //       <p className="m-0 mb-1">
    //         Name: <span>{service?.name}</span>
    //       </p>
    //       <p>
    //         Response Time:{" "}
    //         <span>
    //           {service?.responseTime?.hours}h:
    //           {service?.responseTime?.minutes}m
    //         </span>
    //       </p>
    //     </div>

    //     <div className="table-border">
    //       <table className="table ">
    //         <thead className="text-center">
    //           <tr className="table-body tr">
    //             <th scope="col">Service Day</th>
    //             <th scope="col">Work Day From</th>
    //             <th scope="col">Work Day To</th>
    //             <th scope="col">Total Hours</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {service?.durations?.length > 0
    //             ? service?.durations.map((duration, index) => (
    //                 <tr key={index}>
    //                   <td className="text-center">{duration.day}</td>
    //                   <td className="text-center">
    //                     {formatTime12Hour(
    //                       duration?.from?.hours,
    //                       duration?.from?.minutes
    //                     )}
    //                   </td>
    //                   <td className="text-center">
    //                     {formatTime12Hour(
    //                       duration?.to?.hours,
    //                       duration?.to?.minutes
    //                     )}
    //                   </td>
    //                   <td className="text-center">
    //                     {" "}
    //                     {calculateHours(duration)}
    //                   </td>
    //                 </tr>
    //               ))
    //             : // <tr>
    //               //   <td colSpan="4" className="text-center">
    //               //     No data available
    //               //   </td>
    //               // </tr>
    //               ""}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </div>
   <></>
  )
}
