import React, { useEffect, useState } from "react"
import { Formik, useFormik, validateYupSchema } from "formik"
import { FormControl } from "react-bootstrap"
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Toast,
    ToastBody,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

export default function AddStatus({
    toggle,
    isOpen,
    setShowSuccessToast,
    fetchAllStatuses,
}) {
    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const AddStatusesSchema = Yup.object().shape({
        name: Yup.string().required("required")
            .matches(/^(?!\s+$).*/, "invalid"),
        endUserDisplayName: Yup.string().required("required")
            .matches(/^(?!\s+$).*/, "invalid"),
        // assigneeDisplayName: Yup.string().required("required")
        //     .matches(/^(?!\s+$).*/, "invalid"),
        description: Yup.string(),
        category: Yup.string().required("required"),
        // endUserView: Yup.string().required("required"),
    })

    // formik
    const {
        handleChange,
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        handleBlur,
        touched,
    } = useFormik({
        initialValues: {
            name: "",
            endUserDisplayName: "",
            // assigneeDisplayName: "",
            description: "",
            category: "",
        },
        validationSchema: AddStatusesSchema,
        onSubmit: values => {
            console.log("valuee", values)
            setIsLoading(true)
            axios
                .post(`${Url}/${apiVersion}/ticketStatuses`, values, {
                    headers: {
                        "Content-Type": "application/json; charset=utf8",
                        Authorization: `Bearer ${access}`,
                    },
                })
                .then(res => {
                    if (res.status === 201) {
                        fetchAllStatuses()
                        toggle()
                        resetForm()
                        setIsLoading(false)
                        setShowSuccessToast(true)
                        setTimeout(() => setShowSuccessToast(false), 2500)
                    } else {
                        console.error("Unexpected status code:", res.status, res.data)
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error("Error:", error)
                    if (error.response.status === 401 || error.response.status === 0) {
                        UnAuthorized()
                        // dispatch(authorized())
                    } else if (error.response?.status === 403) {
                        setErrorMessage(error.response.data.message)
                        setTimeout(() => setErrorMessage(false), 3500)
                    } else {
                        setErrorMessage(error.response?.data.message)
                        setTimeout(() => setErrorMessage(false), 3500)
                        console.log(error)
                    }
                })
        },
    })


    //select
    const colorStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "gray" : "white",
            backgroundColor: state.isFocused ? "#EBEDF0" : "white",
            color: state.isSelected ? "gray" : "black",
        }),
        menu: provided => ({
            ...provided,
            zIndex: 10000,
            height: "1rem",
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: "10rem",
            overflowY: "auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // color: "white",
            color: state.data.color,
            backgroundColor: state.data.backgroundColor,
            width: "max-content",
            padding: "0.2rem 0.8rem",
            borderRadius: "0.6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
        }),
    }
    const options = [
        {
            label: "new",
            value: "new",
            color: "#00BFAE",
            backgroundColor: "#E0F7FA",
        },
        {
            label: "open",
            value: "open",
            color: "#0288D1",
            backgroundColor: "#E3F2FD",
        },
        {
            label: "pending",
            value: "pending",
            color: "#C62828",
            backgroundColor: "#FFEBEE",
        },
        {
            label: "solved",
            value: "solved",
            color: "#388E3C",
            backgroundColor: "#e9ffef",
        },
    ]
    const [selectedCategory, setSelectedCategory] = useState(null)
    const handleChangeCategory = selectedOption => {
        setSelectedCategory(selectedOption)
        setFieldValue("category", selectedOption.label)
    }

    // handle names match:
    const [manualChange, setManualChange] = useState({
        endUserDisplayName: false,
        // assigneeDisplayName: false,
    });
    useEffect(() => {
        if (!manualChange.endUserDisplayName) {
            setFieldValue("endUserDisplayName", values.name);
        }
        // if (!manualChange.assigneeDisplayName) {
        //     setFieldValue("assigneeDisplayName", values.creatorDisplayName);
        // }
    }, [values.name, setFieldValue, manualChange]);
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        handleChange(e);


        if (name === "endUserDisplayName") {
            setManualChange(prev => ({ ...prev, endUserDisplayName: true }));
        }
        // if (name === "assigneeDisplayName") {
        //     setManualChange(prev => ({ ...prev, assigneeDisplayName: true }));
        // }
    };


    if (!authorized) {
        return <UnAuthorizedPopup />
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            onClosed={() => resetForm()}
            size="lg"
            centered
        >
            <ModalHeader toggle={toggle}>Add New Status</ModalHeader>
            <ModalBody>
                {errorMessage && (
                    <div className="" aria-live="polite" aria-atomic="true">
                        <div className="">
                            <Toast isOpen={errorMessage} fade={true}>
                                <ToastBody className="delete-message-bg">
                                    <h5 className="m-0 mb-2">Error</h5>
                                    <p className="m-0">{errorMessage}</p>
                                </ToastBody>
                            </Toast>
                        </div>
                    </div>
                )}

                <Row className="justify-content-center mt-4">
                    <form onSubmit={handleSubmit}>

                        <Row>
                            <Col>
                                <FormGroup className="m-0 m-mb-3">
                                    <Label className="mb-1">Name</Label>
                                    <Input
                                        className=" p-5px m-0 "
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        // onChange={handleChange}
                                        onChange={handleFieldChange}
                                        onBlur={handleBlur}
                                        type="text"
                                    />
                                    {errors.name && touched.name && (
                                        <div className="error mb-0">
                                            {errors.name}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup className="m-0 m-mb-3">
                                    <Label className="mb-1">End User View</Label>
                                    <Input
                                        className=" p-5px m-0 "
                                        id="endUserDisplayName"
                                        name="endUserDisplayName"

                                        value={values.endUserDisplayName}
                                        // onChange={handleChange}
                                        onChange={handleFieldChange}
                                        onBlur={handleBlur}
                                        type="text"
                                    />
                                    {errors.endUserDisplayName && touched.endUserDisplayName && (
                                        <div className="error mb-0">
                                            {errors.endUserDisplayName}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col>
                                <FormGroup className="m-0 m-mb-3">
                                    <Label className="mb-1">Assignee View</Label>
                                    <Input
                                        className=" p-5px m-0 "
                                        id="assigneeDisplayName"
                                        name="assigneeDisplayName"
                                        value={values.assigneeDisplayName}
                                        // onChange={handleChange}
                                        onChange={handleFieldChange}
                                        onBlur={handleBlur}
                                        type="text"
                                    />
                                    {errors.assigneeDisplayName &&
                                        touched.assigneeDisplayName && (
                                            <div className="error mb-0">
                                                {errors.assigneeDisplayName}
                                            </div>
                                        )}
                                </FormGroup>
                            </Col>
                        </Row> */}

                        <Row>
                            <Col>
                                <FormGroup className="m-0 m-mb-5">
                                    <Label className="mb-1">Description</Label>
                                    <FormControl
                                        as="textarea"
                                        rows={6}
                                        id="description"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="p-2"
                                    />
                                    {errors.description && touched.description && (
                                        <div className="error mb-0">{errors.description}</div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup className="m-0 m-mb-5">
                                    <Label className="mb-1">Category</Label>
                                    <Select
                                        className="form-selects flex-grow-1"
                                        id="category"
                                        name="category"
                                        options={options}
                                        styles={colorStyles}
                                        value={selectedCategory}
                                        onChange={handleChangeCategory}
                                    />
                                    {errors.category && touched.category && (
                                        <div className="error mb-0">{errors.category}</div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        {isLoading && (
                            <div className="loading-overlay">
                                <Spinner />
                            </div>
                        )}

                        <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
                            <Button type="submit" className="edit-button">
                                Add
                            </Button>{" "}
                            <Button onClick={toggle} className="edit-button cancel-delete ">
                                Cancel
                            </Button>
                        </FormGroup>
                    </form>
                </Row>
            </ModalBody>
        </Modal>
    )
}





