import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    Label,
    Legend,
    Tooltip,
} from "recharts"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import moment from 'moment'


export default function NatureRequest({ startDate,
    endDate, }) {

    const [isloading, setIsLoading] = useState(false)
    const [ticketRequestNature, setTicketRequestNature] = useState([])

    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))

    const dispatch = useDispatch()

    const fetchAllRequestNature = async () => {
        try {
            setIsLoading(true)
            const params = {
                startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
            };
            axios
                .get(`${Url}/${apiVersion}/ticket-dashboard/request-nature`, {
                    headers: { Authorization: `Bearer ${access}` },
                    params: params,
                })
                .then(response => {
                    const AllTickerRequestNature = response.data.data
                    console.log("All Ticket Request Nature", response.data.data)
                    const dataArray = Object.keys(AllTickerRequestNature)
                        .filter(key => key !== "total")
                        .map(key => ({
                            name: key,
                            value: AllTickerRequestNature[key],
                        }));
                    setTicketRequestNature(dataArray)
                    setIsLoading(false)
                })
                .catch(err => {
                    if (err.response?.status == 401 || err.response?.status == 0) {
                        dispatch(unAuthUser())
                        // UnAuthorized()
                    } else {
                        console.log(err)
                    }
                })
        } catch (error) {
            console.error("Unexpected error:", error)
        }
    }
    useEffect(() => {
        fetchAllRequestNature()
    }, [startDate, endDate]);


    const totalValue = ticketRequestNature.reduce((sum, entry) => sum + entry.value, 0)
    const COLORS = ["#6B7889", "#959DAC", "#C6CBD4", "#E0E3E7"];


    const CustomLegendFormatter = (value, entry) => {
        const nameColor = "#9291A5"
        const valueColor = "#6B7889"
        // const percentage = Math.round((entry.payload.value / totalValue) * 100)
        const percentage = totalValue === 0 ? 0 : Math.round((entry.payload.value / totalValue) * 100);
        return (
            < >
                <span style={{ display: 'inline-flex', alignItems: 'center', lineHeight: '1.5', marginBottom: '10px' }}>
                    <span style={{ color: nameColor, width: "60px", marginRight: "10px" }}>{value}</span>
                    <span style={{ color: valueColor, fontWeight: 'bold', width: "20px" }}>
                        {entry.payload.value}
                    </span>
                    <span style={{ color: valueColor, fontWeight: 'bold' }}>
                        {percentage}%
                    </span>
                </span>
            </>
        )
    }
    const CustomTooltip = ({ payload, label }) => {
        if (!payload || !payload.length) return null
        return (
            <div className='custom-tooltip' style={{ backgroundColor: "#5A6778", color: '#FFFFFF', padding: '7px', borderRadius: '4px' }}>
                <p className='value m-0'>{`${payload[0].value}`}</p>
            </div>
        );
    };
    const CustomLabel = ({ viewBox }) => {
        const { cx, cy } = viewBox;
        return (
            <g>

                <text
                    x={cx}
                    y={cy - 10}
                    textAnchor="middle"
                    fill="#9291A5"
                    fontSize="10"

                >
                    Total
                </text>

                <text
                    x={cx}
                    y={cy + 10}
                    textAnchor="middle"
                    fontSize="16"
                    fontWeight="bold"
                    fill="#6B7889"
                >
                    {totalValue}

                </text>
            </g>
        );
    };

    if (!authorized) {
        return <UnAuthorizedPopup />
    }
    console.log("totalValuesssss", ticketRequestNature)
    return (
        <>

            {isloading ? (
                <div className="loading d-flex justify-content-center align-items-center mb-2 p-3">
                    <div className="spinner-border d-flex justify-content-center align-items-center"></div>
                </div>
            ) : (ticketRequestNature && ticketRequestNature.length > 0 && ticketRequestNature.some(item => item.value > 0)) ? (
                <div className="d-flex justify-content-around align-items-center">
                    <ResponsiveContainer width="50%" height={170}>
                        <PieChart>
                            <Pie
                                // data={ticketRequestNature}
                                data={ticketRequestNature}
                                cx={70} cy="50%" dataKey="value" stroke="none" outerRadius="95%">
                                {ticketRequestNature.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}

                                    />
                                ))}
                            </Pie>
                            {/* <Legend
                            verticalAlign="middle"
                            align="right"
                            // width="40%"
                            layout="vertcal"
                            // wrapperStyle={{ gap: "20px" }}
                            iconType="circle"
                            formatter={CustomLegendFormatter}
                            iconSize={6}

                        /> */}
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </ResponsiveContainer>
                    <div >
                        {ticketRequestNature.map((ticket, idx) => (
                            <div key={idx} className="d-flex gap-2 align-items-center justify-content-between mt-1 ">
                                <div style={{
                                    backgroundColor: COLORS[idx % COLORS.length],
                                    width: "6px",
                                    height: "6px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                }}></div>
                                <p className="m-0 flex-grow-1 chart-zero-values-p">{ticket.name}</p>
                                <p style={{
                                    width: "25px",

                                }} className="m-0 chart-zero-values-span   ">{ticket.value}</p>
                                <span className="m-0 chart-zero-values-span " >
                                    {Math.round((ticket.value / totalValue) * 100)} %
                                </span>
                            </div>
                        ))}

                    </div>
                </div >
            ) : (ticketRequestNature && ticketRequestNature.length > 0) ? (
                <div className="d-flex justify-content-around align-items-center">
                    {console.log("object", ticketRequestNature)}
                    <ResponsiveContainer width="50%" height={170}>
                        <PieChart>
                            <Pie
                                data={[{ value: 1 }]}
                                cx={65}
                                cy="50%"
                                innerRadius={50}
                                outerRadius={70}
                                dataKey="value"
                                stroke="none"
                            >
                                <Cell fill="#e0e0e0" />
                                <Label content={<CustomLabel />} />
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div >
                        {ticketRequestNature.map((ticket, idx) => (
                            <div key={idx} className="d-flex gap-3 align-items-center justify-content-between">
                                <div style={{
                                    backgroundColor: "#e0e0e0",
                                    width: "6px",
                                    height: "6px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginLeft: "10px",
                                }}></div>
                                <p className="m-0 flex-grow-1 chart-zero-values-p">{ticket.name}</p>
                                <span className="m-0 chart-zero-values-span ">{ticket.value}</span>
                            </div>
                        ))}

                    </div>
                </div>

            ) : (
                <p className="d-flex justify-content-center align-items-center mb-2 fw-bold p-3 fs-5">No data available</p>
            )}
        </>
    )
}
