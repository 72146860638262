import React from "react"

import StepperComponent from "components/Broadcast/StepperComponent"
import EnterDataSingleTemplatePreviewComponent from "components/Broadcast/EnterDataSingleTemplatePreviewComponent"

function EnterDataTemplatePreviewComponent({
  step,
  setStep,
  data,
  setData,
  singleTemplateData,
  setSingleTemplateData,
}) {
  return (
    <div className="steps-page-layout my-auto px-3">
      <div className="stepper-wrapper">
        <StepperComponent step={step} />
      </div>

      <div>
        <EnterDataSingleTemplatePreviewComponent
          setStep={setStep}
          data={data}
          setData={setData}
          singleTemplateData={singleTemplateData}
          setSingleTemplateData={setSingleTemplateData}
        />
      </div>
    </div>
  )
}

export default EnterDataTemplatePreviewComponent
