import React from "react"

import { displayFileName } from "helpers/helper_functions"

import configs from "config"
const { Url } = configs.client

function TemplateSingleMessage({ message }) {
  return (
    <div
      style={{
        textAlign: message.template.language == "ar" ? "right" : "left",
      }}
      dir={message.template.language == "ar" ? "rtl" : "ltr"}
    >
      <h6 className="mb-2 text-center">
        {message.template.components.map(component => {
          if (component.type == "HEADER")
            return (
              component.format === "DOCUMENT" && (
                <div className="d-flex">
                  <tbody>
                    <tr>
                      <td className="td-icon-container">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-24">
                            <i className="bx bxs-file-doc" />
                          </span>
                        </div>
                      </td>
                      <td style={{ padding: "15px" }}>
                        <h5
                          className="font-size-14"
                          style={{ marginBottom: "0" }}
                        >
                          <span
                            className="text-dark"
                            title={component.document?.filename}
                          >
                            {displayFileName(component.document?.filename, 15)}
                          </span>
                        </h5>
                      </td>
                      <td>
                        <div className="text-center">
                          <a
                            href={`${Url}/${component.document?.link}`}
                            className="text-dark"
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bx bx-download h3 m-0" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </div>
              )
            )
        })}
      </h6>

      <h6>
        {message.template.components.map(component => {
          if (component.type == "HEADER")
            return (
              component.format === "IMAGE" && (
                <img
                  src={`${Url}/${component.image?.link}`}
                  style={{ maxWidth: "20rem" }}
                />
              )
            )
        })}
      </h6>

      <h6>
        {message.template.components.map(component => {
          if (component.type == "HEADER") return component.text
        })}
      </h6>

      <>
        {message.template.components.map(component => {
          if (component.type == "BODY")
            return component.text
              .split("\n")
              .map((str, idx) => <p key={idx}>{str}</p>)
        })}
      </>

      <p>
        {message.template.components.map(component => {
          if (component.type == "FOOTER") return component.text
        })}
      </p>

      {message.template.components.map(component => {
        if (component.type == "BUTTONS")
          return component.buttons.map((button, idx) => {
            return (
              <button className="btn btn-primary d-block mb-1" key={idx}>
                {button.text}
              </button>
            )
          })
      })}
    </div>
  )
}

export default TemplateSingleMessage
