import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeChatFilters } from "store/actions"

function ChatFilters() {
  const { chatsFilter } = useSelector(state => ({
    chatsFilter: state.Login.chatsFilter,
  }))

  const dispatch = useDispatch()

  return (
    <div className="btns-container d-flex row">
      <div
        className={`btn-all col ${chatsFilter === "all" && "active"}`}
        onClick={() => dispatch(changeChatFilters("all"))}
      >
        All
      </div>
      <div
        className={`btn-read col ${chatsFilter === "read" && "active"}`}
        onClick={() => dispatch(changeChatFilters("read"))}
      >
        Read
      </div>
      <div
        className={`btn-unread col ${chatsFilter === "unread" && "active"}`}
        onClick={() => dispatch(changeChatFilters("unread"))}
      >
        Unread
      </div>
    </div>
  )
}

export default ChatFilters
