import React, { Children } from "react"

export default function ActionOpation() {
  return (
    <button className="next-page-option-button position-relative">
      Next
      {/* <i className="fas fa-arrow-right button-arrow"></i> */}
    </button>
  )
}
