import React, { useState } from "react"
import axios from "axios"
import AwayIcon from "components/Common/Icons/SidebarIcons/AwayIcon"
import OfflineIcon from "components/Common/Icons/SidebarIcons/OfflineIcon"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import ServiceHoursIcon from "components/Common/Icons/SidebarIcons/ServiceHoursIcon"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { useSelector } from "react-redux"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

import configs from "../../../config"
const { Url, apiVersion } = configs.client

import { useDispatch } from "react-redux"
import { unAuthUser, updateUserData } from "store/actions"

function TeamMemberStatusController({ userId, setUserStatus }) {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    userData: state.Login.userData,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [subDropdownOpen, setSubDropdownOpen] = useState(false)

  const toggleSubDropdown = () => {
    setSubDropdownOpen(!subDropdownOpen)
  }

  const updateUserStatus = userStatus => {
    const updateUsersStatus = {
      status: userStatus,
    }

    axios
      .patch(`${Url}/${apiVersion}/users/${userId}`, updateUsersStatus, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setUserStatus(res.data.data.user.status)

        if (userData._id === res.data.data.user._id) {
          localStorage.setItem("authUser", JSON.stringify(res.data.data))
          dispatch(updateUserData(res.data.data.user))
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response.status == 401 || err.response.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Dropdown
      tag="span"
      isOpen={subDropdownOpen}
      toggle={toggleSubDropdown}
      direction="right"
    >
      <DropdownToggle tag="span">
        <i className="mdi mdi-dots-vertical"></i>
      </DropdownToggle>

      <DropdownMenu
        // data-popper-placement="left-end"
        className="sub-dropdown-menu"
        left
      >
        <DropdownItem
          className="d-flex align-items-center"
          onClick={() => {
            updateUserStatus("Service hours")
          }}
          // disabled={userprofileStatus === "Service hours"}
        >
          <ServiceHoursIcon />
          <h6 className="ms-2 mb-0">Service hours</h6>
        </DropdownItem>

        <DropdownItem
          className="d-flex align-items-center"
          onClick={() => {
            updateUserStatus("Online")
          }}
          // disabled={userprofileStatus === "Online"}
        >
          <OnlineIcon />
          <h6 className="ms-2 mb-0">Online</h6>
        </DropdownItem>

        <DropdownItem
          className="d-flex align-items-center"
          onClick={() => {
            updateUserStatus("Offline")
          }}
          // disabled={userprofileStatus === "Offline"}
        >
          <OfflineIcon />
          <h6 className="ms-2 mb-0">Offline</h6>
        </DropdownItem>

        <DropdownItem
          className="d-flex align-items-center"
          onClick={() => {
            updateUserStatus("Away")
          }}
          // disabled={userprofileStatus === "Away"}
        >
          <AwayIcon />
          <h6 className="ms-2 mb-0">Away</h6>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default TeamMemberStatusController
