import React from "react"

function Addicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.375 18.75C8.49637 18.75 7.78409 18.0377 7.78409 17.1591V1.59091C7.78409 0.712273 8.49637 0 9.375 0C10.2536 0 10.9659 0.712274 10.9659 1.59091V17.1591C10.9659 18.0377 10.2536 18.75 9.375 18.75ZM1.59091 10.9659C0.712275 10.9659 0 10.2536 0 9.375C0 8.49637 0.712274 7.78409 1.59091 7.78409H17.1591C18.0377 7.78409 18.75 8.49637 18.75 9.375C18.75 10.2536 18.0377 10.9659 17.1591 10.9659H1.59091Z"
        fill="white"
      />
    </svg>
  )
}

export default Addicon
