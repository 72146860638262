import React, { PureComponent, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import moment from 'moment'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

export default function AverageTickets() {
    const [isloading, setIsLoading] = useState(false)
    const [ticketAverageSolved, setTicketAverageSolved] = useState([])

    const { access, authorized } = useSelector(state => ({
        access: state.Login.userAccess,
        authorized: state.Login.authorized,
    }))
    const dispatch = useDispatch()

    const [totalTicketsSum, setTotalTicketsSum] = useState(0);
    const [solvedTicketsSum, setSolvedTicketsSum] = useState(0);


    const [errorMessage, setErrorMessage] = useState(false)

    const [startDateAverage, setStartDateAverage] = useState(null)
    const [endDateAverage, setEndDateAverage] = useState(null)

    //setDate
    // const handleDateAverageChange = dates => {
    //     const [start, end] = dates
    //     setStartDateAverage(start)
    //     setEndDateAverage(end)
    // }

    // maximum Date range
    const calculateMaxEndDate = startDate => {
        if (!startDate) return null;
        const today = moment().startOf('day');
        const maxEndDate = moment(startDate).add(6, 'days');
        return maxEndDate.isAfter(today) ? today.toDate() : maxEndDate.toDate();
    }



    // Handle date changes
    const handleDateAverageChange = dates => {
        const [start, end] = dates
        setStartDateAverage(start)
        if (end && start && end - start <= 6 * 24 * 60 * 60 * 1000) {
            setEndDateAverage(end)
        } else if (start) {

            const maxEndDate = calculateMaxEndDate(start)
            // const maxEndDate = maxEndDate.isAfter(today) ? maxEndDate.toDate() : calculateMaxEndDate(start)
            setEndDateAverage(maxEndDate)
        } else {
            // setStartDateAverage(null)
            setEndDateAverage(null)
            // fetchAverageSolved()
        }
    }

    const fetchAverageSolved = async () => {
        try {
            setIsLoading(true)
            const params = {
                startDate: startDateAverage ? moment(startDateAverage).format('YYYY-MM-DD') : null,
                endDate: endDateAverage ? moment(endDateAverage).format('YYYY-MM-DD') : null
            };
            console.log("Sending parameters:", params)
            axios
                .get(`${Url}/${apiVersion}/ticket-dashboard/average-solved`, {
                    headers: { Authorization: `Bearer ${access}` },
                    params: params,
                })
                .then(response => {
                    const AllTicketAverageSolved = response.data.data.groupedTickets

                    let totalTicketsSum = 0;
                    let solvedTicketsSum = 0;

                    Object.keys(AllTicketAverageSolved).forEach(key => {
                        totalTicketsSum += AllTicketAverageSolved[key].totalTickets;
                        solvedTicketsSum += AllTicketAverageSolved[key].solvedTickets;
                    });
                    setTotalTicketsSum(totalTicketsSum);
                    setSolvedTicketsSum(solvedTicketsSum);


                    console.log("All Ticket Request Nature", response.data.data)
                    const dataArray = Object.keys(AllTicketAverageSolved).map(key => ({
                        name: key,
                        "Total Tickets": AllTicketAverageSolved[key].totalTickets,
                        "Solved Tickets": AllTicketAverageSolved[key].solvedTickets,
                    }))
                    setTicketAverageSolved(dataArray)
                    setIsLoading(false)
                })
                .catch(err => {
                    if (err.response?.status == 401 || err.response?.status == 0) {
                        dispatch(unAuthUser())
                        // UnAuthorized()
                    }
                    if (err?.response?.status == 400) {
                        console.log("errrrrr.", err.message)
                        setErrorMessage(err?.response?.data.message)
                        setIsLoading(false)
                    } else {
                        console.log(err)
                    }
                })
        } catch (error) {
            console.error("Unexpected error:", error)
        }
    }


    useEffect(() => {
        fetchAverageSolved()
        console.log("start date", startDateAverage, "end", endDateAverage)
    }, [startDateAverage, endDateAverage])

    const CustomTooltip = ({ payload }) => {
        const nameColor = "#6B7889";
        const valueColor = "#E5E5EF";

        if (payload && payload.length) {
            const value1 = payload[0]?.value || 0;
            const value2 = payload[1]?.value || 0;

            return (
                <div
                    style={{
                        backgroundColor: "#5A6778",
                        color: "#fff",
                        padding: "5px 15px 5px 5px ",
                        borderRadius: "10px",
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <div
                            style={{
                                backgroundColor: nameColor,
                                width: "7px",
                                height: "7px",
                                borderRadius: "50%",
                                marginRight: "5px",
                            }}
                        ></div>
                        <p className="m-0" style={{ margin: 0 }}>{` ${value1}`}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{
                                backgroundColor: valueColor,
                                width: "7px",
                                height: "7px",
                                borderRadius: "50%",
                                marginRight: "5px",
                            }}
                        ></div>
                        <p className="m-0" style={{ margin: 0 }}>{` ${value2}`}</p>
                    </div>
                </div>
            );
        }


    };


    const CustomLegendFormatter = (value, entry) => {
        const nameColor = "#6B7889";
        const valueColor = "#E5E5EF";

        let displayValue = "";
        if (value === "Total Tickets") {
            displayValue = totalTicketsSum;
        } else if (value === "Solved Tickets") {
            displayValue = solvedTicketsSum;
        }

        return (
            <>
                <span
                    style={{
                        // display: "inline-flex",
                        alignItems: "center",
                        lineHeight: "1.5",
                        marginBottom: "8px",
                    }}
                >
                    <span style={{ color: nameColor }}>{value}: </span>
                    <p className='ticket-overview-item-details fs-3' style={{ fontWeight: "bold" }}>
                        {displayValue}
                    </p>
                </span>
            </>
        );
    };

    if (!authorized) {
        return <UnAuthorizedPopup />
    }

    return (
        <>
            <Row className="d-flex align-items-center heading-border">
                <Col>
                    <p className="charts-heading  p-0">Average Tickets Created</p>
                </Col>
                <Col className="d-flex justify-content-end flex-grow-0 mb-3">
                    <DatePicker
                        className="broadcast-filter-date-picker text-capital"
                        selectsRange={true}
                        dateFormat="yyyy/MM/dd"
                        startDate={startDateAverage}
                        endDate={endDateAverage}
                        onChange={handleDateAverageChange}
                        placeholderText="select Date"
                        isClearable
                        // minDate={null}
                        // minDate={startDateAverage}
                        minDate={startDateAverage ? moment(startDateAverage).toDate() : null}
                        // maxDate={calculateMaxEndDate(startDateAverage)}
                        // maxDate={new Date()}
                        maxDate={calculateMaxEndDate(startDateAverage)}
                       
                    />
                </Col>
            </Row>

            {isloading ? (
                <div className="loading d-flex justify-content-center align-items-center mb-2 p-3">
                    <div className="spinner-border d-flex justify-content-center align-items-center"></div>
                </div>
            ) : ticketAverageSolved && ticketAverageSolved.length > 0 ? (
                <ResponsiveContainer width="100%" height={180}>
                    <BarChart
                        data={ticketAverageSolved}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        barSize={35}
                    >
                        {/* <CartesianGrid strokeDasharray="2 3" /> */}
                        <CartesianGrid
                            strokeDasharray="2 2"
                            stroke="rgba(0, 0, 0, 0.09)"
                            vertical={false}
                            horizontal={true}
                        />
                        <XAxis dataKey="name" />
                        <YAxis />
                        {/* <Tooltip /> */}
                        <Tooltip cursor={{ fill: "none" }} content={<CustomTooltip />} />

                        <Legend
                            verticalAlign="middle"
                            align="left"
                            layout="vertical"


                            formatter={CustomLegendFormatter}
                            // wrapperStyle={{ marginBottom: '20px' ,paddingTop: '10px' }}
                            iconType="square"
                            iconSize={8}
                        />

                        <Bar dataKey="Solved Tickets" stackId="a" fill="#6B7889" />
                        <Bar dataKey="Total Tickets" stackId="a" fill="#E5E5EF" />
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <p className="d-flex justify-content-center align-items-center mb-2 fw-bold p-3 fs-5 ">
                    No data available{" "}
                </p>
            )}
        </>
    )
}
