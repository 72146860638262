import React from "react"

function ArchiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M2.08301 3.97925V12.6459C2.08408 13.0047 2.22709 13.3485 2.48082 13.6023C2.73454 13.856 3.07836 13.999 3.43717 14.0001H12.6455C13.0043 13.999 13.3481 13.856 13.6019 13.6023C13.8556 13.3485 13.9986 13.0047 13.9997 12.6459V3.97925"
        stroke="#7E8998"
        strokeWidth="0.880208"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1354 1H1.94792C1.4244 1 1 1.4244 1 1.94792V2.76042C1 3.28394 1.4244 3.70833 1.94792 3.70833H14.1354C14.6589 3.70833 15.0833 3.28394 15.0833 2.76042V1.94792C15.0833 1.4244 14.6589 1 14.1354 1Z"
        stroke="#7E8998"
        strokeWidth="0.880208"
        strokeLinejoin="round"
      />
      <path
        d="M10.2083 9.12508L8.04167 11.2917L5.875 9.12508M8.04167 10.5432V6.41675"
        stroke="#7E8998"
        strokeWidth="0.880208"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArchiveIcon
