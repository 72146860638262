import React from "react"

import configs from "config"
import { displayFileName } from "helpers/helper_functions"
const { Url } = configs.client

function DocumentSingleMessage({ docURL, message }) {
  return message.document.type === "file" ? (
    <tbody>
      <tr>
        <td className="td-icon-container">
          <div className="avatar-sm">
            <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-24">
              <i className="bx bxs-file-doc" />
            </span>
          </div>
        </td>
        <td style={{ padding: "15px" }}>
          <h5 className="font-size-14" style={{ marginBottom: "0" }}>
            <span className="text-dark" title={message.document.filename}>
              {displayFileName(message.document.filename, 15)}
            </span>
          </h5>
        </td>
        <td>
          <div className="text-center">
            <a
              href={`${Url}/${message.document.file}`}
              className="text-dark"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bx bx-download h3 m-0" />
            </a>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="3">
          <div style={{ marginTop: "5px" }} className="text-end">
            {message.document.caption ? message.document.caption : ""}
          </div>
        </td>
      </tr>
    </tbody>
  ) : (
    <div>
      <a
        target="_blank"
        href={message.document.link}
        className="text-primary"
        rel="noreferrer"
      >
        {message.document.link}
      </a>

      <p className="mt-2">File name: {message.document.filename}</p>
      <div className="text-end">
        {message.document.caption ? message.document.caption : ""}
      </div>
    </div>
  )
}

export default DocumentSingleMessage
