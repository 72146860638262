import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
export default function UploadProfilePhoto({
  userData,
  photoView,
  setPhotoView,
  setFieldValue,
}) {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))
  const handleFileChange = e => {
    let file = e.target.files[0]
    if (file) {
      setPhotoView(file)
    }
  }
  const RemoveProfilePhoto = () => {
    // setPhotoView(null)
    setPhotoView("")
    // setFieldValue("removePhoto",true)
    userData.photo = ""

  }

  // const RemoveProfilePhoto = () => {
  //   axios
  //     .patch(
  //       `${Url}/${apiVersion}/users/updateMe`,
  //       { removePhoto: true },
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",

  //           Authorization: `Bearer ${access}`,
  //         },
  //       }
  //     )
  //     .then(res => {
  //       if (res.status === 200) {
  //         userData.photo = ""
  //         setPhotoView("")
  //       } else {
  //         console.error("Unexpected status code:", res.status, res.data)
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Error:", error)
  //     })
  // }

  return (
    <div className="change-user-name  upload-profile-photo gap-2 mb-4">
      <h4 className="m-0 mb-4 d-flex justify-content-start ">
        Change User Data
      </h4>
      <div className="change-user-name d-flex flex-column justify-content-center align-items-center upload-profile-photo gap-2 mb-4">
        <div>
          {photoView ? (
            <div className="photo-container">
              <img
                src={URL.createObjectURL(photoView)}
                alt="Profile"
                className="profile-photo"
              />
              <div onClick={RemoveProfilePhoto} className="delete-icon ">
                <TableDeleteIcon />
              </div>
            </div>
          ) : userData?.photo && userData?.photo != "" ? (
            <div className="photo-container">
              <img
                className="profile-photo"
                // src={URL.createObjectURL(userData?.photo)}
                src={`${Url}/${userData?.photo}`}
                alt={`${userData?.firstName} ${userData?.lastName} img`}
              />
              <div onClick={RemoveProfilePhoto} className="delete-icon">
                <TableDeleteIcon />
              </div>
            </div>
          ) : (
            // <img
            //   className="profile-photo"
            //   // src={URL.createObjectURL(userData?.photo)}
            //   src={""}
            // />
            <div className="avatar-img d-flex justify-content-center align-items-center"></div>
          )}
        </div>{" "}
        <input
          className="input"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <button
          onClick={() => document.querySelector('input[type="file"]').click()}
        >
          Upload Photo
        </button>
        <h4 className="m-0">{`${userData?.firstName} ${userData?.lastName}`}</h4>
        <p className="m-0 mt-0">{userData?.email}</p>
      </div>
    </div>
  )
}
