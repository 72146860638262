import React, { useState } from "react"
import { useSelector } from "react-redux"

import InboxHeader from "./ChatList/InboxHeader"
import ChatFilters from "./ChatList/ChatFilters"
import SearchAddContainer from "./ChatList/SearchAddContainer"
import ChatListContainer from "./ChatList/ChatListContainer"
import ArchivedChatsFilters from "./ChatList/ArchivedChatsFilters"

function ChatList() {
  const { chatsType } = useSelector(state => ({
    chatsType: state.Login.chatsType,
  }))

  const [searchInChatsValue, setSearchInChatsValue] = useState("")

  return (
    <div className="inbox-section col-8 d-flex flex-column">
      <InboxHeader />

      <div className="inbox-chat-list d-flex flex-column">
        <div className="d-flex flex-column btn-search-container">
          {chatsType === "archivedChats" ? (
            <ArchivedChatsFilters />
          ) : (
            <ChatFilters />
          )}

          <SearchAddContainer setSearchInChatsValue={setSearchInChatsValue} />
        </div>

        <ChatListContainer searchInChatsValue={searchInChatsValue} />
      </div>
    </div>
  )
}

export default ChatList
