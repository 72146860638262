import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import ReactEcharts from "echarts-for-react"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"
import CustomSpinner from "components/Common/CustomSpinner"

const Doughnut = ({
  sessionLoading,
  dataColors,
  graphSelectedOption,
  setNoDataToShow,
}) => {
  const { userSessions, teamSessions } = useSelector(state => ({
    userSessions: state.Login.userSessions,
    teamSessions: state.Login.teamSessions,
  }))

  const doughnutEChartColors = getChartColorsArray(dataColors)

  const selectedSessions =
    graphSelectedOption === "me" ? userSessions : teamSessions
  const totalMessages = selectedSessions
    .split(",")
    .reduce((sum, value, index) => {
      return index > 0 ? sum + parseInt(value, 10) : sum
    }, 0)

  useEffect(() => {
    if (totalMessages == 0) {
      setNoDataToShow(true)
    } else {
      setNoDataToShow(false)
    }
  }, [totalMessages, graphSelectedOption])

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    legend: {
      show: false,
      orient: "vertical",
      x: "left",
      data: ["On Time", "Danger", "Too Late", "Open"],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: doughnutEChartColors,
    series: [
      {
        name: "Total Messages",
        type: "pie",
        radius: ["72%", "85%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: false,
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: selectedSessions.split(",")[1], name: "On Time" },
          { value: selectedSessions.split(",")[2], name: "Danger" },
          { value: selectedSessions.split(",")[3], name: "Too Late" },
          { value: selectedSessions.split(",")[4], name: "Open" },
        ],
      },
    ],
    graphic: {
      type: "group",
      left: "center",
      top: "center",
      children: [
        {
          type: "text",
          z: 100,
          left: "center",
          top: "center",
          style: {
            text: `${
              sessionLoading
                ? "⏳"
                : `{a|Inbox}\n{b|${totalMessages.toString()}}`
            }`,
            textAlign: "center",
            fill: "#000",
            fontSize: "1.75rem",
            rich: {
              a: {
                color: "#000",
                fontSize: "2rem",
                lineHeight: 30,
              },
              b: {
                color: "#000",
                fontSize: "2rem",
                lineHeight: 60,
              },
            },
          },
        },
      ],
    },
  }

  return (
    <React.Fragment>
      {sessionLoading ? (
        <CustomSpinner />
      ) : (
        <ReactEcharts style={{ height: "16.5625rem" }} option={options} />
      )}
    </React.Fragment>
  )
}

export default Doughnut
