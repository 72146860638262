import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import React, { useState } from "react"
import ViewConversations from "./ViewConversations"

export default function ConversationTable({
  conversations,
  isloading,
  toggleDelete,
  toggleEdit,
}) {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const toggleAccordion = index => {
    if (expandedIndex === index) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(index)
    }
  }

  return (
    <div className="table-container-services suggested-answer-table">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 table-header ">
        <h5 className="m-0 col-5">Name</h5>
        <h5 className="m-0 col-5">Description</h5>
        <h5 className="m-0 col-2 d-flex justify-content-end">Action</h5>
      </div>
      {isloading ? (
        <div className="   d-flex justify-content-center align-center p-5">
          <div className="spinner-border "></div>
        </div>
      ) : (
        <div>
          {conversations?.length > 0 ? (
            conversations?.map((conv, index) => (
              <>
                <div
                  onClick={() => toggleAccordion(index)}
                  key={conv}
                  className="d-flex justify-content-between align-items-center  m-0 p-0 table-data mt-3 "
                >
                  <h4 className="m-0 col-5">{conv.name}</h4>
                  <h4 className="m-0 col-5">{conv.description?conv.description : "ــــــ"}  </h4>
                  <div className="col-2 d-flex justify-content-end">
                    <button
                      onClick={e => {
                        e.stopPropagation()
                        toggleEdit(conv)
                      }}
                      // onClick={() => toggleEdit(answer)}
                      title="edit"
                      className="border-0 bg-transparent"
                    >
                      <TableEditIcon />
                    </button>
                    <button
                      onClick={e => {
                        e.stopPropagation()
                        toggleDelete(conv)
                      }}
                      // onClick={() => toggleDelete(conv)}
                      className="border-0 bg-transparent"
                      title="delete"
                    >
                      <TableDeleteIcon />
                    </button>
                  </div>
                </div>
                <div>
                  {expandedIndex === index && (
                    <div className="accordion-content m-0 ">
                      <ViewConversations conv={conv} />
                    </div>
                  )}
                </div>
              </>
            ))
          ) : (
            <div className="d-flex justify-content-between align-items-center  m-0 p-0 table-data ">
              No Conversations found
            </div>
          )}
        </div>
      )}
    </div>
  )
}
