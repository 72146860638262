import React from "react"

function PlusIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91619 17.2841V0H10.3551V17.2841H6.91619ZM0 10.3551V6.91619H17.2841V10.3551H0Z"
        fill="white"
      />
    </svg>
  )
}

export default PlusIcon
