import ArrowBackIcon from "components/Common/Icons/SettingIcons/ArrowBackIcon"
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import ViewTeamconversation from "./ViewTeamconversation"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import ViewTeamServiceHours from "../UsersComponents/ViewTeamServiceHours"
import ViewTeamAnswer from "./ViewTeamAnswer"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import UserTeamProfile from "pages/UserProfile/UserTeamProfile"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
export default function TeamsInfo({
  teams,
  isloading,
  setIsLoading,
  toggleViewTeamComponents,
  team,
}) {
  const defaultTeam = teams?.defaultTeam?._id
  const UserTeam = teams?.userTeam?._id
  const [viewDefaultTeamTeam, setViewDefaultTeamTeam] = useState([])
  const [viewUserTeam, setViewUserTeam] = useState([])

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const fetchUserTeamData = () => {
    setIsLoading(true)

    axios
      .get(`${Url}/${apiVersion}/teams/${UserTeam}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setIsLoading(false)
        // setViewUserTeam(res.data.data.team)
        toggleViewTeamComponents(res.data.data.team)

        toggle()
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response?.status === 401 || error.response?.status === 0) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
        } else {
          console.log(error)
        }
      })
  }
  const fetchDefaultTeamData = () => {
    setIsLoading(true)

    axios
      .get(`${Url}/${apiVersion}/teams/${defaultTeam}`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setIsLoading(false)
        // setViewDefaultTeamTeam(res.data.data.team)
        toggleViewTeamComponents(res.data.data.team)
        toggle()
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response?.status === 401 || error.response?.status === 0) {
          dispatch(unAuthUser())
        } else if (error.response?.status === 403) {
          console.log("error response", error)
        } else {
          console.log(error)
        }
      })
  }
  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <div className=" d-flex gap-4  justify-content-between users-setting-active">
      <div className=" user-status-item ">
        {isloading ? (
          <p className="m-0 mb-3 fw-bold">loading...</p>
        ) : (
          <h5 className="m-0 mb-3 fw-bold">{teams?.totalTeams}</h5>
        )}
        <p className="m-0">Total Teams</p>
      </div>
      <div
        onClick={() => {
          if (defaultTeam) {
            fetchDefaultTeamData()
          }
          toggleViewTeamComponents(viewDefaultTeamTeam)
        }}
        // onClick={() => {
        //   if (defaultTeam) {
        //     // fetchDefaultTeamData()
        //     toggleViewTeamComponents(viewDefaultTeamTeam)
        //   }
        // }}
        className=" user-status-item "
      >
        {isloading ? (
          <p className="m-0 mb-3 fw-bold">loading...</p>
        ) : (
          <h5 className="m-0 mb-3 fw-bold">{teams?.defaultTeam?.name}</h5>
        )}

        <p className="m-0">Default Team</p>
      </div>
      <div
        className=" user-status-item flex-end"
        // onClick={() => toggleViewTeamComponents(viewUserTeam)}
        onClick={() => {
          if (defaultTeam) {
            fetchUserTeamData()
          }
          toggleViewTeamComponents(viewUserTeam)
        }}
      >
        {isloading ? (
          <p className="m-0 mb-3 fw-bold">loading...</p>
        ) : (
          <h5 className="m-0 mb-3 fw-bold">{teams?.userTeam?.name}</h5>
        )}

        <p className="m-0">My Team</p>
      </div>
    </div>
  )
}
