import React from "react"
import ChatToolsDropdown from "./ConversationHeader/ChatToolsDropdown"

import DefaultPhoto from "./../../../assets/images/default.jpg"
import { useParams } from "react-router-dom"

import { ToastContainer, toast } from "react-toastify"

function ChatHeader({
  loadingMessages,
  clientName,
  isChatOwner,
  setIsChatOwner,
  isChatArchived,
  isUserShareTeamChat,
  setClientName,
  setSendingTemplateLoading,
}) {
  const params = useParams()

  const notify = () =>
    toast.success("The number has been copied to the clipboard.")

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(params.id)
      .then(() => {
        notify()
      })
      .catch(error => {
        console.error("Error copying to clipboard:", error)
      })
  }

  return (
    <div className="d-flex justify-content-between align-items-center conversation-header">
      <div className="d-flex align-items-center flex-grow-1 user-details">
        <img src={DefaultPhoto} />

        <h2
          className="mb-0 flex-grow-1"
          role="button"
          onClick={handleCopyToClipboard}
          title={params.id}
        >
          {loadingMessages ? (
            <div className="placeholder-glow">
              <span className="placeholder col-6 placeholder-lg rounded"></span>
            </div>
          ) : clientName ? (
            clientName.length > 25 ? (
              clientName.substring(0, 25) + "..."
            ) : (
              clientName
            )
          ) : (
            params.id
          )}
        </h2>
      </div>

      <ChatToolsDropdown
        isChatOwner={isChatOwner}
        setIsChatOwner={setIsChatOwner}
        isChatArchived={isChatArchived}
        isUserShareTeamChat={isUserShareTeamChat}
        setClientName={setClientName}
        setSendingTemplateLoading={setSendingTemplateLoading}
      />

      <ToastContainer />
    </div>
  )
}

export default ChatHeader
