import React from "react"

function OpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.05836 0.0588379C4.12209 0.0588379 0.117188 4.06374 0.117188 9.00001C0.117188 13.9363 4.12209 17.9412 9.05836 17.9412C13.9946 17.9412 17.9995 13.9363 17.9995 9.00001C17.9995 4.06374 13.9946 0.0588379 9.05836 0.0588379Z"
        fill="#3879F0"
      />
      <path d="M9.05859 3.03931V9.74519H13.5292" fill="#3879F0" />
      <path
        d="M9.05859 3.03931V9.74519H13.5292"
        stroke="#F5F6F7"
        strokeWidth="1.02273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OpenIcon
