import React from "react"
import { DropdownButton } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import { Col, Container, Row } from "reactstrap"

const fileIcon = (
  <svg
    width="23"
    height="29"
    viewBox="0 0 23 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4828 12.2446V24.8846C21.4828 25.7109 21.1591 26.5033 20.5829 27.0875C20.0067 27.6718 19.2252 28 18.4103 28H4.07241C3.25756 28 2.47608 27.6718 1.89989 27.0875C1.3237 26.5033 1 25.7109 1 24.8846V4.11538C1 3.28913 1.3237 2.49672 1.89989 1.91248C2.47608 1.32823 3.25756 1 4.07241 1H10.3933C10.9363 1.00008 11.4571 1.21883 11.8411 1.60815L20.883 10.7765C21.2669 11.1659 21.4827 11.6939 21.4828 12.2446Z"
      stroke="#A59EB2"
      strokeWidth="1.86207"
      strokeLinejoin="round"
    />
    <path
      d="M11.2414 1.93115V9.28142C11.2414 9.80127 11.4479 10.2998 11.8155 10.6674C12.1831 11.035 12.6816 11.2415 13.2015 11.2415H20.5517"
      stroke="#A59EB2"
      strokeWidth="1.86207"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const deleteIcon = (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.47018 2.16667V1H8.66316V2.16667M11.8561 4.79167V13.8333C11.8561 14.1428 11.7216 14.4395 11.4821 14.6583C11.2425 14.8771 10.9177 15 10.5789 15H3.55439C3.21565 15 2.89079 14.8771 2.65127 14.6583C2.41175 14.4395 2.27719 14.1428 2.27719 13.8333V4.79167M8.66316 5.73958V13.5417M5.47018 5.73958V13.5417M1 2.16667H13.1333V3.33333H1V2.16667Z"
      stroke="#9EA7B2"
      strokeWidth="0.77"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const imageIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.232 32C2.31067 32 1.542 31.692 0.926 31.076C0.31 30.46 0.00133333 29.6907 0 28.768V3.232C0 2.31067 0.308666 1.542 0.926 0.926C1.54333 0.31 2.312 0.00133333 3.232 0H28.77C29.69 0 30.4587 0.308666 31.076 0.926C31.6933 1.54333 32.0013 2.312 32 3.232V28.77C32 29.69 31.692 30.4587 31.076 31.076C30.46 31.6933 29.6907 32.0013 28.768 32H3.232ZM3.232 30H28.77C29.0767 30 29.3587 29.872 29.616 29.616C29.8733 29.36 30.0013 29.0773 30 28.768V3.232C30 2.924 29.872 2.64133 29.616 2.384C29.36 2.12667 29.0773 1.99867 28.768 2H3.232C2.924 2 2.64133 2.128 2.384 2.384C2.12667 2.64 1.99867 2.92267 2 3.232V28.77C2 29.0767 2.128 29.3587 2.384 29.616C2.64 29.8733 2.922 30.0013 3.23 30M7 25H25.308L19.654 17.46L14.424 24.076L10.924 19.846L7 25Z"
      fill="#A59EB2"
    />
  </svg>
)

export default function TemplateFormatFiles({
  selectedTemplateData,
  isSubmitted,
  selectFileOpation,
  selectFileColumn,
  uploadProgress,
  FileSizeMB,
  chooseFile,
  isUploaderOpen,
  filesTemplate,
  fileUploadRequired,
  handleSelectFileOption,
  visableChoeFile,
  columns,
  selectedOptions,
  selectedColumnHeader,
  selectNumber,
  removeFile,
  getRootProps,
  getInputProps,
  handleSelectedFileColumn,
  hideChooseFile,
  hideselectedFilesColumn,
}) {
  return (
    <Row>
      {selectedTemplateData?.components.map((component, idx) => {
        if (component.format === "DOCUMENT" || component.format === "IMAGE")
          return (
            <Container
              style={{
                direction: "ltr",
              }}
              key={idx}
              className="py-1 d"
            >
              <Container className="input-group input-group-sm my-1 d-flex justify-content-around gap-1 ">
                <Col
                  style={{
                    color: "#2a3042",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    background: "white",
                  }}
                  className=" d-flex justify-content-center align-items-center col-2"
                  id="inputGroup-sizing-sm"
                >
                  {component.format === "DOCUMENT" ? "doc" : "img"}
                </Col>

                <Col>
                  <DropdownButton
                    className="dropdown-toggle-temp-preview-var  border rounded-3 "
                    style={{
                      background: "white",
                    }}
                    title={
                      <>
                        <Container
                          style={{
                            backgroundColor: "white",
                            width: "40vw",
                            color: "#2a3042",
                          }}
                          className=" d-flex justify-content-between align-items-center p-1"
                        >
                          <p
                            style={{
                              color:
                                selectFileOpation || selectFileColumn
                                  ? "black"
                                  : "#ACB4BF",
                            }}
                            className="mb-0 "
                          >
                            {selectFileOpation && (
                              <>
                                <div className="d-flex align-items-center gap-3">
                                  <p className="m-0">Upload a File</p>{" "}
                                  <span
                                    style={{
                                      color: "#2aaa4a",
                                      fontSize: "12px",
                                      // marginLeft: "25px",
                                    }}
                                  >
                                    {component.format === "DOCUMENT"
                                      ? "(Accepts Files Only)"
                                      : "(Accepts Photos Only)"}
                                  </span>
                                </div>
                              </>
                            )}
                            {selectFileColumn && selectFileColumn}
                            {!selectFileColumn &&
                              !selectFileOpation &&
                              "select aFile Opation "}
                          </p>

                          <i
                            style={{
                              color: "rgb(172, 180, 191)",
                            }}
                            className="fas fa-chevron-down "
                          ></i>
                        </Container>
                      </>
                    }
                  >
                    <Dropdown.Item
                      style={{
                        width: "250px",
                      }}
                      className="DropdownItem d-flex gap-4 align-items-center justify-content-between "
                      onClick={() => {
                        handleSelectFileOption("upload")
                        visableChoeFile()
                      }}
                    >
                      Upload a File
                    </Dropdown.Item>

                    <DropdownButton
                      drop="end"
                      className="dropdown-toggle-temp-preview-var"
                      title={
                        <>
                          <Container
                            style={{
                              width: "250px",
                              paddingLeft: "25px",
                            }}
                            className="   d-flex justify-content-between   align-items-center"
                          >
                            <Row md={8}>
                              {selectFileColumn ||
                                "Choose the respective column"}
                            </Row>
                            <i className="fas fa-angle-right"></i>
                          </Container>
                        </>
                      }
                    >
                      {columns.map((column, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => {
                            handleSelectedFileColumn(column)
                            hideChooseFile()
                          }}
                          disabled={
                            selectedOptions.includes(column) ||
                            selectedColumnHeader.includes(column) ||
                            selectNumber.includes(column)
                          }
                        >
                          {column}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </DropdownButton>
                </Col>
                {/* filee choseeee */}
                <div
                  ref={chooseFile}
                  className=" input-group  input-group-sm my-1 d-flex gap-2  justify-content-arround  d-none"
                >
                  <Col
                    md={2}
                    className=" d-flex justify-content-center align-items-center "
                  ></Col>
                  <Col
                    md={9}
                    style={{
                      cursor: "pointer",
                      width: "82%",
                    }}
                    {...getRootProps()}
                    className={`input-style col-9 bg-white  d-flex flex-column justify-content-center align-items-center ${
                      fileUploadRequired ? "error-input-style" : ""
                    }`}
                  >
                    <input
                      style={{
                        width: "40vw",
                      }}
                      className=""
                      {...getInputProps()}
                    />
                    <p className="m-0 mb-3 mt-3">
                      Drag & drop filesTemplate or{" "}
                      <span
                        style={{
                          color: "#2aaa4a",
                          textDecoration: "underline",
                        }}
                      >
                        Browse
                      </span>
                    </p>
                  </Col>
                </div>

                {/* file upload  */}
                {filesTemplate && isUploaderOpen && (
                  <>
                    {filesTemplate.map(file => (
                      <>
                        <Container
                          key={file.name}
                          className=" input-group input-group-sm my-1 d-flex  justify-content-arround align-items-center "
                        >
                          <Col
                            md={2}
                            className=" d-flex justify-content-center align-items-center "
                          ></Col>

                          <Col
                            md={10}
                            className="file-upload-loader col-9 d-flex gap-2 justify-content-between align-items-center"
                          >
                            {/* {console.log(file.type === TypeFile)} */}
                            {component.format === "DOCUMENT" && (
                              <div>{fileIcon}</div>
                            )}
                            {component.format === "IMAGE" && (
                              <div>{imageIcon}</div>
                            )}

                            <div className="col-11">
                              <div className="d-flex justify-content-between align-content-center">
                                <p className="mb-1">{file.name}</p>
                                <p className="mb-1">
                                  (
                                  {(
                                    (uploadProgress * FileSizeMB) /
                                    100
                                  ).toFixed(2)}{" "}
                                  / {FileSizeMB} MB)
                                </p>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "4px",
                                  backgroundColor: "#d9d9d9",
                                }}
                                className="file-load-preview position-relative rounded-1"
                              >
                                <div
                                  className="file-load-preview-present position-absolute top-0 start-0 rounded-1 "
                                  style={{
                                    width: `${uploadProgress}%`,
                                    height: "100%",
                                    backgroundColor: "#a59eb2",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => removeFile(file.name)}
                            >
                              {deleteIcon}
                            </div>
                          </Col>
                        </Container>
                      </>
                    ))}
                  </>
                )}
              </Container>

              {component.format === "DOCUMENT" ||
                (component.format === "IMAGE" &&
                  !(selectFileColumn || selectFileOpation) &&
                  isSubmitted && (
                    <>
                      <p
                        style={{
                          direction: "rtl",
                          textAlign: "right",
                        }}
                        className="text-danger mb-0"
                      >
                        required
                      </p>
                    </>
                  ))}

              {component.format === "DOCUMENT" &&
                !(selectFileColumn || selectFileOpation) &&
                isSubmitted && (
                  <>
                    <Row>
                      <p
                        style={{
                          direction: "rtl",
                          textAlign: "right",
                        }}
                        className="text-danger mb-0"
                      >
                        required
                      </p>
                    </Row>
                  </>
                )}
            </Container>
          )
      })}
    </Row>
  )
}
