import React from "react"

export default function RestoreIcone() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 400 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="action-svg-restore"
    >
      <path
        d="M0 113.64L113.8 227.34L141.96 199.2L76.3 133.6H278.92C301.86 133.6 322.72 142.94 337.78 158.04C345.543 165.764 351.703 174.945 355.908 185.056C360.112 195.167 362.278 206.009 362.28 216.96C362.217 239.049 353.414 260.215 337.794 275.834C322.175 291.454 301.009 300.257 278.92 300.32H166.36V340H276.8C344.54 340 400 284.6 400 216.86C399.998 200.709 396.799 184.718 390.585 169.81C384.372 154.902 375.269 141.372 363.8 130C352.386 118.556 338.826 109.476 323.897 103.281C308.968 97.0867 292.963 93.8987 276.8 93.9H76.3L141.96 28.2L113.8 0L0 113.64Z"
        fill="#8D9EB5"
      />
    </svg>
  )
}
