export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const UPDATE_USER_DATA = "UPDATE_USER_DATA"
export const RESET_DATA = "RESET_DATA"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const UPDATE_CHATS_TYPE = "UPDATE_CHATS_TYPE"
export const UPDATE_CHATS_STATUS = "UPDATE_CHATS_STATUS"
export const CHANGE_SELECTED_OVERVIEW = "CHANGE_SELECTED_OVERVIEW"
export const UPDATE_SELECTED_TEAMS = "UPDATE_SELECTED_TEAMS"
export const USER_SESSION_NUMBERS = "USER_SESSION_NUMBERS"
export const TEAM_SESSION_NUMBERS = "TEAM_SESSION_NUMBERS"
export const CHANGE_CHAT_FILTER = "CHANGE_CHAT_FILTER"

export const UPDATE_ARCHIVED_USER_ID = "UPDATE_ARCHIVED_USER_ID"
export const UPDATE_ARCHIVED_START_DATE = "UPDATE_ARCHIVED_START_DATE"
export const UPDATE_ARCHIVED_END_DATE = "UPDATE_ARCHIVED_END_DATE"
export const RESET_ARCHIVED_USER_ID = "RESET_ARCHIVED_USER_ID"
export const RESET_ARCHIVED_DATE = "RESET_ARCHIVED_DATE"

export const SET_SELECTED_TEAM_MEMBER = "SET_SELECTED_TEAM_MEMBER"

export const ADD_OPEN_TABS = "ADD_OPEN_TABS"
export const DELETE_CLOSED_TABS = "DELETE_CLOSED_TABS"
export const UPDATE_OPEN_TABS = "UPDATE_OPEN_TABS"

export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST"
export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN"

export const UN_AUTH_USER = "UN_AUTH_USER"
