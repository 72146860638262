import React from "react"
import { Table } from "reactstrap"

const MissingDataTable = ({ data }) => {
  return (
    <div style={{ maxHeight: "24.375rem", overflowY: "scroll" }}>
      {data && data.length > 0 && (
        <div className="table-responsive">
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th>Report Name</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, key) => (
                <tr key={key}>
                  <td>{item.OrgReportName || item.ReportName}</td>
                  <td>{item.CreatedAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default MissingDataTable
