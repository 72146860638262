import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import TableView from "components/Common/Icons/SettingIcons/TableView"
import React, { useState } from "react"
import ViewServiceHours from "./ViewServiceHours"
import ViewServices from "./ViewServices"

export default function ServiceHoursTable({
  isloading,
  serviceHours,
  toggleDelete,
  toggleView,
  toggleEdit,
}) {
  const [expandedRow, setExpandedRow] = useState(null)

  const handleRowClick = serviceId => {
    if (expandedRow === serviceId) {
      setExpandedRow(null)
    } else {
      setExpandedRow(serviceId)
    }
  }

  return (
    <div>
      <div className="table-container table-container-services m-0 p-0">
        {isloading ? (
          <div className="  loading">
            <div className="spinner-border "></div>
          </div>
        ) : (
          <table>
            <thead className="text-center">
              <tr>
                <th>Name </th>
                <th> response Time </th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody>
              {serviceHours && serviceHours.length > 0 ? (
                serviceHours.map(service => (
                  <>
                    <tr
                      className="tr"
                      key={service._id}
                      onClick={() => {
                        handleRowClick(service._id)
                      }}
                    >
                      <td className="text-center">{service.name}</td>
                      <td className="text-center">
                        {service.responseTime.hours}h :{" "}
                        {service.responseTime.minutes}m
                      </td>
                      <td className="text-center">
                        <button
                          className="border-0 bg-transparent"
                          title="Edit"
                          onClick={e => {
                            e.stopPropagation()
                            toggleEdit(service)
                          }}
                        >
                          <TableEditIcon />
                        </button>
                        <button
                          className="border-0 bg-transparent"
                          title="Delete"
                          onClick={e => {
                            e.stopPropagation()
                            toggleDelete(service)
                          }}
                        >
                          <TableDeleteIcon />
                        </button>
                        {/* <button
                          className="border-0 bg-transparent"
                          title="View"
                          onClick={() => {
                            toggleView(service)
                            // console.log("View clicked for", service)
                          }}
                        >
                          <TableView />
                        </button> */}
                      </td>
                    </tr>

                    {expandedRow === service._id && (
                      <tr>
                        <td colSpan="3" className="text-center">
                          {/* <ViewServiceHours service={service} /> */}
                          <ViewServices service={service} />
                        </td>
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center p-2">
                    No Service Hours found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
