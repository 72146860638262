import React from "react"

function TooLateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.99977 0.0588379C4.0635 0.0588379 0.0585938 4.06374 0.0585938 9.00001C0.0585938 13.9363 4.0635 17.9412 8.99977 17.9412C13.936 17.9412 17.9409 13.9363 17.9409 9.00001C17.9409 4.06374 13.936 0.0588379 8.99977 0.0588379Z"
        fill="#DF4128"
      />
      <path
        d="M9 3.03931V9.74519H13.4706"
        stroke="#F5F6F7"
        strokeWidth="1.02273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TooLateIcon
