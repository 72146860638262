import React from "react"
import SingleNoteToolsIcon from "components/Common/Icons/Chat/Notes/SingleNoteToolsIcon"
import { convertData, tagOptions } from "helpers/helper_functions"
import { Badge } from "reactstrap"

function SingleNote({ note }) {
  return (
    <div className="single-note">
      <div className="note-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">{note.title ? note.title : ""}</h5>
          <span className="note-tools">
            <SingleNoteToolsIcon />
          </span>
        </div>

        <span className="note-time">
          {`Created at ${convertData(note.time)} by ${note.creator.firstName} ${
            note.creator.lastName
          }`}
        </span>
      </div>

      <div className="note-body">{note.body}</div>

      <div className="d-flex note-tags">
        {note.tag
          ? note.tag.map((tag, idx) => {
              const tagOption = tagOptions.find(option => option.value === tag)
              const color = tagOption ? tagOption.color : "secondary"

              return (
                <Badge key={idx} color={color} className="font-size-12">
                  {tag}
                </Badge>
              )
            })
          : ""}
      </div>
    </div>
  )
}

export default SingleNote
