import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import configs from "config"
const { Url } = configs.client

import "./ContactSingleMessage.css"
import { Link } from "react-router-dom"

import DefaultPhoto from "../../../../assets/images/default.jpg"

function ContactSingleMessage({ message }) {
  const { contacts } = message
  const [contactInfoModal, setContactInfoModal] = useState(false)
  const toggleContactInfoModal = () => setContactInfoModal(!contactInfoModal)

  return (
    <div className="message-contact py-2">
      <div className="row contact-info" onClick={toggleContactInfoModal}>
        <div className="col-auto">
          <img
            src={DefaultPhoto}
            alt="Contact"
            className="rounded-circle avatar-xs"
          />
        </div>
        <div className="col d-flex">
          <h6 className="font-size-14 mb-0 align-self-center">
            {contacts.length === 1
              ? contacts[0]?.name
              : `${contacts[0]?.name} and ${
                  contacts.length - 1
                } other contacts`}
          </h6>
        </div>
      </div>

      <Modal isOpen={contactInfoModal} toggle={toggleContactInfoModal}>
        <ModalHeader toggle={toggleContactInfoModal}>View contact</ModalHeader>
        <ModalBody className="pb-0">
          {contacts.map(contact => (
            <>
              <div className="row border-bottom py-2">
                <div className="col-auto">
                  <img
                    src={DefaultPhoto}
                    alt="Contact"
                    className="rounded-circle avatar-xs"
                  />
                </div>
                <div className="col d-flex">
                  <h6 className="font-size-14 mb-0 align-self-center">
                    {contact.name}
                  </h6>
                </div>
              </div>

              <div>
                {Array.isArray(contact?.phones) &&
                  contact.phones.length > 0 &&
                  contact.phones.map((phone, index) => (
                    <div key={index} className="py-2 d-flex">
                      <div className="flex-grow-1">
                        <h6 className="mb-1">{phone.phone}</h6>
                        <p className="mb-0">{phone.type}</p>
                      </div>
                      {phone.wa_id && (
                        <div
                          className="d-flex align-content-center"
                          // onClick={() => {
                          //   history.push(`/chat-room/${phone.wa_id}`)
                          // }}
                        >
                          <Link to={`/chat/${phone.wa_id}`}>
                            <i
                              className="mdi mdi-android-messages"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </Link>
                        </div>
                      )}
                      {contact.phone?.wa_id && (
                        <div className="row pt-2">
                          <div className="col d-flex justify-content-center">
                            {/* <p>{contact.phone.wa_id}</p> */}
                            <p
                              className="align-self-center mb-0 text-primary"
                              style={{ cursor: "pointer" }}
                            >
                              Message
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                {Array.isArray(contact?.emails) &&
                  contact.emails.length > 0 &&
                  contact.emails.map((email, index) => (
                    <div key={index} className="py-2 d-flex">
                      <div className="flex-grow-1">
                        <h6 className="mb-1">{email.email}</h6>
                        <p className="mb-0">{email.type}</p>
                      </div>
                      {email.wa_id && (
                        <div className="d-flex align-content-center">
                          <i
                            className="mdi mdi-android-messages"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleContactInfoModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ContactSingleMessage
