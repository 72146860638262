import React from "react"
import ChatListContactMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Contact.MessageContent"
import ChatListDocumentMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Document.MessageContent"
import ChatListImageMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Image.MessageContent"
import ChatListInteractiveMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Interactive.MessageContent"
import ChatListLocationMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Location.MessageContent"
import ChatListStickerMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Sticker.MessageContent"
import ChatListTextMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Text.MessageContent"
import ChatListVideoMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Video.MessageContent"
import ChatListAudioMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Audio.MessageContent"
import ChatListTemplateMessageContent from "pages/Home/ChatList/ChatListMessageConttent/ChatList.Template.MessageContent"
import ImageSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/ImageSingleMessage"
import AudioSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/AudioSingleMessage"
import VideoSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/VideoSingleMessage"
import DocumentSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/DocumentSingleMessage"
import TemplateSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/TemplateSingleMessage"
import StickerSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/StickerSingleMessage"
import ContactSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/ContactSingleMessage"
import InteractiveSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/InteractiveSingleMessage"
import LocationSingleMessage from "pages/Chat/Conversation/SingleMessageTypes/LocationSingleMessage"
import moment from "moment"

export function convertToValidDate(timestamp) {
  let parts = timestamp.match(
    /(\d{2}):(\d{2}):(\d{2}), (\w{3}) (\w{3}) (\d{2}) (\d{4})/
  )

  if (parts) {
    const [, hours, minutes, seconds, _, month, day, year] = parts

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    const monthIndex = months.indexOf(month)

    if (monthIndex === -1) {
      throw new Error("Invalid month abbreviation")
    }

    return new Date(
      parseInt(year, 10),
      monthIndex,
      parseInt(day, 10),
      parseInt(hours, 10),
      parseInt(minutes, 10),
      parseInt(seconds, 10)
    )
  }

  parts = timestamp.match(
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z/
  )

  if (parts) {
    const [, year, month, day, hours, minutes, seconds] = parts

    return new Date(
      Date.UTC(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10),
        parseInt(hours, 10),
        parseInt(minutes, 10),
        parseInt(seconds, 10)
      )
    )
  }

  throw new Error("Invalid timestamp format")
}

export function formatTimestamp(timestamp) {
  if (typeof timestamp !== "string") return ""

  const validDate = convertToValidDate(timestamp)

  const now = new Date()
  const messageTime = new Date(validDate)

  // Check if the message was sent yesterday before midnight
  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)
  yesterday.setHours(0, 0, 0, 0)

  if (messageTime < yesterday) {
    const formattedDate = messageTime.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    })
    const formattedTime = messageTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
    return `${formattedDate}, ${formattedTime}`
  }

  // If the message was sent within the last 24 hours and on the same day, display the time only
  if (messageTime.getDate() === now.getDate()) {
    return messageTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
  }

  // If the message was sent within the last 48 hours but on a different day, display "Yesterday" and the time
  if (messageTime.getDate() === yesterday.getDate()) {
    return (
      "Yesterday, " +
      messageTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    )
  }

  // If the message is not from yesterday or today, display the date without the day name and the time in hours:mins
  const formattedDate = messageTime.toLocaleDateString(undefined, {
    month: "short",
    day: "numeric",
  })
  const formattedTime = messageTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })
  return `${formattedDate}, ${formattedTime}`
}

export function generateUserLocalDateFromTimestamp(timestamp) {
  const validDate = convertToValidDate(timestamp)

  const year = validDate.getFullYear()
  const month = String(validDate.getMonth() + 1).padStart(2, "0")
  const day = String(validDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

export function formatTimestampWithYear(timestamp) {
  if (typeof timestamp !== "string") return ""

  const validDate = convertToValidDate(timestamp)

  const now = new Date()
  const messageTime = new Date(validDate)

  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)
  yesterday.setHours(0, 0, 0, 0)

  if (messageTime < yesterday) {
    const formattedDate = messageTime.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    const formattedTime = messageTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })

    return `${formattedDate}, ${formattedTime}`
  }

  if (messageTime.getDate() === now.getDate()) {
    return messageTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
  }

  if (messageTime.getDate() === yesterday.getDate()) {
    return (
      "Yesterday, " +
      messageTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    )
  }

  const formattedDate = messageTime.toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
  const formattedTime = messageTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })

  return `${formattedDate}, ${formattedTime}`
}

export function compareDates(dateStr1, dateStr2) {
  const date1 = convertToValidDate(dateStr1)
  const date2 = convertToValidDate(dateStr2)

  if (date1 < date2) {
    return 2
  } else if (date1 > date2) {
    return 1
  } else {
    return 0
  }
}

export const returnRightEmoji = chat => {
  const dateStr1 =
    chat.clientReaction !== undefined
      ? chat.clientReaction.time
      : "00:00:00, Sun Aug 00 0000"
  const dateStr2 =
    chat.userReaction !== undefined
      ? chat.userReaction.time
      : "00:00:00, Sun Aug 00 0000"

  if (
    dateStr1 === "00:00:00, Sun Aug 00 0000" &&
    dateStr2 === "00:00:00, Sun Aug 00 0000"
  )
    return undefined

  const result = compareDates(dateStr1, dateStr2)

  switch (result) {
    case 0:
      return `You reacted ${chat.clientReaction.emoji} to: "${chat.text}"`
    case 1:
      return `Reacted ${chat.clientReaction.emoji} to: "${chat.text}"`
    case 2:
      return `You reacted ${chat.userReaction.emoji} to: "${chat.text}"`
    default:
      return "Unexpected result."
  }
}

export function getMessageContent(chat) {
  switch (chat.lastMessage?.type) {
    case "text":
      return (
        <ChatListTextMessageContent
          idx={chat._id}
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          messageContent={
            chat.lastMessage.text.length > 20
              ? `${chat.lastMessage.text.substring(0, 60)}...`
              : chat.lastMessage.text
          }
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    case "audio":
      return (
        <ChatListAudioMessageContent
          sentMessage={chat.lastMessage.sent}
          fromWho={chat.lastMessage.status}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
        />
      )

    case "image":
      return (
        <ChatListImageMessageContent
          messageContent={chat.lastMessage.image.caption}
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    case "document":
      return (
        <ChatListDocumentMessageContent
          messageContent={chat.lastMessage.document.filename}
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    case "video":
      return (
        <ChatListVideoMessageContent
          messageContent={chat.lastMessage.video.caption}
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    case "template":
      return <ChatListTemplateMessageContent chat={chat} />

    case "contacts":
      return (
        <ChatListContactMessageContent
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
          chat={chat}
        />
      )

    case "interactive":
      return (
        <ChatListInteractiveMessageContent
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    case "sticker":
      return (
        <ChatListStickerMessageContent
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    case "location":
      return (
        <ChatListLocationMessageContent
          sentMessage={chat.lastMessage.sent}
          deliveredMessage={chat.lastMessage.delivered}
          seenMessage={chat.lastMessage.seen}
          fromWho={chat.lastMessage.status}
          emojiReaction={() => returnRightEmoji(chat.lastMessage)}
        />
      )

    default:
      return <>Message</>
  }
}

export const processDataType = (
  access,
  imgFile,
  videoFile,
  docFile,
  voiceFile,
  selectedReplyMessage,
  curMessage
) => {
  if (imgFile && imgFile.length > 0) {
    const formData = new FormData()

    formData.append("type", "image")
    imgFile.forEach((file, index) => {
      formData.append(`files`, file)
    })
    formData.append("caption", curMessage)

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "multipart/form-data",
      },
    }
    requestOptions.data = formData

    return requestOptions
  }

  if (videoFile && videoFile.length > 0) {
    const formData = new FormData()
    formData.append("type", "video")
    formData.append("files", videoFile[0])
    formData.append("caption", curMessage)

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "multipart/form-data",
      },
    }
    requestOptions.data = formData

    return requestOptions
  }

  if (docFile && docFile.length > 0) {
    const formData = new FormData()
    formData.append("type", "document")
    docFile.forEach((file, index) => {
      formData.append(`files`, file)
    })
    formData.append("caption", curMessage)

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "multipart/form-data",
      },
    }
    requestOptions.data = formData

    return requestOptions
  }

  if (voiceFile) {
    const formData = new FormData()
    formData.append("type", "audio")
    formData.append("files", voiceFile[0])

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "multipart/form-data",
      },
    }
    requestOptions.data = formData

    return requestOptions
  }

  const requestOptions = {
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: { type: "text", text: curMessage },
  }

  if (selectedReplyMessage) {
    requestOptions.data.replyMessage = selectedReplyMessage._id
  }

  return requestOptions
}

export const filterMessageType = message => {
  if (!message.type) {
    return `action type is ${message.actionType}`
  }

  if (message.type === "text") {
    const textContent = message.text
    const lines = textContent.split("\n")

    return lines.map((line, idx) => (
      <p key={idx} className="mb-0">
        {line}
      </p>
    ))
  }

  if (message.type === "image") return <ImageSingleMessage message={message} />

  if (message.type === "audio")
    return (
      <AudioSingleMessage audioSource={message.audio.file} dataSource="url" />
    )

  if (message.type === "video") return <VideoSingleMessage message={message} />

  if (message.type === "document")
    return <DocumentSingleMessage message={message} />

  if (message.type === "template")
    return <TemplateSingleMessage message={message} />

  if (message.type === "contacts")
    return <ContactSingleMessage message={message} />

  if (message.type === "sticker")
    return <StickerSingleMessage message={message} />

  if (message.type === "interactive")
    return <InteractiveSingleMessage message={message} />

  if (message.type === "location")
    return <LocationSingleMessage message={message} />

  return `Not a handled type (${message.type})`
}

export const displayFileName = (fileName, maxCharacters) => {
  if (!fileName) {
    return ""
  }

  if (fileName.length <= maxCharacters) {
    return fileName
  } else {
    const extension = fileName.slice(fileName.lastIndexOf("."))
    const fileNameWithoutExtension = fileName.slice(
      0,
      fileName.length - extension.length
    )
    const truncatedName = fileNameWithoutExtension.slice(
      0,
      maxCharacters - extension.length - 3
    )
    return truncatedName + "..." + extension
  }
}

export const checkSession = sessionTime => {
  if (!sessionTime) {
    return false //no session
  }

  const parsedDate = moment(sessionTime, "YYYY-MM-DDTHH:mm:ss.SSSZ")
  const currentTime = moment()

  const duration = moment.duration(currentTime.diff(parsedDate))
  const hoursDifference = duration.asHours()

  if (hoursDifference >= 24) {
    return false
  } else {
    return true
  }
}

export const convertData = inputDate => {
  let parsedDate

  // Check if inputDate is an ISO 8601 string
  if (moment(inputDate, moment.ISO_8601, true).isValid()) {
    parsedDate = moment(inputDate)
  } else {
    // Assume inputDate is in the format "HH:mm:ss, ddd MMM DD YYYY"
    parsedDate = moment(inputDate, "HH:mm:ss, ddd MMM DD YYYY")
  }

  // Check if the date was successfully parsed
  if (!parsedDate.isValid()) {
    return "Invalid date"
  }

  // Format the date to "DD/MM/YYYY hh:mm A"
  return parsedDate.format("DD/MM/YYYY hh:mm A")
}

export const tagOptions = [
  { value: "Urgent", label: "Urgent", color: "danger" },
  { value: "Resolved", label: "Resolved", color: "success" },
  { value: "Pending", label: "Pending", color: "warning" },
  { value: "Important", label: "Important", color: "danger" },
  { value: "Technical Support", label: "Technical Support", color: "info" },
  { value: "Feedback", label: "Feedback", color: "success" },
  { value: "Action Required", label: "Action Required", color: "dark" },
  {
    value: "Information Provided",
    label: "Information Provided",
    color: "info",
  },
  {
    value: "Needs Clarification",
    label: "Needs Clarification",
    color: "warning",
  },
  { value: "Weekly Review", label: "Weekly Review", color: "success" },
  { value: "Payment Failed", label: "Payment Failed", color: "danger" },
  { value: "Refund Request", label: "Refund Request", color: "info" },
  { value: "Login Issue", label: "Login Issue", color: "danger" },
]

export const chunkArray = (array, size) => {
  const chunkedArr = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size))
  }
  return chunkedArr
}

import * as XLSX from "xlsx"

export const fetchExcelRatesData = async filePath => {
  const response = await fetch(filePath)
  const arrayBuffer = await response.arrayBuffer()
  const data = new Uint8Array(arrayBuffer)
  const workbook = XLSX.read(data, { type: "array" })
  const sheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

  const headers = jsonData[0]
  const rows = jsonData.slice(1)
  const formattedData = rows.map(row => {
    let rowData = {}
    row.forEach((cell, index) => {
      rowData[headers[index]] = cell
    })
    return rowData
  })

  return formattedData
}

export const isUserSupervisor = (userData, teamsData, supervisorTeamId) => {
  const superVisorTeam = teamsData?.find(team => team._id === supervisorTeamId)

  if (superVisorTeam && superVisorTeam.supervisor._id === userData._id) {
    return true
  } else {
    return false
  }
}
