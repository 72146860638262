import React from "react"
import { Col, Row } from "reactstrap"

export default function FileStepper({ step }) {
  // steps content
  const steps = [
    { stepNumber: 1, description: "Choose a Template" },
    { stepNumber: 2, description: "Upload Excel File" },
    { stepNumber: 3, description: "Preview the Template" },
    { stepNumber: 4, description: "Finished" },
  ]

  return (
    <Col className="all-number position-relative d-flex flex-column gap-5">
      {steps.map(stepItem => (
        <div
          key={stepItem.stepNumber}
          className="d-flex gap-3 number position-relative "
        >
          <div
            className={`d-flex justify-content-center align-items-center ${
              step === stepItem.stepNumber
                ? "step-active rounded-circle"
                : step > stepItem.stepNumber
                ? "  d-flex justify-content-center align-items-center bg-success text-white rounded-circle step-complete"
                : "rounded-circle number-steps bg-white"
            }`}
          >
            {/* chick active step and add "complate mark" */}
            {step === stepItem.stepNumber ? (
              stepItem.stepNumber !== steps.length ? (
                stepItem.stepNumber
              ) : (
                <Col className=" step-complete">
                  <i className="fas fa-check"></i>
                </Col>
                // <div className="step-complete">f</div>
              )
            ) : step > stepItem.stepNumber ? (
              <Col className=" step-complete">
                <i className="fas fa-check"></i>
              </Col>
            ) : (
              // <div className="step-complete">f</div>
              stepItem.stepNumber
            )}
          </div>
          <Col className="m-0 d-flex flex-column gap-4">
            <h5
              style={{
                fontSize: "20px",
              }}
            >
              {stepItem.description}
            </h5>
          </Col>
        </div>
      ))}
    </Col>
  )
}
