import React, { useEffect, useState } from "react"
import axios from "axios"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import {
  unAuthUser,
  updateArhciveEndDate,
  updateArhciveStartDate,
  updateArhcivedUserId,
} from "store/actions"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

import configs from "config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
const { Url, apiVersion } = configs.client

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#EBEEF3" : "#ffffff",
    color: "#000000",

    "&:hover": {
      backgroundColor: "#EBEEF3",
    },
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderRadius: "0.3125rem",
    border: "none",
    height: "3.5rem",
    width: "19.5rem",
    width: "100%",
  }),
  control: provided => ({
    ...provided,
    borderRadius: "0.3125rem",
    border: "none",
    boxShadow: "none",
    height: "3.5rem",
  }),
  singleValue: provided => ({
    ...provided,
    color: "#000000",
  }),
}

function ArchivedChatsFilters() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [userID, setUserID] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const fetchUsers = () => {
    axios
      .get(`${Url}/${apiVersion}/users`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(response => {
        const usersFromAPI = response.data.data.users.map(user => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          chats: user.chats,
        }))

        setUsers(usersFromAPI)
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const handleDateChange = dates => {
    const [start, end] = dates

    start
      ? dispatch(updateArhciveStartDate(start))
      : dispatch(updateArhciveStartDate(""))

    end
      ? dispatch(updateArhciveEndDate(end))
      : dispatch(updateArhciveEndDate(""))

    setStartDate(start)
    setEndDate(end)

    if (!start && !end && !selectedUser) {
      // fetchAllChats()
    }
  }

  const handleUserChange = selectedOption => {
    if (selectedOption !== null) {
      setSelectedUser(selectedOption)
      setUserID(selectedOption.value)
      dispatch(updateArhcivedUserId(selectedOption.value))
    } else {
      setSelectedUser(null)
      setUserID("")
      dispatch(updateArhcivedUserId(""))
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="d-flex justify-content-between archived-chats-chatlist">
      <div className="archived-chats-user col-5">
        <Select
          className="archived-chats-user-selector"
          value={selectedUser}
          options={users}
          placeholder="User Name"
          onChange={handleUserChange}
          isClearable={true}
          styles={customStyles}
        />
      </div>

      <div className="col-6">
        <DatePicker
          className="archived-chatlist-datepicker"
          selectsRange={true}
          dateFormat="yyyy/MM/dd"
          startDate={startDate}
          placeholderText="MM/DD/YYYY - MM/DD/YYYY"
          endDate={endDate}
          onChange={handleDateChange}
          maxDate={new Date()}
          isClearable
          minDate={null}
        />
      </div>
    </div>
  )
}

export default ArchivedChatsFilters
