import React, { useState } from "react"
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"

function SubmitButtonComponent({
  statuses,
  handleSubmit,
  handleSubmitStatus,
  disabledStatus,
}) {
  const [drp_link, setdrp_link] = useState(false)

  return (
    <ButtonDropdown
      isOpen={drp_link}
      toggle={() => setdrp_link(!drp_link)}
      disabled={disabledStatus}
    >
      <Button
        id="caret"
        color="secondary"
        onClick={() => handleSubmit(null)}
        style={{ padding: "0.1875rem 0.875rem" }}
        disabled={disabledStatus} // Disabling the button based on disabledStatus
      >
        Submit
      </Button>
      <DropdownToggle
        caret
        color="secondary"
        title="Change Ticket's Status"
        style={{ padding: "0.1875rem 0.675rem" }}
        disabled={disabledStatus} // Disabling the dropdown button based on disabledStatus
      >
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu>
        {statuses?.map(status => (
          <DropdownItem
            key={status._id}
            onClick={() => handleSubmitStatus(status._id)}
          >
            {status.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default SubmitButtonComponent
