import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import tasksList from "pages/Tasks/tasks-list"

export default function ViewUserTicket({ toggle, isOpen, user }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>View User</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p className="fs-4">
              name:
              <span className="fw-bold ">
                &nbsp; {user?.firstName} {user?.lastName}
              </span>
            </p>
          </Col>
          <Col className="d-flex justify-content-end">
            {user?.team !== "" && (
              <p className="fs-4">
                Team:
                <span className="fw-bold fs-4"> &nbsp;{user?.team}</span>
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <div className="item-user ">
            <div className="user-ticket">
              <h5>Ticket Request</h5>
            </div>
            <div className="user-ticket-data">
              {user?.ticketRequests?.length > 0 ? (
                user.ticketRequests.map((ticket, index) => (
                  <div key={ticket.index} className="accordion-item">
                    <div className="ticket d-flex justify-content-between mb-2">
                      <p className="m-0">{ticket}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center">No data available</p>
              )}
            </div>
          </div>
        </Row>

        <Row className="mt-3 bg-red">
          <div className="item-user ">
            <div className="user-ticket">
              <h5>Tasks</h5>
            </div>
            <div className="user-ticket-data">
              {user?.tasks?.length > 0 ? (
                user.tasks.map((task, index) => (
                  <div key={task.index} className="accordion-item">
                    <div className="ticket d-flex justify-content-between mb-2">
                      <p className="m-0">{task}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center mt-3">No data available</p>
              )}
            </div>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  )
}
