import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
export default function AddViewAnswers({
  isOpen,
  toggle,
  Answer,
  fetchAllAnswersData,
  setShowSuccessToast,
  fetchSuggestedAnswersData,
}) {
  useEffect(() => {
    setFieldValue("answersSet", Answer?._id)
  }, [Answer])
  //   const AnswerID = Answer?._id
  const AnswerID = String(Answer?._id)

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const AddUserSchema = Yup.object().shape({
    name: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    body: Yup.string()
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
  })

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  // Formik
  const {
    handleChange,
    values,
    values: { Signature },
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      body: "",
      answersSet: AnswerID,
    },
    validationSchema: AddUserSchema,
    onSubmit: values => {
      setIsLoading(true)
      axios
        .post(`${Url}/${apiVersion}/answers`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          toggle()
          setIsLoading(false)
          setShowSuccessToast(true)
          setTimeout(() => setShowSuccessToast(false), 2500)
          fetchSuggestedAnswersData()
          fetchAllAnswersData()
          resetForm()
        })
        .catch(error => {
          setIsLoading(false)
          if (error?.response?.status === 401 || error.response?.status === 0) {
            UnAuthorized()
            setErrorMessage(error?.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            setIsLoading(false)
          } else {
            console.log(error)
            setErrorMessage(error?.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            setIsLoading(false)
          }
        })
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  const handleModalClosed = () => {
    resetForm()
  }
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={handleModalClosed}
        centered
        size="lg"
      >
        <ModalHeader toggle={toggle}>Add Answer </ModalHeader>
        <ModalBody>
          {errorMessage && (
            <div className="" aria-live="polite" aria-atomic="true">
              <div className="">
                <Toast isOpen={errorMessage} fade={true}>
                  <ToastBody className="delete-message-bg">
                    <h5 className="m-0 mb-2">Error</h5>
                    <p className="m-0">{errorMessage}</p>
                  </ToastBody>
                </Toast>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1"> Name</Label>
              <Input
                className=" p-5px m-0"
                id="team-name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                // placeholder="Email"
                type="text"
              />
              {errors.name && touched.name && (
                <div className="error mb-0">{errors.name}</div>
              )}
            </FormGroup>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1"> Answer </Label>
              <Input
                className=" p-5px m-0"
                id="answer-name"
                name="body"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.body}
                // placeholder="Email"
                type="text"
              />
              {errors.body && touched.body && (
                <div className="error mb-0">{errors.body}</div>
              )}
            </FormGroup>

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}

            <FormGroup className=" d-flex justify-content-end gap-3 edit-popup">
              <Button type="submit" className="edit-button">
                Add
              </Button>{" "}
              <Button onClick={toggle} className="edit-button cancel-delete ">
                Cancel
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}
