import React from "react"
import { Alert, FormFeedback, Input, Label, Form } from "reactstrap"

function LoginForm() {
  return (
    <div className="form-container">
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}

        <div className="mb-4">
          <Label className="form-label">Email address</Label>
          <Input
            name="email"
            className="form-control"
            placeholder="Enter email"
            type="email"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">
              {validation.errors.email}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-4">
          <Label className="form-label">Password</Label>
          <Input
            name="password"
            value={validation.values.password || ""}
            type="password"
            placeholder="Enter your password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.password && validation.errors.password
                ? true
                : false
            }
          />
          {validation.touched.password && validation.errors.password ? (
            <FormFeedback type="invalid">
              {validation.errors.password}
            </FormFeedback>
          ) : null}
        </div>

        <div className="my-4 d-grid">
          <button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Log In"}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default LoginForm
