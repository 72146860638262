import React, { useState } from "react"
import axios from "axios"
import { Row, Col, Card, Form, CardBody, Container, Alert } from "reactstrap"
import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"

import configs from "config"
const { Url, apiVersion } = configs.client

import { useDispatch } from "react-redux"
import { unAuthUser } from "store/actions"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useSelector } from "react-redux"

const FormUploadDragDrop = ({
  selectedFile,
  setSelectedFile,
  singleTemplateData,
  access,
  singleFileName,
  setSingleFileName,
  loaderIndicator,
  setLoaderIndicator,
}) => {
  const { authorized } = useSelector(state => ({
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const [selectedFiles, setselectedFiles] = useState([])

  const getIconForFileType = fileType => {
    switch (fileType) {
      case "application/pdf":
        return <i className="bx bxs-file-pdf bx-md" />
      case "text/csv":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <i className="fas fa-file-csv fa-3x" />
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <i className="bx bxs-file-doc bx-md" />
      case "image/*":
        return <i className="bx bxs-file-image bx-md" />
      default:
        return <i className="bx bx-file-blank bx-md" />
    }
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    setSelectedFile(files[0])
  }

  const handleSendingSingleFile = () => {
    setLoaderIndicator(true)

    const formData = new FormData()
    formData.append("file", selectedFile)

    axios
      .post(`${Url}/${apiVersion}/upload/single`, formData, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setSingleFileName(res.data.file)
        setLoaderIndicator(false)
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }

        setLoaderIndicator(false)
      })
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <React.Fragment>
      <div className="page-content p-2">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card className="mb-0">
                <CardBody>
                  <Form>
                    <p>
                      HINT: Please select{" "}
                      {singleTemplateData.components[0].format === "IMAGE"
                        ? "an image"
                        : "a doc file"}
                    </p>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="dropzone"
                          style={{ minHeight: "100px" }}
                        >
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              accept={
                                singleTemplateData.components[0].format ===
                                "IMAGE"
                                  ? "image/*"
                                  : ".doc, .pdf, .csv, .xsls"
                              }
                            />

                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  {getIconForFileType(f.type)}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    {singleFileName && (
                      <Alert
                        className="mt-3 text-center"
                        key="light"
                        variant="light"
                      >
                        Go to the next step!
                      </Alert>
                    )}
                  </Form>

                  <div className="text-center mt-4 upload-single-file">
                    <button
                      disabled={!selectedFile}
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleSendingSingleFile()}
                    >
                      Upload
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormUploadDragDrop
