import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Our pages
import Home from "pages/Home/Home"
import Broadcast from "pages/Broadcast/Broadcast"
import BroadcastReports from "pages/Reports/BroadcastReports"
import PerformanceReports from "pages/Reports/PerformanceReports"
import Users from "pages/Settings/Users"
import Teams from "pages/Settings/Teams"
import Conversation from "pages/Settings/Conversation"
import ServiceHours from "pages/Settings/ServiceHours"
import SuggestedAnswers from "pages/Settings/SuggestedAnswers"
import Chat from "pages/Chat/Chat"
import UserTeamProfile from "pages/UserProfile/UserTeamProfile"
import PrivateAnswers from "pages/UserProfile/PrivateAnswers"
import UserProfile from "pages/UserProfile/UserProfile"
import UploadFileSteps from "pages/Broadcast/UploadeFile/UploadFileSteps"
import Temp from "pages/Temp/Temp"
import TicketList from "pages/Ticket/TicketList"
import TicketFields from "pages/Ticket/TicketFields"
import TicketForms from "pages/Ticket/TicketForms"
import TicketCategories from "pages/Ticket/TicketCategories"
import AddTicketField from "pages/Ticket/TicketFields/AddTicketField"
import TicketStatus from "pages/Ticket/TicketStatus"

import DropdownField from "pages/Ticket/TicketFields/FieldTypes/DropdownField"
import EditField from "pages/Ticket/TicketFields/FieldTypes/EditField"
import AddFormTicket from "pages/Ticket/TicketForms/AddFormTicket"
import EditFormTicket from "pages/Ticket/TicketForms/EditFormTicket"
import AddTicket from "pages/Ticket/TicketList/AddTicket"
import ViewTicket from "pages/Ticket/TicketList/ViewTicket"
import TicketHome from "pages/Ticket/TicketHome"
import TicketDahboard from "pages/Ticket/TicketDahboard"

const authProtectedRoutes = [
  // Home
  { path: "/", component: <Home /> },
  { path: "/temp", component: <Temp /> },

  // Chatpage
  { path: "/chat/:id", component: <Chat /> },

  // Broadcast
  { path: "/broadcast", component: <Broadcast /> },
  { path: "/broadcast/upload-file", component: <UploadFileSteps /> },

  // Reports
  { path: "/broadcast-reports", component: <BroadcastReports /> },
  { path: "/performance-reports", component: <PerformanceReports /> },

  // Settings
  { path: "/users", component: <Users /> },
  { path: "/teams", component: <Teams /> },
  { path: "/conversation", component: <Conversation /> },
  { path: "/suggested-answers", component: <SuggestedAnswers /> },
  { path: "/service-hours", component: <ServiceHours /> },

  // Profile:
  { path: "/team-info", component: <UserTeamProfile /> },
  { path: "/private-answers", component: <PrivateAnswers /> },
  { path: "/profile", component: <UserProfile /> },

  // Tickets
  { path: "/ticket/home", component: <TicketHome /> },
  { path: "/ticket/list", component: <TicketList /> },
  { path: "/ticket/new", component: <AddTicket /> },
  { path: "/ticket/list/:ticketId", component: <ViewTicket /> },

  // ticket dashboard
  { path: "/ticket/dashboard", component: <TicketDahboard /> },

  // ticket fields
  { path: "/ticket/fields", component: <TicketFields /> },
  { path: "/ticket/fields/new", component: <AddTicketField /> },
  { path: "/ticket/fields/:fieldId", component: <EditField /> },

  // ticket forms
  { path: "/ticket/forms", component: <TicketForms /> },
  { path: "/ticket/categories", component: <TicketCategories /> },
  { path: "/ticket/status", component: <TicketStatus /> },

  { path: "/ticket/forms/new", component: <AddFormTicket /> },
  { path: "/ticket/forms/:formId", component: <EditFormTicket /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-500", component: <Pages500 /> },

  { path: "/*", component: <Pages404 /> },
]

export { authProtectedRoutes, publicRoutes }
