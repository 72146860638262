import React from "react"

function InteractiveSingleMessage({ message }) {
  const { interactive } = message
  return (
    <div dir="rtl">
      {interactive.header && <h5>{interactive.header.text}</h5>}

      {!interactive.header && interactive.body && (
        <h5>{interactive.body.text}</h5>
      )}

      {interactive.header && interactive.body && (
        <p className="mb-1">{interactive.body.text}</p>
      )}

      {interactive.footer && (
        <p className="mb-2 text-muted">{interactive.footer.text}</p>
      )}

      {interactive.type === "list" && (
        <div>
          <p className="mb-1">
            <i className="dripicons-list"></i> الاختيارات
          </p>

          {interactive.action.sections[0].rows.map((option, index) => (
            <div className="form-check text-end" key={index}>
              <i className="mdi mdi-checkbox-blank-circle-outline"></i>{" "}
              <span>{option.title}</span>
              {option.description && (
                <small className="d-block">({option.description})</small>
              )}
            </div>
          ))}
        </div>
      )}

      {interactive.type === "list_reply" && (
        <p className="mb-1">{interactive.list_reply.title}</p>
      )}

      {interactive.type === "button" &&
        Array.isArray(interactive.action.buttons) && (
          <>
            {interactive.action.buttons.map((option, index) => (
              <button
                key={index}
                className={`btn btn-light btn-sm btn-block mb-2 border`}
                style={{ width: "100%" }}
                disabled
              >
                {option.reply.title}
              </button>
            ))}
          </>
        )}

      {interactive.type === "button_reply" && (
        <p className="mb-1">{interactive.button_reply.title}</p>
      )}
    </div>
  )
}

export default InteractiveSingleMessage
