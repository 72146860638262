import Doughnut from "pages/AllCharts/echart/doughnutchart"
import React, { useEffect, useState } from "react"
import DoughnutChart from "./DoughnutChart"
import DownloadExcelSheet from "./DownloadExcelSheet"
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap"
export default function BroadcastReportChart({
  ClosePopUp,
  messageInformation,
  selectedBroadcastData,
}) {
  const [modal, setModal] = useState(true)
  const toggle = () => {
    setModal(!modal)
    if (modal) {
      ClosePopUp()
    }
  }
  return (
    <>
      {messageInformation?.length > 0 ? (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          className="modle-chart-report"
        >
          <ModalHeader toggle={toggle}>
            <p className="m-0 m-2"> Total Messages</p>
            <Row>
              <h3 className="m-0 fw-bold">
                {selectedBroadcastData?.broadcastData?.totalMessages}
                <span>Messages</span>
              </h3>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row></Row>

            <div className="d-flex align-items-center justify-content-center gap-5 ">
              <DoughnutChart selectedBroadcastData={selectedBroadcastData} />
              <Col lg={4} xs={4} md={4} className="">
                <Row className=" d-flex flex-column gap-2">
                  <Row className="d-flex gap-2 align-items-center">
                    <Col md={2} xs={2}>
                      <div className="chart-broadcast-report read"></div>
                    </Col>
                    <Col md={2} xs={2}>
                      <p className="m-0 d-flex align-items-center type-broadcast-message">
                        <span>{selectedBroadcastData.broadcastData?.read}</span>
                        Read
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex gap-2 align-items-center ">
                    <Col md={2} xs={2}>
                      <div className="chart-broadcast-report delivered "></div>
                    </Col>
                    <Col md={2} xs={2}>
                      <p className="m-0 d-flex align-items-center type-broadcast-message">
                        <span>
                          {selectedBroadcastData.broadcastData?.delivered}
                        </span>
                        Delivered
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex gap-2 align-items-center">
                    <Col md={2} xs={2}>
                      <div className="chart-broadcast-report sent"></div>
                    </Col>
                    <Col md={2} xs={2}>
                      <p className="m-0 d-flex align-items-center type-broadcast-message">
                        <span>{selectedBroadcastData.broadcastData?.sent}</span>
                        Sent
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex gap-2 align-items-center">
                    <Col md={2} xs={2}>
                      <div className="chart-broadcast-report failed"></div>
                    </Col>
                    <Col md={2} xs={2}>
                      <p className="m-0 d-flex align-items-center type-broadcast-message">
                        <span>
                          {selectedBroadcastData.broadcastData?.failed}
                        </span>
                        Failed
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex gap-2 align-items-center ">
                    <Col md={2} xs={2}>
                      <div className="chart-broadcast-report pending "></div>
                    </Col>
                    <Col md={2} xs={2}>
                      <p className="m-0 d-flex align-items-center type-broadcast-message">
                        <span>
                          {selectedBroadcastData.broadcastData?.pending}
                        </span>
                        Pending
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <DownloadExcelSheet
                BroadcastID={selectedBroadcastData.id}
                BroadcastName={selectedBroadcastData.template}
              />
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <Row className="text-center">
          <Col>No broadcasts available</Col>
        </Row>
      )}
    </>
  )
}
