import React from "react"
import { useDispatch } from "react-redux"
import { deleteClosedTab } from "store/actions"
import { useNavigate, useParams } from "react-router-dom"

function Tab({ children, name, index, id, number, openChatsTabs, tab }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const params = useParams()

  const handleOnClick = (e, tab) => {
    e.stopPropagation()
    // console.log("openChatsTabs[index -]", openChatsTabs[index - 1])

    if (tab.client === params.id) {
      if (openChatsTabs.length === 1) {
        navigate(`/`)
      } else {
        openChatsTabs[index - 1]
          ? navigate(`/chat/${openChatsTabs[index - 1].client}`)
          : navigate(`/chat/${openChatsTabs[index + 1].client}`)
      }

      dispatch(deleteClosedTab(number))
      return
    } else {
      dispatch(deleteClosedTab(number))
      return
    }
  }

  return (
    <div
      className={`tab ${number === params.id && "active"}`}
      onClick={() => navigate(`/chat/${number}`)}
    >
      <span className="tab-status align-self-center d-flex justify-content-center align-items-center">
        {children}
      </span>

      <span className="tab-name align-self-center">
        {name.length > 10 ? name.substring(0, 10) + "..." : name}
      </span>

      <span
        onClick={e => handleOnClick(e, tab)}
        className="tab-close align-self-center d-flex justify-content-center align-items-center"
      >
        <i className="mdi mdi-close"></i>
      </span>
    </div>
  )
}

export default Tab
