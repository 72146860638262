import TableDeleteIcon from "components/Common/Icons/SettingIcons/TableDeleteIcon"
import TableEditIcon from "components/Common/Icons/SettingIcons/TableEditIcon"
import React, { useEffect, useState } from "react"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import TableView from "components/Common/Icons/SettingIcons/TableView"

export default function TeamsTable({
  setIsLoading,
  isloading,
  teams,
  setTeams,
  toggleDelete,
  toggleEdit,
  toggleViewTeamComponents,
  setSearchName,
}) {
  const { access } = useSelector(state => ({
    access: state.Login.userAccess,
  }))

  return (
    <div>
      {/*   Teams search */}
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="d-flex gap-4 align-items-center mt-3 mb-2">
          <button className="filter-button">All Teams</button>
          <button className="filter-button">My Team</button>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3 ">
          <input
            className="filter-user-input"
            type="search"
            placeholder=" Search by Team"
            onChange={e => setSearchName(e.target.value)}
          />
        </div>
      </div>

      <div className="table-container table-container-team m-0 p-0">
        {isloading ? (
          <div className="  loading">
            <div className="spinner-border "></div>
          </div>
        ) : (
          <table>
            <thead className="text-center">
              <tr>
                <th>Name </th>
                <th> Supervisor </th>
                <th>Members </th>
                <th> service Hours </th>
                <th>conversation </th>
                <th>Action </th>
              </tr>
            </thead>
            {teams && teams.length > 0 ? (
              <tbody>
                {teams.map(team => (
                  <tr
                    className="tr"
                    key={team.id}
                    onClick={() => toggleViewTeamComponents(team)}
                  >
                    <td className="text-center">{team.name}</td>
                    <td className="text-center">
                      {" "}
                      {team.supervisor.firstName +
                        " " +
                        team.supervisor.lastName}{" "}
                    </td>
                    <td className="text-center">{team.users.length}</td>
                    <td className="text-center">{team.serviceHours.name}</td>
                    <td className="text-center">{team.conversation.name}</td>

                    <td className="text-center">
                      <button
                        onClick={e => {
                          e.stopPropagation()
                          toggleEdit(team)
                        }}
                        className="border-0 bg-transparent"
                        title="edit"
                      >
                        <TableEditIcon />
                      </button>
                      <button
                        onClick={e => {
                          e.stopPropagation()
                          toggleDelete(team)
                        }}
                        // onClick={() => toggleDelete(team)}
                        className="border-0 bg-transparent"
                        title="delete"
                      >
                        <TableDeleteIcon />
                      </button>
                      {/* <button
                        onClick={e => {
                          e.stopPropagation()
                          toggleViewTeamComponents(team)
                        }}
                        className="border-0 bg-transparent"
                        title="view"
                      >
                        <TableView />
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="6" className="text-center p-2">
                    No Teams found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        )}
      </div>
    </div>
  )
}
