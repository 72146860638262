import React from "react"

import ChatList from "./ChatList"
import PerformanceFiltersTeamMembers from "./PerformanceFiltersTeamMembers"

function Home() {
  document.title = "ChatPlus | Home"

  return (
    <div className="row home-page">
      <ChatList />

      <PerformanceFiltersTeamMembers />
    </div>
  )
}

export default Home
