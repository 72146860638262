import axios from "axios"
import React, { useEffect, useState } from "react"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import MissingDataTable from "./MissingDataTable"
import TicketsTable from "./TicketsTable"
import InspectionVisitsTable from "./InspectionVisitsTable"
import { Input, Table } from "reactstrap"

import { useParams } from "react-router-dom"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useSelector } from "react-redux"

const ProjectDetails = ({ sessionIndicator }) => {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [noData, setNoData] = useState(false)

  let { authorized } = useSelector(state => ({
    authorized: state.Login.authorized,
  }))

  const params = useParams()

  const [ref, setRef] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    axios
      .get(
        `https://rd0.cpvarabia.com/api/Care/Project.php?Token=OKRJ_R85rkn9nrgg&Phone=${params.id}&Ref=${ref}`
      )
      .then(res => {
        res.data.error ? setNoData(true) : setNoData(false)
        if (res.data.error === false) {
          let array = Object.values(res.data)
          array.splice(-1)
          setProjects(array)
        }
        setLoading(false)
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }

        setLoading(false)
      })
  }, [ref])

  useEffect(() => {
    setRef("")
  }, [params.id])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      <Input
        className="rounded-pill ps-3 my-2 w-100"
        type="number"
        name="ref"
        value={ref}
        onChange={e => {
          setRef(e.target.value)
        }}
        placeholder="Reference No ..."
      />

      {noData ? (
        <div className="text-center">No project found</div>
      ) : loading ? (
        <div>Loading...</div>
      ) : projects.length === 1 ? (
        <div>
          <div className="table-responsive my-2">
            <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
              <thead>
                <tr>
                  <th>Ref</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>City</th>
                  <th>Owner</th>
                  <th>Contractor</th>
                  <th>Payment Status</th>
                  <th>RD0 Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{projects[0].ProjectDetails.ReferenceNo}</td>
                  <td>{projects[0].ProjectDetails.ProjectName}</td>
                  <td>{projects[0].ProjectDetails.Category}</td>
                  <td>{projects[0].ProjectDetails.CityName}</td>
                  <td>{projects[0].ProjectDetails.OwnerName}</td>
                  <td>{projects[0].ProjectDetails.ConstructorName}</td>
                  <td>
                    <span
                      className={
                        projects[0].ProjectDetails.Paid[0] === "1"
                          ? "badge bg-success"
                          : projects[0].ProjectDetails.Paid[0] === "0"
                          ? "badge bg-danger"
                          : ""
                      }
                    >
                      {projects[0].ProjectDetails.Paid[0] === "1"
                        ? "Paid"
                        : projects[0].ProjectDetails.Paid[0] === "0"
                        ? "Not Paid"
                        : ""}
                    </span>
                  </td>
                  <td>{projects[0].ProjectDetails.RD0Status}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <Tabs id="justify-tab-example" className="mb-3" justify fill>
            <Tab eventKey="missingData" title="Missing Data">
              <MissingDataTable data={projects[0].MissingData} />
            </Tab>
            <Tab eventKey="tickets" title="Tickets">
              <TicketsTable tickets={projects[0].Tickets} />
            </Tab>
            <Tab eventKey="inspectionVisits" title="Inspection Visits">
              <InspectionVisitsTable
                sessionIndicator={sessionIndicator}
                visits={projects[0].InspectonVisit}
              />
            </Tab>
          </Tabs>
        </div>
      ) : projects.length > 1 ? (
        <div
          className="table-responsive mt-2"
          style={{ maxHeight: "350px", overflowY: "scroll" }}
        >
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th>Ref.</th>
                <th>Name</th>
                <th>Category</th>
                <th>City</th>
                <th>Owner</th>
                <th>Contractor</th>
                <th>Payment Status</th>
                <th>RD0 Status</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((item, key) => (
                <tr key={key}>
                  <td>{item.ProjectDetails.ReferenceNo}</td>
                  <td>{item.ProjectDetails.ProjectName}</td>
                  <td>{item.ProjectDetails.Category}</td>
                  <td>{item.ProjectDetails.CityName}</td>
                  <td>{item.ProjectDetails.OwnerName}</td>
                  <td>{item.ProjectDetails.ConstructorName}</td>
                  <td>
                    <span
                      className={
                        item.ProjectDetails.Paid[0] === "1"
                          ? "badge bg-success"
                          : item.ProjectDetails.Paid[0] === "0"
                          ? "badge bg-danger"
                          : ""
                      }
                    >
                      {item.ProjectDetails.Paid[0] === "1"
                        ? "Paid"
                        : item.ProjectDetails.Paid[0] === "0"
                        ? "Not Paid"
                        : ""}
                    </span>
                  </td>
                  <td>{item.ProjectDetails.RD0Status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        !loading &&
        projects.length === 0 && (
          <div className="text-center">No project found</div>
        )
      )}
    </>
  )
}

export default ProjectDetails
