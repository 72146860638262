import moment from "moment"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  RESET_DATA,
  UPDATE_CHATS_TYPE,
  UPDATE_CHATS_STATUS,
  CHANGE_SELECTED_OVERVIEW,
  UPDATE_SELECTED_TEAMS,
  USER_SESSION_NUMBERS,
  TEAM_SESSION_NUMBERS,
  CHANGE_CHAT_FILTER,
  UPDATE_ARCHIVED_USER_ID,
  UPDATE_ARCHIVED_START_DATE,
  UPDATE_ARCHIVED_END_DATE,
  SET_SELECTED_TEAM_MEMBER,
  ADD_OPEN_TABS,
  DELETE_CLOSED_TABS,
  SEND_OTP_REQUEST,
  RESET_ARCHIVED_USER_ID,
  RESET_ARCHIVED_DATE,
  UPDATE_USER_DATA,
  UPDATE_ACCESS_TOKEN,
  UN_AUTH_USER,
  UPDATE_OPEN_TABS,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    // payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const updateAccessToken = token => {
  return {
    type: UPDATE_ACCESS_TOKEN,
    payload: token,
  }
}

export const updateUserData = user => {
  return {
    type: UPDATE_USER_DATA,
    payload: user,
  }
}

export const sendOTPRequest = () => {
  return {
    type: SEND_OTP_REQUEST,
  }
}

export const ResetData = history => {
  localStorage.clear()

  return {
    type: RESET_DATA,
    // payload: { history },
  }
}
export const logoutUser = history => {
  localStorage.clear()

  return {
    type: LOGOUT_USER,
    // payload: { history },
  }
}

export const unAuthUser = () => {
  return {
    type: UN_AUTH_USER,
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history },
  }
}

export const deleteUserData = error => {
  localStorage.clear()
  return {
    type: RESET_DATA,
    payload: error,
  }
}

export const updateChatsType = chatsType => {
  return {
    type: UPDATE_CHATS_TYPE,
    payload: chatsType,
  }
}

export const updateChatStatus = chatStatus => {
  return {
    type: UPDATE_CHATS_STATUS,
    payload: chatStatus,
  }
}

export const changeSelectedOverview = (
  overview,
  newChatStatus,
  newChatsType
) => {
  return {
    type: CHANGE_SELECTED_OVERVIEW,
    payload: { overview, newChatStatus, newChatsType },
  }
}

export const updateSelectedTeams = selectedTeams => {
  return {
    type: UPDATE_SELECTED_TEAMS,
    payload: selectedTeams.join(","),
  }
}

export const userSessionNumbers = userSessions => {
  const outputArray = [
    userSessions["all"],
    userSessions["onTime"],
    userSessions["danger"],
    userSessions["tooLate"],
    userSessions["open"],
  ]

  return {
    type: USER_SESSION_NUMBERS,
    payload: outputArray.join(","),
  }
}

export const teamSessionNumbers = teamSessions => {
  const outputArray = [
    teamSessions["all"],
    teamSessions["onTime"],
    teamSessions["danger"],
    teamSessions["tooLate"],
    teamSessions["open"],
  ]

  return {
    type: TEAM_SESSION_NUMBERS,
    payload: outputArray.join(","),
  }
}

export const changeChatFilters = filter => {
  return {
    type: CHANGE_CHAT_FILTER,
    payload: filter,
  }
}

export const updateArhcivedUserId = userId => {
  return {
    type: UPDATE_ARCHIVED_USER_ID,
    payload: userId,
  }
}

export const updateArhciveStartDate = startDate => {
  let formatedStartDate
  startDate
    ? (formatedStartDate = moment(startDate).format("MM-DD-Y"))
    : (formatedStartDate = "")

  return {
    type: UPDATE_ARCHIVED_START_DATE,
    payload: formatedStartDate,
  }
}

export const updateArhciveEndDate = EndDate => {
  let formatedEndDate
  EndDate
    ? (formatedEndDate = moment(EndDate).format("MM-DD-Y"))
    : (formatedEndDate = "")

  return {
    type: UPDATE_ARCHIVED_END_DATE,
    payload: formatedEndDate,
  }
}

export const setTeamMemberUserId = userId => {
  return {
    type: SET_SELECTED_TEAM_MEMBER,
    payload: userId,
  }
}

export const addNewOpenTab = chat => {
  return {
    type: ADD_OPEN_TABS,
    payload: chat,
  }
}

export const deleteClosedTab = chatClient => {
  return {
    type: DELETE_CLOSED_TABS,
    payload: chatClient,
  }
}

export const updateOpenTabs = openTabs => {
  return {
    type: UPDATE_OPEN_TABS,
    payload: openTabs,
  }
}

export const resetArchivedUserId = () => {
  return {
    type: RESET_ARCHIVED_USER_ID,
  }
}

export const resetArchivedDate = () => {
  return {
    type: RESET_ARCHIVED_DATE,
  }
}
