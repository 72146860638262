import React, { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
  Container,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import * as Yup from "yup"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { updateAccessToken } from "store/actions"
import { useDispatch } from "react-redux"

export default function ChangeUserPassword({ setShowSuccessToast }) {
  const EditUserPasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required("required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("required"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("required"),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  // formik
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      passwordConfirm: "",
    },
    validationSchema: EditUserPasswordSchema,

    onSubmit: values => {
      setIsLoading(true)
      axios
        .patch(`${Url}/${apiVersion}/users/updateMyPassword`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            setIsLoading(false)
            setShowSuccessToast(true)
            dispatch(updateAccessToken(res.data.token))
            setTimeout(() => setShowSuccessToast(false), 2500)

            // fetchTeamsData()
            // setUserDataProfile(res.data.data.user)
          } else {
            console.error("Unexpected status code:", res.status, res.data)
          }
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error:", error)
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0
          ) {
            UnAuthorized()
            // dispatch(authorized())
          } else if (error.response?.status === 403) {
            setErrorMessage(error?.response?.data?.message)
            setTimeout(() => setErrorMessage(false), 3500)
          } else {
            setErrorMessage(error?.response?.data?.message)
            setTimeout(() => setErrorMessage(false), 3500)
            console.log(error)
          }
        })
    },
  })

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="change-user-name">
      <h4 className="m-0 mb-4">Change User Password</h4>

      {errorMessage && (
        <div className="" aria-live="polite" aria-atomic="true">
          <div className="">
            <Toast isOpen={errorMessage} fade={true}>
              <ToastBody className="delete-message-bg">
                <h5 className="m-0 mb-2">Error</h5>
                <p className="m-0">{errorMessage}</p>
              </ToastBody>
            </Toast>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <FormGroup className="m-0 m-mb-2">
          <Label className="mb-1">Old Password</Label>
          <Input
            className=" p-5px m-0"
            id="currentPassword"
            name="currentPassword"
            value={values.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            placeholder="old password"
          />
          {errors.currentPassword && touched.currentPassword && (
            <div className="error mb-0">{errors.currentPassword}</div>
          )}
        </FormGroup>
        <FormGroup className="m-0 m-mb-2">
          <Label className="mb-1">New Password</Label>
          <Input
            className=" p-5px m-0"
            id="password"
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            placeholder="new password"
          />
          {errors.newPassword && touched.newPassword && (
            <div className="error mb-0">{errors.newPassword}</div>
          )}
        </FormGroup>
        <FormGroup className="m-0 m-mb-2">
          <Label className="mb-1">Confirm Password</Label>
          <Input
            className=" p-5px m-0"
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.passwordConfirm}
            placeholder="confirm"
          />
          {errors.passwordConfirm && touched.passwordConfirm && (
            <div className="error mb-0">{errors.passwordConfirm}</div>
          )}
        </FormGroup>

        {isLoading && (
          <div className="loading-overlay">
            <Spinner />
          </div>
        )}

        <FormGroup className=" d-flex justify-content-end gap-3 edit-popup mt-2">
          <Button type="submit" className="edit-button">
            Update
          </Button>
        </FormGroup>
      </form>
    </div>
  )
}
