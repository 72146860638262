import React from "react"

export default function PerformanceReportsTable({
  isloading,
  PerformancesData,
}) {
  // console.log("PerformancesData", PerformancesData)
  return (
    <div className="table-container table-performance  m-0 p-0">
      {isloading ? (
        <div className="  loading">
          <div className="spinner-border "></div>
        </div>
      ) : (
        <table className="">
          <thead className="text-center">
            <tr>
              <th>Employee </th>
              <th>Total </th>
              <th>On time </th>
              <th> Danger </th>
              <th>Too late </th>
            </tr>
          </thead>
          {PerformancesData && PerformancesData.length > 0 ? (
            <tbody>
              {PerformancesData.map(Performance => (
                <tr key={Performance}>
                  <td className="text-center">{Performance.user}</td>
                  <td className="text-center">{Performance.totalChats}</td>
                  <td className="text-center">{Performance.onTime}</td>
                  <td className="text-center">{Performance.danger}</td>
                  <td className="text-center">{Performance.tooLate}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="6" className="text-center p-2">
                  No found
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </div>
  )
}
