import React from 'react'

export default function ViewTeamTicketRequest({ viewSelectedTeam }) {
    return (
        <div className="item mt-3">
            <div className="team-conversation">
                <h5>Ticket Request</h5>
            </div>
            <div className="team-conversation-data">
                {viewSelectedTeam?.ticketRequests?.length > 0 ? (
                    viewSelectedTeam.ticketRequests.map((ticket, index) => (
                        <div key={ticket.index} className="accordion-item">
                            <div
                                className="answer d-flex justify-content-between mb-2"
                            >
                                <p className="answer-name m-0">{ticket}</p>
                            </div>


                        </div>
                    ))
                ) : (
                    <p className="text-center">No data available</p>
                )}
            </div>
        </div>
    )
}
