import React, { useEffect, useState } from "react"
import axios from "axios"
import socket from "socket"

import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import { useParams } from "react-router-dom"
import SingleMessage from "./SingleMessage"
import CustomSpinner from "components/Common/CustomSpinner"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { checkSession } from "helpers/helper_functions"
import { Spinner } from "reactstrap"
import NoMessageFound from "./../../../../src/components/Common/Icons/Chat/Conversation/no-message-found.png"
import { addNewOpenTab, unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

import configs from "../../../config"
const { Url, apiVersion } = configs.client

function AllMessages({
  loadingMessages,
  setLoadingMessages,
  setClientName,
  setIsChatOwner,
  setOnSession,
  setSelectedReplyMessage,
  setIsChatArchived,
  setIsUserShareTeamChat,
}) {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  const params = useParams()
  const dispatch = useDispatch()

  const [messages, setMessages] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [messageIds, setMessageIds] = useState(new Set())
  const [noMessageFound, setNoMessageFound] = useState(false)

  const fetchMessagesData = () => {
    setLoadingMessages(true)
    setNoMessageFound(false)

    axios
      .get(`${Url}/${apiVersion}/chats/${params.id}/messages?page=1`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        if (userData._id === res.data.data.currentUser?._id) {
          setIsChatOwner(true)
        } else {
          setIsChatOwner(false)
        }

        if (res.data.data.chatStatus === "archived") {
          setIsChatArchived(true)
        } else {
          setIsChatArchived(false)
        }
        res.data.data.currentUser?.teamID === userData.team &&
          setIsUserShareTeamChat(true)
        setOnSession(checkSession(res.data.data.session))

        const newMessages = res.data.data.messages
        if (res.data.data.messages.length === 0) {
          setNoMessageFound(true)
        }
        setMessages(newMessages)
        setTotalPages(res.data.data.totalPages)

        page > 1 && setPage(1)
        setMessageIds(new Set(newMessages.map(message => message._id)))

        res.data.data?.contactName &&
          res.data.data.contactName.name &&
          setClientName(res.data.data.contactName.name)
        !res.data.data?.contactName?.name && setClientName(params.id)

        res.data.data?.contactName
          ? dispatch(
              addNewOpenTab({
                client: params.id,
                contactName: { name: res.data.data?.contactName.name },
              })
            )
          : dispatch(addNewOpenTab({ client: params.id }))
        setLoadingMessages(false)
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          setLoadingMessages(false)
          setNoMessageFound(true)

          console.log(err)
        }

        if (err.response?.status === 400) {
          setIsChatArchived(true)
        }

        dispatch(addNewOpenTab({ client: params.id }))
      })
  }

  const fetchMoreData = () => {
    const nextPage = page + 1

    axios
      .get(
        `${Url}/${apiVersion}/chats/${params.id}/messages?page=${page + 1}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        const newMessages = res.data.data.messages
        const uniqueMessages = newMessages.filter(
          message => !messageIds.has(message._id)
        )

        setMessages(prevMessages => [...prevMessages, ...uniqueMessages])
        setPage(nextPage)
        setMessageIds(prevIds => {
          const newIds = new Set(prevIds)
          uniqueMessages.forEach(message => newIds.add(message._id))
          return newIds
        })
      })
      .catch(err => {
        if (err.response.status == 401 || err.response.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    fetchMessagesData()
  }, [params.id])

  useEffect(() => {
    let socketEmitBody = { chatNumber: params.id, page: page }

    if (socket) {
      const handleUpdating = () => {
        socket.emit("client_to_server", socketEmitBody)
      }

      const handleServerToClient = response => {
        if (response.chatStatus) {
          if (userData._id === response.currentUser?._id) {
            // console.log("setting it from server socket")
            setIsChatOwner(true)
          } else {
            setIsChatOwner(false)
          }
          if (response.chatStatus === "archived") {
            setIsChatArchived(true)
          } else {
            setIsChatArchived(false)
          }
        }

        response.currentUser?.teamID !== userData.team &&
          setIsUserShareTeamChat(true)
        response.session && setOnSession(checkSession(response.session))
        response.messages && setMessages(response.messages)
      }

      socket.on("updating", handleUpdating)
      socket.on("server_to_client", handleServerToClient)

      return () => {
        socket.off("updating", handleUpdating)
        socket.off("server_to_client", handleServerToClient)
      }
    }
  }, [params.id, page, socket])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div
      id="scrollableDiv"
      className={`d-flex flex-column-reverse my-2 position-relative messages-container ${
        loadingMessages && "justify-content-center"
      }`}
    >
      {noMessageFound ? (
        <div className="align-self-center d-flex flex-column justify-content-center mw-50 my-auto">
          <img
            className="mx-auto"
            style={{ height: "13.25rem" }}
            src={NoMessageFound}
          />
          <h6 className="mx-auto font-size-20">No Messages Yet!</h6>
          <span className="mx-auto font-size-16 text-muted">
            To start a new conversation you need to send a template
          </span>
        </div>
      ) : loadingMessages ? (
        <CustomSpinner />
      ) : (
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreData}
          hasMore={page <= totalPages}
          loader={
            <div className="text-center py-2">
              <Spinner color="secondary" />
            </div>
          }
          scrollableTarget="scrollableDiv"
          inverse={true}
          className="d-flex flex-column-reverse messages-container"
          style={{
            margin: "0",
            padding: "0",
            overflow: "hidden",
          }}
        >
          {Array.isArray(messages) &&
            messages.map((message, idx) => (
              <SingleMessage
                key={message._id}
                message={message}
                nextMessage={
                  messages[idx + 1] ? messages[idx + 1] : { from: "" }
                }
                clientNumber={params.id}
                setSelectedReplyMessage={setSelectedReplyMessage}
              />
            ))}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default AllMessages
