import React from "react"

function ProfileDropdownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
    >
      <path
        d="M4.88477 24.929C4.88477 24.929 8.13708 20.7764 16.1621 20.7764C24.1871 20.7764 27.4408 24.929 27.4408 24.929M16.1621 15.6696C17.323 15.6696 18.4364 15.2084 19.2573 14.3875C20.0782 13.5666 20.5393 12.4532 20.5393 11.2923C20.5393 10.1314 20.0782 9.01801 19.2573 8.19711C18.4364 7.37621 17.323 6.91504 16.1621 6.91504C15.0011 6.91504 13.8878 7.37621 13.0669 8.19711C12.246 9.01801 11.7848 10.1314 11.7848 11.2923C11.7848 12.4532 12.246 13.5666 13.0669 14.3875C13.8878 15.2084 15.0011 15.6696 16.1621 15.6696Z"
        stroke="#6B7889"
        strokeWidth="1.94545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2335 1.07861C8.17493 1.07861 1.64258 7.61096 1.64258 15.6695C1.64258 23.7281 8.17493 30.2604 16.2335 30.2604C24.292 30.2604 30.8244 23.7281 30.8244 15.6695C30.8244 7.61096 24.292 1.07861 16.2335 1.07861Z"
        stroke="#6B7889"
        strokeWidth="1.94545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProfileDropdownIcon
