import React from "react"

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        d="M13.0468 12.9199V14.9065C13.0468 15.4334 12.8375 15.9387 12.465 16.3113C12.0924 16.6839 11.5871 16.8932 11.0602 16.8932H3.1136C2.58671 16.8932 2.0814 16.6839 1.70883 16.3113C1.33626 15.9387 1.12695 15.4334 1.12695 14.9065V2.98665C1.12695 2.45976 1.33626 1.95445 1.70883 1.58188C2.0814 1.20931 2.58671 1 3.1136 1H10.6629C11.76 1 13.0468 1.88952 13.0468 2.98665V4.9733"
        stroke="#6B7889"
        strokeWidth="1.29697"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2246 12.9197L19.2207 9.92368C19.7604 9.38396 19.7604 8.50892 19.2207 7.96921L16.2246 4.97314"
        stroke="#6B7889"
        strokeWidth="1.29697"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.68945 8.94629H19.404"
        stroke="#6B7889"
        strokeWidth="1.29697"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LogoutIcon
