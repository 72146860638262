import React, { useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import axios from "axios"
import { tagOptions } from "helpers/helper_functions"

import configs from "../../../config"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { useSelector } from "react-redux"
const { Url, apiVersion } = configs.client

const customStyles = {
  menuList: provided => ({
    ...provided,
    maxHeight: 150,
    overflowY: "auto",
    backgroundColor: "white",
  }),
}

function CreateNewNote({
  access,
  params,
  createNewNoteModal,
  toggleCreateNewNoteModal,
  fetchNotesData,
}) {
  const { authorized } = useSelector(state => ({
    authorized: state.Login.authorized,
  }))

  const [formikInitialValues, setFormikInitialValues] = useState({
    title: "",
    body: "",
    tag: [],
  })

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Note title is required"),
      body: Yup.string().required("Note body is required"),
    }),
    onSubmit: values => {
      toggleCreateNewNoteModal()
      sendNewNote(values)
    },
  })

  const sendNewNote = data => {
    axios
      .post(`${Url}/${apiVersion}/chats/${params.id}/notes`, data, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        fetchNotesData()
      })
      .catch(err => {
        if (err.response?.status == 401 || err.response?.status == 0) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })

    // setCurNote("")
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <Modal
      size="lg"
      isOpen={createNewNoteModal}
      toggle={toggleCreateNewNoteModal}
      centered
      onClick={e => e.stopPropagation()}
    >
      <ModalHeader toggle={toggleCreateNewNoteModal}>Add New Note</ModalHeader>
      <ModalBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={7}>
              <Label htmlFor="note-title">Note Title</Label>
              <Input
                type="text"
                name="title"
                className="form-control"
                id="note-title"
                placeholder="Enter Your Note Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.title && formik.errors.title ? true : false
                }
              />
              {formik.touched.title && formik.errors.title ? (
                <FormFeedback>{formik.errors.title}</FormFeedback>
              ) : null}

              <Label htmlFor="note-body" className="mt-3">
                Note
              </Label>
              <Input
                type="textarea"
                name="body"
                className="form-control"
                id="note-body"
                placeholder="Enter Your Note Body"
                rows="6"
                style={{ resize: "vertical" }}
                value={formik.values.body}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.body && formik.errors.body ? true : false
                }
              />
              {formik.touched.body && formik.errors.body ? (
                <FormFeedback>{formik.errors.body}</FormFeedback>
              ) : null}
            </Col>
            <Col md={5}>
              <div>
                <h5>Select Tags</h5>
                <Select
                  isMulti
                  name="tag"
                  options={tagOptions}
                  styles={customStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={formik.values.tag.map(value =>
                    tagOptions.find(option => option.value === value)
                  )}
                  onChange={selectedOptions => {
                    const selectedValues = selectedOptions.map(
                      option => option.value
                    )
                    formik.setFieldValue("tag", selectedValues)
                  }}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
        <Button
          color="secondary"
          onClick={formik.handleSubmit}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Add
        </Button>{" "}
        <Button
          color="secondary"
          outline
          onClick={toggleCreateNewNoteModal}
          style={{ padding: "0.1875rem 0.875rem" }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateNewNote
