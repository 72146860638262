import React from "react"

function NewTabIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        d="M4.79439 9.375V0H6.3853V9.375H4.79439ZM0.902344 5.48295V3.89205H10.2773V5.48295H0.902344Z"
        fill="#7E8998"
      />
    </svg>
  )
}

export default NewTabIcon
