import React from "react"
import { DropdownButton } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import { Col, Container, Row } from "reactstrap"

export default function TemplateHeaderVar({
  selectedOptions,
  selectNumber,
  selectFileColumn,
  headerVar,
  selectedColumnHeader,
  isSubmitted,
  columns,
  setSelectedColumnHeader,
}) {
  return (
    <Row>
      {headerVar && (
        <Container>
          <Container className="input-group input-group-sm my-1 d-flex justify-content-around gap-1 ">
            <Col
              style={{
                color: "#2a3042",
                borderRadius: "5px",
                fontWeight: "bold",
                background: "white",
              }}
              className=" d-flex justify-content-center align-items-center col-2"
              id="inputGroup-sizing-sm"
            >
              {"{{1}}"}
            </Col>

            <Col>
              <DropdownButton
                name="headerVarsValue"
                className=" dropdown-toggle-temp-preview-var  border rounded-3 "
                style={{
                  background: "white",
                }}
                title={
                  <>
                    <div
                      style={{
                        backgroundColor: "white",
                        width: "40vw",
                        color: "#2a3042",
                      }}
                      className=" d-flex justify-content-between align-items-center p-1"
                    >
                      <p
                        style={{
                          color: selectedColumnHeader ? "black" : "#ACB4BF",
                        }}
                        className="mb-0 "
                      >
                        {selectedColumnHeader || "Choose the respective column"}
                      </p>

                      <i
                        style={{
                          color: "rgb(172, 180, 191)",
                        }}
                        className="fas fa-chevron-down "
                      ></i>
                    </div>
                  </>
                }
              >
                {columns.map((column, index) => (
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedColumnHeader(column)
                    }}
                    disabled={
                      selectedOptions.includes(column) ||
                      selectNumber.includes(column) ||
                      (selectFileColumn && selectFileColumn.includes(column))
                    }
                    className="DropdownItem  "
                    key={column}
                  >
                    {column}
                  </Dropdown.Item>
                ))}{" "}
              </DropdownButton>
            </Col>
          </Container>
          {headerVar && !selectedColumnHeader && isSubmitted && (
            <>
              <Row>
                {" "}
                <p
                  style={{
                    direction: "rtl",
                    textAlign: "right",
                  }}
                  className="text-danger mb-0"
                >
                  required
                </p>
              </Row>
            </>
          )}
        </Container>
      )}
    </Row>
  )
}
