import React from "react"

export default function TableEditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      className="action-svg action-svg-edit"
    >
      <path
        d="M14.75 4.2813L18.5 8.0313M12.25 21.7813H22.25M2.25 16.7813L1 21.7813L6 20.5313L20.4825 6.0488C20.9512 5.57998 21.2145 4.94421 21.2145 4.2813C21.2145 3.61839 20.9512 2.98262 20.4825 2.5138L20.2675 2.2988C19.7987 1.83012 19.1629 1.56683 18.5 1.56683C17.8371 1.56683 17.2013 1.83012 16.7325 2.2988L2.25 16.7813Z"
        stroke="#8D9EB5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
