import React, { useEffect, useState } from "react"
import { Form, Formik, FieldArray, ErrorMessage, Field } from "formik"
import {
  Col,
  Input,
  Label,
  Row,
  Spinner,
  Button,
  FormFeedback,
} from "reactstrap"
import { PhoneInput } from "react-international-phone"
import SendSingleFileForEachComponent from "./SendSingleFileForEachComponent"

import * as Yup from "yup"

let initialFormikValues

const generateValidationSchema = (
  headerVar,
  bodyVars,
  singleTemplateData,
  singleFileName
) => {
  const schemaFields = {
    number: Yup.string().min(11, "Invalid").required("Required"),
  }

  let headerLength

  singleTemplateData.components.map((component, idx) => {
    if (component.type == "HEADER") {
      if (component.example) {
        headerLength = component.text?.length - 5
      } else {
        headerLength = component.text?.length
      }
    }
  })

  if (headerVar) {
    schemaFields[headerVar] = Yup.string()
      .required("Required")
      .test(
        "header-length",
        `You have only ${60 - headerLength} characters available for use.`,
        function (value) {
          const combinedLength = (value ? value.length : 0) + headerLength
          return combinedLength <= 60
        }
      )
  }

  if (bodyVars && Array.isArray(bodyVars)) {
    bodyVars.map((bodyVar, idx) => {
      schemaFields[bodyVar] = Yup.string().required("Required")
    })
  }

  if (singleFileName == "") {
    singleTemplateData.components.map((component, idx) => {
      if (component.format === "DOCUMENT" || component.format === "IMAGE")
        return (schemaFields.fileName = Yup.string().required("Required"))
    })
  }

  initialFormikValues = Object.keys(schemaFields).reduce((acc, key) => {
    acc[key] = ""
    return acc
  }, {})

  return Yup.object().shape({
    formValues: Yup.array().of(Yup.object().shape(schemaFields)),
  })
}

function EnterPhoneDataComponent({
  data,
  handleSendingAllData,
  setData,
  setCountryCode,
  countryOptions,
  headerVar,
  bodyVars,
  singleFileName,
  singleTemplateData,
  setSelectedFile,
  handleSendingEachFile,
  fileForEachLoading,
  setFileForEachLoading,
  selectedFile,
  setTempObject,
  tempObject,
  toggleModal,
  setFormValuesArr,
}) {
  let validationSchema = generateValidationSchema(
    headerVar,
    bodyVars,
    singleTemplateData,
    singleFileName
  )

  useEffect(() => {
    headerVar &&
      setData(prevData => ({
        ...prevData,
        clients: prevData.clients.map((client, index) =>
          index === 0 ? { ...client, [headerVar]: "" } : client
        ),
      }))

    bodyVars &&
      setData(prevData => ({
        ...prevData,
        clients: prevData.clients.map((client, index) => {
          if (index === 0) {
            let updatedClient = { ...client, fileName: "", number: "" }

            bodyVars.forEach((bodyVar, idx) => {
              updatedClient[bodyVar] = ""
            })
            return updatedClient
          }
          return client
        }),
      }))
  }, [headerVar, bodyVars])

  useEffect(() => {
    setTempObject(data.clients[0])
  }, [data])

  useEffect(() => {
    validationSchema = generateValidationSchema(
      headerVar,
      bodyVars,
      singleTemplateData,
      singleFileName
    )
  }, [singleFileName])

  return (
    <div>
      <Formik
        initialValues={{ formValues: [initialFormikValues] }}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values, { setSubmitting }) => {
          setFormValuesArr(values.formValues)
          toggleModal()
          // handleSendingAllData(values.formValues)
        }}
      >
        {({ errors, touched, setFieldValue, values, isSubmitting }) => (
          <Form>
            <FieldArray
              name="formValues"
              render={arrayHelpers => (
                <>
                  {Array.isArray(values.formValues) &&
                    values.formValues.map((client, clientIdx) => {
                      return (
                        <div
                          key={clientIdx}
                          className="d-flex mt-3 user-fields-conatiner"
                        >
                          <div
                            className="flex-grow-0 text-center fileds-order"
                            style={{ width: "10%", fontSize: "24px" }}
                          >
                            {clientIdx + 1}.
                          </div>

                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center row flex-row">
                              <div className="col-6">
                                <PhoneInput
                                  defaultCountry="sa"
                                  countries={countryOptions}
                                  placeholder="Enter phone number"
                                  value={data.clients[clientIdx]?.name}
                                  onChange={countryCode => {
                                    setCountryCode(countryCode)

                                    setFieldValue(
                                      `formValues[${clientIdx}].number`,
                                      countryCode.replace("+", "")
                                    )

                                    setData({
                                      ...data,
                                      clients: data.clients.map(
                                        (client, index) => {
                                          if (index === clientIdx) {
                                            return {
                                              ...client,
                                              number: countryCode.replace(
                                                "+",
                                                ""
                                              ),
                                            }
                                          }
                                          return client
                                        }
                                      ),
                                    })
                                  }}
                                />
                              </div>

                              {data.clients.length > 1 && clientIdx > 0 && (
                                <div className="col-2">
                                  <i
                                    className="bx bx-trash bx-sm ms-1"
                                    onClick={() => {
                                      arrayHelpers.remove(clientIdx)
                                    }}
                                  ></i>
                                </div>
                              )}

                              <div
                                id="insert-form-array-element"
                                className="d-none"
                                onClick={() => {
                                  arrayHelpers.insert(
                                    values.formValues.length,
                                    initialFormikValues
                                  )
                                }}
                              >
                                add
                              </div>
                            </div>

                            {errors.formValues &&
                            errors.formValues[clientIdx] &&
                            errors.formValues[clientIdx].number &&
                            ((touched.formValues &&
                              touched.formValues[clientIdx] &&
                              touched.formValues[clientIdx].number) ||
                              isSubmitting) ? (
                              <div
                                className="error"
                                style={{ marginLeft: "80px" }}
                              >
                                {errors.formValues[clientIdx].number}
                              </div>
                            ) : null}

                            <Row>
                              {headerVar && (
                                <Col sm={6}>
                                  <div className="">
                                    <div>
                                      <div className="input-group mt-3">
                                        <Label
                                          className="input-group-text me-2"
                                          htmlFor={headerVar + clientIdx}
                                        >
                                          {singleTemplateData.components[0]
                                            ?.example?.header_text &&
                                          singleTemplateData.components[0]
                                            ?.example?.header_text[0]
                                            ? `1`
                                            : `0`}
                                        </Label>

                                        <Field
                                          name={`formValues[${clientIdx}].${headerVar}`}
                                          render={({ field }) => (
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id={`${headerVar}-${clientIdx}`}
                                              {...field}
                                              onChange={headerValue => {
                                                setFieldValue(
                                                  `formValues[${clientIdx}].${headerVar}`,
                                                  headerValue.target.value
                                                )

                                                setData({
                                                  ...data,
                                                  clients: data.clients.map(
                                                    (c, index) => {
                                                      if (index === clientIdx) {
                                                        return {
                                                          ...c,
                                                          [headerVar]:
                                                            headerValue.target
                                                              .value,
                                                        }
                                                      }
                                                      return c
                                                    }
                                                  ),
                                                })
                                              }}
                                            />
                                          )}
                                        />
                                      </div>

                                      {errors.formValues &&
                                      errors.formValues[clientIdx] &&
                                      errors.formValues[clientIdx][headerVar] &&
                                      ((touched.formValues &&
                                        touched.formValues[clientIdx] &&
                                        touched.formValues[clientIdx][
                                          headerVar
                                        ]) ||
                                        isSubmitting) ? (
                                        <div
                                          className="error"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          {
                                            errors.formValues[clientIdx][
                                              headerVar
                                            ]
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              )}

                              {bodyVars && (
                                <FieldArray name="bodyVarsValues">
                                  {({ remove, push }) =>
                                    bodyVars.map((bodyVarInput, idx) => (
                                      <Col key={idx} sm={6}>
                                        <div className="">
                                          <div>
                                            <div className="input-group mt-3">
                                              <Label
                                                className="input-group-text me-2"
                                                htmlFor={
                                                  bodyVarInput + clientIdx
                                                }
                                              >
                                                {singleTemplateData
                                                  .components[0]?.example
                                                  ?.header_text &&
                                                singleTemplateData.components[0]
                                                  ?.example?.header_text[0]
                                                  ? `${idx + 2}`
                                                  : `${idx + 1}`}
                                              </Label>

                                              <Field
                                                name={`formValues[${clientIdx}].${bodyVarInput}`}
                                                render={({ field }) => (
                                                  <Input
                                                    type="text"
                                                    className="form-control"
                                                    id={`${bodyVarInput}-${clientIdx}`}
                                                    {...field}
                                                    onChange={bodyValue => {
                                                      setFieldValue(
                                                        `formValues[${clientIdx}].${bodyVarInput}`,
                                                        bodyValue.target.value
                                                      )

                                                      setData({
                                                        ...data,
                                                        clients:
                                                          data.clients.map(
                                                            (c, index) => {
                                                              if (
                                                                index ===
                                                                clientIdx
                                                              ) {
                                                                return {
                                                                  ...c,
                                                                  [bodyVarInput]:
                                                                    bodyValue
                                                                      .target
                                                                      .value,
                                                                }
                                                              }
                                                              return c
                                                            }
                                                          ),
                                                      })
                                                    }}
                                                  />
                                                )}
                                              />
                                            </div>

                                            {errors.formValues &&
                                            errors.formValues[clientIdx] &&
                                            errors.formValues[clientIdx][
                                              bodyVarInput
                                            ] &&
                                            ((touched.formValues &&
                                              touched.formValues[clientIdx] &&
                                              touched.formValues[clientIdx][
                                                bodyVarInput
                                              ]) ||
                                              isSubmitting) ? (
                                              <div
                                                className="error"
                                                style={{ marginLeft: "80px" }}
                                              >
                                                {
                                                  errors.formValues[clientIdx][
                                                    bodyVarInput
                                                  ]
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>
                                    ))
                                  }
                                </FieldArray>
                              )}

                              {!singleFileName &&
                                singleTemplateData.components.map(
                                  (component, idx) => {
                                    if (
                                      component.format === "DOCUMENT" ||
                                      component.format === "IMAGE"
                                    )
                                      return (
                                        <Col key={idx} sm={12}>
                                          <div className="">
                                            <div>
                                              <div className="input-group mt-3 d-flex align-items-center">
                                                <Label
                                                  className="input-group-text me-2"
                                                  htmlFor={
                                                    "inputGroupFile" + clientIdx
                                                  }
                                                >
                                                  {component.format ===
                                                  "DOCUMENT"
                                                    ? "doc"
                                                    : "img"}
                                                </Label>

                                                <Input
                                                  type="file"
                                                  name={`formValues[${clientIdx}].fileName`}
                                                  accept={
                                                    component.format ===
                                                    "DOCUMENT"
                                                      ? ".doc, .pdf, .csv, .xsls"
                                                      : "image/*"
                                                  }
                                                  className="form-control"
                                                  id={
                                                    "inputGroupFile" +
                                                    +clientIdx
                                                  }
                                                  onChange={e => {
                                                    e.preventDefault()

                                                    setSelectedFile(
                                                      e.target.files[0]
                                                    )
                                                  }}
                                                />

                                                <SendSingleFileForEachComponent
                                                  key={clientIdx}
                                                  setFieldValue={setFieldValue}
                                                  data={data}
                                                  selectedFile={selectedFile}
                                                  setData={setData}
                                                  setFileForEachLoading={
                                                    setFileForEachLoading
                                                  }
                                                  fileForEachLoading={
                                                    fileForEachLoading
                                                  }
                                                  handleSendingEachFile={
                                                    handleSendingEachFile
                                                  }
                                                  setSelectedFile={
                                                    setSelectedFile
                                                  }
                                                  clientIdx={clientIdx}
                                                />
                                              </div>

                                              {errors.formValues &&
                                              errors.formValues[clientIdx] &&
                                              errors.formValues[clientIdx]
                                                .fileName &&
                                              ((touched.formValues &&
                                                touched.formValues[clientIdx] &&
                                                touched.formValues[clientIdx]
                                                  .fileName) ||
                                                isSubmitting) ? (
                                                <div
                                                  className="error"
                                                  style={{ marginLeft: "80px" }}
                                                >
                                                  {
                                                    errors.formValues[clientIdx]
                                                      .fileName
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                  }
                                )}
                            </Row>
                          </div>
                        </div>
                      )
                    })}
                </>
              )}
            />

            <button
              id="formEnterPhoneNumbers"
              type="submit"
              color="primary"
              className="mt-3 d-none"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EnterPhoneDataComponent
