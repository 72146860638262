import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  changeSelectedOverview,
  updateChatStatus,
  updateChatsType,
} from "store/actions"
import AdminTeamsDropdownContainer from "./AdminTeamsDropdownContainer"
import { Spinner } from "reactstrap"

function Overview({ overview, sessionLoading }) {
  const {
    userData,
    chatStatus,
    chatsType,
    currentOverView,
    userSessions,
    teamSessions,
  } = useSelector(state => ({
    userData: state.Login.userData,
    chatStatus: state.Login.chatStatus,
    chatsType: state.Login.chatsType,
    currentOverView: state.Login.currentOverView,
    userSessions: state.Login.userSessions,
    teamSessions: state.Login.teamSessions,
  }))

  const dispatch = useDispatch()
  const [selectedStatuses, setSelectedStatuses] = useState([])

  const handleStatusClick = status => {
    let updatedStatuses = [...selectedStatuses]

    if (status === "all") {
      updatedStatuses = ["all"]
    } else {
      if (updatedStatuses.includes(status)) {
        updatedStatuses = updatedStatuses.filter(s => s !== status)
        if (updatedStatuses.length === 0) {
          updatedStatuses = ["all"]
        }
      } else {
        updatedStatuses = updatedStatuses.filter(s => s !== "all")
        updatedStatuses.push(status)
      }
    }

    if (overview !== currentOverView) {
      setSelectedStatuses([status])
    } else {
      setSelectedStatuses(updatedStatuses)
    }

    if (overview !== currentOverView) {
      dispatch(
        changeSelectedOverview(
          overview,
          status,
          overview === "My Overview" ? "userChats" : "teamChats"
        )
      )
    } else {
      dispatch(updateChatStatus(updatedStatuses.join(",")))
    }

    if (chatStatus === "") {
      dispatch(updateChatStatus(updatedStatuses.join(",")))
      dispatch(
        updateChatsType(overview === "My Overview" ? "userChats" : "teamChats")
      )
    }
  }

  useEffect(() => {}, [chatStatus, chatsType])

  return (
    <div className="overview-container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>{overview}</h4>

        <AdminTeamsDropdownContainer overview={overview} />
      </div>

      <div className="d-flex flex-column align-items-stretch filters">
        <button
          className={`d-flex justify-content-between align-items-center inbox ${
            currentOverView === overview &&
            chatStatus?.split(",")?.includes("all") &&
            "active-inbox"
          }`}
          onClick={() => handleStatusClick("all")}
        >
          <span>inbox</span>
          <span className={`number ${sessionLoading ? "loading" : ""}`}>
            {sessionLoading ? (
              <Spinner color="secondary" size="sm" type="grow">
                Loading...
              </Spinner>
            ) : overview === "My Overview" ? (
              userSessions.split(",")[0]
            ) : (
              teamSessions.split(",")[0]
            )}
          </span>
        </button>

        <div className="d-flex flex-wrap filters-btns">
          <button
            className={`d-flex justify-content-between align-items-center too-late ${
              currentOverView === overview &&
              chatStatus?.split(",")?.includes("tooLate") &&
              "active-too-late"
            }`}
            onClick={() => handleStatusClick("tooLate")}
          >
            <span>too late</span>
            <span className={`number ${sessionLoading ? "loading" : ""}`}>
              {sessionLoading ? (
                <Spinner color="secondary" size="sm" type="grow">
                  Loading...
                </Spinner>
              ) : overview === "My Overview" ? (
                userSessions.split(",")[3]
              ) : (
                teamSessions.split(",")[3]
              )}
            </span>
          </button>

          <button
            className={`d-flex justify-content-between align-items-center danger ${
              currentOverView === overview &&
              chatStatus?.split(",")?.includes("danger") &&
              "active-danger"
            }`}
            onClick={() => handleStatusClick("danger")}
          >
            <span>danger</span>
            <span className={`number ${sessionLoading ? "loading" : ""}`}>
              {sessionLoading ? (
                <Spinner color="secondary" size="sm" type="grow">
                  Loading...
                </Spinner>
              ) : overview === "My Overview" ? (
                userSessions.split(",")[2]
              ) : (
                teamSessions.split(",")[2]
              )}
            </span>
          </button>

          <button
            className={`d-flex justify-content-between align-items-center on-time ${
              currentOverView === overview &&
              chatStatus?.split(",")?.includes("onTime") &&
              "active-on-time"
            }`}
            onClick={() => handleStatusClick("onTime")}
          >
            <span>on time</span>
            <span className={`number ${sessionLoading ? "loading" : ""}`}>
              {sessionLoading ? (
                <Spinner color="secondary" size="sm" type="grow">
                  Loading...
                </Spinner>
              ) : overview === "My Overview" ? (
                userSessions.split(",")[1]
              ) : (
                teamSessions.split(",")[1]
              )}
            </span>
          </button>

          <button
            className={`d-flex justify-content-between align-items-center open ${
              currentOverView === overview &&
              chatStatus?.split(",")?.includes("open") &&
              "active-open"
            }`}
            onClick={() => handleStatusClick("open")}
          >
            <span>open</span>
            <span className={`number ${sessionLoading ? "loading" : ""}`}>
              {sessionLoading ? (
                <Spinner color="secondary" size="sm" type="grow">
                  Loading...
                </Spinner>
              ) : overview === "My Overview" ? (
                userSessions.split(",")[4]
              ) : (
                teamSessions.split(",")[4]
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Overview
