import React from "react"
import { Container, Row, Col } from "reactstrap"
// import "./Footer.scss" // Import the SCSS styles

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="footer-content">
          <Col>
            <div className="copyright">
              <span className="copyright-year">2024 &copy; DomApp.</span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
