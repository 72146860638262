import FilterIcon from "components/Common/Icons/SettingIcons/FilterIcon"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { useSelector } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import ResetFiltersIcon from "components/Common/Icons/SettingIcons/ResetFiltersIcon"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
export default function FilterUsers({
  selectedTeam,
  setSelectedTeam,
  handleShowResetFiters,
  setSearchName,
  searchName,
  handleResetFilters,
}) {
  const dispatch = useDispatch()

  //  fetch team
  useEffect(() => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const Allteams = res.data.data.teams.map(team => ({
          value: team._id,
          label: team.name,
        }))
        // setTeams(res.data.data.teams)
        setTeams(Allteams)
      })
      .catch(error => {
        if (error?.response?.status === 401 || error?.response?.status === 0) {
          dispatch(unAuthUser())
        } else {
          console.log(error)
        }
      })
  }, [])

  const [teams, setTeams] = useState([])

  const { access } = useSelector(state => ({
    access: state.Login.userAccess,
  }))

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "0",
      borderRadius: "0.5rem",
      padding: "0rem 1rem",
      fontSize: "1rem",
      width: "20rem",
      // minHeight: "3rem",
      height: "3rem",
      // flex: "1",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "white",
      backgroundColor: state.isFocused ? "#EBEDF0" : "white",
      color: state.isSelected ? "gray" : "black",
      fontSize: "1rem",
    }),
  }

  return (
    <>
      <div className=" d-flex gap-3 justify-content-between mb-2 align-items-center ">
        <input
          value={searchName}
          type="search"
          placeholder=" Search by Name"
          className="filter-user-input"
          onChange={e => setSearchName(e.target.value)}
        />

        <Select
          id="user-team"
          name="team"
          value={selectedTeam}
          onChange={setSelectedTeam}
          options={teams}
          styles={customStyles}
          isClearable
        ></Select>

        {handleShowResetFiters ? (
          <button onClick={handleResetFilters} className="reset-filters d-flex">
            <ResetFiltersIcon />
          </button>
        ) : null}

        {/* <input type="search" placeholder="Search by Team" /> */}
      </div>
    </>
  )
}
