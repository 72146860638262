// PaginationComponent.js
import React, { useState } from "react"
import { Col, PaginationItem, PaginationLink } from "reactstrap"

const PaginationComponent = ({ currentPage, totalPages, setCurrentPage }) => {
  //===================================================
  const [inputPage, setInputPage] = useState("")

  // handle input enter:
  const handleInputEnter = event => {
    if (event.key === "Enter") {
      const pageNumber = Number(inputPage)
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
      } else {
        alert(`Please enter a number between 1 and ${totalPages}`)
      }
      setInputPage("")
    }
  }
  // handle input change:
  const handleInputChange = event => {
    setInputPage(event.target.value)
  }
  //   ===========================================
  //update current page:
  const handlePageClick = pageNumber => {
    setCurrentPage(pageNumber)
  }

  //create array of page numbers:
  let pagesArray = []
  for (let i = 0; i < totalPages; i++) {
    pagesArray.push(i + 1)
  }
  pagesArray = pagesArray.filter(
    (item, index) =>
      item === currentPage ||
      item === currentPage - 1 ||
      item === currentPage - 2 ||
      item === currentPage + 1 ||
      item === currentPage + 2 ||
      item === 1 ||
      item === totalPages
  )

  return (
    <Col xs="12">
      <div className=" mt-3 d-flex justify-content-center align-items-center">
        <ul className="pagination pagination-rounded justify-content-center m-0 ">
          {/* previous page */}
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink
              previous
              href="#"
              onClick={() => handlePageClick(currentPage - 1)}
            />
          </PaginationItem>
          {/* all page numbers */}
          {pagesArray.map((item, i) => (
            <div className="d-flex" key={i}>
              {item === totalPages &&
                pagesArray[i - 1] !== totalPages - 1 &&
                totalPages !== 1 && (
                  <PaginationItem disabled>
                    <PaginationLink>
                      <div style={{ fontSize: "16px", marginTop: "-25%" }}>
                        ...
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                )}
              {/* selected page number */}
              <PaginationItem active={item === currentPage}>
                <PaginationLink onClick={() => handlePageClick(item)} href="#">
                  {item}
                </PaginationLink>
              </PaginationItem>
              {item === 1 && pagesArray[i + 1] !== 2 && totalPages !== 1 && (
                <PaginationItem disabled>
                  <PaginationLink>
                    <div style={{ fontSize: "16px", marginTop: "-25%" }}>
                      ...
                    </div>
                  </PaginationLink>
                </PaginationItem>
              )}
            </div>
          ))}
          {/* next page */}
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink
              next
              href="#"
              onClick={() => handlePageClick(currentPage + 1)}
            />
          </PaginationItem>
        </ul>
        {/* input for page number */}
        {/* <div className="d-flex justify-content-center ">
          <input
            type="number"
            value={inputPage}
            onChange={handleInputChange}
            onKeyPress={handleInputEnter}
            placeholder="Go to page..."
            min="1"
            max={totalPages}
            // style={{ width: "100px", textAlign: "center" }}
          />
        </div> */}
      </div>
    </Col>
  )
}

export default PaginationComponent
