import React from "react"
import "./StepperComponent.css"

function StepperComponent({ step }) {
  const steps = [
    { stepNumber: 1, description: "Choose a Template" },
    {
      stepNumber: 2,
      description: "Preview the Template & Enter Phone Number ",
    },
    { stepNumber: 3, description: "Finished" },
  ]

  return (
    <div className="steps d-flex flex-column gap-5">
      {steps.map(stepItem => (
        <div key={stepItem.stepNumber} className="d-flex gap-3 step">
          <div
            className={`d-flex justify-content-center align-items-center ${
              step === stepItem.stepNumber
                ? "active-step"
                : step > stepItem.stepNumber
                ? "completed-step"
                : "normal-step"
            }`}
          >
            {step === stepItem.stepNumber ? (
              stepItem.stepNumber !== steps.length ? (
                stepItem.stepNumber
              ) : (
                <div className="completed-step">
                  <i className="fas fa-check"></i>
                </div>
              )
            ) : step > stepItem.stepNumber ? (
              <div className="completed-step">
                <i className="fas fa-check"></i>
              </div>
            ) : (
              stepItem.stepNumber
            )}
          </div>

          <div className="m-0 d-flex flex-column gap-4">
            <h5>{stepItem.description}</h5>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StepperComponent
