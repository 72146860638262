import React from "react"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useNavigate } from "react-router-dom"

function CreateNewChat({ createChatModal, toggleCreateChatModal }) {
  const navigate = useNavigate()

  const initialValues = {
    chatNumber: "",
  }

  const validationSchema = Yup.object().shape({
    chatNumber: Yup.string()
      .matches(
        /^\d{10,}$/,
        "Chat number must be at least 10 digits long and contain only numbers"
      )
      .required("Chat number is required"),
  })

  const handleSubmit = (values, { resetForm }) => {
    const { chatNumber } = values
    navigate(`/chat/${chatNumber}`)
    toggleCreateChatModal()
    resetForm()
  }

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    const numericRegex = /^[0-9]+$/

    if (!numericRegex.test(keyValue) && keyCode !== 13) {
      event.preventDefault()
    }
  }

  return (
    <Modal
      isOpen={createChatModal}
      toggle={toggleCreateChatModal}
      onOpened={() => document.getElementById("chatNumber").focus()}
    >
      <ModalHeader toggle={toggleCreateChatModal}>
        Create a new chat
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, touched, errors }) => (
          <Form>
            <ModalBody>
              <div className="form-group">
                <label htmlFor="chatNumber">Chat Number</label>
                <Field
                  type="text"
                  id="chatNumber"
                  name="chatNumber"
                  className={`form-control ${
                    errors.chatNumber ? "is-invalid" : ""
                  }`}
                  placeholder="Enter a new number"
                  autoComplete="off"
                  onKeyPress={handleKeyPress}
                />

                {errors.chatNumber && (
                  <div className="text-danger">{errors.chatNumber}</div>
                )}
              </div>
            </ModalBody>

            <ModalFooter style={{ borderTop: "0", paddingTop: "0" }}>
              <Button
                color="secondary"
                type="submit"
                style={{ padding: "0.1875rem 0.875rem" }}
              >
                Create
              </Button>{" "}
              <Button
                color="secondary"
                outline
                onClick={() => {
                  toggleCreateChatModal()
                }}
                style={{ padding: "0.1875rem 0.875rem" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateNewChat
